import React, { FC, memo, ReactNode, useMemo } from 'react';

import { Checkbox, generateId, useId } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';
import { Selected } from './useSelected';

type ColumnType = {
  title: string | React.ElementType | JSX.Element;
  style?: React.CSSProperties;
  className?: string;
};

export type ColumnsType = ColumnType[];

interface FilesTableProps extends React.HTMLAttributes<HTMLTableElement> {
  columns: ColumnsType;
  children: ReactNode;
  selected?: Selected;
  tableClassName?: string;
}

export const FilesTable: FC<FilesTableProps> = memo(({ columns, children, selected, tableClassName, ...rest }) => {
  const id = useId();
  const innerColumns = useMemo<({ key: string } & ColumnType)[]>(() => {
    return columns.map(column => ({
      key: `${typeof column.title === 'string' ? column.title : generateId()}'}`,
      ...column,
    }));
  }, [columns]);
  return (
    <table className={cn(styles.table, tableClassName)} {...rest}>
      <thead>
        <tr>
          {selected && (
            <th className={styles.th} style={{ width: 40, transform: 'translateY(-3px)' }}>
              <Checkbox
                name={`checkbox${id}`}
                className={cn({ [styles.partialSelect]: !selected.isCleared && !selected.isAllSelected })}
                value={selected.isAllSelected || !selected.isCleared}
                onChange={() => selected.allHandler()}
              />
            </th>
          )}
          {innerColumns.map(column => (
            <th className={cn(styles.th, column.className)} key={column.key} style={column.style}>
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
});
