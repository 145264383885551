import React from 'react';

import { Button } from '@just-ai/just-ui';
import cn from 'classnames';
import { getCurrentAccountData, currentUserInvitations } from 'models/currentUser';

import { selectAccountButtonLocalization } from './localization/selectAccountButton.loc';
import styles from './styles.module.scss';
import { goToSelectAccount } from '../../../../api/cc.api';
import localize, { t } from '../../../../localization';

localize.addTranslations(selectAccountButtonLocalization);

export const SelectAccountButton = () => {
  return (
    <div className={styles.SelectAccount}>
      <Button
        outline
        color='secondary'
        className={cn(styles.SelectAccount__button, { [styles.notification]: !!currentUserInvitations.value?.length })}
        iconRight='farArrowRight'
        onClick={goToSelectAccount}
        data-test-id='goToAccountSelect'
      >
        <span className={styles.SelectAccount__text}>
          {t('SelectAccount', { currentAccountName: getCurrentAccountData()?.name })}
        </span>
      </Button>
    </div>
  );
};
