import React, { MutableRefObject, KeyboardEvent, useState, useEffect } from 'react';

import { IconButton, InputText } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from '../header.module.scss';

type Props = {
  name: string;
  isRenameable: boolean;
  isRenaming: boolean;
  onStartRenaming: () => void;
  onConfirmRenaming: (confirmedName: string) => void;
  renamingValueRef: MutableRefObject<string>;
};

export default function ChatName({
  name,
  isRenameable,
  isRenaming,
  onStartRenaming: handleStartRenaming,
  onConfirmRenaming: handleConfirmRenaming,
  renamingValueRef,
}: Props) {
  const [inputValue, setInputValue] = useState(name);

  useEffect(() => {
    renamingValueRef.current = inputValue;
  }, [inputValue, renamingValueRef]);

  useEffect(() => {
    if (!isRenaming) {
      // The input value will always be prepared at the start of the renaming action,
      // but it will be not changed by updates during the user's input.
      setInputValue(name);
    }
  }, [isRenaming, name]);

  const handleConfirmByKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter' || !inputValue.trim().length) {
      return;
    }
    e.preventDefault();
    handleConfirmRenaming(inputValue);
  };

  if (isRenaming) {
    return (
      <InputText
        className={cn('flex-1', styles.editInput)}
        data-test-id='Chat.name_input'
        value={inputValue}
        onChange={value => setInputValue(value)}
        onKeyDown={handleConfirmByKey}
        autoFocus
        maxLength={45}
      />
    );
  }

  return (
    <div className='flex flex-1 align-items-center gap-4 overflow-hidden'>
      <div data-test-id='Chat.name_static' className={cn('text-ellipsis color-gray-950', styles.header__name)}>
        <b className='text-truncate font-weight-500'>{name}</b>
      </div>
      {isRenameable ? (
        <IconButton
          className={cn(styles.header__icon, 'd-none d-md-flex')}
          data-test-id='Chat.name_btn_edit'
          outline
          name='farPen'
          onClick={handleStartRenaming}
        />
      ) : null}
    </div>
  );
}
