import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import { ResourceType } from '@just-ai/api/dist/generated/AppsAdapter';
import { Tabs, useTranslation } from '@just-ai/just-ui';

import { isX5 } from '../../isX5';

export const FilesTabs: FC<{
  tabs: { title: string; value: string }[];
  activeTab: string;
  onTabChange: (value: string) => void;
}> = memo(({ tabs, onTabChange, activeTab }) => {
  return (
    <Tabs
      className='mb-0'
      activeTab={activeTab}
      closePlace='right'
      name='FilesTabs'
      onChange={onTabChange}
      tabs={tabs.map(tab => {
        return {
          dataTestId: `FilesTabs:${tab.value}`,
          name: <span className='gray-600 d-flex gap-8'>{tab.title}</span>,
          value: tab.value,
        };
      })}
    />
  );
});

export const useTabs = (
  initialActiveTab: ResourceType
): [ResourceType, (name: string) => void, { title: string; value: string }[]] => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ResourceType>(initialActiveTab);

  const onTabChangeHandle = useCallback((name: string) => {
    setActiveTab(name as ResourceType);
  }, []);
  const tabs = useMemo(() => {
    if (isX5) {
      return [
        { title: t('Files:Tabs:GeneratedFiles'), value: ResourceType.AppFiles },
        { title: t('Files:Tabs:UsedLinks'), value: ResourceType.Links },
      ];
    }
    return [
      { title: t('Files:Tabs:GeneratedFiles'), value: ResourceType.AppFiles },
      { title: t('Files:Tabs:UploadedFiles'), value: ResourceType.UserFiles },
      { title: t('Files:Tabs:UsedLinks'), value: ResourceType.Links },
    ];
  }, [t]);

  return [activeTab, onTabChangeHandle, tabs];
};
