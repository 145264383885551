import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import { IconButton, useToggle } from '@just-ai/just-ui';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

import { TFavouriteElement, toggleFavourite } from '../../../models/favourite/favourite.api';
import { AgentType, CreatedAgent } from '../../../types/agents';
import { FieldConfig } from '../components/TemplateForm/useFormBuilder';
import { CreationFromFavouriteModal } from './CreationFromFavouriteModal';
import { DeleteFromFavouriteModal } from './DeleteFromFavouriteModal';
import { EditFavouriteModal } from './EditFavouriteModal';
import { hideDeleteFavoriteModal, showDeleteFavoriteModal, showEditFavoriteModal } from './favoriteButtons.control';
import styles from './style.module.scss';

export const FavoriteItem: FC<{ favTemplate: AgentType | CreatedAgent; favorite: TFavouriteElement }> = memo(
  ({ favorite, favTemplate }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOpenCreationModal, openCreationModal, closeCreationModal] = useToggle(false);
    const deleteFromFavoriteHandle = useCallback(async () => {
      setIsDeleting(true);
      try {
        await toggleFavourite({ appId: favorite.id, favorite: false });
      } finally {
        setIsDeleting(false);
        hideDeleteFavoriteModal();
      }
    }, [favorite.id]);

    const templateFields = useMemo((): FieldConfig[] => {
      return favTemplate.params
        ? Object.entries(favTemplate.params).map(([paramName, param]) => {
            const newParam = cloneDeep(param) as unknown as FieldConfig;
            // @ts-ignore
            newParam.name = paramName;
            newParam.value = favorite.params[paramName] || (typeof param !== 'object' ? param : param?.value);
            return newParam;
          })
        : [];
    }, [favTemplate.params, favorite.params]);

    return (
      <div className={cn(styles.templateList__favorite, 'relative')}>
        <div
          key={`template-${favTemplate.template}-${favorite.id}`}
          className={cn('h-100 w-100', styles.templateList__item)}
          data-test-id={favorite.name}
          onClick={openCreationModal}
        >
          {favTemplate.info && (
            <div>
              <div className='flex gap-8'>
                {favTemplate.info.icon}
                <b>{favorite.name || favTemplate.info.title}</b>
              </div>
              <p className='mt-2 padding-right-32'>{favorite.description || favTemplate.info.description}</p>
            </div>
          )}
        </div>
        <CreationFromFavouriteModal
          isOpen={isOpenCreationModal}
          templateFields={templateFields}
          favorite={favorite}
          closeCreationModal={closeCreationModal}
          template={favTemplate}
        />
        <div className={cn('flex flex-column', styles.templateList__item_buttons)}>
          <IconButton
            name='farPen'
            onClick={() => showEditFavoriteModal(favorite.id)}
            className={cn(styles.templateList__item_buttons_button, styles.templateList__item_buttons_button_edit)}
            data-test-id={`Favorite:btn:edit:${favTemplate.info.title}`}
          />
          <EditFavouriteModal
            favorite={favorite}
            templateFrom={{
              title: favTemplate.info.title,
              description: favTemplate.info.description,
            }}
            templateDefaultName={favorite.name || favTemplate.info.title}
            templateFields={templateFields}
          />
          <IconButton
            name='farTrashAlt'
            onClick={() => showDeleteFavoriteModal(favorite.id)}
            className={cn(styles.templateList__item_buttons_button, styles.templateList__item_buttons_button_delete)}
            data-test-id={`Favorite:btn:delete:${favTemplate.info.title}`}
          />
          <DeleteFromFavouriteModal
            favoriteId={favorite.id}
            isDeleting={isDeleting}
            deleteFromFavoriteHandle={deleteFromFavoriteHandle}
            favoriteName={favorite.name || favTemplate.info.title}
          />
        </div>
      </div>
    );
  }
);

FavoriteItem.displayName = 'memo(FavoriteItem)';
