import { useState } from 'react';

import { useTranslation } from '@just-ai/just-ui';

import useApiService from '../../../services/useApiService';
import { Conversation } from '../../../types/chat';
import { KeyValuePair } from '../../../types/data';
import { createDefaultMsg, processConversationContext, processHistory } from '../../../utils/app/conversation';

export default function useConversationResetAction({
  selectedConversation,
  submitConversationUpdate,
}: {
  selectedConversation: Conversation;
  submitConversationUpdate: (conversation: Conversation, data: KeyValuePair | KeyValuePair[]) => void;
}) {
  const { t } = useTranslation();

  const { clearChatHistory } = useApiService();
  const [isResetInProgress, setIsResetInProgress] = useState(false);
  const handleConfirmReset = async () => {
    setIsResetInProgress(true);
    const { data: response } = await clearChatHistory(selectedConversation.id);
    const processedHistory = processHistory(response.history);
    const defaultMsg = createDefaultMsg(
      [{ type: 'text', text: t(`prompt_${selectedConversation.app.template}`) }],
      selectedConversation.id,
      'system'
    );
    submitConversationUpdate(selectedConversation, [
      { key: 'history', value: processedHistory.length ? processedHistory : [defaultMsg] },
      { key: 'contextValue', value: processConversationContext(processedHistory) },
      { key: 'externalInstances', value: response.externalInstances },
      {
        key: 'isCanvasChat',
        value: !!response.externalInstances && Object.keys(response.externalInstances).length > 0,
      },
    ]);
    setIsResetInProgress(false);
  };

  return {
    isResetInProgress,
    handleConfirmReset,
  };
}
