import { signal } from '@preact/signals-react';

export const isEditShowModalOpen = signal('');
export const showEditFavoriteModal = (favoriteId: string) => (isEditShowModalOpen.value = favoriteId);
export const hideEditFavoriteModal = () => {
  isEditShowModalOpen.value = '';
};

export const isDeleteShowModalOpen = signal('');
export const showDeleteFavoriteModal = (favoriteId: string) => (isDeleteShowModalOpen.value = favoriteId);
export const hideDeleteFavoriteModal = () => (isDeleteShowModalOpen.value = '');
