import { ResourceSearchRequest, ResourceType } from '@just-ai/api/dist/generated/AppsAdapter';
import { computed, effect, signal } from '@preact/signals-react';
import { cloneDeep, merge } from 'lodash';
import { reduxConnectSignal } from 'utils/reduxConnector';

import { defaultDate } from './consts';
import { defaultAppFilesFilters, defaultLinkFilters, defaultUserFilesFilters } from '../../models/files/consts';
import { createImmerSignal } from '../../models/hooks';

export const isShowModalFileSelector = signal(false);

export const defaultFilters = cloneDeep({
  [ResourceType.UserFiles]: merge(cloneDeep(defaultUserFilesFilters), { filters: defaultDate() }),
  [ResourceType.AppFiles]: merge(cloneDeep(defaultAppFilesFilters), { filters: defaultDate() }),
  [ResourceType.Links]: merge(cloneDeep(defaultLinkFilters), { filters: defaultDate() }),
});

export const [filters, setFilters] = createImmerSignal<Record<ResourceType, ResourceSearchRequest>>(defaultFilters);

effect(() => {
  // if (filters.value[ResourceType.UserFiles]?.filters?.formats?.length) debugger;
});

export const dropFilters = () => {
  setFilters(cloneDeep(defaultFilters));
};

reduxConnectSignal(
  'Filters',
  computed(() => ({ filters: filters.value }))
);

export const setDefaultFilters = () => {
  setFilters(cloneDeep(defaultFilters));
};

export const setTabFilters = (
  resourceFilters: ResourceType,
  filters: {
    filters?: Partial<ResourceSearchRequest['filters']>;
    sort?: Partial<ResourceSearchRequest['sort']>;
    page?: ResourceSearchRequest['page'];
  }
) => {
  setFilters(prevFilters => {
    merge(prevFilters[resourceFilters], filters);
  });
};

export const setShowModalFileSelector = (filters: Partial<ResourceSearchRequest['filters']>) => {
  setFilters(prevFilters => {
    merge(prevFilters.appFiles.filters, filters);
    merge(prevFilters.userFiles.filters, filters);
    delete prevFilters.appFiles.filters.conversationId;
    delete prevFilters.userFiles.filters.conversationId;
  });
  isShowModalFileSelector.value = true;
};
export const closeShowModalFileSelector = () => {
  isShowModalFileSelector.value = false;
  dropFilters();
};

export const toggleSortDirection = (resourceFilters: ResourceType) => {
  setFilters(prevFilters => {
    prevFilters[resourceFilters].sort.direction =
      prevFilters[resourceFilters]?.sort?.direction === 'asc' ? 'desc' : 'asc';
  });
};

export const setFilterDates = (resourceFilters: ResourceType, dates: { startDate: Date; endDate: Date }) => {
  setFilters(prevFilters => {
    prevFilters[resourceFilters].filters.periodFrom = dates.startDate.toISOString();
    prevFilters[resourceFilters].filters.periodTo = dates.endDate.toISOString();
  });
};
