import React, { createContext, memo, useContext, useLayoutEffect } from 'react';

import { setZuserId } from '../api/client';
import localize from '../localization';
import { useTelegramTemplates } from '../models/templates';

const initialState = {
  userId: '',
  locale: 'eng',
};

const TelegramContext = createContext(initialState);

export const TelegramContextProvider = memo(({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const userId = params.get('id');
  const locale = params.get('locale') || 'eng';

  useLayoutEffect(() => {
    userId && setZuserId(userId);
    localize.setLocale(locale);
  }, [locale, userId]);

  const { loadingTemplates } = useTelegramTemplates(locale, true);

  if (!userId || loadingTemplates) return null;

  return (
    <TelegramContext.Provider
      value={{
        userId,
        locale,
      }}
    >
      {children}
    </TelegramContext.Provider>
  );
});

export const useTelegramContext = () => useContext(TelegramContext);
