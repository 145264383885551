import { useCallback, useEffect } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import { useSignalEffect } from '@preact/signals-react';
import { showModalFromChatInput } from 'components/Chat/signals/ChatUpdateSignal';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { useForm, UseFormReturn } from 'react-hook-form';

import { guideTourEvent$ } from '../../../../modules/GuideTourStepper/guideTourEvents';
import { AgentType, CreatedAgent } from '../../../../types/agents';

export type FieldConfig = {
  name: string;
  label?: string;
  hint?: string;
  dependsOn?: string;
  expression?: string;
  value?: any;
  required?: boolean;
  type?: string;
  options?: string | string[];
};

type FormBuilderLogicProps = {
  userId?: string;
  template: AgentType | CreatedAgent;
  submit: (config: any) => void;
  isEdit?: boolean;
  data?: unknown;
  cancel: () => unknown;
  isOpenFromTelegram: boolean;
};
export function useFormBuilder({
  template,
  userId,
  isEdit,
  isOpenFromTelegram,
  submit,
  cancel,
}: FormBuilderLogicProps) {
  const methods = useForm(isEdit ? { defaultValues: template.params } : undefined);

  const { handleSubmit: methodsHandleSubmit } = methods;

  const { t } = useTranslation();

  useEffect(() => {
    const sub = methods.watch((value, info) => {
      if (!info.name) return;
      guideTourEvent$.next(`ChangeField:${template.template}:${info.name}`);
    });
    return () => sub.unsubscribe();
  }, [methods, template.template]);

  const handleSubmit = useCallback(
    (formValues?: any) => {
      if (Object.keys(methods.formState.errors).length > 0) {
        window.Telegram?.WebApp.showAlert(t('TemplateFormInvalid'));
        return;
      }

      const config = Object.assign({ owner: userId }, { ...cloneDeep(template) }, { params: formValues });
      config.info.title = template.info.title || template.info.title || template.template;
      config.info.title = `${
        template?.info?.title || template?.info?.title || template?.template || 'Assistant'
      } - ${format(new Date(), 'HH:mm dd.MM')}`;
      guideTourEvent$.next(`TemplateForm:${isEdit ? 'Edit' : 'Create'}:${template.template}`);
      return submit(config);
    },
    [isEdit, methods, submit, t, template, userId]
  );

  const onClickHandler = useCallback(() => {
    window.Telegram?.WebApp.MainButton.disable();
    methodsHandleSubmit(handleSubmit, () => window.Telegram?.WebApp.MainButton.enable())();
  }, [handleSubmit, methodsHandleSubmit]);

  useSignalEffect(() => {
    if (showModalFromChatInput.value) {
      methodsHandleSubmit(handleSubmit)();
      showModalFromChatInput.value = false;
    }
  });

  useEffect(() => {
    if (!isOpenFromTelegram) return;
    window.Telegram?.WebApp.MainButton.setParams({
      text: t('Create'),
      is_active: true,
      is_visible: true,
    });

    window.Telegram?.WebApp.BackButton.show();
    window.Telegram?.WebApp.MainButton.show();

    window.Telegram?.WebApp.BackButton.onClick(cancel);
    window.Telegram?.WebApp.MainButton.onClick(onClickHandler);

    return () => {
      window.Telegram?.WebApp.MainButton.offClick(onClickHandler);
      window.Telegram?.WebApp.BackButton.offClick(cancel);
    };
  }, [cancel, t, isOpenFromTelegram, onClickHandler]);

  return {
    formMethods: methods,
    onSubmit: methods.handleSubmit(handleSubmit),
    template,
  };
}

export const FormBuilderProvider = (props: {
  data: FormBuilderLogicProps;
  children: React.FC<{ formMethods: UseFormReturn; onSubmit: () => Promise<void> }>;
}) => {
  const { formMethods, onSubmit } = useFormBuilder(props.data);
  return props.children({ formMethods, onSubmit });
};
