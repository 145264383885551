export function jsExpression(expression: string, context: object = {}) {
  // eslint-disable-next-line
  return new Function(
    'document',
    'window',
    'globalThis',
    'self',
    'fetch',
    `with (this) {return (${expression});}`
  ).call(context, undefined, undefined, undefined, undefined, undefined);
}

export function jsExpressionForFormBuilder(expression: string, context: { $params: object; $data: object }) {
  return jsExpression(expression, context);
}
export function safeJsExpressionForFormBuilder(expression: string, context: { $params: object; $data: object }) {
  try {
    return jsExpression(expression, context);
  } catch (e) {
    console.error('Error in safeJsExpressionForFormBuilder', e);
  }
}
