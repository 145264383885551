import React, { CSSProperties, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Checkbox,
  DropdownButton,
  DropdownMenu,
  DropdownToggle,
  SearchInput,
  useId,
  useToggle,
} from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './filterContainer.module.scss';
import useSelected from '../FilesTable/useSelected';

type Option = {
  label: string;
  value: string;
};

const FilterContainerSection: FC<{ noBorder?: boolean; style?: CSSProperties }> = ({
  children,
  noBorder = false,
  style,
}) => (
  <div className={cn('padding-8', { 'border-bottom-gray-300': !noBorder })} style={style}>
    {children}
  </div>
);
export const FilterContainer: FC<{
  buttonText: string;
  displayValue: string;
  options: Option[];
  DropDownTitle: string | React.ElementType;
  AllValueSelect: string | React.ElementType;
  onSelectChange: (selected: Option['value'][]) => unknown;
}> = memo(({ buttonText, displayValue, options, DropDownTitle, AllValueSelect, onSelectChange }) => {
  const wasOpen = useRef<boolean>(false);
  const [search, setSearch] = useState('');
  const [isOpen, , , toggle] = useToggle(false);
  const setSearchHandler = useCallback((value: string) => {
    setSearch(value.trimStart());
  }, []);

  const innerOptions = useMemo(() => {
    if (search) {
      return options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()));
    }
    return options;
  }, [options, search]);

  const optionsSelect = useSelected('value', options);

  const allValue = useId();

  useEffect(() => {
    if (isOpen) {
      wasOpen.current = true;
    }
    if (!isOpen && wasOpen.current) {
      wasOpen.current = false;
      onSelectChange(optionsSelect.selected);
    }
  }, [isOpen, onSelectChange, optionsSelect.selected]);

  return (
    <DropdownButton direction='down' toggle={toggle} isOpen={isOpen}>
      <DropdownToggle color='none' tag='div'>
        <div className={styles.filterContainer}>
          <div className='font-size-12 cursor-pointer'>
            {buttonText} <span className={styles.filterContainer__accentText}>{displayValue}</span>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu
        className={cn('border-0', styles.filterContainer__dropdown)}
        positionFixed={true}
        tag='div'
        // right={true}
      >
        <div className='padding-16 flex-column gap-16'>
          <b>{DropDownTitle}</b>
          <div className='border-gray-300 border-radius-3'>
            <FilterContainerSection>
              <SearchInput
                className={styles.searchInputSize}
                alwaysOpen
                clearable={Boolean(search)}
                autoFocus
                onChange={setSearchHandler}
                value={search}
                size='md'
              />
            </FilterContainerSection>
            <FilterContainerSection>
              <label
                data-test-id='FilterContainer.AllCheckboxLabel'
                htmlFor={`checkbox${allValue}`}
                className='mb-0 d-flex gap-8 align-items-center cursor-pointer'
              >
                <Checkbox
                  className={cn({ [styles.partialSelect]: !optionsSelect.isCleared && !optionsSelect.isAllSelected })}
                  name={`checkbox${allValue}`}
                  value={optionsSelect.isAllSelected || !optionsSelect.isCleared}
                  onChange={() => optionsSelect.allHandler()}
                  data-test-id='FilterContainer.AllCheckbox'
                />
                {AllValueSelect}
              </label>
            </FilterContainerSection>
            <FilterContainerSection noBorder style={{ maxHeight: 188, overflowY: 'auto' }}>
              {innerOptions.map(option => (
                <label
                  key={option.value + option.label}
                  htmlFor={`checkbox${option.value}`}
                  className='mb-0 d-flex gap-8 align-items-center cursor-pointer'
                >
                  <Checkbox
                    name={`checkbox${option.value}`}
                    value={optionsSelect.isIncludes({ value: option.value })}
                    onChange={() => optionsSelect.selectedHandler(option)}
                    data-test-id={`FilterContainer.${option.value}`}
                  />
                  {option.label}
                </label>
              ))}
            </FilterContainerSection>
          </div>
        </div>
      </DropdownMenu>
    </DropdownButton>
  );
});
