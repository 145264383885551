import React from 'react';

import { Button, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { appOptions } from '../../api/cc.api';
import { goToCloud, isDemoMode } from '../../api/client';
import styles from './style.module.scss';

export default function DemoModePanel() {
  const { t } = useTranslation();

  if (!isDemoMode.value) return null;

  return (
    <div className={cn(styles.topPanel, styles.topPanel_demo)}>
      <p>{t('Demo:Panel:title')}</p>
      <Button
        data-test-id='Demo.Signup'
        color='primary'
        size='sm'
        onClick={() => goToCloud('register', appOptions.value?.appUrl)}
      >
        {t('register')}
      </Button>
    </div>
  );
}
