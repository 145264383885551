import React, { memo, useState, useCallback, useEffect } from 'react';

import { JustSelect, useTranslation, OptionType } from '@just-ai/just-ui';
import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { isMobile } from 'utils/app/common';

import { safeJsExpressionForFormBuilder } from '../../../../jsExpression';
import { FormBuilderField } from '../../types';
import { AudioPlayerButton } from '../AudioPlayerButton';

const FormSelect = memo(
  ({
    control,
    label,
    hint,
    name,
    required,
    data,
    options,
    dependsOn,
    dropDownHint,
    emptyOptionLabel,
    voiceAudios,
    type,
    playerVoices,
    disabled,
  }: FormBuilderField) => {
    const { tWithCheck } = useTranslation();
    const { field, fieldState } = useController({ name, control, rules: { required } });
    const { getValues, watch } = useFormContext();
    const [isParseJsonError, setJsonOptionsError] = useState(false);

    const [innerOptions, setInnerOptions] = useState<OptionType[]>([]);
    const [innerVoiceAudios, setInnerVoiceAudios] = useState<Record<string, string>>({});

    const generateInnerOptions = useCallback(() => {
      let rawOpts: string | string[];
      let rawVoices: Record<string, string>;

      const formValues = getValues();

      try {
        if (typeof options === 'string') {
          if (Object.keys(formValues).length === 0) {
            requestAnimationFrame(generateInnerOptions);
            return;
          }

          rawOpts = safeJsExpressionForFormBuilder(options, {
            $params: { ...formValues },
            $data: data as object,
          }) as string[];
        } else {
          rawOpts = options as string[];
        }

        if (playerVoices && typeof playerVoices === 'string') {
          rawVoices = safeJsExpressionForFormBuilder(playerVoices, {
            $params: { ...formValues },
            $data: data as object,
          }) as Record<string, string>;
        } else {
          rawVoices = playerVoices as Record<string, string>;
        }
        if (!required && rawOpts && rawOpts.includes && !rawOpts.includes(emptyOptionLabel || '--')) {
          rawOpts.unshift(emptyOptionLabel || '--');
        }
        setJsonOptionsError(false);
        setInnerVoiceAudios(rawVoices);
        return setInnerOptions(
          rawOpts.map(rawOpt => ({
            value: rawOpt === emptyOptionLabel ? '--' : rawOpt,
            label: tWithCheck(`Key:${rawOpt}`) || tWithCheck(rawOpt) || rawOpt,
          }))
        );
      } catch (e) {
        setJsonOptionsError(true);
        return setInnerOptions([]);
      }
    }, [options, playerVoices, required, emptyOptionLabel, data, getValues, tWithCheck]);

    useEffect(() => {
      if (!dependsOn) return;
      const sub = watch((_, { name }) => {
        if (name !== dependsOn) return;
        generateInnerOptions();
      });
      return () => sub.unsubscribe();
    }, [dependsOn, generateInnerOptions, watch]);

    useEffect(() => {
      generateInnerOptions();
    }, [generateInnerOptions]);

    useEffect(() => {
      const innerOptsValues = innerOptions.map(opt => opt.value);
      if (required && (!field.value || !innerOptsValues.includes(field.value)) && innerOptions[0]?.value) {
        field.onChange(String(innerOptions[0].value));
      }
    }, [field, innerOptions, options, required]);

    const onChangeHandler = useCallback(
      arg => {
        arg && arg[0] && field.onChange(arg[0].toString());
      },
      [field]
    );

    if (isParseJsonError)
      return (
        <div style={{ color: 'red' }}>
          JSON field <b>{name}</b> parse error
        </div>
      );

    return (
      <div key={name} className='w-full' data-test-id={`TemplateForm.Select.${name}.Wrapper`}>
        <label htmlFor={`${name}`}>{label}</label>
        <div
          className={cn('d-flex gap-8', {
            'flex-row items-start': type !== 'select',
            'flex-column': type === 'select',
          })}
        >
          <JustSelect
            showSystemSelect={isMobile()}
            disabled={disabled}
            options={innerOptions}
            value={field.value}
            defaultValue={required ? field.value || innerOptions[0]?.value : ''}
            position='fixed'
            onChange={onChangeHandler}
            data-test-id={`TemplateForm.Select.${name}`}
            fullWidth
            listAutoPosition={true}
            dropDownHint={dropDownHint}
            hint={hint}
            errorText={fieldState.error?.message}
          />
          {voiceAudios ? (
            <AudioPlayerButton audio={innerVoiceAudios ? innerVoiceAudios[field.value] : undefined} />
          ) : null}
        </div>
      </div>
    );
  }
);

export default FormSelect;
