import React from 'react';

import { Spinner } from '@just-ai/just-ui';

import { resourcesLoading } from '../../models/files';

export const FilesSpinner = () => {
  if (!resourcesLoading.value) return null;

  return <Spinner />;
};
