import React, { memo, useCallback, useEffect, useState } from 'react';

import { DropDownHint, OptionType, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';

import { safeJsExpressionForFormBuilder } from '../../../../jsExpression';
import { FormBuilderField } from '../../types';
import classes from './Radio.module.scss';

export const Radio = memo(
  ({
    control,
    label,
    hint,
    name,
    required,
    data,
    options,
    dependsOn,
    dropDownHint,
    emptyOptionLabel,
    disabled,
  }: FormBuilderField) => {
    const { tWithCheck } = useTranslation();
    const { field } = useController({ name, control, rules: { required } });
    const { getValues, watch } = useFormContext();
    const [isParseJsonError, setJsonOptionsError] = useState(false);

    const [innerOptions, setInnerOptions] = useState<OptionType[]>([]);

    const generateInnerOptions = useCallback(() => {
      let rawOpts: string | string[];

      const formValues = getValues();

      try {
        if (Object.keys(formValues).length === 0) {
          requestAnimationFrame(generateInnerOptions);
          return;
        }

        if (typeof options === 'string') {
          rawOpts = safeJsExpressionForFormBuilder(options, {
            $params: { ...formValues },
            $data: data as object,
          }) as string[];
        } else {
          rawOpts = options as string[];
        }

        setJsonOptionsError(false);
        return setInnerOptions(
          rawOpts.map(rawOpt => ({
            value: rawOpt === emptyOptionLabel ? '--' : rawOpt,
            label: tWithCheck(`Key:${rawOpt}`) || tWithCheck(String(rawOpt)) || rawOpt,
          }))
        );
      } catch (e) {
        setJsonOptionsError(true);
        return setInnerOptions([]);
      }
    }, [getValues, options, data, emptyOptionLabel, tWithCheck]);

    useEffect(() => {
      if (!dependsOn) return;
      const sub = watch((_, { name, type }) => {
        if (name !== dependsOn) return;
        generateInnerOptions();
      });
      return () => sub.unsubscribe();
    }, [dependsOn, generateInnerOptions, watch]);

    useEffect(() => {
      generateInnerOptions();
    }, [generateInnerOptions]);

    useEffect(() => {
      if (required && !field.value && innerOptions[0]?.value) {
        field.onChange(String(innerOptions[0].value));
      }
    }, [field, innerOptions, options, required]);

    const onChangeHandler = useCallback(
      arg => {
        field.onChange(arg);
      },
      [field]
    );

    if (isParseJsonError)
      return (
        <div style={{ color: 'red' }}>
          JSON field <b>{name}</b> parse error
        </div>
      );
    return (
      <div className='d-flex flex-col' key={name} data-test-id={`TemplateForm.Slider.${name}-wrapper`}>
        <label>{label}</label>
        <div className={cn(classes.radioCards, 'd-flex m-0 gap-8')}>
          {innerOptions.map(({ value, label }) => {
            return (
              <label
                htmlFor={`${value}-radio`}
                key={value}
                className={cn(classes.radioCards_card, 'd-inline-flex padding-10 border-radius-4 mb-0', {
                  [classes.radioCards_card_active]: field.value === value,
                })}
              >
                <div className={cn(classes.radioCards_card_wrap, 'd-flex flex-column')}>
                  <div>{label}</div>
                  <div className='text-muted hide-on-empty'>{tWithCheck(`Key:${value}_small`)}</div>
                  <input
                    type='radio'
                    id={`${value}-radio`}
                    name={name}
                    value={value}
                    onChange={() => onChangeHandler(value)}
                    disabled={disabled}
                  />
                </div>
              </label>
            );
          })}
        </div>
        {hint && <span className='hint' dangerouslySetInnerHTML={{ __html: hint }} />}
        {dropDownHint ? <DropDownHint dropDownHint={dropDownHint} /> : null}
      </div>
    );
  }
);
