import { signal } from '@preact/signals-react';

import { pollCurrentUserForPaymentRequests } from '../models/currentUser';

export type PaymentData =
  | {
      status: 'pending_one' | 'pending_many' | 'done' | 'failed';
      pendingAmount?: number;
    }
  | undefined;

export const showBilling = signal<boolean | 'outOfTokens'>(false);
export const showBillingModal = (value: boolean | 'outOfTokens') => {
  showBilling.value = value;
};

export const paymentData = signal<PaymentData>(undefined);
export const paymentProcessing = signal<boolean>(false);

export const setPaymentProcessing = (value: boolean) => {
  paymentProcessing.value = value;
};

export const setPaymentData = (value: PaymentData) => {
  paymentData.value = value;
};

export const recursivePaymentProcessingPolling = async () => {
  const result = await pollCurrentUserForPaymentRequests();
  if (result.successfulOperations) {
    setPaymentData({ status: 'done', pendingAmount: result.pendingRequests });
  }
  if (result.failures) {
    setPaymentData({ status: 'failed', pendingAmount: result.pendingRequests });
  }
  if (result.pendingRequests > 0) {
    setPaymentData({
      status: result.pendingRequests > 1 ? 'pending_many' : 'pending_one',
      pendingAmount: result.pendingRequests,
    });
    setTimeout(recursivePaymentProcessingPolling, 5000);
  } else {
    setTimeout(() => setPaymentData(undefined), 5000);
  }
};
