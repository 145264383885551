import React, { FC, memo, useCallback } from 'react';

import { IconButton, IconButtonProps, Spinner, Tooltip, useTranslation } from '@just-ai/just-ui';
import jgIcon from 'images/maskedicon.svg';
import { omit } from 'lodash';

import { DATA_GUARD_ERROR } from './ChatMessage';
import styles from './style.module.scss';
import { TOKENS_BACKEND_MULT } from '../../api/constants';
import useDelayToggle from '../../hooks/useDelayToggle';
import { ClipboardService } from '../../services/clipboardService';
import { Message, TextMessagePart } from '../../types/chat';
import { isMobile } from '../../utils/app/common';

interface ChatMessageButtonsProps {
  regenerateMessage?: () => void;
  deleteMessage?: (id: string) => void;
  isMessageBeingDeleted?: boolean;
  isDeleteButtonEnabled?: boolean;
  isCopyButtonShown?: boolean;
  messageId: string;
  textMessageRef?: React.RefObject<HTMLDivElement>;
  tokensAmount?: number;
  redactEntities?: string[];
  nextMessage?: Message;
}

const ChatMessageButtons: FC<ChatMessageButtonsProps> = memo(
  ({
    regenerateMessage,
    deleteMessage,
    isMessageBeingDeleted = false,
    isDeleteButtonEnabled = true,
    isCopyButtonShown = true,
    textMessageRef,
    messageId,
    tokensAmount,
    redactEntities,
    nextMessage,
  }) => {
    const { t, getLocale } = useTranslation();

    const iconSize = isMobile() ? 'lg' : 'md';
    const iconButtonProps: Partial<IconButtonProps> = {
      size: iconSize,
      flat: true,
      withoutPadding: true,
      wrapperIconClassName: styles.chat__messageButton,
    };

    const [isMessageCopied, trigger] = useDelayToggle(false, 2000);

    const handleCopyMessage = () =>
      ClipboardService.copyHtmlToClipboard(textMessageRef?.current?.innerHTML ?? '').then(() => trigger());

    const deleteHandler = useCallback(() => {
      if (!deleteMessage) return;

      deleteMessage(messageId);
      const needDeleteNextMessage =
        nextMessage && (nextMessage?.content?.at(0) as TextMessagePart)?.localizationKey === DATA_GUARD_ERROR;
      if (needDeleteNextMessage && nextMessage?.id) deleteMessage(nextMessage?.id);
    }, [deleteMessage, messageId, nextMessage]);

    if (!regenerateMessage && !isCopyButtonShown && !deleteMessage) {
      return null;
    }

    return (
      <div className={styles.chat__messageButtons}>
        {regenerateMessage && (
          <>
            <IconButton
              id={`chatMessageRegenerateButton-${messageId}`}
              name='falSync'
              aria-label={t('ChatMessageButton:Regenerate')}
              onClick={regenerateMessage}
              {...iconButtonProps}
            />
            <Tooltip placement='top' target={`chatMessageRegenerateButton-${messageId}`}>
              {t('ChatMessageButton:Regenerate')}
            </Tooltip>
          </>
        )}

        {isCopyButtonShown && (
          <>
            {isMessageCopied ? (
              <IconButton
                id={`chatMessageCopyButton-${messageId}`}
                name='farCheck'
                aria-label={t('ChatMessageButton:Copied')}
                {...omit(iconButtonProps, 'wrapperIconClassName')}
                color='success'
              />
            ) : (
              <IconButton
                id={`chatMessageCopyButton-${messageId}`}
                name='falCopy'
                aria-label={t('ChatMessageButton:Copy')}
                onClick={handleCopyMessage}
                {...iconButtonProps}
              />
            )}
            <Tooltip placement='top' target={`chatMessageCopyButton-${messageId}`}>
              {t(isMessageCopied ? 'ChatMessageButton:Copied' : 'ChatMessageButton:Copy')}
            </Tooltip>
          </>
        )}

        {deleteMessage &&
          (isMessageBeingDeleted ? (
            <Spinner size={iconSize} inline />
          ) : (
            <>
              <IconButton
                id={`chatMessageDeleteButton-${messageId}`}
                name='falTrashAlt'
                aria-label={t('ChatMessageButton:Delete')}
                onClick={deleteHandler}
                disabled={!isDeleteButtonEnabled}
                {...iconButtonProps}
              />
              <Tooltip placement='top' target={`chatMessageDeleteButton-${messageId}`}>
                {t('ChatMessageButton:Delete')}
              </Tooltip>
            </>
          ))}

        {!!redactEntities?.length && (
          <>
            <div className={styles.chat__maskedBanner} id={`maskedBanner-${messageId}`} data-test-id='Message.JGMasked'>
              <img src={jgIcon} alt='Redact Icon' />
              <span>{t('ChatMessage:isMasked')}</span>
            </div>
            <Tooltip target={`maskedBanner-${messageId}`} innerClassName={styles.chat__tooltipInner}>
              <div className='font-size-12'>
                <p>{t('validation:Redact:Summary')}</p>
                <ul className={styles.chat__tooltipInner__entityList}>
                  {redactEntities.map((el, index) => (
                    <li key={`${el}_${index}`}>{el}</li>
                  ))}
                </ul>
                <p>{t('validation:Redact:Tooltip')}</p>
              </div>
            </Tooltip>
          </>
        )}
        {!!tokensAmount && typeof tokensAmount === 'number' && (
          <>
            <p className={styles.chat__tokensSpent} id={`tokensSpent-${messageId}`}>
              {(tokensAmount / TOKENS_BACKEND_MULT).toLocaleString(getLocale(), { maximumFractionDigits: 3 })} JT
            </p>
            <Tooltip target={`tokensSpent-${messageId}`}>{t('tokensSpent')}</Tooltip>
          </>
        )}
      </div>
    );
  }
);

ChatMessageButtons.displayName = 'ChatMessageButtons';

export default ChatMessageButtons;
