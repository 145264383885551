import React from 'react';

export default function TelegramIcon() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.3335 8.46533C1.3335 4.80254 4.30278 1.83325 7.96558 1.83325C11.6284 1.83325 14.5977 4.80254 14.5977 8.46533C14.5977 12.1281 11.6284 15.0974 7.96558 15.0974C4.30278 15.0974 1.3335 12.1281 1.3335 8.46533Z'
        fill='#64A9DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.91876 7.86696L10.8083 5.20964C11.123 5.06682 11.4267 5.28527 11.3066 5.76685L10.1333 11.2956C10.0514 11.6885 9.81397 11.7825 9.48508 11.6011L7.69774 10.2805L7.663 10.3143C7.25738 10.7087 6.90023 11.0559 6.83866 11.1162C6.83645 11.1183 6.83425 11.1205 6.83206 11.1226C6.73552 11.217 6.6558 11.295 6.48098 11.295C6.2995 11.295 6.28436 11.2413 6.2479 11.112C6.23781 11.0762 6.2261 11.0347 6.20878 10.9869L5.55133 8.97115L3.83305 8.43484C3.45132 8.31789 3.44853 8.05562 3.91876 7.86696ZM9.694 6.40443L5.82099 8.84793L6.47364 10.8488L6.60276 9.47156L9.91907 6.47941C10.0646 6.35021 9.88735 6.28717 9.694 6.40443Z'
        fill='white'
      />
    </svg>
  );
}
