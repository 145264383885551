import { useCallback, useEffect } from 'react';

import { usePromiseProcessing } from '@just-ai/just-ui';

import { isAlpha } from '../../isAlpha';
import useApiService from '../../services/useApiService';
import { rawConversations, rawConversationsLoaded } from './signals';

export const useConversations = (shouldInitialLoad?: boolean) => {
  const { getUserChats, getMainChat } = useApiService();
  const [{ result: resultUserChats, error: errorUserChats, loading: loadingUserChats }, getUserChatsHandle] =
    usePromiseProcessing(getUserChats, {
      throwOnError: true,
    });

  const loadUserChats = useCallback(async () => {
    try {
      const [userChatsResult, mainChatResult] = await Promise.allSettled([
        getUserChatsHandle(),
        !isAlpha ? getMainChat() : Promise.reject(),
      ]);
      const userChats = userChatsResult.status === 'fulfilled' ? userChatsResult.value.data.conversations || [] : [];
      const mainChat = mainChatResult.status === 'fulfilled' ? mainChatResult.value.data : null;
      rawConversations.value = mainChat ? [...userChats, mainChat] : userChats;
    } finally {
      rawConversationsLoaded.value = true;
    }
  }, [getMainChat, getUserChatsHandle]);

  useEffect(() => {
    shouldInitialLoad && loadUserChats();
  }, [shouldInitialLoad, loadUserChats]);

  return {
    resultUserChats,
    errorUserChats,
    loadingUserChats,
    loadUserChats,
  };
};
