import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { signal } from '@preact/signals-react';

import { isDemoMode } from '../../api/client';

export const alerts = signal<AlertNotificationItemProps[]>([]);

export const addAlert = (
  message: string,
  type?: AlertNotificationItemProps['type'],
  title?: AlertNotificationItemProps['title']
) => {
  if (!isDemoMode.value)
    alerts.value = [
      ...alerts.value,
      {
        title,
        message,
        showed: true,
        time: Date.now(),
        type: type || 'error',
      },
    ];
};
