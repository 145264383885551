import React, { ReactElement, useCallback, useMemo } from 'react';

import { Modal, useTranslation, getCountryList, TextareaAutosize, JustSelect, PhoneNumber } from '@just-ai/just-ui';
import { IPhoneNumber } from '@just-ai/just-ui/dist/PhoneNumber';
import cn from 'classnames';
import { useController, useForm } from 'react-hook-form';

import { currentUser } from '../../models/currentUser';
import { isMobile } from '../../utils/app/common';
import TextField from '../Agents/components/TemplateForm/FormFields/TextField';

export type RequestFeatureModalInputs = {
  name: string;
  companyName: string;
  industry: string;
  phone: IPhoneNumber;
  email: string;
  comment: string;
};

type Props = {
  title: string;
  infoText: string | ReactElement;
  type: 'template' | 'paywall';
  isRequestModalOpen: boolean;
  sendRequestForTemplate: (data: RequestFeatureModalInputs) => void;
  closeModal: () => void;
};

const inputsArr: {
  name: keyof RequestFeatureModalInputs;
  required?: boolean;
  type: 'text' | 'textarea' | 'phone' | 'select';
}[] = [
  {
    name: 'name',
    required: true,
    type: 'text',
  },
  {
    name: 'companyName',
    required: true,
    type: 'text',
  },
  {
    name: 'industry',
    required: true,
    type: 'select',
  },
  {
    name: 'phone',
    required: true,
    type: 'phone',
  },
  {
    name: 'email',
    required: true,
    type: 'text',
  },
  {
    name: 'comment',
    type: 'textarea',
  },
];

export default function RequestFeatureModal(props: Props) {
  const { isRequestModalOpen, sendRequestForTemplate, closeModal, title, infoText } = props;

  const { t } = useTranslation();

  const industryOptions = useMemo(
    () => [
      { label: t('Modal_RequestFeature:option:ecom'), value: 'ecom' },
      { label: t('Modal_RequestFeature:option:fintech'), value: 'fintech' },
      { label: t('Modal_RequestFeature:option:it'), value: 'it' },
      { label: t('Modal_RequestFeature:option:gov'), value: 'gov' },
      { label: t('Modal_RequestFeature:option:housing'), value: 'housing' },
      { label: t('Modal_RequestFeature:option:logistics'), value: 'logistics' },
      { label: t('Modal_RequestFeature:option:marketing'), value: 'marketing' },
      { label: t('Modal_RequestFeature:option:medicine'), value: 'medicine' },
      { label: t('Modal_RequestFeature:option:realEstate'), value: 'realEstate' },
      { label: t('Modal_RequestFeature:option:edu'), value: 'edu' },
      { label: t('Modal_RequestFeature:option:production'), value: 'production' },
      { label: t('Modal_RequestFeature:option:industry'), value: 'industry' },
      { label: t('Modal_RequestFeature:option:horeca'), value: 'horeca' },
      { label: t('Modal_RequestFeature:option:retail'), value: 'retail' },
      { label: t('Modal_RequestFeature:option:trading'), value: 'trading' },
      { label: t('Modal_RequestFeature:option:telecom'), value: 'telecom' },
      { label: t('Modal_RequestFeature:option:other'), value: 'other' },
    ],
    [t]
  );

  const { control, handleSubmit, setError } = useForm<RequestFeatureModalInputs>();

  const countriesList = getCountryList(currentUser.value?.language?.toLowerCase() || '', false);

  const { field: commentField } = useController({
    name: 'comment',
    control,
  });

  const { field: industryField, fieldState: industryFieldState } = useController({
    name: 'industry',
    rules: { required: true },
    defaultValue: industryOptions[industryOptions.length - 1].value,
    control,
  });

  const { field: phoneField, fieldState: phoneFieldState } = useController({
    name: 'phone',
    rules: { required: true },
    control,
    defaultValue: {
      code: '7',
      number: '',
    },
  });

  const onNumberChange = useCallback(
    number => {
      phoneField.onChange(number);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const localSubmit = useCallback(
    (data: RequestFeatureModalInputs) => {
      if (!data.phone.number) {
        return setError('phone', { type: 'required', message: t('This field is required') });
      }
      return sendRequestForTemplate(data);
    },
    [sendRequestForTemplate, setError, t]
  );

  return (
    <Modal
      title={title}
      isOpen={isRequestModalOpen}
      buttonSubmitText={t(`Modal_RequestFeatureFor:submit`)}
      onActionClick={handleSubmit(localSubmit)}
      buttonCancelText={t('cancel')}
      buttonCancelColor='secondary'
      onCancelClick={closeModal}
      className={cn('mobileBottomModal')}
      disableActionButtonAutoFocus
    >
      <div className='mb-5'>{infoText}</div>
      {inputsArr.map(input => {
        if (input.type === 'phone')
          return (
            <div key={input.name} className='w-full rounded-lg bg-transparent mb-5'>
              <label>{t(`Modal_RequestFeature:label:${input.name}`)}</label>
              <PhoneNumber
                countryList={countriesList}
                error={phoneFieldState.invalid}
                focused={false}
                onChange={onNumberChange}
                phoneNumber={phoneField.value}
                placeholderSearch={t('Search')}
                selectFixedPosition={false}
              />
              {phoneFieldState.error && (
                <small className='font-color-danger font-size-12'>{phoneFieldState.error?.message}</small>
              )}
            </div>
          );

        if (input.type === 'select')
          return (
            <div key={input.name} className='w-full rounded-lg bg-transparent mb-5'>
              <label>{t(`Modal_RequestFeature:label:${input.name}`)}</label>
              <JustSelect
                showSystemSelect={isMobile()}
                options={industryOptions}
                value={industryField.value}
                position='fixed'
                onChange={arg => arg && arg[0] && industryField.onChange(arg[0].toString())}
                fullWidth
                listAutoPosition={true}
              />
              {industryFieldState.error && (
                <small className='font-color-danger font-size-12'>{industryFieldState.error?.message}</small>
              )}
            </div>
          );
        if (input.type === 'textarea')
          return (
            <div key={input.name} className='mb-5'>
              <label>{t(`Modal_RequestFeature:label:${input.name}`)}</label>
              <TextareaAutosize
                name={input.name}
                rowsMax={3}
                rowsMin={3}
                value={commentField.value}
                onChange={value => commentField.onChange(value)}
              />
            </div>
          );
        return (
          <div key={input.name} className='mb-5'>
            <TextField
              label={t(`Modal_RequestFeature:label:${input.name}`)}
              control={control as any}
              required={input.required}
              name={input.name}
            />
          </div>
        );
      })}
    </Modal>
  );
}
