export const dynamicErrorsLocalization = {
  'agentapi.agents.request_length_error': {
    eng: 'The maximum context length for the selected model is {contextLength} {contextLengthWord}. Your message is {requestedTokens} {requestedTokensWord} long. Please make your message shorter and try again.',
    ru: 'Максимальный размер контекста для выбранной модели — {contextLength} {contextLengthWord}. В вашем сообщении {requestedTokens} {requestedTokensWord}. Уменьшите размер сообщения и попробуйте снова.',
  },
  'agentapi.agents.system_prompt_length_error': {
    eng: 'The maximum context length for the selected model is {contextLength} {contextLengthWord}. Your system prompt is {requestedTokens} {requestedTokensWord} long. Please make your prompt shorter and try again.',
    ru: 'Максимальный размер контекста для выбранной модели — {contextLength} {contextLengthWord}. В вашем системном промте {requestedTokens} {requestedTokensWord}. Уменьшите размер промта и попробуйте снова.',
  },
  'agentapi.agents.document_length_error': {
    eng: 'The maximum number of characters in a document is {maxCharacters} {maxCharactersWord}. Your document has {characters} {charactersWord}. Please upload a document with fewer characters.',
    ru: 'Максимальное количество символов в документе — {maxCharacters} {maxCharactersWord}. В вашем документе {characters} {charactersWord}. Загрузите документ, в котором меньше символов.',
  },
};
