import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import { Button, Modal, useTranslation } from '@just-ai/just-ui';
import axios from 'axios';
import { omit } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';

import localize, { tWithCheck } from '../../../localization';
import { addAlert } from '../../../models/alerts';
import {
  copyFavorite,
  createNewDialogFromFavourite,
  creatingNewAgentFromFavorite,
  TFavouriteElement,
} from '../../../models/favourite/favourite.api';
import { goToConversation } from '../../../routes';
import { getFormFields } from '../components/AddToFavouriteModal';
import { addToFavoriteLocalization } from '../components/AddToFavouriteModal/addToFavorite.loc';
import FormBuilder from '../components/TemplateForm/FormBuilder';
import { FieldConfig } from '../components/TemplateForm/useFormBuilder';
import jsonData from '../JsonData';
import { hideEditFavoriteModal, isEditShowModalOpen } from './favoriteButtons.control';
localize.addTranslations(addToFavoriteLocalization);

export const EditFavouriteModal: FC<{
  templateFields: FieldConfig[];
  templateDefaultName: string;
  templateFrom: {
    title: string;
    description: string;
  };
  favorite: TFavouriteElement;
  data?: any;
}> = memo(({ templateDefaultName, templateFields, templateFrom, favorite, data = jsonData }) => {
  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState(false);
  const methods = useForm();
  const { formState, handleSubmit, control, reset } = methods;

  const saveToFavoriteHandle = useCallback(
    async (formValues?: any) => {
      return copyFavorite({
        appId: favorite.id,
        params: omit(formValues, 'title', 'description'),
        name: formValues.title,
        description: formValues.description,
      });
    },
    [favorite.id]
  );
  const saveNewFavouriteAndCreateNewConversationHandle = useCallback(
    async (formValues?: any) => {
      setInProgress(true);
      try {
        const newFavourite = await saveToFavoriteHandle(formValues);
        creatingNewAgentFromFavorite.value = true;

        const conversation = await createNewDialogFromFavourite({
          appId: newFavourite.id,
          name: formValues.title,
        });
        hideEditFavoriteModal();
        creatingNewAgentFromFavorite.value = false;

        goToConversation(conversation.id);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          addAlert(`${tWithCheck(e?.response?.data.error) + ' '}${e?.response?.data.message}`, 'error');
        }
      }
    },
    [saveToFavoriteHandle]
  );

  const fields = useMemo(
    () => [
      ...getFormFields(favorite.name || templateDefaultName, favorite.description || templateFrom.description),
      ...templateFields,
    ],
    [favorite.name, favorite.description, templateDefaultName, templateFrom.description, templateFields]
  );

  const saveNewFavourite = useCallback(async () => {
    setInProgress(true);
    await handleSubmit(saveToFavoriteHandle)();
    setInProgress(false);
    hideEditFavoriteModal();
    reset({});
  }, [handleSubmit, reset, saveToFavoriteHandle]);

  const saveNewFavouriteAndCreateNewConversation = useCallback(() => {
    handleSubmit(saveNewFavouriteAndCreateNewConversationHandle)();
  }, [handleSubmit, saveNewFavouriteAndCreateNewConversationHandle]);

  const clearFormValues = useCallback(() => {
    reset({});
  }, [reset]);

  return (
    <Modal
      isOpen={isEditShowModalOpen.value === favorite.id}
      className='mobileBottomModal'
      title={t('EditFavorite')}
      disableActionButtonAutoFocus
      onClosed={clearFormValues}
      onClickCloseIcon={hideEditFavoriteModal}
      customFooter={
        <div className='flex flex-md-row gap-8 modal-base-buttons'>
          <Button data-test-id='EditFavModal.cancel' color='secondary' outline onClick={hideEditFavoriteModal}>
            {t('cancel')}
          </Button>
          <Button
            data-test-id='EditFavModal.create'
            color='primary'
            outline
            disabled={inProgress}
            onClick={saveNewFavouriteAndCreateNewConversation}
          >
            {t('EditFavorite:CreateAndSave')}
          </Button>
          <Button
            data-test-id='EditFavModal.save'
            color='primary'
            disabled={Object.keys(formState.errors).length > 0 || inProgress}
            onClick={saveNewFavourite}
          >
            {t('save')}
          </Button>
        </div>
      }
      inProgress={inProgress}
    >
      <b>{templateFrom.title}</b>
      <p className='margin-bottom-24'>{templateFrom.description}</p>
      <FormProvider {...methods}>
        <div className='flex flex-col gap-24'>
          {fields.map(field => (
            <FormBuilder
              key={field.name}
              control={control}
              isEdit={false}
              data={data}
              param={field}
              handleFileUpload={() => Promise.resolve(undefined)}
            />
          ))}
        </div>
      </FormProvider>
    </Modal>
  );
});
EditFavouriteModal.displayName = 'memo(EditFavouriteModal)';
