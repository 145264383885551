import React from 'react';

import { Icon, useTranslation, Button, ButtonProps, IconProps } from '@just-ai/just-ui';

export default function ChatHeaderSelectFileFromHistoryButton<RenderVariant extends 'dropdownItem' | 'button'>({
  renderAs,
  className,
  buttonProps,
  iconProps,
  onClick: handleClick,
}: {
  className: string;
  renderAs: RenderVariant;
  buttonProps?: RenderVariant extends 'button' ? Partial<ButtonProps> : never;
  iconProps?: RenderVariant extends 'dropdownItem' ? Partial<IconProps> : never;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  switch (renderAs) {
    case 'button':
      return (
        <Button {...buttonProps} className={className} onClick={handleClick} iconLeft='farArchive'>
          {t('ChatHeader:SelectFileFromHistory')}
        </Button>
      );
    case 'dropdownItem':
    default:
      return (
        <div className={className} onClick={handleClick}>
          <Icon {...iconProps} name='farArchive' />
          {t('ChatHeader:SelectFileFromHistory')}
        </div>
      );
  }
}
