import { ConversationResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { computed, signal } from '@preact/signals-react';

import { Conversation } from '../../types/chat';
import { reduxConnectSignal } from '../../utils/reduxConnector';
import { createImmerSignal } from '../hooks';

export const [rawConversations, setRawConversations] = createImmerSignal<ConversationResponse[]>([]);
export const rawConversationsLoaded = signal(false);
export const rawConversationsLoading = true;
reduxConnectSignal(
  'Raw Conversations',
  computed(() => ({ rawConversations: rawConversations.value, rawConversationsLoaded: rawConversationsLoaded.value }))
);

export const [conversations, setConversationsValue] = createImmerSignal<Conversation[]>([]);
export const conversationsLoaded = signal(false);

export const newConversationLoading = signal(false);
reduxConnectSignal(
  'Conversations',
  computed(() => ({ conversations: conversations.value, conversationsLoaded: conversationsLoaded.value }))
);

export const setNewConversations = (newConversations: Conversation[]) => {
  conversations.value = newConversations;
};

export const defaultContextValue: Conversation['contextValue'] = {
  fullness: 0,
  messagesTruncationMode: false,
};

export const jGuardFailedToProtect = signal<boolean | undefined>(false);
export const setJGuardFailureToProtect = (value?: boolean) => {
  jGuardFailedToProtect.value = value;
};

export const jGuardModalShown = signal<boolean>(false);
export const setJGuardModalShown = (value: boolean) => {
  jGuardModalShown.value = value;
};
