import React, { memo, useEffect, useMemo, useState } from 'react';

import { useTranslation, Tabs } from '@just-ai/just-ui';
import { useLocation } from 'react-router';

import { AnalyticsTab } from './tabs/AnalyticsTab';
import LimitsTab from './tabs/LimitsTab';
import { MainTab } from './tabs/MainTab';
import { isTovie } from '../../isTovie';
import { hasPermission } from '../../models/currentUser';
import { ContentContainer } from '../../pages/FullContainer/ContentContainer';
import { MobileHeader } from '../../pages/Main/MobileHeader';
import { goToAccount, goToAccountAnalytics, goToAccountLimits, routes } from '../../routes';

export type JGuardKeyStatus = {
  keyStatus: 'off' | 'on' | 'error';
  emailSent: boolean;
};

const TabsTypes = {
  MAIN: 'main',
  ANALYTICS: 'analytics',
  LIMITS: 'limits',
};

const AccountPage = memo(function AccountPage() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TabsTypes.MAIN);

  const location = useLocation();

  const tabs = useMemo(() => {
    const tabsList = [
      {
        value: TabsTypes.MAIN,
        name: t('AccountPage:MainTabs:Main'),
      },
      {
        value: TabsTypes.ANALYTICS,
        name: t('AccountPage:MainTabs:Analytics'),
      },
    ];
    if (hasPermission('COPILOT_MANAGE_USER_LIMITS'))
      tabsList.push({
        value: TabsTypes.LIMITS,
        name: t('AccountPage:MainTabs:Limits'),
      });
    return tabsList;
  }, [t]);

  const changeTabHandler = (value: string) => {
    if (value === TabsTypes.MAIN) {
      return goToAccount();
    }
    if (value === TabsTypes.ANALYTICS) {
      return goToAccountAnalytics();
    }
    if (value === TabsTypes.LIMITS) {
      return goToAccountLimits();
    }
    setCurrentTab(value);
  };

  useEffect(() => {
    if (location.pathname === routes.account) {
      return setCurrentTab(TabsTypes.MAIN);
    }
    if (location.pathname === routes.analytics) {
      return setCurrentTab(TabsTypes.ANALYTICS);
    }
    if (location.pathname === routes.limits && hasPermission('COPILOT_MANAGE_USER_LIMITS')) {
      return setCurrentTab(TabsTypes.LIMITS);
    }
  }, [location.pathname]);

  return (
    <ContentContainer withLicense={!isTovie}>
      <MobileHeader pageName={t('account')} />
      <div className='p-5 h-full flex flex-column'>
        <h1 className='d-none d-sm-block mb-5'>{t('account')}</h1>
        <Tabs tabs={tabs} activeTab={currentTab} onChange={changeTabHandler} className='mb-5' />
        {currentTab === TabsTypes.MAIN && <MainTab />}
        {currentTab === TabsTypes.ANALYTICS && <AnalyticsTab />}
        {currentTab === TabsTypes.LIMITS && <LimitsTab />}
      </div>
    </ContentContainer>
  );
});

export default AccountPage;
