import React, { FC, memo, useEffect, useState } from 'react';

import { Spinner } from '@just-ai/just-ui';
import { getCurrentUser } from 'models/currentUser';

import { getOptions, useCloudLogin } from '../../api/cc.api';
import { hasDemo, X5_User_Should_haveGroup_Error } from '../../api/client';
import { isAlpha } from '../../isAlpha';
import { isX5 } from '../../isX5';
import localize from '../../localization';

export const UseAuthLoader: FC<{ innerLoadingDefaultState?: boolean }> = memo(
  ({ children, innerLoadingDefaultState = true }) => {
    const [innerLoading, setInnerLoading] = useState(innerLoadingDefaultState);
    useEffect(() => {
      getOptions(isX5 ? { callbackNegative: () => setInnerLoading(false) } : {}).then(data => {
        if (!isX5 && !isAlpha && data?.demoHosts && data.demoHosts.includes(window.location.host)) {
          hasDemo.value = true;
        }
        if (!X5_User_Should_haveGroup_Error.value)
          getCurrentUser(useCloudLogin.value ? 'cc' : 'keycloak').finally(() => {
            if (data?.product?.docsUrl) {
              localize.addTranslations({
                docsUrl: {
                  eng: data.product.docsUrl,
                  ru: data.product.docsUrl,
                },
              });

              localize.setConfigVariables({
                ...localize.getConfigVariables(),
                docsUrl: {
                  eng: data.product?.docsUrl,
                  ru: data.product?.docsUrl,
                },
              });
            }
            setInnerLoading(false);
          });
      });
    }, []);

    if (innerLoading) return <Spinner />;
    return <>{children}</>;
  }
);
