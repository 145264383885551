import React, { FC, memo, useCallback, useRef, useState } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import classnames from 'classnames';
import { pdfjs, Document, Page } from 'react-pdf';
import { AutoSizer } from 'react-virtualized';
import { List as VList } from 'react-virtualized/dist/es/List';

import { SlideMessagePart } from '../../../types/chat';
import { isDev } from '../../../utils/app/common';
import classes from '../style.module.scss';

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `${window.location.protocol}//${window.location.host}/pdf.worker.js`
).toString();

export const SlideMessage: FC<{ slide: SlideMessagePart }> = memo(({ slide }) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }, []);

  return (
    <div className='flex flex-col relative'>
      <div className={classnames('flex-1', classes.chat__messageText)}>
        <div ref={containerRef} style={{ height: 550, width: '100%' }}>
          <Document
            file={isDev() ? new URL(slide.url || '').pathname : slide.url}
            options={options}
            onLoadSuccess={onDocumentLoadSuccess}
            error={t('Chat:FileSettings:error')}
            loading={t('Chat:FileSettings:loading')}
            noData={t('Chat:FileSettings:noFile')}
          >
            <AutoSizer>
              {({ height, width }) => (
                <VList
                  width={width}
                  height={height}
                  rowCount={numPages}
                  rowHeight={height}
                  rowRenderer={({ index, key, style }) => (
                    <div key={key} style={style}>
                      <Page pageNumber={index + 1} key={key} width={width} />
                    </div>
                  )}
                />
              )}
            </AutoSizer>
          </Document>
        </div>
      </div>
    </div>
  );
});

SlideMessage.displayName = 'memo(SlideMessage)';
