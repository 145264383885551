// tslint:disable
// @ts-nocheck
/**
 * Copilot Gateway Service Api
 * An API to interact with generative models
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var AccessPolicyAction;
(function (AccessPolicyAction) {
    AccessPolicyAction["ALLOW"] = "ALLOW";
    AccessPolicyAction["DENY"] = "DENY";
})(AccessPolicyAction || (AccessPolicyAction = {}));
/**
 * @export
 * @enum {string}
 */
export var AuthenticationTypeEnum;
(function (AuthenticationTypeEnum) {
    AuthenticationTypeEnum["CC"] = "CC";
    AuthenticationTypeEnum["KEYCLOAK"] = "KEYCLOAK";
    AuthenticationTypeEnum["NONE"] = "NONE";
})(AuthenticationTypeEnum || (AuthenticationTypeEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BillingEvent;
(function (BillingEvent) {
    BillingEvent["Promo"] = "promo";
    BillingEvent["Course"] = "course";
    BillingEvent["Refill"] = "refill";
    BillingEvent["Bonus"] = "bonus";
})(BillingEvent || (BillingEvent = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var Currency;
(function (Currency) {
    Currency["Rub"] = "rub";
    Currency["Usd"] = "usd";
    Currency["Eur"] = "eur";
})(Currency || (Currency = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ExpenseField;
(function (ExpenseField) {
    ExpenseField["Date"] = "date";
    ExpenseField["User"] = "user";
    ExpenseField["Application"] = "application";
    ExpenseField["Conversation"] = "conversation";
    ExpenseField["Template"] = "template";
})(ExpenseField || (ExpenseField = {}));
/**
 * Available types for account user token limits
 * @export
 * @enum {string}
 */
export var RefreshPeriodType;
(function (RefreshPeriodType) {
    RefreshPeriodType["Days"] = "days";
    RefreshPeriodType["Weeks"] = "weeks";
})(RefreshPeriodType || (RefreshPeriodType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ResourceToAccess;
(function (ResourceToAccess) {
    ResourceToAccess["Agent"] = "agent";
    ResourceToAccess["CorporateAccount"] = "corporateAccount";
})(ResourceToAccess || (ResourceToAccess = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TokenType;
(function (TokenType) {
    TokenType["Started"] = "started";
    TokenType["Bonus"] = "bonus";
    TokenType["Promo"] = "promo";
    TokenType["Paid"] = "paid";
    TokenType["Onboarding"] = "onboarding";
    TokenType["Tariff"] = "tariff";
    TokenType["NonBillable"] = "nonBillable";
})(TokenType || (TokenType = {}));
/**
 * APIKeyApi - axios parameter creator
 * @export
 */
export const APIKeyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling createApiKey.');
            }
            const localVarPath = `/api/gateway/web/user/{userId}/api-key`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApiKeysByUser(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getAllApiKeysByUser.');
            }
            const localVarPath = `/api/gateway/web/user/{userId}/api-key/list`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * APIKeyApi - functional programming interface
 * @export
 */
export const APIKeyApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(userId, options) {
            const localVarAxiosArgs = APIKeyApiAxiosParamCreator(configuration).createApiKey(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApiKeysByUser(userId, options) {
            const localVarAxiosArgs = APIKeyApiAxiosParamCreator(configuration).getAllApiKeysByUser(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * APIKeyApi - factory interface
 * @export
 */
export const APIKeyApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(userId, options) {
            return APIKeyApiFp(configuration).createApiKey(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApiKeysByUser(userId, options) {
            return APIKeyApiFp(configuration).getAllApiKeysByUser(userId, options)(axios, basePath);
        },
    };
};
/**
 * APIKeyApi - object-oriented interface
 * @export
 * @class APIKeyApi
 * @extends {BaseAPI}
 */
export class APIKeyApi extends BaseAPI {
    /**
     *
     * @param {number} userId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIKeyApi
     */
    createApiKey(userId, options) {
        return APIKeyApiFp(this.configuration).createApiKey(userId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIKeyApi
     */
    getAllApiKeysByUser(userId, options) {
        return APIKeyApiFp(this.configuration).getAllApiKeysByUser(userId, options)(this.axios, this.basePath);
    }
}
/**
 * AccountUserLimitsApi - axios parameter creator
 * @export
 */
export const AccountUserLimitsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * create user limits for current user account
         * @param {AccountUserLimitRequest} accountUserLimitRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLimits(accountUserLimitRequest, options = {}) {
            // verify required parameter 'accountUserLimitRequest' is not null or undefined
            if (accountUserLimitRequest === null ||
                accountUserLimitRequest === undefined) {
                throw new RequiredError('accountUserLimitRequest', 'Required parameter accountUserLimitRequest was null or undefined when calling createUserLimits.');
            }
            const localVarPath = `/api/gateway/web/account/limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountUserLimitRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountUserLimitRequest !== undefined ? accountUserLimitRequest : {})
                : accountUserLimitRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete limit with id
         * @param {number} limitId Limit identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountUserLimit(limitId, options = {}) {
            // verify required parameter 'limitId' is not null or undefined
            if (limitId === null || limitId === undefined) {
                throw new RequiredError('limitId', 'Required parameter limitId was null or undefined when calling deleteAccountUserLimit.');
            }
            const localVarPath = `/api/gateway/web/account/limits/{limitId}`.replace(`{${'limitId'}}`, encodeURIComponent(String(limitId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create user limits for current user account
         * @param {DeleteMultipleUserLimitRequest} deleteMultipleUserLimitRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUserLimits(deleteMultipleUserLimitRequest, options = {}) {
            // verify required parameter 'deleteMultipleUserLimitRequest' is not null or undefined
            if (deleteMultipleUserLimitRequest === null ||
                deleteMultipleUserLimitRequest === undefined) {
                throw new RequiredError('deleteMultipleUserLimitRequest', 'Required parameter deleteMultipleUserLimitRequest was null or undefined when calling deleteManyUserLimits.');
            }
            const localVarPath = `/api/gateway/web/account/limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof deleteMultipleUserLimitRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(deleteMultipleUserLimitRequest !== undefined
                    ? deleteMultipleUserLimitRequest
                    : {})
                : deleteMultipleUserLimitRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get account user limit by id
         * @param {number} limitId Limit identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserLimit(limitId, options = {}) {
            // verify required parameter 'limitId' is not null or undefined
            if (limitId === null || limitId === undefined) {
                throw new RequiredError('limitId', 'Required parameter limitId was null or undefined when calling getAccountUserLimit.');
            }
            const localVarPath = `/api/gateway/web/account/limits/{limitId}`.replace(`{${'limitId'}}`, encodeURIComponent(String(limitId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get current account user limits
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {string} [userSearch] User search (name or email).
         * @param {boolean} [includeAccountWideLimit] Include account wide limit in response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options = {}) {
            const localVarPath = `/api/gateway/web/account/limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (userSearch !== undefined) {
                localVarQueryParameter['userSearch'] = userSearch;
            }
            if (includeAccountWideLimit !== undefined) {
                localVarQueryParameter['includeAccountWideLimit'] =
                    includeAccountWideLimit;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get account wide user limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccountLimit(options = {}) {
            const localVarPath = `/api/gateway/web/account/limit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get current user limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserLimits(options = {}) {
            const localVarPath = `/api/gateway/web/user/limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update limit with selected id
         * @param {number} limitId Limit identifier.
         * @param {AccountUserLimit} accountUserLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUserLimit(limitId, accountUserLimit, options = {}) {
            // verify required parameter 'limitId' is not null or undefined
            if (limitId === null || limitId === undefined) {
                throw new RequiredError('limitId', 'Required parameter limitId was null or undefined when calling updateAccountUserLimit.');
            }
            // verify required parameter 'accountUserLimit' is not null or undefined
            if (accountUserLimit === null || accountUserLimit === undefined) {
                throw new RequiredError('accountUserLimit', 'Required parameter accountUserLimit was null or undefined when calling updateAccountUserLimit.');
            }
            const localVarPath = `/api/gateway/web/account/limits/{limitId}`.replace(`{${'limitId'}}`, encodeURIComponent(String(limitId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountUserLimit !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountUserLimit !== undefined ? accountUserLimit : {})
                : accountUserLimit || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountUserLimitsApi - functional programming interface
 * @export
 */
export const AccountUserLimitsApiFp = function (configuration) {
    return {
        /**
         * create user limits for current user account
         * @param {AccountUserLimitRequest} accountUserLimitRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLimits(accountUserLimitRequest, options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).createUserLimits(accountUserLimitRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete limit with id
         * @param {number} limitId Limit identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountUserLimit(limitId, options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).deleteAccountUserLimit(limitId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * create user limits for current user account
         * @param {DeleteMultipleUserLimitRequest} deleteMultipleUserLimitRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUserLimits(deleteMultipleUserLimitRequest, options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).deleteManyUserLimits(deleteMultipleUserLimitRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get account user limit by id
         * @param {number} limitId Limit identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserLimit(limitId, options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).getAccountUserLimit(limitId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get current account user limits
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {string} [userSearch] User search (name or email).
         * @param {boolean} [includeAccountWideLimit] Include account wide limit in response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get account wide user limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccountLimit(options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).getCurrentAccountLimit(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get current user limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserLimits(options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).getCurrentUserLimits(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update limit with selected id
         * @param {number} limitId Limit identifier.
         * @param {AccountUserLimit} accountUserLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUserLimit(limitId, accountUserLimit, options) {
            const localVarAxiosArgs = AccountUserLimitsApiAxiosParamCreator(configuration).updateAccountUserLimit(limitId, accountUserLimit, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountUserLimitsApi - factory interface
 * @export
 */
export const AccountUserLimitsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * create user limits for current user account
         * @param {AccountUserLimitRequest} accountUserLimitRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLimits(accountUserLimitRequest, options) {
            return AccountUserLimitsApiFp(configuration).createUserLimits(accountUserLimitRequest, options)(axios, basePath);
        },
        /**
         * Delete limit with id
         * @param {number} limitId Limit identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountUserLimit(limitId, options) {
            return AccountUserLimitsApiFp(configuration).deleteAccountUserLimit(limitId, options)(axios, basePath);
        },
        /**
         * create user limits for current user account
         * @param {DeleteMultipleUserLimitRequest} deleteMultipleUserLimitRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyUserLimits(deleteMultipleUserLimitRequest, options) {
            return AccountUserLimitsApiFp(configuration).deleteManyUserLimits(deleteMultipleUserLimitRequest, options)(axios, basePath);
        },
        /**
         * get account user limit by id
         * @param {number} limitId Limit identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserLimit(limitId, options) {
            return AccountUserLimitsApiFp(configuration).getAccountUserLimit(limitId, options)(axios, basePath);
        },
        /**
         * get current account user limits
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {string} [userSearch] User search (name or email).
         * @param {boolean} [includeAccountWideLimit] Include account wide limit in response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options) {
            return AccountUserLimitsApiFp(configuration).getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options)(axios, basePath);
        },
        /**
         * get account wide user limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAccountLimit(options) {
            return AccountUserLimitsApiFp(configuration).getCurrentAccountLimit(options)(axios, basePath);
        },
        /**
         * get current user limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserLimits(options) {
            return AccountUserLimitsApiFp(configuration).getCurrentUserLimits(options)(axios, basePath);
        },
        /**
         * Update limit with selected id
         * @param {number} limitId Limit identifier.
         * @param {AccountUserLimit} accountUserLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUserLimit(limitId, accountUserLimit, options) {
            return AccountUserLimitsApiFp(configuration).updateAccountUserLimit(limitId, accountUserLimit, options)(axios, basePath);
        },
    };
};
/**
 * AccountUserLimitsApi - object-oriented interface
 * @export
 * @class AccountUserLimitsApi
 * @extends {BaseAPI}
 */
export class AccountUserLimitsApi extends BaseAPI {
    /**
     * create user limits for current user account
     * @param {AccountUserLimitRequest} accountUserLimitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    createUserLimits(accountUserLimitRequest, options) {
        return AccountUserLimitsApiFp(this.configuration).createUserLimits(accountUserLimitRequest, options)(this.axios, this.basePath);
    }
    /**
     * Delete limit with id
     * @param {number} limitId Limit identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    deleteAccountUserLimit(limitId, options) {
        return AccountUserLimitsApiFp(this.configuration).deleteAccountUserLimit(limitId, options)(this.axios, this.basePath);
    }
    /**
     * create user limits for current user account
     * @param {DeleteMultipleUserLimitRequest} deleteMultipleUserLimitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    deleteManyUserLimits(deleteMultipleUserLimitRequest, options) {
        return AccountUserLimitsApiFp(this.configuration).deleteManyUserLimits(deleteMultipleUserLimitRequest, options)(this.axios, this.basePath);
    }
    /**
     * get account user limit by id
     * @param {number} limitId Limit identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    getAccountUserLimit(limitId, options) {
        return AccountUserLimitsApiFp(this.configuration).getAccountUserLimit(limitId, options)(this.axios, this.basePath);
    }
    /**
     * get current account user limits
     * @param {number} [page] Pagination page number.
     * @param {number} [size] Pagination page size.
     * @param {string} [userSearch] User search (name or email).
     * @param {boolean} [includeAccountWideLimit] Include account wide limit in response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options) {
        return AccountUserLimitsApiFp(this.configuration).getAccountUserLimits(page, size, userSearch, includeAccountWideLimit, options)(this.axios, this.basePath);
    }
    /**
     * get account wide user limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    getCurrentAccountLimit(options) {
        return AccountUserLimitsApiFp(this.configuration).getCurrentAccountLimit(options)(this.axios, this.basePath);
    }
    /**
     * get current user limits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    getCurrentUserLimits(options) {
        return AccountUserLimitsApiFp(this.configuration).getCurrentUserLimits(options)(this.axios, this.basePath);
    }
    /**
     * Update limit with selected id
     * @param {number} limitId Limit identifier.
     * @param {AccountUserLimit} accountUserLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserLimitsApi
     */
    updateAccountUserLimit(limitId, accountUserLimit, options) {
        return AccountUserLimitsApiFp(this.configuration).updateAccountUserLimit(limitId, accountUserLimit, options)(this.axios, this.basePath);
    }
}
/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Push analytics event into db
         * @param {AnalyticsEvent} analyticsEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushEvent(analyticsEvent, options = {}) {
            // verify required parameter 'analyticsEvent' is not null or undefined
            if (analyticsEvent === null || analyticsEvent === undefined) {
                throw new RequiredError('analyticsEvent', 'Required parameter analyticsEvent was null or undefined when calling pushEvent.');
            }
            const localVarPath = `/api/gateway/web/analytics/events`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof analyticsEvent !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(analyticsEvent !== undefined ? analyticsEvent : {})
                : analyticsEvent || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Push analytics event into db
         * @param {AnalyticsEvent} analyticsEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushEvent(analyticsEvent, options) {
            const localVarAxiosArgs = AnalyticsApiAxiosParamCreator(configuration).pushEvent(analyticsEvent, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Push analytics event into db
         * @param {AnalyticsEvent} analyticsEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushEvent(analyticsEvent, options) {
            return AnalyticsApiFp(configuration).pushEvent(analyticsEvent, options)(axios, basePath);
        },
    };
};
/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     *
     * @summary Push analytics event into db
     * @param {AnalyticsEvent} analyticsEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    pushEvent(analyticsEvent, options) {
        return AnalyticsApiFp(this.configuration).pushEvent(analyticsEvent, options)(this.axios, this.basePath);
    }
}
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Verify whether the user has sufficient tokens to process the request.
         * @param {TokenAvailabilityRequest} tokenAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenAvailability(tokenAvailabilityRequest, options = {}) {
            // verify required parameter 'tokenAvailabilityRequest' is not null or undefined
            if (tokenAvailabilityRequest === null ||
                tokenAvailabilityRequest === undefined) {
                throw new RequiredError('tokenAvailabilityRequest', 'Required parameter tokenAvailabilityRequest was null or undefined when calling checkTokenAvailability.');
            }
            const localVarPath = `/api/gateway/billing/check-availability`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tokenAvailabilityRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tokenAvailabilityRequest !== undefined
                    ? tokenAvailabilityRequest
                    : {})
                : tokenAvailabilityRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all balances for user
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalances(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserBalances.');
            }
            const localVarPath = `/api/gateway/billing/{userId}/balances`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replenish account balance with certain amount of tokens
         * @param {number} accountId Account identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishAccountBalance(accountId, balanceOperationRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling replenishAccountBalance.');
            }
            // verify required parameter 'balanceOperationRequest' is not null or undefined
            if (balanceOperationRequest === null ||
                balanceOperationRequest === undefined) {
                throw new RequiredError('balanceOperationRequest', 'Required parameter balanceOperationRequest was null or undefined when calling replenishAccountBalance.');
            }
            const localVarPath = `/api/gateway/billing/account/{accountId}/replenish`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof balanceOperationRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(balanceOperationRequest !== undefined ? balanceOperationRequest : {})
                : balanceOperationRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replenish organization balance with certain amount of tokens
         * @param {number} orgId Organization identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishOrganizationBalance(orgId, balanceOperationRequest, options = {}) {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId', 'Required parameter orgId was null or undefined when calling replenishOrganizationBalance.');
            }
            // verify required parameter 'balanceOperationRequest' is not null or undefined
            if (balanceOperationRequest === null ||
                balanceOperationRequest === undefined) {
                throw new RequiredError('balanceOperationRequest', 'Required parameter balanceOperationRequest was null or undefined when calling replenishOrganizationBalance.');
            }
            const localVarPath = `/api/gateway/billing/organization/{orgId}/replenish`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof balanceOperationRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(balanceOperationRequest !== undefined ? balanceOperationRequest : {})
                : balanceOperationRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replenish user balance with certain amount of tokens
         * @param {number} userId User identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishUserBalance(userId, balanceOperationRequest, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling replenishUserBalance.');
            }
            // verify required parameter 'balanceOperationRequest' is not null or undefined
            if (balanceOperationRequest === null ||
                balanceOperationRequest === undefined) {
                throw new RequiredError('balanceOperationRequest', 'Required parameter balanceOperationRequest was null or undefined when calling replenishUserBalance.');
            }
            const localVarPath = `/api/gateway/billing/user/{userId}/replenish`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof balanceOperationRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(balanceOperationRequest !== undefined ? balanceOperationRequest : {})
                : balanceOperationRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration) {
    return {
        /**
         * Verify whether the user has sufficient tokens to process the request.
         * @param {TokenAvailabilityRequest} tokenAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenAvailability(tokenAvailabilityRequest, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).checkTokenAvailability(tokenAvailabilityRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all balances for user
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalances(userId, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getUserBalances(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Replenish account balance with certain amount of tokens
         * @param {number} accountId Account identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishAccountBalance(accountId, balanceOperationRequest, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).replenishAccountBalance(accountId, balanceOperationRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Replenish organization balance with certain amount of tokens
         * @param {number} orgId Organization identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishOrganizationBalance(orgId, balanceOperationRequest, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).replenishOrganizationBalance(orgId, balanceOperationRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Replenish user balance with certain amount of tokens
         * @param {number} userId User identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishUserBalance(userId, balanceOperationRequest, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).replenishUserBalance(userId, balanceOperationRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Verify whether the user has sufficient tokens to process the request.
         * @param {TokenAvailabilityRequest} tokenAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenAvailability(tokenAvailabilityRequest, options) {
            return BillingApiFp(configuration).checkTokenAvailability(tokenAvailabilityRequest, options)(axios, basePath);
        },
        /**
         * Get all balances for user
         * @param {number} userId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalances(userId, options) {
            return BillingApiFp(configuration).getUserBalances(userId, options)(axios, basePath);
        },
        /**
         * Replenish account balance with certain amount of tokens
         * @param {number} accountId Account identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishAccountBalance(accountId, balanceOperationRequest, options) {
            return BillingApiFp(configuration).replenishAccountBalance(accountId, balanceOperationRequest, options)(axios, basePath);
        },
        /**
         * Replenish organization balance with certain amount of tokens
         * @param {number} orgId Organization identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishOrganizationBalance(orgId, balanceOperationRequest, options) {
            return BillingApiFp(configuration).replenishOrganizationBalance(orgId, balanceOperationRequest, options)(axios, basePath);
        },
        /**
         * Replenish user balance with certain amount of tokens
         * @param {number} userId User identifier.
         * @param {BalanceOperationRequest} balanceOperationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishUserBalance(userId, balanceOperationRequest, options) {
            return BillingApiFp(configuration).replenishUserBalance(userId, balanceOperationRequest, options)(axios, basePath);
        },
    };
};
/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * Verify whether the user has sufficient tokens to process the request.
     * @param {TokenAvailabilityRequest} tokenAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    checkTokenAvailability(tokenAvailabilityRequest, options) {
        return BillingApiFp(this.configuration).checkTokenAvailability(tokenAvailabilityRequest, options)(this.axios, this.basePath);
    }
    /**
     * Get all balances for user
     * @param {number} userId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    getUserBalances(userId, options) {
        return BillingApiFp(this.configuration).getUserBalances(userId, options)(this.axios, this.basePath);
    }
    /**
     * Replenish account balance with certain amount of tokens
     * @param {number} accountId Account identifier.
     * @param {BalanceOperationRequest} balanceOperationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    replenishAccountBalance(accountId, balanceOperationRequest, options) {
        return BillingApiFp(this.configuration).replenishAccountBalance(accountId, balanceOperationRequest, options)(this.axios, this.basePath);
    }
    /**
     * Replenish organization balance with certain amount of tokens
     * @param {number} orgId Organization identifier.
     * @param {BalanceOperationRequest} balanceOperationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    replenishOrganizationBalance(orgId, balanceOperationRequest, options) {
        return BillingApiFp(this.configuration).replenishOrganizationBalance(orgId, balanceOperationRequest, options)(this.axios, this.basePath);
    }
    /**
     * Replenish user balance with certain amount of tokens
     * @param {number} userId User identifier.
     * @param {BalanceOperationRequest} balanceOperationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    replenishUserBalance(userId, balanceOperationRequest, options) {
        return BillingApiFp(this.configuration).replenishUserBalance(userId, balanceOperationRequest, options)(this.axios, this.basePath);
    }
}
/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} host
         * @param {string} referer
         * @param {string} [lang] Options language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(host, referer, lang, options = {}) {
            // verify required parameter 'host' is not null or undefined
            if (host === null || host === undefined) {
                throw new RequiredError('host', 'Required parameter host was null or undefined when calling getOptions.');
            }
            // verify required parameter 'referer' is not null or undefined
            if (referer === null || referer === undefined) {
                throw new RequiredError('referer', 'Required parameter referer was null or undefined when calling getOptions.');
            }
            const localVarPath = `/api/gateway/public/options`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }
            if (host !== undefined && host !== null) {
                localVarHeaderParameter['Host'] = String(host);
            }
            if (referer !== undefined && referer !== null) {
                localVarHeaderParameter['Referer'] = String(referer);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} host
         * @param {string} referer
         * @param {string} [lang] Options language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(host, referer, lang, options) {
            const localVarAxiosArgs = ConfigurationApiAxiosParamCreator(configuration).getOptions(host, referer, lang, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} host
         * @param {string} referer
         * @param {string} [lang] Options language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(host, referer, lang, options) {
            return ConfigurationApiFp(configuration).getOptions(host, referer, lang, options)(axios, basePath);
        },
    };
};
/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     *
     * @param {string} host
     * @param {string} referer
     * @param {string} [lang] Options language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    getOptions(host, referer, lang, options) {
        return ConfigurationApiFp(this.configuration).getOptions(host, referer, lang, options)(this.axios, this.basePath);
    }
}
/**
 * DataGuardApi - axios parameter creator
 * @export
 */
export const DataGuardApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary returns current active jay guard api token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentApiToken(options = {}) {
            const localVarPath = `/api/gateway/web/dataguard/api-token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary returns if current dataguard api token is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isApiTokenValid(options = {}) {
            const localVarPath = `/api/gateway/web/dataguard/api-token/is-valid`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary returnsTokensListForCurrentAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiTokens(options = {}) {
            const localVarPath = `/api/gateway/web/dataguard/api-token/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary sets current active jay guard api token
         * @param {DataGuardApiToken} dataGuardApiToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiToken(dataGuardApiToken, options = {}) {
            // verify required parameter 'dataGuardApiToken' is not null or undefined
            if (dataGuardApiToken === null || dataGuardApiToken === undefined) {
                throw new RequiredError('dataGuardApiToken', 'Required parameter dataGuardApiToken was null or undefined when calling updateApiToken.');
            }
            const localVarPath = `/api/gateway/web/dataguard/api-token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof dataGuardApiToken !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(dataGuardApiToken !== undefined ? dataGuardApiToken : {})
                : dataGuardApiToken || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DataGuardApi - functional programming interface
 * @export
 */
export const DataGuardApiFp = function (configuration) {
    return {
        /**
         *
         * @summary returns current active jay guard api token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentApiToken(options) {
            const localVarAxiosArgs = DataGuardApiAxiosParamCreator(configuration).getCurrentApiToken(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary returns if current dataguard api token is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isApiTokenValid(options) {
            const localVarAxiosArgs = DataGuardApiAxiosParamCreator(configuration).isApiTokenValid(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary returnsTokensListForCurrentAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiTokens(options) {
            const localVarAxiosArgs = DataGuardApiAxiosParamCreator(configuration).listApiTokens(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary sets current active jay guard api token
         * @param {DataGuardApiToken} dataGuardApiToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiToken(dataGuardApiToken, options) {
            const localVarAxiosArgs = DataGuardApiAxiosParamCreator(configuration).updateApiToken(dataGuardApiToken, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * DataGuardApi - factory interface
 * @export
 */
export const DataGuardApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary returns current active jay guard api token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentApiToken(options) {
            return DataGuardApiFp(configuration).getCurrentApiToken(options)(axios, basePath);
        },
        /**
         *
         * @summary returns if current dataguard api token is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isApiTokenValid(options) {
            return DataGuardApiFp(configuration).isApiTokenValid(options)(axios, basePath);
        },
        /**
         *
         * @summary returnsTokensListForCurrentAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiTokens(options) {
            return DataGuardApiFp(configuration).listApiTokens(options)(axios, basePath);
        },
        /**
         *
         * @summary sets current active jay guard api token
         * @param {DataGuardApiToken} dataGuardApiToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiToken(dataGuardApiToken, options) {
            return DataGuardApiFp(configuration).updateApiToken(dataGuardApiToken, options)(axios, basePath);
        },
    };
};
/**
 * DataGuardApi - object-oriented interface
 * @export
 * @class DataGuardApi
 * @extends {BaseAPI}
 */
export class DataGuardApi extends BaseAPI {
    /**
     *
     * @summary returns current active jay guard api token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGuardApi
     */
    getCurrentApiToken(options) {
        return DataGuardApiFp(this.configuration).getCurrentApiToken(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary returns if current dataguard api token is valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGuardApi
     */
    isApiTokenValid(options) {
        return DataGuardApiFp(this.configuration).isApiTokenValid(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary returnsTokensListForCurrentAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGuardApi
     */
    listApiTokens(options) {
        return DataGuardApiFp(this.configuration).listApiTokens(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary sets current active jay guard api token
     * @param {DataGuardApiToken} dataGuardApiToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataGuardApi
     */
    updateApiToken(dataGuardApiToken, options) {
        return DataGuardApiFp(this.configuration).updateApiToken(dataGuardApiToken, options)(this.axios, this.basePath);
    }
}
/**
 * ManagementApi - axios parameter creator
 * @export
 */
export const ManagementApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {BatchUserRegistrationRequest} batchUserRegistrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiple(batchUserRegistrationRequest, options = {}) {
            // verify required parameter 'batchUserRegistrationRequest' is not null or undefined
            if (batchUserRegistrationRequest === null ||
                batchUserRegistrationRequest === undefined) {
                throw new RequiredError('batchUserRegistrationRequest', 'Required parameter batchUserRegistrationRequest was null or undefined when calling createMultiple.');
            }
            const localVarPath = `/api/gateway/user/management/create-multiple`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof batchUserRegistrationRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(batchUserRegistrationRequest !== undefined
                    ? batchUserRegistrationRequest
                    : {})
                : batchUserRegistrationRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId User identifier.
         * @param {string} keyPrefix API key prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApiKey(userId, keyPrefix, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling revokeApiKey.');
            }
            // verify required parameter 'keyPrefix' is not null or undefined
            if (keyPrefix === null || keyPrefix === undefined) {
                throw new RequiredError('keyPrefix', 'Required parameter keyPrefix was null or undefined when calling revokeApiKey.');
            }
            const localVarPath = `/api/gateway/web/user/{userId}/api-key/{keyPrefix}/revoke`
                .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
                .replace(`{${'keyPrefix'}}`, encodeURIComponent(String(keyPrefix)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke promo code
         * @param {RevokePromoCodeRequest} revokePromoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokePromoCode(revokePromoCodeRequest, options = {}) {
            // verify required parameter 'revokePromoCodeRequest' is not null or undefined
            if (revokePromoCodeRequest === null ||
                revokePromoCodeRequest === undefined) {
                throw new RequiredError('revokePromoCodeRequest', 'Required parameter revokePromoCodeRequest was null or undefined when calling revokePromoCode.');
            }
            const localVarPath = `/api/gateway/management/promo/revoke`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof revokePromoCodeRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(revokePromoCodeRequest !== undefined ? revokePromoCodeRequest : {})
                : revokePromoCodeRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Method for manual oath refresh token set. Normally should be called only once, than service should correctly handle tokens
         * @param {CrmRefreshTokenRequest} crmRefreshTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAmoCrmRefreshToken(crmRefreshTokenRequest, options = {}) {
            // verify required parameter 'crmRefreshTokenRequest' is not null or undefined
            if (crmRefreshTokenRequest === null ||
                crmRefreshTokenRequest === undefined) {
                throw new RequiredError('crmRefreshTokenRequest', 'Required parameter crmRefreshTokenRequest was null or undefined when calling updateAmoCrmRefreshToken.');
            }
            const localVarPath = `/api/gateway/management/amo-crm/refresh-token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof crmRefreshTokenRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(crmRefreshTokenRequest !== undefined ? crmRefreshTokenRequest : {})
                : crmRefreshTokenRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpsertAccessPolicyRequest} upsertAccessPolicyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAccessPolicy(upsertAccessPolicyRequest, options = {}) {
            // verify required parameter 'upsertAccessPolicyRequest' is not null or undefined
            if (upsertAccessPolicyRequest === null ||
                upsertAccessPolicyRequest === undefined) {
                throw new RequiredError('upsertAccessPolicyRequest', 'Required parameter upsertAccessPolicyRequest was null or undefined when calling upsertAccessPolicy.');
            }
            const localVarPath = `/api/gateway/management/access-policy`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof upsertAccessPolicyRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(upsertAccessPolicyRequest !== undefined
                    ? upsertAccessPolicyRequest
                    : {})
                : upsertAccessPolicyRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upsert promo code
         * @param {PromoCodeRequest} promoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertPromoCode(promoCodeRequest, options = {}) {
            // verify required parameter 'promoCodeRequest' is not null or undefined
            if (promoCodeRequest === null || promoCodeRequest === undefined) {
                throw new RequiredError('promoCodeRequest', 'Required parameter promoCodeRequest was null or undefined when calling upsertPromoCode.');
            }
            const localVarPath = `/api/gateway/management/promo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof promoCodeRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(promoCodeRequest !== undefined ? promoCodeRequest : {})
                : promoCodeRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ManagementApi - functional programming interface
 * @export
 */
export const ManagementApiFp = function (configuration) {
    return {
        /**
         *
         * @param {BatchUserRegistrationRequest} batchUserRegistrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiple(batchUserRegistrationRequest, options) {
            const localVarAxiosArgs = ManagementApiAxiosParamCreator(configuration).createMultiple(batchUserRegistrationRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId User identifier.
         * @param {string} keyPrefix API key prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApiKey(userId, keyPrefix, options) {
            const localVarAxiosArgs = ManagementApiAxiosParamCreator(configuration).revokeApiKey(userId, keyPrefix, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Revoke promo code
         * @param {RevokePromoCodeRequest} revokePromoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokePromoCode(revokePromoCodeRequest, options) {
            const localVarAxiosArgs = ManagementApiAxiosParamCreator(configuration).revokePromoCode(revokePromoCodeRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Method for manual oath refresh token set. Normally should be called only once, than service should correctly handle tokens
         * @param {CrmRefreshTokenRequest} crmRefreshTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAmoCrmRefreshToken(crmRefreshTokenRequest, options) {
            const localVarAxiosArgs = ManagementApiAxiosParamCreator(configuration).updateAmoCrmRefreshToken(crmRefreshTokenRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {UpsertAccessPolicyRequest} upsertAccessPolicyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAccessPolicy(upsertAccessPolicyRequest, options) {
            const localVarAxiosArgs = ManagementApiAxiosParamCreator(configuration).upsertAccessPolicy(upsertAccessPolicyRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upsert promo code
         * @param {PromoCodeRequest} promoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertPromoCode(promoCodeRequest, options) {
            const localVarAxiosArgs = ManagementApiAxiosParamCreator(configuration).upsertPromoCode(promoCodeRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ManagementApi - factory interface
 * @export
 */
export const ManagementApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {BatchUserRegistrationRequest} batchUserRegistrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiple(batchUserRegistrationRequest, options) {
            return ManagementApiFp(configuration).createMultiple(batchUserRegistrationRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId User identifier.
         * @param {string} keyPrefix API key prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApiKey(userId, keyPrefix, options) {
            return ManagementApiFp(configuration).revokeApiKey(userId, keyPrefix, options)(axios, basePath);
        },
        /**
         * Revoke promo code
         * @param {RevokePromoCodeRequest} revokePromoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokePromoCode(revokePromoCodeRequest, options) {
            return ManagementApiFp(configuration).revokePromoCode(revokePromoCodeRequest, options)(axios, basePath);
        },
        /**
         * Method for manual oath refresh token set. Normally should be called only once, than service should correctly handle tokens
         * @param {CrmRefreshTokenRequest} crmRefreshTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAmoCrmRefreshToken(crmRefreshTokenRequest, options) {
            return ManagementApiFp(configuration).updateAmoCrmRefreshToken(crmRefreshTokenRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {UpsertAccessPolicyRequest} upsertAccessPolicyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAccessPolicy(upsertAccessPolicyRequest, options) {
            return ManagementApiFp(configuration).upsertAccessPolicy(upsertAccessPolicyRequest, options)(axios, basePath);
        },
        /**
         * Upsert promo code
         * @param {PromoCodeRequest} promoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertPromoCode(promoCodeRequest, options) {
            return ManagementApiFp(configuration).upsertPromoCode(promoCodeRequest, options)(axios, basePath);
        },
    };
};
/**
 * ManagementApi - object-oriented interface
 * @export
 * @class ManagementApi
 * @extends {BaseAPI}
 */
export class ManagementApi extends BaseAPI {
    /**
     *
     * @param {BatchUserRegistrationRequest} batchUserRegistrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    createMultiple(batchUserRegistrationRequest, options) {
        return ManagementApiFp(this.configuration).createMultiple(batchUserRegistrationRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId User identifier.
     * @param {string} keyPrefix API key prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    revokeApiKey(userId, keyPrefix, options) {
        return ManagementApiFp(this.configuration).revokeApiKey(userId, keyPrefix, options)(this.axios, this.basePath);
    }
    /**
     * Revoke promo code
     * @param {RevokePromoCodeRequest} revokePromoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    revokePromoCode(revokePromoCodeRequest, options) {
        return ManagementApiFp(this.configuration).revokePromoCode(revokePromoCodeRequest, options)(this.axios, this.basePath);
    }
    /**
     * Method for manual oath refresh token set. Normally should be called only once, than service should correctly handle tokens
     * @param {CrmRefreshTokenRequest} crmRefreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    updateAmoCrmRefreshToken(crmRefreshTokenRequest, options) {
        return ManagementApiFp(this.configuration).updateAmoCrmRefreshToken(crmRefreshTokenRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {UpsertAccessPolicyRequest} upsertAccessPolicyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    upsertAccessPolicy(upsertAccessPolicyRequest, options) {
        return ManagementApiFp(this.configuration).upsertAccessPolicy(upsertAccessPolicyRequest, options)(this.axios, this.basePath);
    }
    /**
     * Upsert promo code
     * @param {PromoCodeRequest} promoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    upsertPromoCode(promoCodeRequest, options) {
        return ManagementApiFp(this.configuration).upsertPromoCode(promoCodeRequest, options)(this.axios, this.basePath);
    }
}
/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateOrganizationRequest} createOrganizationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequest, options = {}) {
            // verify required parameter 'createOrganizationRequest' is not null or undefined
            if (createOrganizationRequest === null ||
                createOrganizationRequest === undefined) {
                throw new RequiredError('createOrganizationRequest', 'Required parameter createOrganizationRequest was null or undefined when calling createOrganization.');
            }
            const localVarPath = `/api/gateway/organization/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOrganizationRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOrganizationRequest !== undefined
                    ? createOrganizationRequest
                    : {})
                : createOrganizationRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateOrganizationRequest} createOrganizationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequest, options) {
            const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).createOrganization(createOrganizationRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {CreateOrganizationRequest} createOrganizationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequest, options) {
            return OrganizationApiFp(configuration).createOrganization(createOrganizationRequest, options)(axios, basePath);
        },
    };
};
/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     *
     * @param {CreateOrganizationRequest} createOrganizationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    createOrganization(createOrganizationRequest, options) {
        return OrganizationApiFp(this.configuration).createOrganization(createOrganizationRequest, options)(this.axios, this.basePath);
    }
}
/**
 * PromoCodeApi - axios parameter creator
 * @export
 */
export const PromoCodeApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} userId User identifier.
         * @param {ApplyPromoCodeRequest} applyPromoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromoCode(userId, applyPromoCodeRequest, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling applyPromoCode.');
            }
            // verify required parameter 'applyPromoCodeRequest' is not null or undefined
            if (applyPromoCodeRequest === null ||
                applyPromoCodeRequest === undefined) {
                throw new RequiredError('applyPromoCodeRequest', 'Required parameter applyPromoCodeRequest was null or undefined when calling applyPromoCode.');
            }
            const localVarPath = `/api/gateway/web/user/{userId}/promo`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof applyPromoCodeRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(applyPromoCodeRequest !== undefined ? applyPromoCodeRequest : {})
                : applyPromoCodeRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PromoCodeApi - functional programming interface
 * @export
 */
export const PromoCodeApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} userId User identifier.
         * @param {ApplyPromoCodeRequest} applyPromoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromoCode(userId, applyPromoCodeRequest, options) {
            const localVarAxiosArgs = PromoCodeApiAxiosParamCreator(configuration).applyPromoCode(userId, applyPromoCodeRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PromoCodeApi - factory interface
 * @export
 */
export const PromoCodeApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} userId User identifier.
         * @param {ApplyPromoCodeRequest} applyPromoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyPromoCode(userId, applyPromoCodeRequest, options) {
            return PromoCodeApiFp(configuration).applyPromoCode(userId, applyPromoCodeRequest, options)(axios, basePath);
        },
    };
};
/**
 * PromoCodeApi - object-oriented interface
 * @export
 * @class PromoCodeApi
 * @extends {BaseAPI}
 */
export class PromoCodeApi extends BaseAPI {
    /**
     *
     * @param {number} userId User identifier.
     * @param {ApplyPromoCodeRequest} applyPromoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodeApi
     */
    applyPromoCode(userId, applyPromoCodeRequest, options) {
        return PromoCodeApiFp(this.configuration).applyPromoCode(userId, applyPromoCodeRequest, options)(this.axios, this.basePath);
    }
}
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary returns token expenses history report
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {string} timezone User time zone.
         * @param {string} lang User language.
         * @param {Array<ExpenseField>} [groupBy] Report grouping fields.
         * @param {number} [userId] User identifier.
         * @param {string} [userSearch] User search (name or email).
         * @param {string} [conversationId] Conversation identifier.
         * @param {string} [applicationId] Application identifier.
         * @param {string} [templateId] Template identifier
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {Array<string>} [sort] Report sorting params.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options = {}) {
            // verify required parameter 'periodFrom' is not null or undefined
            if (periodFrom === null || periodFrom === undefined) {
                throw new RequiredError('periodFrom', 'Required parameter periodFrom was null or undefined when calling getExpensesHistory.');
            }
            // verify required parameter 'periodTo' is not null or undefined
            if (periodTo === null || periodTo === undefined) {
                throw new RequiredError('periodTo', 'Required parameter periodTo was null or undefined when calling getExpensesHistory.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone', 'Required parameter timezone was null or undefined when calling getExpensesHistory.');
            }
            // verify required parameter 'lang' is not null or undefined
            if (lang === null || lang === undefined) {
                throw new RequiredError('lang', 'Required parameter lang was null or undefined when calling getExpensesHistory.');
            }
            const localVarPath = `/api/gateway/web/report/expenses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (periodFrom !== undefined) {
                localVarQueryParameter['periodFrom'] =
                    periodFrom instanceof Date
                        ? periodFrom.toISOString()
                        : periodFrom;
            }
            if (periodTo !== undefined) {
                localVarQueryParameter['periodTo'] =
                    periodTo instanceof Date
                        ? periodTo.toISOString()
                        : periodTo;
            }
            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }
            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }
            if (groupBy) {
                localVarQueryParameter['groupBy'] = groupBy.join(COLLECTION_FORMATS.csv);
            }
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            if (userSearch !== undefined) {
                localVarQueryParameter['userSearch'] = userSearch;
            }
            if (conversationId !== undefined) {
                localVarQueryParameter['conversationId'] = conversationId;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary returns token replenishments history report
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {Array<string>} [sort] Report sorting params.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options = {}) {
            // verify required parameter 'periodFrom' is not null or undefined
            if (periodFrom === null || periodFrom === undefined) {
                throw new RequiredError('periodFrom', 'Required parameter periodFrom was null or undefined when calling getReplenishmentHistory.');
            }
            // verify required parameter 'periodTo' is not null or undefined
            if (periodTo === null || periodTo === undefined) {
                throw new RequiredError('periodTo', 'Required parameter periodTo was null or undefined when calling getReplenishmentHistory.');
            }
            const localVarPath = `/api/gateway/web/report/replenishments`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (periodFrom !== undefined) {
                localVarQueryParameter['periodFrom'] =
                    periodFrom instanceof Date
                        ? periodFrom.toISOString()
                        : periodFrom;
            }
            if (periodTo !== undefined) {
                localVarQueryParameter['periodTo'] =
                    periodTo instanceof Date
                        ? periodTo.toISOString()
                        : periodTo;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary returns token operations report in xlsx format
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {string} timezone User time zone.
         * @param {string} lang User language.
         * @param {number} [userId] User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options = {}) {
            // verify required parameter 'periodFrom' is not null or undefined
            if (periodFrom === null || periodFrom === undefined) {
                throw new RequiredError('periodFrom', 'Required parameter periodFrom was null or undefined when calling getTokenHistoryReport.');
            }
            // verify required parameter 'periodTo' is not null or undefined
            if (periodTo === null || periodTo === undefined) {
                throw new RequiredError('periodTo', 'Required parameter periodTo was null or undefined when calling getTokenHistoryReport.');
            }
            // verify required parameter 'timezone' is not null or undefined
            if (timezone === null || timezone === undefined) {
                throw new RequiredError('timezone', 'Required parameter timezone was null or undefined when calling getTokenHistoryReport.');
            }
            // verify required parameter 'lang' is not null or undefined
            if (lang === null || lang === undefined) {
                throw new RequiredError('lang', 'Required parameter lang was null or undefined when calling getTokenHistoryReport.');
            }
            const localVarPath = `/api/gateway/web/report/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (periodFrom !== undefined) {
                localVarQueryParameter['periodFrom'] =
                    periodFrom instanceof Date
                        ? periodFrom.toISOString()
                        : periodFrom;
            }
            if (periodTo !== undefined) {
                localVarQueryParameter['periodTo'] =
                    periodTo instanceof Date
                        ? periodTo.toISOString()
                        : periodTo;
            }
            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }
            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary returns token expenses history report
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {string} timezone User time zone.
         * @param {string} lang User language.
         * @param {Array<ExpenseField>} [groupBy] Report grouping fields.
         * @param {number} [userId] User identifier.
         * @param {string} [userSearch] User search (name or email).
         * @param {string} [conversationId] Conversation identifier.
         * @param {string} [applicationId] Application identifier.
         * @param {string} [templateId] Template identifier
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {Array<string>} [sort] Report sorting params.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options) {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary returns token replenishments history report
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {Array<string>} [sort] Report sorting params.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options) {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary returns token operations report in xlsx format
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {string} timezone User time zone.
         * @param {string} lang User language.
         * @param {number} [userId] User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options) {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary returns token expenses history report
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {string} timezone User time zone.
         * @param {string} lang User language.
         * @param {Array<ExpenseField>} [groupBy] Report grouping fields.
         * @param {number} [userId] User identifier.
         * @param {string} [userSearch] User search (name or email).
         * @param {string} [conversationId] Conversation identifier.
         * @param {string} [applicationId] Application identifier.
         * @param {string} [templateId] Template identifier
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {Array<string>} [sort] Report sorting params.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options) {
            return ReportsApiFp(configuration).getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options)(axios, basePath);
        },
        /**
         *
         * @summary returns token replenishments history report
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {number} [page] Pagination page number.
         * @param {number} [size] Pagination page size.
         * @param {Array<string>} [sort] Report sorting params.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options) {
            return ReportsApiFp(configuration).getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options)(axios, basePath);
        },
        /**
         *
         * @summary returns token operations report in xlsx format
         * @param {Date} periodFrom Report start date inclusive.
         * @param {Date} periodTo Report end date inclusive.
         * @param {string} timezone User time zone.
         * @param {string} lang User language.
         * @param {number} [userId] User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options) {
            return ReportsApiFp(configuration).getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options)(axios, basePath);
        },
    };
};
/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     *
     * @summary returns token expenses history report
     * @param {Date} periodFrom Report start date inclusive.
     * @param {Date} periodTo Report end date inclusive.
     * @param {string} timezone User time zone.
     * @param {string} lang User language.
     * @param {Array<ExpenseField>} [groupBy] Report grouping fields.
     * @param {number} [userId] User identifier.
     * @param {string} [userSearch] User search (name or email).
     * @param {string} [conversationId] Conversation identifier.
     * @param {string} [applicationId] Application identifier.
     * @param {string} [templateId] Template identifier
     * @param {number} [page] Pagination page number.
     * @param {number} [size] Pagination page size.
     * @param {Array<string>} [sort] Report sorting params.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options) {
        return ReportsApiFp(this.configuration).getExpensesHistory(periodFrom, periodTo, timezone, lang, groupBy, userId, userSearch, conversationId, applicationId, templateId, page, size, sort, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary returns token replenishments history report
     * @param {Date} periodFrom Report start date inclusive.
     * @param {Date} periodTo Report end date inclusive.
     * @param {number} [page] Pagination page number.
     * @param {number} [size] Pagination page size.
     * @param {Array<string>} [sort] Report sorting params.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options) {
        return ReportsApiFp(this.configuration).getReplenishmentHistory(periodFrom, periodTo, page, size, sort, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary returns token operations report in xlsx format
     * @param {Date} periodFrom Report start date inclusive.
     * @param {Date} periodTo Report end date inclusive.
     * @param {string} timezone User time zone.
     * @param {string} lang User language.
     * @param {number} [userId] User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options) {
        return ReportsApiFp(this.configuration).getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId, options)(this.axios, this.basePath);
    }
}
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} tgId Telegram identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateUserByTelegramId(tgId, options = {}) {
            // verify required parameter 'tgId' is not null or undefined
            if (tgId === null || tgId === undefined) {
                throw new RequiredError('tgId', 'Required parameter tgId was null or undefined when calling getOrCreateUserByTelegramId.');
            }
            const localVarPath = `/api/gateway/user/ensure`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (tgId !== undefined) {
                localVarQueryParameter['tgId'] = tgId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} tgId Telegram identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateUserByTelegramId(tgId, options) {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).getOrCreateUserByTelegramId(tgId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} tgId Telegram identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateUserByTelegramId(tgId, options) {
            return UserApiFp(configuration).getOrCreateUserByTelegramId(tgId, options)(axios, basePath);
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @param {number} tgId Telegram identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getOrCreateUserByTelegramId(tgId, options) {
        return UserApiFp(this.configuration).getOrCreateUserByTelegramId(tgId, options)(this.axios, this.basePath);
    }
}
/**
 * WebUserApi - axios parameter creator
 * @export
 */
export const WebUserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get all balances for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalances(options = {}) {
            const localVarPath = `/api/gateway/web/balances`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options = {}) {
            const localVarPath = `/api/gateway/web/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RequestAccessRequest} requestAccessRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccess(requestAccessRequest, options = {}) {
            // verify required parameter 'requestAccessRequest' is not null or undefined
            if (requestAccessRequest === null || requestAccessRequest === undefined) {
                throw new RequiredError('requestAccessRequest', 'Required parameter requestAccessRequest was null or undefined when calling requestAccess.');
            }
            const localVarPath = `/api/gateway/web/user/request-access`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestAccessRequest !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestAccessRequest !== undefined ? requestAccessRequest : {})
                : requestAccessRequest || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * WebUserApi - functional programming interface
 * @export
 */
export const WebUserApiFp = function (configuration) {
    return {
        /**
         * Get all balances for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalances(options) {
            const localVarAxiosArgs = WebUserApiAxiosParamCreator(configuration).getBalances(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options) {
            const localVarAxiosArgs = WebUserApiAxiosParamCreator(configuration).getCurrentUser(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RequestAccessRequest} requestAccessRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccess(requestAccessRequest, options) {
            const localVarAxiosArgs = WebUserApiAxiosParamCreator(configuration).requestAccess(requestAccessRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * WebUserApi - factory interface
 * @export
 */
export const WebUserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get all balances for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalances(options) {
            return WebUserApiFp(configuration).getBalances(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options) {
            return WebUserApiFp(configuration).getCurrentUser(options)(axios, basePath);
        },
        /**
         *
         * @param {RequestAccessRequest} requestAccessRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccess(requestAccessRequest, options) {
            return WebUserApiFp(configuration).requestAccess(requestAccessRequest, options)(axios, basePath);
        },
    };
};
/**
 * WebUserApi - object-oriented interface
 * @export
 * @class WebUserApi
 * @extends {BaseAPI}
 */
export class WebUserApi extends BaseAPI {
    /**
     * Get all balances for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebUserApi
     */
    getBalances(options) {
        return WebUserApiFp(this.configuration).getBalances(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebUserApi
     */
    getCurrentUser(options) {
        return WebUserApiFp(this.configuration).getCurrentUser(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {RequestAccessRequest} requestAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebUserApi
     */
    requestAccess(requestAccessRequest, options) {
        return WebUserApiFp(this.configuration).requestAccess(requestAccessRequest, options)(this.axios, this.basePath);
    }
}
