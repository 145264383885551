export const countriesLocalization = {
  'Key:AF': {
    eng: 'Afghanistan',
    ru: 'Афганистан',
  },
  'Key:AL': {
    eng: 'Albania',
    ru: 'Албания',
  },
  'Key:DZ': {
    eng: 'Algeria',
    ru: 'Алжир',
  },
  'Key:AD': {
    eng: 'Andorra',
    ru: 'Андорра',
  },
  'Key:AO': {
    eng: 'Angola',
    ru: 'Ангола',
  },
  'Key:AR': {
    eng: 'Argentina',
    ru: 'Аргентина',
  },
  'Key:AM': {
    eng: 'Armenia',
    ru: 'Армения',
  },
  'Key:AU': {
    eng: 'Australia',
    ru: 'Австралия',
  },
  'Key:AT': {
    eng: 'Austria',
    ru: 'Австрия',
  },
  'Key:AZ': {
    eng: 'Azerbaijan',
    ru: 'Азербайджан',
  },
  'Key:BH': {
    eng: 'Bahrain',
    ru: 'Багамы',
  },
  'Key:BD': {
    eng: 'Bangladesh',
    ru: 'Бангладеш',
  },
  'Key:BY': {
    eng: 'Belarus',
    ru: 'Беларусь',
  },
  'Key:BE': {
    eng: 'Belgium',
    ru: 'Бельгия',
  },
  'Key:BO': {
    eng: 'Bolivia',
    ru: 'Боливия',
  },
  'Key:BA': {
    eng: 'Bosnia and Herzegovina',
    ru: 'Босния и Герцеговина',
  },
  'Key:BW': {
    eng: 'Botswana',
    ru: 'Ботсвана',
  },
  'Key:BR': {
    eng: 'Brazil',
    ru: 'Бразилия',
  },
  'Key:BG': {
    eng: 'Bulgaria',
    ru: 'Болгария',
  },
  'Key:CA': {
    eng: 'Canada',
    ru: 'Канада',
  },
  'Key:CL': {
    eng: 'Chile',
    ru: 'Чили',
  },
  'Key:CN': {
    eng: 'China',
    ru: 'Китай',
  },
  'Key:CO': {
    eng: 'Colombia',
    ru: 'Колумбия',
  },
  'Key:CR': {
    eng: 'Costa Rica',
    ru: 'Коста-Рика',
  },
  'Key:HR': {
    eng: 'Croatia',
    ru: 'Хорватия',
  },
  'Key:CU': {
    eng: 'Cuba',
    ru: 'Куба',
  },
  'Key:CY': {
    eng: 'Cyprus',
    ru: 'Кипр',
  },
  'Key:CZ': {
    eng: 'Czech Republic',
    ru: 'Чехия',
  },
  'Key:DK': {
    eng: 'Denmark',
    ru: 'Дания',
  },
  'Key:DO': {
    eng: 'Dominican Republic',
    ru: 'Доминиканская Республика',
  },
  'Key:EC': {
    eng: 'Ecuador',
    ru: 'Эквадор',
  },
  'Key:EG': {
    eng: 'Egypt',
    ru: 'Египет',
  },
  'Key:SV': {
    eng: 'El Salvador',
    ru: 'Сальвадор',
  },
  'Key:EE': {
    eng: 'Estonia',
    ru: 'Эстония',
  },
  'Key:ET': {
    eng: 'Ethiopia',
    ru: 'Эфиопия',
  },
  'Key:FI': {
    eng: 'Finland',
    ru: 'Финляндия',
  },
  'Key:FR': {
    eng: 'France',
    ru: 'Франция',
  },
  'Key:GE': {
    eng: 'Georgia',
    ru: 'Грузия',
  },
  'Key:DE': {
    eng: 'Germany',
    ru: 'Германия',
  },
  'Key:GR': {
    eng: 'Greece',
    ru: 'Греция',
  },
  'Key:GT': {
    eng: 'Guatemala',
    ru: 'Гватемала',
  },
  'Key:HN': {
    eng: 'Honduras',
    ru: 'Гондурас',
  },
  'Key:HU': {
    eng: 'Hungary',
    ru: 'Венгрия',
  },
  'Key:IS': {
    eng: 'Iceland',
    ru: 'Исландия',
  },
  'Key:IN': {
    eng: 'India',
    ru: 'Индия',
  },
  'Key:ID': {
    eng: 'Indonesia',
    ru: 'Индонезия',
  },
  'Key:IR': {
    eng: 'Iran',
    ru: 'Иран',
  },
  'Key:IQ': {
    eng: 'Iraq',
    ru: 'Ирак',
  },
  'Key:IE': {
    eng: 'Ireland',
    ru: 'Ирландия',
  },
  'Key:IL': {
    eng: 'Israel',
    ru: 'Израиль',
  },
  'Key:IT': {
    eng: 'Italy',
    ru: 'Италия',
  },
  'Key:JM': {
    eng: 'Jamaica',
    ru: 'Ямайка',
  },
  'Key:JP': {
    eng: 'Japan',
    ru: 'Япония',
  },
  'Key:JO': {
    eng: 'Jordan',
    ru: 'Иордания',
  },
  'Key:KZ': {
    eng: 'Kazakhstan',
    ru: 'Казахстан',
  },
  'Key:KE': {
    eng: 'Kenya',
    ru: 'Кения',
  },
  'Key:KW': {
    eng: 'Kuwait',
    ru: 'Кувейт',
  },
  'Key:KG': {
    eng: 'Kyrgyzstan',
    ru: 'Киргизия',
  },
  'Key:LA': {
    eng: 'Laos',
    ru: 'Лаос',
  },
  'Key:LV': {
    eng: 'Latvia',
    ru: 'Латвия',
  },
  'Key:LB': {
    eng: 'Lebanon',
    ru: 'Ливан',
  },
  'Key:LY': {
    eng: 'Libya',
    ru: 'Ливия',
  },
  'Key:LI': {
    eng: 'Liechtenstein',
    ru: 'Лихтенштейн',
  },
  'Key:LT': {
    eng: 'Lithuania',
    ru: 'Литва',
  },
  'Key:LU': {
    eng: 'Luxembourg',
    ru: 'Люксембург',
  },
  'Key:MK': {
    eng: 'Macedonia',
    ru: 'Македония',
  },
  'Key:MY': {
    eng: 'Malaysia',
    ru: 'Малайзия',
  },
  'Key:MV': {
    eng: 'Maldives',
    ru: 'Мальдивы',
  },
  'Key:MX': {
    eng: 'Mexico',
    ru: 'Мексика',
  },
  'Key:MD': {
    eng: 'Moldova',
    ru: 'Молдавия',
  },
  'Key:MC': {
    eng: 'Monaco',
    ru: 'Монако',
  },
  'Key:MN': {
    eng: 'Mongolia',
    ru: 'Монголия',
  },
  'Key:ME': {
    eng: 'Montenegro',
    ru: 'Черногория',
  },
  'Key:MZ': {
    eng: 'Mozambique',
    ru: 'Мозамбик',
  },
  'Key:NA': {
    eng: 'Namibia',
    ru: 'Намибия',
  },
  'Key:NP': {
    eng: 'Nepal',
    ru: 'Непал',
  },
  'Key:NL': {
    eng: 'Netherlands',
    ru: 'Нидерланды',
  },
  'Key:NZ': {
    eng: 'New Zealand',
    ru: 'Новая Зеландия',
  },
  'Key:NI': {
    eng: 'Nicaragua',
    ru: 'Никарагуа',
  },
  'Key:NG': {
    eng: 'Nigeria',
    ru: 'Нигерия',
  },
  'Key:KP': {
    eng: 'North Korea',
    ru: 'Северная Корея',
  },
  'Key:NO': {
    eng: 'Norway',
    ru: 'Норвегия',
  },
  'Key:OM': {
    eng: 'Oman',
    ru: 'Оман',
  },
  'Key:PK': {
    eng: 'Pakistan',
    ru: 'Пакистан',
  },
  'Key:PA': {
    eng: 'Panama',
    ru: 'Панама',
  },
  'Key:PY': {
    eng: 'Paraguay',
    ru: 'Парагвай',
  },
  'Key:PE': {
    eng: 'Peru',
    ru: 'Перу',
  },
  'Key:PH': {
    eng: 'Philippines',
    ru: 'Филиппины',
  },
  'Key:PL': {
    eng: 'Poland',
    ru: 'Польша',
  },
  'Key:PT': {
    eng: 'Portugal',
    ru: 'Португалия',
  },
  'Key:QA': {
    eng: 'Qatar',
    ru: 'Катар',
  },
  'Key:RO': {
    eng: 'Romania',
    ru: 'Румыния',
  },
  'Key:RU': {
    eng: 'Russia',
    ru: 'Россия',
  },
  'Key:SA': {
    eng: 'Saudi Arabia',
    ru: 'Саудовская Аравия',
  },
  'Key:SN': {
    eng: 'Senegal',
    ru: 'Сенегал',
  },
  'Key:RS': {
    eng: 'Serbia',
    ru: 'Сербия',
  },
  'Key:SG': {
    eng: 'Singapore',
    ru: 'Сингапур',
  },
  'Key:SK': {
    eng: 'Slovakia',
    ru: 'Словакия',
  },
  'Key:SI': {
    eng: 'Slovenia',
    ru: 'Словения',
  },
  'Key:ZA': {
    eng: 'South Africa',
    ru: 'ЮАР',
  },
  'Key:ES': {
    eng: 'Spain',
    ru: 'Испания',
  },
  'Key:LK': {
    eng: 'Sri Lanka',
    ru: 'Шри-Ланка',
  },
  'Key:SD': {
    eng: 'Sudan',
    ru: 'Судан',
  },
  'Key:SE': {
    eng: 'Sweden',
    ru: 'Швеция',
  },
  'Key:CH': {
    eng: 'Switzerland',
    ru: 'Швейцария',
  },
  'Key:SY': {
    eng: 'Syria',
    ru: 'Сирия',
  },
  'Key:TJ': {
    eng: 'Tajikistan',
    ru: 'Таджикистан',
  },
  'Key:TZ': {
    eng: 'Tanzania',
    ru: 'Танзания',
  },
  'Key:TH': {
    eng: 'Thailand',
    ru: 'Таиланд',
  },
  'Key:TT': {
    eng: 'Trinidad and Tobago',
    ru: 'Тринидад и Тобаго',
  },
  'Key:TN': {
    eng: 'Tunisia',
    ru: 'Тунис',
  },
  'Key:TR': {
    eng: 'Turkey',
    ru: 'Турция',
  },
  'Key:TM': {
    eng: 'Turkmenistan',
    ru: 'Туркмения',
  },
  'Key:UG': {
    eng: 'Uganda',
    ru: 'Уганда',
  },
  'Key:UA': {
    eng: 'Ukraine',
    ru: 'Украина',
  },
  'Key:AE': {
    eng: 'United Arab Emirates',
    ru: 'ОАЭ',
  },
  'Key:GB': {
    eng: 'United Kingdom',
    ru: 'Великобритания',
  },
  'Key:US': {
    eng: 'United States',
    ru: 'США',
  },
  'Key:UY': {
    eng: 'Uruguay',
    ru: 'Уругвай',
  },
  'Key:UZ': {
    eng: 'Uzbekistan',
    ru: 'Узбекистан',
  },
  'Key:VE': {
    eng: 'Venezuela',
    ru: 'Венесуэла',
  },
  'Key:VN': {
    eng: 'Vietnam',
    ru: 'Вьетнам',
  },
  'Key:YE': {
    eng: 'Yemen',
    ru: 'Йемен',
  },
  'Key:ZM': {
    eng: 'Zambia',
    ru: 'Замбия',
  },
  'Key:ZW': {
    eng: 'Zimbabwe',
    ru: 'Зимбабве',
  },
};
