import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';
import { useForm, FormProvider } from 'react-hook-form';

import localize, { t } from '../../../../localization';
import FormBuilder from '../TemplateForm/FormBuilder';
import { FieldConfig } from '../TemplateForm/useFormBuilder';
import { addToFavoriteLocalization } from './addToFavorite.loc';
import { setAddToFavoriteModalOpenFalse } from './modal.control';
localize.addTranslations(addToFavoriteLocalization);

export const getFormFields = (title: string, description = ''): FieldConfig[] => [
  {
    name: 'title',
    value: title,
    type: 'text',
    label: t('AddToFavorite:Title:Label'),
    required: true,
  },
  {
    name: 'description',
    value: description,
    type: 'prompt',
    label: t('AddToFavorite:Description:Label'),
    hint: t('AddToFavorite:Description:Hint'),
  },
];

export const AddToFavouriteModal: FC<{
  saveToFavorite: (title: string, description: string) => Promise<unknown>;
  templateDefaultName: string;
  isOpen: boolean;
}> = memo(({ saveToFavorite, templateDefaultName, isOpen }) => {
  const { t } = useTranslation();
  const [isSaving, setSaving] = useState(false);
  const fields = useMemo(() => (isOpen ? getFormFields(templateDefaultName) : []), [templateDefaultName, isOpen]);
  const methods = useForm({ reValidateMode: 'onChange' });
  const { formState, handleSubmit, control, reset } = methods;

  const saveToFavoriteHandle = useCallback(
    async (formValues?: any) => {
      setSaving(true);
      await saveToFavorite(formValues.title, formValues.description);
      setSaving(false);
      setAddToFavoriteModalOpenFalse();
      reset({});
    },
    [reset, saveToFavorite]
  );

  const save = useCallback(() => {
    handleSubmit(saveToFavoriteHandle)();
  }, [handleSubmit, saveToFavoriteHandle]);

  const closeModalAndResetForm = useCallback(() => {
    reset({});
    setAddToFavoriteModalOpenFalse();
  }, [reset]);

  return (
    <Modal
      isOpen={isOpen}
      className='mobileBottomModal'
      title={t('AddToFavorite')}
      disableActionButtonAutoFocus
      buttonSubmitText={t('AddToFavorite:Confirm')}
      buttonCancelText={t('cancel')}
      onCancelClick={closeModalAndResetForm}
      onActionClick={save}
      buttonSubmitDisabled={Object.keys(formState.errors).length > 0 || isSaving}
      inProgress={isSaving}
      buttonCancelColor='secondary'
      noValidate
    >
      <p className='margin-bottom-24'>{t('AddToFavoriteSubText')}</p>
      <FormProvider {...methods}>
        {/* inline style to prevent modal height change on exit  */}
        <div className='flex flex-col gap-24' style={{ minHeight: '237px' }}>
          {fields.map(field => (
            <FormBuilder
              key={field.name}
              control={control}
              isEdit={false}
              data={{}}
              param={field}
              handleFileUpload={() => Promise.resolve(undefined)}
            />
          ))}
        </div>
      </FormProvider>
    </Modal>
  );
});
AddToFavouriteModal.displayName = 'memo(AddToFavouriteModal)';
