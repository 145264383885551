var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { Button } from '../index';
import './TextareaAutosize.scss';
import { debounce } from 'lodash';
function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    }
    else if (ref) {
        //@ts-ignore
        ref.current = value;
    }
}
function useForkRef(refA, refB) {
    return React.useMemo(() => {
        if (refA == null && refB == null) {
            return null;
        }
        return refValue => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);
}
function getStyleValue(computedStyle, property) {
    //@ts-ignore
    return parseInt(computedStyle[property], 10) || 0;
}
const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;
export const TextareaAutosize = React.forwardRef((props, ref) => {
    const { onChange, rows, rowsMax, rowsMin: rowsMinProp = 1, value, name, clearable, className, valid, invalid, errorText, warning, bsSize = 'md', disableAutosize: disableAutosizeProp = false, maxLength } = props, other = __rest(props, ["onChange", "rows", "rowsMax", "rowsMin", "value", "name", "clearable", "className", "valid", "invalid", "errorText", "warning", "bsSize", "disableAutosize", "maxLength"]);
    const rowsMin = rows || rowsMinProp;
    const { current: isControlled } = React.useRef(typeof value !== 'undefined');
    const inputRef = React.useRef(null);
    const handleRef = useForkRef(ref ? ref : null, inputRef);
    const shadowRef = React.useRef(null);
    const [state, setState] = React.useState({});
    const syncHeight = React.useCallback(() => {
        if (disableAutosizeProp)
            return false;
        if (!inputRef)
            return false;
        const input = inputRef.current;
        if (!input)
            return false;
        const computedStyle = window.getComputedStyle(input);
        if (!shadowRef.current)
            return false;
        const inputShallow = shadowRef.current;
        if (!inputShallow)
            return false;
        inputShallow.style.width = computedStyle.width;
        inputShallow.value = input.value || props.placeholder || 'x';
        const boxSizing = computedStyle.boxSizing;
        const padding = getStyleValue(computedStyle, 'padding-bottom') + getStyleValue(computedStyle, 'padding-top');
        const border = getStyleValue(computedStyle, 'border-bottom-width') + getStyleValue(computedStyle, 'border-top-width');
        // Measure height of a textarea with a single row
        if (inputShallow && !inputShallow.value) {
            inputShallow.value = 'x';
        }
        // The height of the inner content
        let innerHeight = inputShallow.scrollHeight - padding;
        const lh = getStyleValue(computedStyle, 'line-height');
        if (innerHeight < lh) {
            innerHeight = lh;
        }
        const singleRowHeight = lh;
        // The height of the outer content
        let outerHeight = innerHeight;
        if (rowsMin) {
            outerHeight = Math.max(Number(rowsMin) * singleRowHeight, outerHeight);
        }
        outerHeight = Math.max(outerHeight, singleRowHeight);
        let maxHeightStyle = outerHeight;
        if (rowsMax) {
            maxHeightStyle = Number(rowsMax) * singleRowHeight + (boxSizing === 'border-box' ? padding + border : 0);
        }
        // Take the box sizing into account for applying this value as a style.
        const outerHeightStyle = Math.max(outerHeight + (boxSizing === 'border-box' ? padding + border : 0), input.scrollHeight);
        const overflow = Math.abs(outerHeight - innerHeight) <= 1;
        setState(prevState => {
            // Need a large enough different to update the height.
            // This prevents infinite rendering loop.
            if ((outerHeightStyle > 0 && Math.abs((prevState.outerHeightStyle || 0) - outerHeightStyle) > 1) ||
                prevState.overflow !== overflow) {
                return {
                    overflow,
                    outerHeightStyle,
                    maxHeightStyle,
                };
            }
            return prevState;
        });
    }, [disableAutosizeProp, props.placeholder, rowsMin, rowsMax]);
    React.useEffect(() => {
        const handleResize = debounce(() => {
            syncHeight();
        }, 166);
        window.addEventListener('resize', handleResize);
        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, [syncHeight]);
    useEnhancedEffect(() => {
        syncHeight();
    });
    const handleChange = () => {
        if (!isControlled) {
            syncHeight();
        }
        if (onChange) {
            const input = inputRef.current;
            if (!input)
                return false;
            onChange(input.value, name);
        }
    };
    const clearValue = () => {
        const input = inputRef.current;
        if (input) {
            input.value = '';
            syncHeight();
            handleChange();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("textarea", Object.assign({ maxLength: maxLength, className: classNames('form-control', `form-control-${bsSize}`, className, { clearable: clearable && !other.disabled && !other.readOnly }, { 'is-valid': valid }, { 'is-invalid': invalid }, { 'is-warning': warning }), value: value, onChange: handleChange, ref: handleRef, 
                // Apply the rows prop to get a "correct" first SSR paint
                rows: rowsMin, style: {
                    height: state.outerHeightStyle,
                    maxHeight: state.maxHeightStyle,
                    // Need a large enough different to allow scrolling.
                    // This prevents infinite rendering loop.
                    overflow: state.overflow ? 'hidden' : '',
                    // ...style,
                } }, other)), clearable && !other.disabled && !other.readOnly ? (_jsx(Button, { onClick: clearValue, flat: true, className: 'clear', size: bsSize, children: _jsx(Icon, { name: 'faTimesCircle', color: 'primary' }) })) : null, _jsx("textarea", { "aria-hidden": true, className: classNames(props.className, 'TextareaAutosize-shadow'), readOnly: true, ref: shadowRef, tabIndex: -1 }), errorText && _jsx("div", { style: { color: 'var(--danger)', fontSize: 12 }, children: errorText })] }));
});
TextareaAutosize.displayName = 'TextareaAutosize';
