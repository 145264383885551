import React, { FC, useCallback, useEffect } from 'react';

import {
  Button,
  IconButton,
  InputText,
  Modal,
  Spinner,
  usePromiseProcessing,
  useToggle,
  useTranslation,
} from '@just-ai/just-ui';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { linksAddModalLocalization } from './linksAddModal.loc';
import { isValidHttpUrl } from './utils';
import localize from '../../../localization';
import { useFileUploadProvider } from '../../../pages/Files/FilesUploadProvider';

localize.addTranslations(linksAddModalLocalization);

export const LinksAdd: FC<{}> = () => {
  const { t } = useTranslation();
  const [isModalOpen, setOpenModal, setCloseModal] = useToggle(false);
  const { batchCreateLinks } = useFileUploadProvider();

  const { control, reset, handleSubmit, formState } = useForm<{ links: string[] }>({
    shouldUnregister: false,
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      links: [''],
    },
  });
  const { fields, append, remove, update } = useFieldArray({
    //@ts-ignore
    name: 'links',
    control,
    validate: {
      minLength: 1,
    },
  });

  const onClickAdd = useCallback(() => {
    append('');
  }, [append]);

  const onRemoveItem = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const dropState = useCallback(
    (index: number) => {
      update(index, ['']);
    },
    [update]
  );

  useEffect(() => {
    !isModalOpen && reset();
  }, [isModalOpen, reset]);

  const [{ loading }, batchCreateLinksOptimistic] = usePromiseProcessing(batchCreateLinks, { throwOnError: true });

  const onActionClick = useCallback(
    ({ links }: { links: string[] }) => {
      batchCreateLinksOptimistic(links).then(() => {
        setCloseModal();
        reset();
      });
    },
    [batchCreateLinksOptimistic, reset, setCloseModal]
  );

  return (
    <>
      <Button iconLeft='farLink' color='secondary' outline onClick={setOpenModal}>
        {t('Files:Page:button-upload-link')}
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={setCloseModal}
        buttonSubmitTestId='UploadModal.SubmitButton'
        buttonCancelTestId='UploadModal.RejectButton'
        onCancelClick={setCloseModal}
        buttonCancelText={t('close')}
        title={t('ModalUploadLinks:Title')}
        onActionClick={handleSubmit(onActionClick, console.log)}
        buttonSubmitDisabled={(!formState.isValid && formState.isDirty) || loading}
        disableActionButtonAutoFocus
      >
        {loading && <Spinner />}
        <div className='flex-column gap-8'>
          {fields.map((field, index) => (
            <div className='flex flex-row gap-8' key={field.id}>
              <div className='flex flex-grow-1'>
                <Controller
                  name={`links.${index}`}
                  rules={{
                    validate: value => isValidHttpUrl(value),
                  }}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref, name }, fieldState }) => (
                    <InputText
                      innerRef={ref}
                      name={name}
                      onChange={onChange}
                      invalid={fieldState.invalid}
                      errorText={fieldState.invalid ? t('ModalAddLinks:LinkIsInvalid') : undefined}
                      placeholder={t('ModalAddLinks:Placeholder')}
                    />
                  )}
                />
              </div>

              {index > 0 && (
                <IconButton
                  name='farTrashAlt'
                  wrapperIconClassName='gray-800'
                  color='secondary'
                  flat
                  onClick={() => {
                    dropState(index);
                    onRemoveItem(index);
                  }}
                />
              )}
            </div>
          ))}
        </div>

        <Button
          color='primary'
          compact
          flat
          size='sm'
          outline={false}
          onClick={onClickAdd}
          className='margin-top-8 box-shadow-none'
        >
          {t('ModalAddLinks:addLink')}
        </Button>
      </Modal>
    </>
  );
};
