import React from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { appOptions } from '../../api/cc.api';
import { goToCloud } from '../../api/client';
import { showSignup, showSignupModal } from '../../hooks/showSignup';

export default function SignupModal() {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Demo:Modal:name')}
      isOpen={showSignup.value}
      buttonSubmitText={t(`Demo:Modal:submit`)}
      onActionClick={() => goToCloud('register', appOptions.value?.appUrl)}
      buttonCancelColor='secondary'
      onClickCloseIcon={() => showSignupModal(false)}
      className={cn('mobileBottomModal')}
      disableActionButtonAutoFocus
    >
      {t('Demo:Modal:text')}
    </Modal>
  );
}
