import React from 'react';

import { AccountUserLimit, RefreshPeriodType } from '@just-ai/api/dist/generated/CopilotGateway';
import {
  Checkbox,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  IconButton,
  RowComponentType,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import localize, { t } from '../../../../localization';
import { LimitModalProps } from '../LimitCrudModal';

export type Limit = {
  id: number;
  amount?: number;
  period?: string;
  periodType?: RefreshPeriodType;
  nextRefresh?: number | Date;
  userEmail?: string;
  general?: boolean;
  editActions?: {
    edit?: () => void;
    delete?: () => void;
  };
  userData?: AccountUserLimit['user'];
};

type LimitsTableProps = {
  limits?: Limit[];
  selectedLimits: Limit[];
  setSelectedLimits: React.Dispatch<React.SetStateAction<Limit[]>>;
  openModalHandler: (value: LimitModalProps['type'], data?: Limit[]) => void;
};

const RowComponent: RowComponentType<Limit, { isSelected: boolean }> = ({ data, onChange, payload, index }) => {
  const { getLocale } = useTranslation();
  const periodString = () => {
    if (!data.amount) return '—';
    return data.periodType === 'weeks'
      ? t('Limits:periodWeekly')
      : Number(data.period)
        ? t(`Limits:periodDaysString`, {
            amount: data.period,
            pluralString: localize.translateWithPlural('day', Number(data.period)),
          })
        : '—';
  };
  return (
    <>
      {!data.general && (
        <td className='text-center'>
          <Checkbox
            className='table-row-checkbox'
            noMargin
            name={`selectLimit__${index}`}
            value={payload?.isSelected}
            onChange={value => onChange(data)}
            data-test-id={`Limits:table:row:select:${index}`}
          />
        </td>
      )}
      <td className='text-left' colSpan={data.general ? 2 : 1}>
        {data.general ? (
          <div className={styles.Table__offsetCell}>
            <p>{t('Account:limits:generalLimit')}</p>
            <p className='text-small text-secondary'>{t('Account:limits:generalLimitSub')}</p>
          </div>
        ) : (
          data.userEmail
        )}
      </td>
      <td>
        {data.amount
          ? `${data.amount.toLocaleString(getLocale(), { maximumFractionDigits: 3 })} JT`
          : t('Account:limits:noLimit')}
      </td>
      <td>{periodString()}</td>
      <td>{data.nextRefresh ? format(new Date(data.nextRefresh), 'dd.MM.yyyy') : '—'}</td>
      <td className='pr-4'>
        {data.general ? (
          <IconButton
            data-test-id='Limits:table:row:general:edit'
            className='ml-auto'
            name='farPen'
            onClick={data.editActions?.edit}
          />
        ) : (
          <DropdownButton direction='down'>
            <DropdownToggle color='none' tag='div'>
              <IconButton className='ml-auto' name='farEllipsisH' data-test-id={`Limits:table:row:edit:${index}`} />
            </DropdownToggle>
            <DropdownMenu positionFixed={true}>
              <DropdownItem
                data-test-id={`Limits:table:dropdown:edit:${index}`}
                className={cn(styles.Table__dropdownItem, 'flex items-center')}
                iconSize='md'
                iconColor='secondary'
                iconLeft='farPen'
                onClick={data.editActions?.edit}
              >
                {t('edit')}
              </DropdownItem>
              <DropdownItem
                data-test-id={`Limits:table:dropdown:delete:${index}`}
                className={cn(styles.Table__dropdownItem, styles['Table__dropdownItem--red'], 'flex items-center')}
                iconLeft='farTrashAlt'
                color='red'
                iconSize='md'
                onClick={data.editActions?.delete}
              >
                {t('delete')}
              </DropdownItem>
            </DropdownMenu>
          </DropdownButton>
        )}
      </td>
    </>
  );
};

export default function LimitsTable(props: LimitsTableProps) {
  const { limits = [], selectedLimits, setSelectedLimits, openModalHandler } = props;

  const { t } = useTranslation();

  const handleSelectAll = () => {
    if (selectedLimits.length === limits.length - 1) return setSelectedLimits([]);
    return setSelectedLimits(limits.filter(limit => !limit.general));
  };

  const handleSelectRow = (limit: Limit) => {
    if (!selectedLimits.find(selectedLimit => selectedLimit.userEmail === limit.userEmail))
      return setSelectedLimits(prevSelected => [...prevSelected, limit]);
    return setSelectedLimits(prevLimits => prevLimits.filter(prevLimit => prevLimit.userEmail !== limit.userEmail));
  };

  return (
    <>
      <table className={cn(styles.Table, styles.Table__limits)}>
        <thead>
          <tr>
            <td className={cn('text-center', styles.Table__checkboxCell)}>
              <Checkbox
                className='table-row-checkbox'
                noMargin
                name='selectAll'
                value={limits.length > 1 && selectedLimits.length === limits.length - 1}
                onChange={handleSelectAll}
              />
            </td>
            <td className='text-left'>{t('User')}</td>
            <td>{t('AccountPage:MainTabs:Limit')}</td>
            <td>{t('Analytics:limit:period')}</td>
            <td>{t('Account:limit:refresh')}</td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {limits.map((limit, index) => (
            <React.Fragment key={limit.userEmail || 'general'}>
              <tr>
                <RowComponent
                  data={{
                    ...limit,
                    editActions: {
                      edit: () => openModalHandler(limit.general ? 'general' : 'update', [limit]),
                      delete: () => openModalHandler('delete', [limit]),
                    },
                  }}
                  index={index}
                  onChange={handleSelectRow}
                  payload={{
                    isSelected: !!selectedLimits.find(selectedLimit => selectedLimit.userEmail === limit.userEmail),
                  }}
                />
              </tr>
              <tr>
                <td style={{ borderStyle: 'none', padding: limit.general ? '8px 0' : '4px 0' }} colSpan={6}></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
}
