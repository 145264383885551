import React from 'react';

import { Icon } from '@just-ai/just-ui';

import { FormBuilderField } from '../../types';
import styles from './style.module.scss';

export default function UrlField({ label, url, type, title }: FormBuilderField) {
  return (
    <div>
      {title ? <div className='font-size-12 line-height-18 color-gray-800 margin-bottom-8'>{title}</div> : null}
      <a href={url} className={styles.urlField} rel='noopener noreferrer' target='_blank'>
        <div className='d-flex gap-8'>
          <Icon name={type === 'link' ? 'faPlayCircle' : 'faInfoCircle'} color='primary' className='fa-20' />
          {label}
        </div>
        <Icon name='farExternalLink' size='sm' />
      </a>
    </div>
  );
}
