/* eslint-disable import/order */
// tslint:disable
//@ts-nocheck
/**
 * hackgpt_modules API
 * hackgpt_modules API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface BuyAdditionalPackagesResultDto
 */
export interface BuyAdditionalPackagesResultDto {
  /**
   *
   * @type {boolean}
   * @memberof BuyAdditionalPackagesResultDto
   */
  changed?: boolean;
  /**
   *
   * @type {string}
   * @memberof BuyAdditionalPackagesResultDto
   */
  paymentSystem?: PaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof BuyAdditionalPackagesResultDto
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BuyAdditionalPackagesResultDto
   */
  paymentId?: string;
  /**
   *
   * @type {string}
   * @memberof BuyAdditionalPackagesResultDto
   */
  stripeSessionId?: string;
  /**
   *
   * @type {string}
   * @memberof BuyAdditionalPackagesResultDto
   */
  stripePublicKey?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentSystemEnum {
  YANDEXCASH = 'YANDEX_CASH',
  STRIPE = 'STRIPE',
}

/**
 *
 * @export
 * @interface BuyPackageDto
 */
export interface BuyPackageDto {
  /**
   *
   * @type {string}
   * @memberof BuyPackageDto
   */
  packageName?: string;
  /**
   *
   * @type {string}
   * @memberof BuyPackageDto
   */
  redirectUrl?: string;
}
/**
 *
 * @export
 * @interface UserBalance
 */
export interface UserBalance {
  /**
   *
   * @type {string}
   * @memberof UserBalance
   */
  tokenType?: TokenTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UserBalance
   */
  tokenAmount?: number;
  /**
   *
   * @type {Date}
   * @memberof UserBalance
   */
  lastUpdate?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum TokenTypeEnum {
  Value = 'value',
  Started = 'started',
  Bonus = 'bonus',
  Promo = 'promo',
  Paid = 'paid',
  Onboarding = 'onboarding',
}

/**
 *
 * @export
 * @interface UserBalancesResponse
 */
export interface UserBalancesResponse {
  /**
   *
   * @type {Array<UserBalance>}
   * @memberof UserBalancesResponse
   */
  balances?: Array<UserBalance>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary POST api/gateway/web/packages/buy
     * @param {BuyPackageDto} buyPackageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyCCPackage(buyPackageDto: BuyPackageDto, options: any = {}): RequestArgs {
      // verify required parameter 'buyPackageDto' is not null or undefined
      if (buyPackageDto === null || buyPackageDto === undefined) {
        throw new RequiredError(
          'buyPackageDto',
          'Required parameter buyPackageDto was null or undefined when calling buyCCPackage.'
        );
      }
      const localVarPath = `/api/gateway/web/packages/buy`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof buyPackageDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(buyPackageDto !== undefined ? buyPackageDto : {})
        : buyPackageDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary GET api/gateway/web/balances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalances(options: any = {}): RequestArgs {
      const localVarPath = `/api/gateway/web/balances`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary POST api/gateway/web/packages/buy
     * @param {BuyPackageDto} buyPackageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyCCPackage(
      buyPackageDto: BuyPackageDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyAdditionalPackagesResultDto> {
      const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).buyCCPackage(buyPackageDto, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary GET api/gateway/web/balances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalances(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBalancesResponse> {
      const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBalances(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary POST api/gateway/web/packages/buy
     * @param {BuyPackageDto} buyPackageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyCCPackage(buyPackageDto: BuyPackageDto, options?: any): AxiosPromise<BuyAdditionalPackagesResultDto> {
      return DefaultApiFp(configuration).buyCCPackage(buyPackageDto, options)(axios, basePath);
    },
    /**
     *
     * @summary GET api/gateway/web/balances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalances(options?: any): AxiosPromise<UserBalancesResponse> {
      return DefaultApiFp(configuration).getBalances(options)(axios, basePath);
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary POST api/gateway/web/packages/buy
   * @param {BuyPackageDto} buyPackageDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public buyCCPackage(buyPackageDto: BuyPackageDto, options?: any) {
    return DefaultApiFp(this.configuration).buyCCPackage(buyPackageDto, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary GET api/gateway/web/balances
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getBalances(options?: any) {
    return DefaultApiFp(this.configuration).getBalances(options)(this.axios, this.basePath);
  }
}
