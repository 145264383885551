import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { PromptContextAction } from '@just-ai/api/dist/generated/AppsAdapter';
import { Button, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { useSignal } from '@preact/signals-react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { Gallery } from 'react-photoswipe-gallery';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

import AutoContextReset from './AutoContextReset';
import ChatMessageButtons from './ChatMessageButtons';
import { RequestIcon, ResponseIcon } from './ChatMessageIcons';
import { DayOfMessageChanges } from './DayOfMessageChanges';
import FileMessage from './FileMessage';
import ImageGallery from './ImageGallery';
import { SlideMessage } from './SlideMessage';
import styles from './style.module.scss';
import { SystemAppUpdate } from './SystemAppUpdate';
import TemplateMessage from './TemplateMessage';
import { ToolCallMessage } from './ToolCallMessage/ToolCallMessage';
import AppContext from '../../contexts/appContext';
import localize, { tWithPlural } from '../../localization';
import { templates } from '../../models/templates';
import { appsAdapterService } from '../../services/service';
import { ValidationTemplate } from '../../types/agents';
import {
  Conversation,
  CopyMessagePart,
  FileMessagePart,
  isMessageToolCall,
  Message,
  TextMessagePart,
} from '../../types/chat';

import insetJsxInRawText from '../../utils/app/common';
import { createDefaultMsg } from '../../utils/app/conversation';
import ValidationError from '../Agents/components/ValidationError/ValidationError';
import { CodeBlock } from '../Markdown/CodeBlock';
import { MemoizedReactMarkdown } from '../Markdown/MemoizedReactMarkdown';

export interface Props {
  isLastMessage: boolean;
  isMessageStreaming: boolean;
  messageContent: Message['content'];
  messageId: string;
  messageType: Message['type'];
  previousMessageId?: string;
  previousMessageType?: string;
  selectedConversationId: Conversation['id'];
  selectedConversationTemplate?: Conversation['config']['template'];
  selectedConversationActions?: PromptContextAction[];
  selectedConversationName?: Conversation['name'];
  sendMessage?: (message: Message) => void;
  sendMessageById?: (messageId: string) => void;
  messageCreatedAt: Message['createdAt'];
  prevMessageCreatedAt?: Message['createdAt'];
  dataTestId?: string;
  tokensSpent?: number;
  entities?: ValidationTemplate;
  nextMessage?: Message;
}

export const DATA_GUARD_ERROR = 'dataguard.common.personal_data_detected';

const useMessageHighlight = (
  messageType: Message['type'],
  message: Message['content'],
  entities?: ValidationTemplate
) => {
  return useMemo(() => {
    const denyEntities = entities?.at(0)?.entities?.filter(el => el?.action === 'deny') as {
      startIndex: number;
      endIndex: number;
    }[];
    if (messageType !== 'request' || !denyEntities?.length) return [];
    const textMessages = message.filter(el => el.type === 'text');
    if (!textMessages.length) return [];

    let textMessage = (textMessages[0] as TextMessagePart)?.text;
    return insetJsxInRawText(
      textMessage,
      denyEntities.map(el => {
        return {
          start: el.startIndex,
          end: el.endIndex,
          jsx: (
            <span className='red-600' data-test-id='Highlight.JGValidationError'>
              {textMessage.substring(el.startIndex, el.endIndex)}
            </span>
          ),
        };
      })
    ).filter(Boolean);
  }, [message, messageType, entities]);
};

const useGetRedactEntities = (messageType: Message['type'], entities?: ValidationTemplate) => {
  const locale = localize.getLocale() === 'eng' ? 'EN' : localize.getLocale().toUpperCase();
  return useMemo(() => {
    if (!entities || !entities?.length || messageType !== 'request') return [];
    return (
      entities
        ?.at(0)
        ?.entities?.filter(el => el?.action === 'redact')
        ?.map(el => (isEmpty(el?.entityLocalization) ? el?.entityKey : el?.entityLocalization[locale])) ?? []
    );
  }, [entities, locale, messageType]);
};

export const ChatMessage: FC<Props> = memo(
  ({
    isLastMessage,
    isMessageStreaming,
    messageContent,
    messageCreatedAt,
    messageId,
    messageType,
    previousMessageId,
    previousMessageType,
    selectedConversationId,
    selectedConversationTemplate,
    selectedConversationActions,
    sendMessage,
    sendMessageById,
    dataTestId,
    prevMessageCreatedAt,
    tokensSpent,
    entities,
    nextMessage,
    selectedConversationName,
  }) => {
    const { t, tWithCheck } = useTranslation();
    const selfRef = useRef<HTMLDivElement>(null);
    const scrollingToBottom = useSignal(false);
    const { templatesMap } = templates.value;

    const textMessageRef = useRef<HTMLDivElement>(null);
    const {
      addAlert,
      handleDeleteMessage: handleDeleteMessageLocally,
      state: { lightMode },
    } = useContext(AppContext);

    const canMessageBeRegenerated = useMemo(() => {
      const isAfterRequest = previousMessageType === 'request';
      if (!selectedConversationTemplate) return false;
      const isEnabledInTemplate = !!templatesMap[selectedConversationTemplate]?.clientFeatures?.messageRegeneration;
      const isResponse = messageType === 'response';
      return isAfterRequest && isEnabledInTemplate && isLastMessage && isResponse;
    }, [isLastMessage, messageType, previousMessageType, selectedConversationTemplate, templatesMap]);

    const canMessageBeDeleted = useMemo(() => {
      if (!selectedConversationTemplate) return false;
      const isEnabledInTemplate = templatesMap[selectedConversationTemplate]?.clientFeatures?.messageDeletion !== false;
      const isNotSystemMessage = messageType !== 'system';
      return isEnabledInTemplate && isNotSystemMessage;
    }, [messageType, selectedConversationTemplate, templatesMap]);

    const isMainChat = selectedConversationTemplate === 'toolAssistant';

    const showContextActions =
      !isMessageStreaming &&
      !nextMessage &&
      previousMessageType === 'request' &&
      !!selectedConversationActions &&
      selectedConversationActions.length > 0;

    const [{ loading: isMessageBeingDeleted }, handleDeleteMessage] = usePromiseProcessing(
      (id: string) =>
        appsAdapterService
          .deleteMessage(selectedConversationId, id)
          .then(() => handleDeleteMessageLocally(selectedConversationId, id)),
      {
        onError: () => addAlert(t('Alert:ChatMessageDeletionError'), 'error'),
      }
    );

    const handleRegenerateMessage = useCallback(async () => {
      if (previousMessageId && sendMessageById) await sendMessageById(previousMessageId);
    }, [previousMessageId, sendMessageById]);

    const handleSendContextAction = useCallback(
      async (prompt: string) => {
        if (sendMessage !== undefined) {
          sendMessage(createDefaultMsg([{ type: 'text', text: prompt }], selectedConversationId, 'request'));
        }
      },
      [selectedConversationId, sendMessage]
    );

    const renderTextMessagePart = (msgContent: TextMessagePart): string => {
      if (msgContent.text === 'defaultMsg') {
        return t(`prompt_${selectedConversationTemplate}`, {
          productName: t('defaultProductName'),
        });
      }

      if (msgContent.localizationKey) {
        let { payload = {}, localizationKey: key } = msgContent;

        /**
         * !!! CODE SMELL ALERT !!!
         * An error message may have dynamic interpolated values that depend on the payload of the error itself.
         * Such errors can be found at {@link file:../../localization/dynamicErrors.loc.js}.
         * This is the place to resolve these values and inject them to the localization template.
         */
        if (['agentapi.agents.request_length_error', 'agentapi.agents.system_prompt_length_error'].includes(key)) {
          payload = {
            ...payload,
            contextLengthWord: tWithPlural('token', Number(payload.contextLength ?? 0)),
            requestedTokensWord: tWithPlural('token', Number(payload.requestedTokens ?? 0)),
          };
        } else if (key === 'agentapi.agents.document_length_error') {
          payload = {
            ...payload,
            charactersWord: tWithPlural('character', Number(payload.characters ?? 0)),
            maxCharactersWord: tWithPlural('character', Number(payload.maxCharacters ?? 0)),
          };
        }

        return tWithCheck(`${key}_${selectedConversationTemplate}`, payload) || t(key, payload);
      }

      return msgContent.text;
    };

    const isContextReset = useMemo(() => {
      if (messageType === 'request') return false;
      return messageContent.some(({ type }) => ['autoContextReset', 'systemAppUpdate'].includes(type));
    }, [messageContent, messageType]);

    const isCopyingEnabled = useMemo(() => {
      const hasTextContent = messageContent.some(
        (msgContent): msgContent is CopyMessagePart =>
          (msgContent.type === 'text' && msgContent.text !== '') ||
          msgContent.type === 'link' ||
          msgContent.type === 'audio' ||
          msgContent.type === 'image'
      );
      return hasTextContent;
    }, [messageContent]);

    useEffect(() => {
      let timeout;
      if (
        isLastMessage &&
        !scrollingToBottom.value &&
        messageContent.some(
          content => ['file', 'image', 'link'].includes(content.type) || (content.type === 'text' && !!content.text)
        )
      ) {
        scrollingToBottom.value = true;
        timeout = setTimeout(() => {
          selfRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [isLastMessage, messageContent, previousMessageType, scrollingToBottom]);

    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
        selfRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
      if (selfRef.current && isMessageStreaming && isLastMessage) resizeObserver.observe(selfRef.current);

      return () => resizeObserver.disconnect();
    }, [isMessageStreaming, isLastMessage]);

    const visualizationContent = useMessageHighlight(messageType, messageContent, entities);
    const redactEntities = useGetRedactEntities(messageType, entities);

    const firstTextMessagePart = messageContent?.at(0) as TextMessagePart;
    if (firstTextMessagePart?.localizationKey === DATA_GUARD_ERROR) {
      return (
        <div className={cn('padding-left-30', styles.chat__message, styles.chat__errorBlock)}>
          <ValidationError errorState={[firstTextMessagePart?.payload] as ValidationTemplate} isMessage={true} />
        </div>
      );
    }

    return (
      <div
        className={cn(`group`, lightMode, styles.chat__messageWrapper, styles[`chat__messageWrapper__${messageType}`])}
        style={{ overflowWrap: 'anywhere' }}
        ref={selfRef}
        data-test-id={dataTestId}
      >
        <DayOfMessageChanges messageCreatedAt={messageCreatedAt} prevMessageCreatedAt={prevMessageCreatedAt} />
        <div className={cn('relative m-auto flex flex-wrap', styles.chat__message)}>
          {!isContextReset && <>{['response', 'system'].includes(messageType) ? <ResponseIcon /> : <RequestIcon />}</>}

          <div className={cn('flex-1 relative')}>
            {!isContextReset && (selectedConversationTemplate || selectedConversationName) && (
              <div className={cn('flex flex-row justify-content-between', styles.chat__message__title)}>
                <div className={styles.chat__message__title__person}>
                  {messageType === 'request'
                    ? t('ChatMessage:you')
                    : selectedConversationTemplate
                      ? templatesMap[selectedConversationTemplate]?.info?.title ?? t('assistantJay')
                      : selectedConversationName}
                </div>
                <div className={styles.chat__message__title__time}>
                  {DateTime.fromMillis(messageCreatedAt).toLocaleString(DateTime.TIME_24_SIMPLE)}
                </div>
              </div>
            )}

            {messageType === 'request' ? (
              <>
                <div ref={textMessageRef}>
                  {messageContent.map((msgContent, index) => (
                    <div
                      key={`${msgContent.type}-${index}`}
                      className={cn(
                        'flex flex-col gap-4 padding-right-32',
                        styles[`chat__messageWrapper__${messageType}__body`]
                      )}
                    >
                      {msgContent.type === 'audio' && (
                        <audio controls className='w-full'>
                          <source src={msgContent.url} type='audio/mpeg' />
                        </audio>
                      )}
                      {msgContent.type === 'file' && (
                        <FileMessage
                          messageType={msgContent.type}
                          messageTimestamp={messageCreatedAt.toString()}
                          file={msgContent.file}
                          name={msgContent.name}
                        />
                      )}
                      {msgContent.type === 'link' && (
                        <FileMessage
                          messageType={msgContent.type}
                          messageTimestamp={messageCreatedAt.toString()}
                          file={msgContent.url}
                          name={msgContent.name}
                          link={{
                            text: msgContent.url,
                            url: msgContent.url,
                          }}
                        />
                      )}
                      {msgContent.type === 'text' && (
                        <div className={cn('flex-1', styles.chat__messageText)}>
                          <p className='margin-bottom-0'>
                            {visualizationContent?.length
                              ? visualizationContent.map((el, index) => (
                                  <React.Fragment key={typeof el === 'string' ? `${el}_${index}` : el.key}>
                                    {el}
                                  </React.Fragment>
                                ))
                              : msgContent.text}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <ChatMessageButtons
                  deleteMessage={canMessageBeDeleted ? handleDeleteMessage : undefined}
                  isMessageBeingDeleted={isMessageBeingDeleted}
                  isDeleteButtonEnabled={!isMessageStreaming}
                  isCopyButtonShown={isCopyingEnabled}
                  messageId={messageId}
                  textMessageRef={textMessageRef}
                  tokensAmount={tokensSpent}
                  redactEntities={redactEntities}
                  nextMessage={nextMessage}
                />
              </>
            ) : (
              <>
                <div
                  ref={textMessageRef}
                  className={cn(styles[`chat__messageWrapper__${messageType}__body`], messageType)}
                >
                  {messageContent.map((msgContent, index) => {
                    if (isMessageToolCall(msgContent)) {
                      if (!isLastMessage) {
                        return null;
                      }
                      // toolcall messages should be in one msg part
                      return (
                        <ToolCallMessage
                          key={`${msgContent.type}-${index}`}
                          content={msgContent.toolCalls}
                          selectedConversationId={selectedConversationId}
                        />
                      );
                    }
                    if (msgContent.type === 'systemAppUpdate' && selectedConversationTemplate) {
                      return (
                        <SystemAppUpdate
                          agentTemplateName={selectedConversationTemplate}
                          content={msgContent}
                          key={`${msgContent.type}-${index}`}
                        />
                      );
                    }
                    if (msgContent.type === 'autoContextReset') {
                      return <AutoContextReset key={`${msgContent.type}-${index}`} />;
                    }
                    if (msgContent.type === 'slide') {
                      return <SlideMessage key={`${msgContent.type}-${index}`} slide={msgContent} />;
                    }

                    return (
                      <React.Fragment key={`${msgContent.type}-${index}`}>
                        {msgContent.type === 'app' && <TemplateMessage agent={msgContent.app} />}
                        {msgContent.type === 'audio' && (
                          <audio controls className='w-full'>
                            <source src={msgContent.url} type='audio/mpeg' />
                          </audio>
                        )}
                        {(msgContent.type === 'link' || msgContent.type === 'image' || msgContent.type === 'file') && (
                          <FileMessage
                            messageType={msgContent.type}
                            messageTimestamp={messageCreatedAt?.toString()}
                            file={(msgContent as FileMessagePart).file || msgContent.url}
                            name={(msgContent as FileMessagePart).name}
                            selectedConversationId={selectedConversationId}
                            isMainChat={isMainChat}
                          />
                        )}
                        <div
                          className='flex flex-col relative'
                          style={{
                            minHeight: msgContent.type === 'text' && isLastMessage && isMessageStreaming ? 54 : '',
                          }}
                        >
                          {msgContent.type === 'text' && msgContent.text && (
                            <>
                              <Gallery id={`#gallery-${messageCreatedAt.toString()}`} withDownloadButton>
                                <MemoizedReactMarkdown
                                  className={cn('flex-1', styles.chat__messageText)}
                                  remarkPlugins={[remarkGfm, remarkMath]}
                                  rehypePlugins={[rehypeMathjax]}
                                  components={{
                                    code({ node, inline, className, children, ...props }) {
                                      if (children.length) {
                                        if (children[0] === '▍') {
                                          return <span className='w-full animate-pulse cursor-default mt-1'>▍</span>;
                                        }

                                        children[0] = (children[0] as string).replace('`▍`', '▍');
                                      }

                                      const match = /language-(\w+)/.exec(className || '');

                                      return !inline ? (
                                        <CodeBlock
                                          key={Math.random()}
                                          language={(match && match[1]) || ''}
                                          value={String(children).replace(/\n$/, '')}
                                          {...props}
                                        />
                                      ) : (
                                        <code className={className} {...props}>
                                          {children}
                                        </code>
                                      );
                                    },
                                    table({ children }) {
                                      return (
                                        <table className='border-collapse border border-black px-3 py-1 dark:border-white'>
                                          {children}
                                        </table>
                                      );
                                    },
                                    th({ children }) {
                                      return (
                                        <th className='break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white'>
                                          {children}
                                        </th>
                                      );
                                    },
                                    td({ children }) {
                                      return (
                                        <td className='break-words border border-black px-3 py-1 dark:border-white'>
                                          {children}
                                        </td>
                                      );
                                    },
                                    img(args) {
                                      return (
                                        <ImageGallery
                                          withExistingGallery
                                          galleryId={`gallery-${messageCreatedAt.toString()}`}
                                          images={[{ largeURL: args.src || '', thumbnailURL: args.src || '' }]}
                                          selectedConversationId={selectedConversationId}
                                          isMainChat={isMainChat}
                                        />
                                      );
                                    },
                                  }}
                                >
                                  {renderTextMessagePart(msgContent)}
                                </MemoizedReactMarkdown>
                              </Gallery>
                            </>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                {messageType === 'response' && (
                  <ChatMessageButtons
                    textMessageRef={textMessageRef}
                    isCopyButtonShown={isCopyingEnabled}
                    regenerateMessage={canMessageBeRegenerated ? handleRegenerateMessage : undefined}
                    deleteMessage={canMessageBeDeleted ? handleDeleteMessage : undefined}
                    isMessageBeingDeleted={isMessageBeingDeleted}
                    isDeleteButtonEnabled={!isMessageStreaming}
                    messageId={messageId}
                    tokensAmount={tokensSpent}
                  />
                )}
              </>
            )}
          </div>
          {showContextActions && (
            <div className={styles.chat__contextActionContainer}>
              {selectedConversationActions!.map((action, index) => (
                <Button
                  key={`chatContextAction-${action.type}-${index}`}
                  className={cn('text-left padding-12 justify-between bg-hover-color-gray-200')}
                  iconRight='farChevronRight'
                  onClick={() => handleSendContextAction(action.prompt)}
                  outline={true}
                  color='secondary'
                >
                  {action.prompt}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
);
ChatMessage.displayName = 'ChatMessage';
