import { ResourceSearchRequest, ResourceType } from '@just-ai/api/dist/generated/AppsAdapter';

export const defaultUserFilesFilters: ResourceSearchRequest = {
  filters: {
    type: ResourceType.UserFiles,
  },
  sort: {
    field: 'createdAt',
    direction: 'desc',
  },
  page: {
    index: 0,
    size: 20,
  },
};
export const defaultAppFilesFilters: ResourceSearchRequest = {
  filters: {
    type: ResourceType.AppFiles,
  },
  sort: {
    field: 'createdAt',
    direction: 'desc',
  },
  page: {
    index: 0,
    size: 20,
  },
};
export const defaultLinkFilters: ResourceSearchRequest = {
  filters: {
    type: ResourceType.Links,
  },
  sort: {
    field: 'createdAt',
    direction: 'desc',
  },
  page: {
    index: 0,
    size: 20,
  },
};
