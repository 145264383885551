import React from 'react';

import { IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import { useAppContext } from '../../../contexts/appContext';
import styles from '../style.module.scss';

interface Props {
  onClick: any;
  side: 'left' | 'right';
}

export const CloseSidebarButton = ({ onClick, side }: Props) => {
  const { state } = useAppContext();
  return (
    <IconButton
      className={cn(`${side} ${state.lightMode}`, styles.sidebar__btn, styles.sidebar__btnClose)}
      onClick={onClick}
      name='faBars'
    />
  );
};

export const OpenSidebarButton = ({ onClick }: Props) => {
  const { state } = useAppContext();

  return (
    <IconButton
      className={cn(`${state.lightMode}`, styles.sidebar__btn, styles.sidebar__btnOpen)}
      onClick={onClick}
      name='faBars'
    />
  );
};
