import React, { useState } from 'react';

import { Button, IconButton, InputText, useTranslation } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import axios, { AxiosError } from 'axios';
import cn from 'classnames';
import DOMPurify from 'dompurify';

import styles from './style.module.scss';
import { TOKENS_BACKEND_MULT } from '../../api/constants';
import localize from '../../localization';
import { currentUser } from '../../models/currentUser';
import useApiService from '../../services/useApiService';

export default function PromocodeBlock() {
  const { t, getLocale } = useTranslation();

  const [promocodeInput, setPromocodeInput] = useState('');
  const [resultTokens, setResultTokens] = useState<string | undefined>();
  const [codeError, setCodeError] = useState('');

  const { applyPromoCode } = useApiService();

  const handlePromocode = async () => {
    if (!currentUser.value?.userId || !promocodeInput) return;
    setCodeError('');
    setResultTokens(undefined);
    try {
      const result = await applyPromoCode(DOMPurify.sanitize(promocodeInput), currentUser.value?.userId);
      setResultTokens(
        (result.data.tokenAmount / TOKENS_BACKEND_MULT).toLocaleString(getLocale(), { maximumFractionDigits: 3 })
      );
    } catch (error) {
      const supportedErrors = [
        'gateway.promo_code.not_found',
        'gateway.promo_code.already_applied',
        'gateway.promo_code.too_many_requests',
        'gateway.promo_code.already_applied_for_account',
        'gateway.promo_code.cant_be_used_for_organization',
        'gateway.promo_code.expired',
        'gateway.promo_code.usage_limit_reached',
      ];
      if (axios.isAxiosError(error) && error.code !== AxiosError.ERR_CANCELED) {
        supportedErrors.includes(error.response?.data.error)
          ? setCodeError(t(error.response?.data.error))
          : AppLogger.error({ message: 'failed to apply promocode', exception: error });
      }
    }
  };

  return (
    <div
      className={cn(styles.accountPage__block, styles['accountPage__block--maxWidth'])}
      data-test-id='Account.PromocodesBlock'
    >
      <p className='text-secondary mb-3'>{t('promocode')}</p>
      <InputText
        className='greyParent'
        placeholder={t('enterPromocode')}
        value={promocodeInput}
        onChange={setPromocodeInput}
        errorText={t(codeError)}
        data-test-id='Account:promo:input'
      />
      {resultTokens && (
        <div className={styles.accountPage__promocodeSuccess} data-test-id='Account:promo:success'>
          <p>
            {t('promocodeSuccess', {
              amount: resultTokens,
              pluralString: localize.translateWithPlural('jayToken', resultTokens),
            })}
          </p>
          <IconButton size='sm' name='farTimes' onClick={() => setResultTokens(undefined)} />
        </div>
      )}
      <Button
        outline
        data-test-id='Account:promo:submit'
        color='primary'
        className='mt-3'
        flat
        onClick={handlePromocode}
      >
        {t('activatePromocode')}
      </Button>
    </div>
  );
}
