import React from 'react';

import { DropdownButton, DropdownMenu, DropdownToggle, IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from '../../../header.module.scss';

type Props = {
  children: React.ReactNode;
};

export default function CanvasLayoutDropdown({ children }: Props) {
  return (
    <DropdownButton direction='down'>
      <DropdownToggle color='none' tag='div'>
        <IconButton className={cn(styles.header__icon)} name='faEllipsisH' size='sm' outline flat color='secondary' />
      </DropdownToggle>
      <DropdownMenu className={cn('border-0', styles.header__dropdown)} positionFixed={true} tag='div' right={true}>
        <div className='padding-x-8'>{children}</div>
      </DropdownMenu>
    </DropdownButton>
  );
}
