import React, { useEffect, useMemo, useRef } from 'react';

import { Banner, Icon } from '@just-ai/just-ui';
import { isEmpty } from 'lodash';

import localize, { t } from '../../../../localization';
import { ValidationTemplate } from '../../../../types/agents';
import styles from './style.module.scss';

const ValidationError = (props: { errorState: ValidationTemplate; isMessage?: boolean }) => {
  const { errorState, isMessage } = props;
  const locale = localize.getLocale() === 'eng' ? 'EN' : localize.getLocale().toUpperCase();
  const deniedEntities = useMemo(() => {
    const entities: string[] = [];
    errorState?.forEach(el =>
      el.entities?.forEach(el => {
        const value = isEmpty(el?.entityLocalization) ? el?.entityKey : el?.entityLocalization[locale];
        if (value && value?.length && el?.action === 'deny') entities.push(value);
      })
    );
    return entities;
  }, [errorState, locale]);

  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [ref]);

  return (
    <div ref={ref} data-test-id={`Banner.JGValidationError.${isMessage ? 'Message' : 'App'}`}>
      <Banner
        type='danger'
        BannerText={() => {
          return (
            <div className={styles.bannerContainer}>
              <p className='flex items-center mb-2'>
                <Icon name='faBan' className={styles.bannerContainer__icon} size='lg' />
                <span>{t(isMessage ? 'validation:Error:Message:Header' : 'validation:Error:Header')}</span>
              </p>
              <p>{t('validation:Error:Summary')}</p>
              <ul className={styles.bannerContainer__entityList}>
                {deniedEntities.map(el => (
                  <li key={el}>{el}</li>
                ))}
              </ul>
              <p>{t(isMessage ? 'validation:Error:Message:Tooltip' : 'validation:Error:Tooltip')}</p>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ValidationError;
