import { Dispatch, createContext, useContext, useCallback } from 'react';

import { ConversationResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';

import { ActionType } from '../hooks/useCreateReducer';
import { AgentType } from '../types/agents';
import { Conversation } from '../types/chat';
import { KeyValuePair } from '../types/data';

export interface AppInitialState {
  userId?: string;
  lightMode: 'light' | 'dark';
  messageIsStreaming: boolean;
  showChatbar: boolean;
  searchTerm: string;
  locale: 'eng' | 'ru';
  balance?: number;
}

export const initialState: AppInitialState = {
  lightMode: 'light',
  messageIsStreaming: false,
  showChatbar: localStorage.getItem('showChatbar') === 'true',
  searchTerm: '',
  locale: 'ru',
};

export interface AppContextProps {
  state: AppInitialState;
  dispatch: Dispatch<ActionType<AppInitialState>>;
  handleNewConversation: (
    rawConversationResponse: ConversationResponse,
    templateSource: AgentType
  ) => Promise<Conversation>;
  handleDeleteConversation: (conversation: Conversation['id']) => void;
  handleUpdateConversation: (conversation: Conversation, data: KeyValuePair | KeyValuePair[]) => void;
  handleDeleteMessage: (conversationId: Conversation['id'], messageId: string) => void;
  addAlert: (message: string, type?: AlertNotificationItemProps['type']) => void;
}

const AppContext = createContext<AppContextProps>(undefined!);

export const useSidebarActions = () => {
  const {
    state: { showChatbar },
    dispatch: homeDispatch,
  } = useContext(AppContext);

  const toggleSidebar = useCallback(() => {
    homeDispatch({ field: 'showChatbar', value: !showChatbar });
    localStorage.setItem('showChatbar', JSON.stringify(!showChatbar));
  }, [homeDispatch, showChatbar]);

  const closeSidebar = useCallback(() => {
    homeDispatch({ field: 'showChatbar', value: false });
    localStorage.setItem('showChatbar', 'false');
  }, [homeDispatch]);

  return [toggleSidebar, closeSidebar];
};

export const useAppContext = () => useContext(AppContext);

export default AppContext;
