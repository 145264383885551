import React, { memo, SVGProps } from 'react';

import AlphaLogo from '../../images/Alfabank_logo.png';
import ResponseImg from '../../images/logo.svg';
import { isAlpha } from '../../isAlpha';

export const ResponseIcon = memo((props: SVGProps<SVGSVGElement>) => {
  return isAlpha ? (
    <img src={AlphaLogo} style={{ width: '34px', height: '24px' }} alt='' />
  ) : (
    <img src={ResponseImg} style={{ width: '20px', height: '20px' }} alt='' />
  );
});

export const RequestIcon = memo((props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x='2' y='2' width='16' height='16' rx='8' fill='#FF8585' />
    </svg>
  );
});
