import React, { FC, memo, useEffect } from 'react';

import cn from 'classnames';

import { CanvasHolderTab } from './CanvasHolderTab';
import styles from './styles.module.scss';
import { Conversation } from '../../../types/chat';
import { useCanvasHolder } from '../CanvasHolderContext/CanvasHolderContext';

const IframePresentation: FC<{
  index: number;
  chatInstance: { instanceId: string; url: string; title: string };
}> = memo(({ index, chatInstance }) => {
  const { registerIframe, unregisterIframe } = useCanvasHolder();

  const iframe = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!iframe.current) return;
    registerIframe(iframe.current, chatInstance.instanceId);

    return () => {
      unregisterIframe(chatInstance.instanceId);
    };
  }, [chatInstance.instanceId, registerIframe, unregisterIframe]);

  return (
    <iframe
      ref={iframe}
      id={chatInstance.instanceId}
      title={chatInstance.title}
      src={chatInstance.url}
      className={cn(styles.canvasHolder_iframe)}
      width='100%'
      height='100%'
    />
  );
});

export const CanvasHolder: FC<{ selectedConversation?: Conversation }> = memo(() => {
  const { canvasInstances } = useCanvasHolder();

  return (
    <div className={cn(styles.canvasHolder)}>
      <div className={cn(styles.canvasHolder_Tabs)}>
        {canvasInstances.map((canvasInstance, index) => (
          <CanvasHolderTab key={canvasInstance.instanceId} canvasInstance={canvasInstance} />
        ))}
      </div>
      {canvasInstances.map((chatInstance, index) => (
        <div
          key={chatInstance.instanceId}
          className={cn(styles.canvasHolder_iframe, { 'd-none': !chatInstance.isActive })}
        >
          <IframePresentation index={index} chatInstance={chatInstance} />
        </div>
      ))}
    </div>
  );
});
