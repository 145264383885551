import React, { useState } from 'react';

import {
  DropdownButton,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  useToggle,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';

import { appOptions } from '../../../api/cc.api';
import TelegramIcon from '../../../images/TelegramIcon';
import { isMobile } from '../../../utils/app/common';
import { SidebarButton } from '../../Sidebar/SidebarButton';
import styles from './style.module.scss';

export default function CommunityDropdown() {
  const [communityModal, showCommunityModal, hideCommunityModal] = useToggle(false);

  const communityLinks = appOptions.value?.product?.communities;

  const { t } = useTranslation();

  if (isMobile())
    return (
      <>
        <SidebarButton
          text={t('community')}
          dataTestId='Sidebar:CommunityBtn'
          iconLeft='farComments'
          iconRight='farChevronRight'
          onClick={showCommunityModal}
        />
        <Modal
          isOpen={communityModal}
          className={cn('mobileBottomModal', styles.chatbarSettings__modal)}
          onClickCloseIcon={hideCommunityModal}
          hideFooter
        >
          {communityLinks?.map(communityLink => (
            <a
              href={communityLink.url}
              rel='noopener noreferrer'
              target='_blank'
              className={cn('d-flex mt-5', styles.chatbarSettings__modalLink)}
              key={communityLink.name}
            >
              {communityLink.icon ? (
                <img src={communityLink.icon} alt='' className={styles.chatbarSettings__commIcon} />
              ) : communityLink.url.startsWith('https://t.me/') ? (
                <TelegramIcon />
              ) : null}
              <div>
                <p className='text-default'>{communityLink.name}</p>
                {communityLink.text && <p className='text-sm text-secondary'>{communityLink.text}</p>}
              </div>
            </a>
          ))}
        </Modal>
      </>
    );

  return (
    <DropdownButton data-test-id='dropdown-wrapper' direction='right' className='w-full'>
      <DropdownToggle color='none' className='w-full p-0' data-test-id='dropdown-toggle' size='default'>
        <SidebarButton
          text={t('community')}
          dataTestId='Sidebar:CommunityBtn'
          iconLeft='farComments'
          iconRight='farChevronRight'
        />
      </DropdownToggle>
      <DropdownMenu data-test-id='dropdown-menu'>
        {communityLinks?.map(communityLink => (
          <DropdownItem key={communityLink.name} className='flex items-center'>
            <a href={communityLink.url} rel='noopener noreferrer' target='_blank' className='d-contents'>
              {communityLink.icon ? (
                <img src={communityLink.icon} alt='' className={styles.chatbarSettings__commIcon} />
              ) : communityLink.url.startsWith('https://t.me/') ? (
                <TelegramIcon />
              ) : null}
              <div>
                <p className='text-default'>{communityLink.name}</p>
                {communityLink.text && <p className='text-sm text-secondary'>{communityLink.text}</p>}
              </div>
            </a>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownButton>
  );
}
