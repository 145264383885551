export const addToFavoriteLocalization = {
  AddToFavorite: {
    eng: 'Add to Favorites',
    ru: 'Добавить в Избранное',
  },
  EditFavorite: {
    eng: 'Edit favorite application',
    ru: 'Редактировать избранное приложение',
  },
  AddToFavoriteSubText: {
    eng: 'The application settings will be saved, it will be available in the “Favorites” section of the application catalog.',
    ru: 'Настройки приложения будут сохранены, оно будет доступно в разделе «Избранное» каталога приложений.',
  },
  'AddToFavorite:Title:Label': {
    eng: 'Application name',
    ru: 'Название приложения',
  },
  'AddToFavorite:Description:Label': {
    eng: 'Description (optional)',
    ru: 'Описание (опционально)',
  },
  'AddToFavorite:Description:Hint': {
    eng: 'The description helps you find the required application faster',
    ru: 'Поможет быстрее найти нужное приложение',
  },
  'AddToFavorite:Confirm': {
    eng: 'Confirm',
    ru: 'Подтвердить',
  },
  'EditFavorite:CreateAndSave': {
    eng: 'Save and create',
    ru: 'Сохранить и создать',
  },
};
