import React, { FC, useCallback } from 'react';

import { Badge, DropDownHint, InputText, useTranslation } from '@just-ai/just-ui';
import { TextInputProps } from '@just-ai/just-ui/dist/InputText';
import { clamp } from 'lodash';
import { useController } from 'react-hook-form';
import { UseControllerReturn } from 'react-hook-form/dist/types/controller';

import { FormBuilderField } from '../../types';

type TextFieldProps = Omit<FormBuilderField, 'data' | 'handleFileUpload'>;

const TextBadge: FC<{ fastOption: string; field: UseControllerReturn['field'] }> = ({ fastOption, field }) => {
  const setFastOption = useCallback(() => {
    field.onChange(fastOption);
  }, [fastOption, field]);

  return <Badge pastel text={fastOption} color='primary' className='cursor-pointer' onClick={setFastOption} />;
};

const TextField = ({
  control,
  name,
  label,
  hint,
  maxLength,
  type,
  max,
  min,
  placeholder,
  required,
  fastOptions,
  count,
  dropDownHint,
  disabled,
}: TextFieldProps) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({ name, control, rules: { required } });

  const numberOnChangeHandler = useCallback(
    value => {
      if (type === 'number') {
        let innerValue = clamp(value, min || 0, max || Infinity);
        field.onChange(value ? innerValue : value);
        return;
      }
      field.onChange(value.trimStart());
    },
    [type, field, min, max]
  );

  return (
    <div className='flex flex-col' key={name} data-test-id={`TemplateForm.InputText.${name}`}>
      <label>{label}</label>
      <InputText
        className='w-full'
        maxLength={maxLength}
        type={type as TextInputProps['type']}
        max={max}
        min={min || 0}
        placeholder={placeholder}
        required={required}
        name={name}
        value={field.value || ''}
        onChange={numberOnChangeHandler}
        data-test-id={`TemplateForm.InputText.${type}-${name}`}
        hint={hint}
        errorText={fieldState.invalid ? t('This field is required') : undefined}
        disabled={disabled}
      />
      {fastOptions?.length ? (
        <div className='fastOptions d-flex flex-row gap-4 margin-top-8'>
          {fastOptions?.map(fastOption => (
            <TextBadge key={fastOption} fastOption={fastOption} field={field} />
          ))}
        </div>
      ) : null}
      {dropDownHint ? <DropDownHint dropDownHint={dropDownHint} /> : null}
    </div>
  );
};

TextField.displayName = 'TextField';

export default React.memo(TextField);
