import React from 'react';

import { IconButton, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { paymentData, setPaymentData } from 'hooks/showBilling';

import localize from '../../localization';
import styles from './style.module.scss';

export default function BillingProcessingPanel() {
  const { t } = useTranslation();
  if (!paymentData.value) return null;

  return (
    <div
      data-test-id='TopPanel.Payment'
      className={cn(styles.topPanel, styles.topPanel_payment, {
        [styles.topPanel_done]: paymentData.value.status === 'done',
        [styles.topPanel_failed]: paymentData.value.status === 'failed',
      })}
    >
      <p>
        {t(
          `Payment:${paymentData.value.status}`,
          { amount: paymentData.value.pendingAmount },
          { pluralString: localize.translateWithPlural('process', Number(paymentData.value.pendingAmount)) }
        )}
      </p>
      {paymentData.value.status === 'done' && (
        <IconButton name='farTimes' size='sm' onClick={() => setPaymentData(undefined)} />
      )}
    </div>
  );
}
