import { computed } from '@preact/signals-react';

import { reduxConnectSignal } from '../../../utils/reduxConnector';
import { createImmerSignal } from '../../hooks';

export const [silentMessages, setSilentMessages] = createImmerSignal<
  {
    id?: string;
    instanceId: string;
    content: ({ text?: string; image_url: never } | { image_url?: string; text: never })[];
  }[]
>([]);

export const dropSilentMessages = () => {
  setSilentMessages([]);
};

reduxConnectSignal(
  'SilentMessages',
  computed(() => ({ silentMessages: silentMessages.value }))
);
