import React, { useEffect, useState } from 'react';

import { ReplenishmentHistoryResponse } from '@just-ai/api/dist/generated/CopilotGateway';
import { Spinner, usePromiseProcessing } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import { DateTime } from 'luxon';
import useApiService from 'services/useApiService';

import { AnalyticsTable } from '.';

export const ReplenishTable = ({ dates }: { dates: Range }) => {
  const [replenishData, setReplenishData] = useState<ReplenishmentHistoryResponse>();

  const { getReplenishmentHistory } = useApiService();

  const [{ loading }, getReplenishHistory] = usePromiseProcessing(
    async () => {
      const { data } = await getReplenishmentHistory({
        periodFrom: DateTime.fromJSDate(dates.startDate).startOf('day').toJSDate(),
        userTimeZone: DateTime.now().zoneName,
        periodTo: DateTime.fromJSDate(dates.endDate).endOf('day').toJSDate(),
        sort: ['timestamp', 'desc'],
      });
      setReplenishData(data);
    },
    {
      deps: [dates, getReplenishmentHistory],
    }
  );

  useEffect(() => {
    getReplenishHistory();
  }, [getReplenishHistory]);

  if (loading) {
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );
  }
  return <AnalyticsTable data={replenishData} dataType='replenish' />;
};
