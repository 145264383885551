import React, { memo, useEffect, useState } from 'react';

import { IconButton, Spinner, usePromiseProcessing } from '@just-ai/just-ui';

import apiClient from '../../api/client';
import { isDev } from '../../utils/app/common';
import hfLogo from './hf-logo.png';

export const HuggingfaceBillingInformer = memo(() => {
  const [isShow] = useState(isDev() || window.location.hostname.endsWith('lo.test-ai.net'));
  const [isRequested, setIsRequested] = useState(false);

  const [{ result, loading, error: errorMessage }, apiCall] = usePromiseProcessing(() =>
    apiClient.get<string>('/api/appsAdapter/tools/hf/billing')
  );
  useEffect(() => {
    if (isShow && !isRequested) {
      setIsRequested(true);
      apiCall();
    }
  }, [apiCall, isRequested, isShow]);
  if (!isShow || errorMessage) return null;

  return (
    <div
      className='d-flex gap-4 border-radius-12 padding-x-8 align-items-center'
      style={{ position: 'fixed', top: 8, right: 16, backgroundColor: '#ffed93', zIndex: 9 }}
    >
      <img src={hfLogo} alt='' />
      <b title=''>{loading ? <Spinner inline /> : result?.data ? '$ ' + parseFloat(result?.data).toFixed(2) : ''}</b>
      <IconButton flat size='sm' onClick={() => apiCall()} name='farSyncAlt' className='cursor-pointer' />
    </div>
  );
});
