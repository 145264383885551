import { useEffect } from 'react';

import { useSignal } from '@preact/signals-react';
import { useParams } from 'react-router';

import appHistory from './appHistory';
import { Conversation } from './types/chat';

export const routes = {
  login: '/login',
  main: '/',
  conversation: '/conversations/:conversationId',
  account: '/account',
  corporate: '/corporate',
  myFiles: '/myfiles',
  analytics: '/account/analytics',
  limits: '/account/limits',
};

export const goToConversation = (id: Conversation['id']) => {
  appHistory.push(routes.conversation.replace(':conversationId', id));
};

export const conversationLink = (id: Conversation['id']) => {
  return routes.conversation.replace(':conversationId', id);
};

export const useConversationId = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const cID = useSignal(conversationId);
  useEffect(() => {
    cID.value = conversationId;
  }, [cID, conversationId]);
  return cID;
};

export const goToMain = () => {
  appHistory.push(routes.main);
};

export const goToAccount = () => {
  appHistory.push(routes.account);
};

export const goToMyFiles = () => {
  appHistory.push(routes.myFiles);
};

export const goToAccountAnalytics = () => {
  appHistory.push(routes.analytics);
};

export const goToAccountLimits = () => {
  appHistory.push(routes.limits);
};

export const goToCorporate = () => {
  appHistory.push(routes.corporate);
};

export const goToLogin = () => {
  appHistory.push(routes.login);
};

export const telegramRoutes = {
  base: '/telegram*',
  telegram: '/telegram',
  telegramDetail: '/telegram/:templateId',
};

export const goTo403 = () => {
  if (!appHistory.location.pathname.endsWith('/403')) {
    appHistory.push('/403');
  }
};
