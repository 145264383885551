import { mimeToExtMap } from './consts';
import { get2LettersLocale } from '../../localization';

export function bytesPrettier(size?: number): string {
  const localeArray = get2LettersLocale() === 'ru' ? ['б', 'Кб', 'Мб', 'Гб'] : ['B', 'kB', 'MB', 'GB'];
  if (!size) return '0';
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${+(size / Math.pow(1024, i)).toFixed(2)} ${localeArray[i]}`;
}
export function getSourceSize(size?: number | null, fallback?: string) {
  if (size && size > 0) return bytesPrettier(size);
  return fallback;
}

export function getOnlyDate(numberdate: number | null): string {
  if (numberdate)
    return new Date(numberdate).toLocaleString(get2LettersLocale(), {
      month: '2-digit',
      day: 'numeric',
      year: 'numeric',
    });
  return 'unknown';
}

export const mimeToExt = (mime?: string, ext?: string) => {
  if (ext) return ext.toUpperCase();
  if (mime && mimeToExtMap[mime]) {
    let ext = mimeToExtMap[mime];
    ext = ext.slice(1, ext.length);
    return ext.toUpperCase();
  }
  if (mime) return mime;
  return '<unknown>';
};
