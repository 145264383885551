import { ErrorResponse, FileResponse } from '@just-ai/api/dist/generated/AppsAdapter';

import apiClient from '../../api/client';

export const getFiles = () => {
  return apiClient.get<FileResponse[]>('/api/appsAdapter/files');
};
export const batchUploadFiles = (files: File[]) => {
  const fd = new FormData();
  for (let file of files) {
    fd.append('files', file);
  }
  return apiClient.post<(FileResponse | ErrorResponse)[]>('/api/appsAdapter/files/batch', fd);
};
export const uploadFile = (file: File) => {
  const fd = new FormData();
  fd.append('file', file);
  return apiClient.post<FileResponse>('/api/appsAdapter/files/upload', fd, {});
};
