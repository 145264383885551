import { useCallback, useEffect, useRef, useState } from 'react';

import { useToggle } from '@just-ai/just-ui';
import useService from 'hooks/useSevice';
import { Subject } from 'rxjs';

import { LocalStorageService } from '../../services/Storage/LocalStorageService';
import { GuideStep } from './types';

export async function beforeStepHandler(
  step: GuideStep,
  allSteps: GuideStep[]
): Promise<[GuideStep, number | undefined]> {
  let nextStepIndex: number | undefined;
  let nextStepWaitEvent: string | null | undefined;
  if (step.beforeStep) {
    await step.beforeStep({
      goToStep: (stepIndex, config) => {
        nextStepIndex = stepIndex;
        if (config?.hasOwnProperty('waitEvent')) {
          nextStepWaitEvent = config.waitEvent;
        }
      },
    });
  }
  if (nextStepIndex !== undefined) {
    const nextStep = allSteps[nextStepIndex];
    if (nextStepWaitEvent !== undefined) {
      nextStep.waitEvent = nextStepWaitEvent;
    }
    return [nextStep, nextStepIndex];
  }
  return [step, undefined];
}

type SkipStatusType = 'skippedOnce' | 'skippedForever' | 'none';
const GUIDE_TOUR_SKIP_STATUS_KEY = 'ASSISTANT_TOUR_SKIP_STATUS_KEY';
export function useSkipGuideTour() {
  const [skipStatus, setSkipStatus] = useState<SkipStatusType | null>(null);
  const [isShowSkipForeverModal, openSkipForeverModal, closeSkipForeverModal] = useToggle(false);
  const localStorageService = useService(LocalStorageService);

  useEffect(() => {
    localStorageService.get<SkipStatusType>(GUIDE_TOUR_SKIP_STATUS_KEY).then(status => {
      setSkipStatus(status.success && status.payload ? status.payload : 'none');
    });
  }, [localStorageService]);

  const skip = useCallback(() => {
    if (!skipStatus || skipStatus === 'none') {
      localStorageService.set(GUIDE_TOUR_SKIP_STATUS_KEY, 'skippedOnce', true);
    }
    if (skipStatus === 'skippedOnce') {
      openSkipForeverModal();
    }
    return;
  }, [localStorageService, openSkipForeverModal, skipStatus]);

  const skipForever = useCallback(() => {
    localStorageService.set(GUIDE_TOUR_SKIP_STATUS_KEY, 'skippedForever', true);
    setSkipStatus('skippedForever');
    closeSkipForeverModal();
  }, [closeSkipForeverModal, localStorageService]);

  const unSkip = useCallback(() => {
    localStorageService.remove(GUIDE_TOUR_SKIP_STATUS_KEY);
    setSkipStatus('none');
  }, [localStorageService]);

  return {
    skipStatus,
    skip,
    skipForever,
    isShowSkipForeverModal,
    closeSkipForeverModal,
    unSkip,
  };
}

export const tourSkipEvent$ = new Subject<string>();
export const useGuideTourCustomSkipEvents = (onStepEventReceived: (event: string) => void) => {
  const onStepEventReceivedRef = useRef(onStepEventReceived);
  useEffect(() => {
    onStepEventReceivedRef.current = onStepEventReceived;
  }, [onStepEventReceived]);

  useEffect(() => {
    const sub = tourSkipEvent$.subscribe(event => onStepEventReceived(event));
    return () => sub.unsubscribe();
  }, [onStepEventReceived]);
};
