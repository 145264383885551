import React, { memo } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import classnames from 'classnames';

import classes from './style.module.scss';

const AutoContextReset = memo(() => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col relative'>
      <div className={classnames('flex-1', classes.chat__messageText)}>
        <div className={classes.SystemAppUpdate_header}>{t('AutoContextReset:contextReset')}</div>
      </div>
    </div>
  );
});

AutoContextReset.displayName = 'memo(AutoContextReset)';
export default AutoContextReset;
