import React, { memo } from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';

import { hideDeleteFavoriteModal, isDeleteShowModalOpen } from './favoriteButtons.control';

export const DeleteFromFavouriteModal = memo(
  ({
    deleteFromFavoriteHandle,
    isDeleting,
    favoriteName,
    favoriteId,
  }: {
    deleteFromFavoriteHandle: () => unknown;
    isDeleting: boolean;
    favoriteName: string;
    favoriteId: string;
  }) => {
    const { t } = useTranslation();
    return (
      <Modal
        isOpen={isDeleteShowModalOpen.value === favoriteId}
        inProgress={isDeleting}
        className='mobileBottomModal'
        title={t('DeleteFromFavorite:Title')}
        buttonSubmitText={t('DeleteFromFavorite:Confirm')}
        onCancelClick={hideDeleteFavoriteModal}
        onActionClick={deleteFromFavoriteHandle}
        buttonCancelColor='secondary'
      >
        <p className='mb-0'>{t('DeleteFromFavorite:Text', { favoriteName })}</p>
      </Modal>
    );
  }
);
DeleteFromFavouriteModal.displayName = 'memo(DeleteFromFavouriteModal)';
