import { isX5 } from '../../isX5';
import json from './data.json';

let jsonData: Record<string, any> = json;
if (isX5) {
  jsonData.models = jsonData.models.filter(
    modelName => modelName.toLowerCase() !== 'GPT-4-Vision-Preview'.toLowerCase()
  );
}

export default jsonData;
