import { computed, signal } from '@preact/signals-react';

import { ExternalApp } from './types';
import { reduxConnectSignal } from '../../utils/reduxConnector';
import { createImmerSignal } from '../hooks';

export const [externalApps, setExternalAppsValue] = createImmerSignal<ExternalApp[]>([]);
export const externalAppsLoaded = signal(false);

reduxConnectSignal(
  'External Apps',
  computed(() => ({ externalApps: externalApps.value, externalAppsLoaded: externalAppsLoaded.value }))
);
