import React, { FC, memo } from 'react';

import { FileResponse, LinkResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { Modal, useTranslation } from '@just-ai/just-ui';

import { FilesPage } from './Files';
import { closeShowModalFileSelector, dropFilters } from './FIlters.Contorller';
import { chatFileFromFileService, chatTextAppendSignal } from '../../components/Chat/signals/ChatUpdateSignal';

export const ChatInputFilesModal: FC<{ isOpen: boolean; formats?: string[] }> = memo(({ isOpen, formats }) => {
  const { t } = useTranslation();

  const onTableRowClick = (source: FileResponse | LinkResponse) => {
    if ('contentType' in source) {
      chatFileFromFileService.value = source;
    } else {
      chatTextAppendSignal.value = source.url;
    }
    closeShowModalFileSelector();
    return;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickCloseIcon={closeShowModalFileSelector}
      title={t('Files:Page:title')}
      size='xl'
      naturalScrollbar
      onClosed={dropFilters}
    >
      <FilesPage
        hideTos
        hideHeader
        hideUploadButtons
        hideFormatsFilter
        noPagePadding
        hideTableRowSelection
        hideTableRowDropdown
        tableRowOnClick={onTableRowClick}
        filtersParam={{
          filters: {
            formats,
          },
        }}
        pageStyle={{ height: '100%', minHeight: 320 }}
      />
    </Modal>
  );
});
