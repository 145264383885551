import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import classes from './page403.module.scss';
import appHistory from '../../appHistory';
import { isX5 } from '../../isX5';
import { t } from '../../localization';
import { goToMain } from '../../routes';
import { Button } from '@just-ai/just-ui';

export const Page403 = () => {
  const { state, pathname } = useLocation<{ redirect: boolean }>();
  useEffect(() => {
    if (state?.redirect) {
      goToMain();
    } else {
      appHistory.replace(pathname, { redirect: true });
    }
  }, []);

  return (
    <div className={classes.page403}>
      <div className={classes.page403_card}>
        <h3 className='mb-0'>{t(isX5 ? 'page403:Title' : 'accessDenied')}</h3>
        <p className='mb-4' dangerouslySetInnerHTML={{ __html: t(`page403:Body${isX5 ? ':x5' : ''}`) }} />
        <Button color='secondary' outline onClick={goToMain}>
          {t('page403:Btn')}
        </Button>
      </div>
    </div>
  );
};
