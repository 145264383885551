import React, { useCallback, useMemo, useState } from 'react';

import { UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import { RefreshPeriodType } from '@just-ai/api/dist/generated/CopilotGateway';
import { IconButton, InputText, Modal, RadioButton, useTranslation } from '@just-ai/just-ui';

import styles from './styles.module.scss';
import { currentUser } from '../../../../models/currentUser';
import useApiService from '../../../../services/useApiService';
import SearchUsersSelect from '../SearchUsersSelect';
import { Limit } from '../Tables/LimitsTable';

type FormState = {
  limitSpecify: boolean;
  limitPeriodSpecify: boolean;
  limitAmount?: string;
  limitPeriod?: string;
};

export type LimitModalProps = {
  type: 'general' | 'create' | 'update' | 'delete';
  limitData: Limit[];
  closeModal: () => void;
  submitHandler: (data?: {
    amount: number;
    period: number;
    emails?: UserSearchRecord[];
    periodType: RefreshPeriodType;
  }) => void;
  loading?: boolean;
};

type FormStateErrors = {
  limitInput?: string;
  limitPeriodInput?: string;
  emailsInput?: string;
};

export default function LimitCrudModal(props: LimitModalProps) {
  const { type, closeModal, limitData, submitHandler, loading } = props;

  const { t } = useTranslation();

  const { getAccountsForLimitSetting } = useApiService();

  const defaultFormStateSetter = useMemo(() => {
    if (limitData.length === 1) {
      const actualLimit = limitData[0];
      return {
        limitSpecify: Boolean(actualLimit.amount),
        limitAmount: String(actualLimit.amount),
        limitPeriodSpecify: actualLimit.periodType === 'days',
        limitPeriod: actualLimit.period,
      };
    }
    if (limitData.length > 1) {
      if (!limitData.map(limit => limit.amount).every((amount, index, arr) => amount === arr[0])) {
        return {
          limitSpecify: false,
          limitPeriodSpecify: false,
        };
      }
      if (!limitData.map(limit => limit.period).every((period, index, arr) => period === arr[0])) {
        return {
          limitSpecify: true,
          limitAmount: String(limitData[0].amount),
          limitPeriodSpecify: false,
        };
      }
      return {
        limitSpecify: true,
        limitAmount: String(limitData[0].amount),
        limitPeriodSpecify: true,
        limitPeriod: limitData[0].period,
      };
    }
    return {
      limitSpecify: false,
      limitPeriodSpecify: false,
    };
  }, [limitData]);

  const [formState, setFormState] = useState<FormState>(defaultFormStateSetter);
  const [formStateErrors, setFormStateErrors] = useState<FormStateErrors>();

  const [userDataArr, setUserDataArr] = useState(
    limitData
      .filter(limit => Boolean(limit.userEmail))
      .map(limit => ({ email: limit.userEmail, ...limit.userData }) as UserSearchRecord)
  );

  const submitHandlerLocal = useCallback(() => {
    if (type === 'delete') return submitHandler();

    setFormStateErrors(undefined);
    if (type !== 'general' && (!userDataArr || !userDataArr.length))
      return setFormStateErrors(prevState => ({ ...prevState, emailsInput: t('This field is required') }));
    if (formState.limitSpecify && !Number(formState.limitAmount))
      return setFormStateErrors(prevState => ({ ...prevState, limitInput: t('This field is required') }));
    if (formState.limitPeriodSpecify && !formState.limitPeriod)
      return setFormStateErrors(prevState => ({ ...prevState, limitPeriodInput: t('This field is required') }));
    const processedFormData = {
      //если стоит галочка на "нет" то лимит надо убрать
      amount: formState.limitSpecify ? Number(formState.limitAmount || 0) : 0,
      period: formState.limitPeriodSpecify ? Number(formState.limitPeriod) : 1,
      periodType: (formState.limitPeriodSpecify ? 'days' : 'weeks') as RefreshPeriodType,
      emails: userDataArr,
      limitId: limitData && limitData[0] && limitData[0].id,
    };

    return submitHandler(processedFormData);
  }, [formState, limitData, submitHandler, t, type, userDataArr]);

  const searchForUsers = useCallback(
    async (search?: string) => {
      if (!currentUser.value?.accountId) return [];
      const response = await getAccountsForLimitSetting(currentUser.value?.accountId, search || '');
      return response.records
        .filter(record => !record.roles.find(role => role.name === 'COPILOT_ADMIN'))
        .map(record => ({ label: record.email, value: JSON.stringify(record) }));
    },
    [getAccountsForLimitSetting]
  );

  const handleUsersSelect = (data: (string | number)[] | null) => {
    if (data) {
      setFormStateErrors(prevState => ({ ...prevState, emailsInput: undefined }));
      setUserDataArr(
        data.map(item => {
          const parsedData = JSON.parse(String(item));
          return parsedData;
        })
      );
    }
  };

  const limitsDataForm = (
    <div>
      <div className='mb-4'>
        <p className='mb-3'>
          <b>{t('Limits:limitFormHeader:limit')}</b>
        </p>
        <RadioButton
          rootClassName='mb-3'
          name='limitSpecify'
          label={t('Limits:specifyNo')}
          checked={!formState.limitSpecify}
          onClick={() =>
            setFormState({
              limitSpecify: false,
              limitAmount: undefined,
              limitPeriod: undefined,
              limitPeriodSpecify: false,
            })
          }
          data-test-id='Limits:input:specifyFalse'
        />
        <RadioButton
          rootClassName='mb-3'
          name='specifyNoRadio'
          label={t('Limits:specify')}
          checked={formState.limitSpecify}
          onClick={() => setFormState(prevState => ({ ...prevState, limitSpecify: true }))}
          data-test-id='Limits:input:specifyTrue'
        />
        <div className='w-full pl-5'>
          <InputText
            placeholder='1000'
            type='number'
            disabled={!formState.limitSpecify}
            max={1000000}
            min={0}
            value={formState.limitAmount}
            invalid={formStateErrors?.limitInput}
            errorText={formStateErrors?.limitInput}
            onChange={value => {
              setFormStateErrors(prevState => ({ ...prevState, limitInput: undefined }));
              setFormState(prevState => ({ ...prevState, limitAmount: value }));
            }}
            data-test-id='Limits:input:limitAmount'
          />
        </div>
      </div>
      <div>
        <p className='mb-3'>
          <b>{t('Limits:limitFormHeader:period')}</b>
        </p>
        <RadioButton
          rootClassName='mb-3'
          name='periodSpecify'
          label={t('Limits:periodWeeklyMon')}
          disabled={!formState.limitSpecify}
          checked={!formState.limitPeriodSpecify}
          onClick={() => setFormState(prevState => ({ ...prevState, limitPeriodSpecify: false }))}
          data-test-id='Limits:input:periodWeekly'
        />
        <RadioButton
          rootClassName='mb-3'
          name='periodSpecify'
          label={t('Limits:periodSpecify')}
          checked={formState.limitPeriodSpecify}
          onClick={() => setFormState(prevState => ({ ...prevState, limitPeriodSpecify: true }))}
          disabled={!formState.limitSpecify}
          data-test-id='Limits:input:periodSpecify'
        />
        <div className='w-full pl-5'>
          <InputText
            placeholder='10'
            max={365}
            min={0}
            type='number'
            disabled={!formState.limitPeriodSpecify}
            value={formState.limitPeriod}
            invalid={formStateErrors?.limitPeriodInput}
            errorText={formStateErrors?.limitPeriodInput}
            onChange={value => {
              setFormStateErrors(prevState => ({ ...prevState, limitPeriodInput: undefined }));
              setFormState(prevState => ({ ...prevState, limitPeriod: value }));
            }}
            data-test-id='Limits:input:periodAmount'
          />
        </div>
      </div>
    </div>
  );

  if (type === 'delete')
    return (
      <Modal
        title={t('Limits:deleteLimit')}
        isOpen={true}
        onCancelClick={closeModal}
        className='mobileBottomModal'
        size='md'
        buttonSubmitColor='danger'
        buttonSubmitText={t('delete')}
        onActionClick={submitHandlerLocal}
        inProgress={loading}
        disableActionButtonAutoFocus
        buttonSubmitTestId='Limits:modal:deleteSubmit'
        buttonCancelTestId='Limits:modal:deleteCancel'
      >
        {limitData?.length > 1
          ? t('Limits:deleteLimitInfo:multiple', {
              email: limitData.map(limit => limit.userEmail).join(', '),
            })
          : t('Limits:deleteLimitInfo', { email: limitData[0]?.userEmail })}
      </Modal>
    );

  if (type === 'general')
    return (
      <Modal
        title={t('Account:limits:generalLimit')}
        isOpen={true}
        onCancelClick={closeModal}
        className='mobileBottomModal'
        size='md'
        buttonSubmitText={t('save')}
        onActionClick={submitHandlerLocal}
        inProgress={loading}
        disableActionButtonAutoFocus
        buttonSubmitTestId='Limits:modal:generalSubmit'
        buttonCancelTestId='Limits:modal:generalCancel'
      >
        <p className='mb-4'>{t('Limits:generalEditInfo')}</p>
        {limitsDataForm}
      </Modal>
    );

  if (type === 'create')
    return (
      <Modal
        title={t('Limits:createLimit')}
        isOpen={true}
        onCancelClick={closeModal}
        className='mobileBottomModal'
        size='md'
        buttonSubmitText={t('Account:limits:addLimitShort')}
        onActionClick={submitHandlerLocal}
        inProgress={loading}
        disableActionButtonAutoFocus
        buttonSubmitTestId='Limits:modal:createSubmit'
        buttonCancelTestId='Limits:modal:createCancel'
      >
        <p className='mb-4'>{t('Limits:newLimitInfo')}</p>
        <p className='mb-2'>{t('Limits:users')}</p>
        <SearchUsersSelect
          error={formStateErrors?.emailsInput}
          searchFunction={searchForUsers}
          onChange={handleUsersSelect}
          className={styles.select}
          data-test-id='Limits:modal:usersSearch'
        />
        {limitsDataForm}
      </Modal>
    );
  if (type === 'update')
    return (
      <Modal
        title={t('Limits:editLimit')}
        isOpen={true}
        onCancelClick={closeModal}
        className='mobileBottomModal'
        size='md'
        buttonSubmitText={t('save')}
        onActionClick={submitHandlerLocal}
        inProgress={loading}
        disableActionButtonAutoFocus
        buttonSubmitTestId='Limits:modal:updateSubmit'
        buttonCancelTestId='Limits:modal:updateCancel'
      >
        <p className='mb-4'>{t('Limits:editLimitInfo')}</p>
        <div className='flex gap-8 mb-4'>
          {userDataArr.map(userData => (
            <div className={styles.badge} key={userData.email} color='secondary'>
              {userData.email}
              {userDataArr.length > 1 && (
                <IconButton
                  name='falTimes'
                  size='sm'
                  flat
                  color='secondary'
                  data-test-id='Limits:modal:update:removeMailBtn'
                  onClick={() =>
                    setUserDataArr(prevState => prevState.filter(prevEmail => prevEmail.email !== userData.email))
                  }
                />
              )}
            </div>
          ))}
        </div>
        {limitsDataForm}
      </Modal>
    );

  return null;
}
