import React from 'react';

import { DropdownButton, DropdownMenu, DropdownToggle, IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import filesPageStyles from '../../../../../../pages/Files/styles.module.scss';
import { Conversation } from '../../../../../../types/chat';
import styles from '../../../header.module.scss';
import { ChatHeaderDeleteConversationButton } from '../../buttons/ChatHeaderDeleteConversationButton';
import { ChatHeaderSelectFileFromHistoryButton } from '../../buttons/ChatHeaderSelectFileFromHistoryButton';

type Props = {
  selectedConversation: Conversation;
  onDeleteClick?: (() => void) | null;
  onSelectFileClick?: (() => void) | null;
};

export default function ChatLayoutDropdown({
  selectedConversation,
  onDeleteClick: handleDeleteClick,
  onSelectFileClick: handleSelectFileClick,
}: Props) {
  return (
    <DropdownButton direction='down'>
      <DropdownToggle color='none' tag='div'>
        <IconButton className={cn(styles.header__icon)} name='faEllipsisH' size='sm' outline flat color='secondary' />
      </DropdownToggle>
      <DropdownMenu className={cn('border-0', styles.header__dropdown)} positionFixed={true} tag='div' right={true}>
        {handleDeleteClick ? (
          <ChatHeaderDeleteConversationButton selectedConversation={selectedConversation} onClick={handleDeleteClick} />
        ) : null}
        {handleSelectFileClick ? (
          <ChatHeaderSelectFileFromHistoryButton
            renderAs='dropdownItem'
            className={cn(filesPageStyles.dropdownMenu__Item, 'text-nowrap')}
            iconProps={{ color: 'secondary', size: 'md' }}
            onClick={handleSelectFileClick}
          />
        ) : null}
      </DropdownMenu>
    </DropdownButton>
  );
}
