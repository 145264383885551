export const TemplatesListLocalization = {
  'DeleteFromFavorite:Title': {
    eng: 'Confirm deletion',
    ru: 'Подтвердите удаление',
  },
  'DeleteFromFavorite:Text': {
    eng: 'Are you sure you want to delete the favorite application “{favoriteName}”?',
    ru: 'Вы уверены, что хотите удалить избранное приложение «{favoriteName}»?',
  },
  'DeleteFromFavorite:Confirm': {
    eng: 'Yes, delete',
    ru: 'Да, удалить',
  },
};
