import React from 'react';

import styles from './style.module.scss';
import { FormBuilderField } from '../../types';

export default function HeplerField({ label, title }: FormBuilderField) {
  return (
    <div>
      {title ? <div className='font-size-12 line-height-18 color-gray-800 margin-bottom-8'>{title}</div> : null}
      <div className={styles.helperField} dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
}
