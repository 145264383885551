import React, { useCallback, useRef } from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import classes from 'pages/TelegramTemplatesList/styles.module.scss';
import { AgentFileUploader, AgentType, CreatedAgent, ValidationTemplate } from 'types/agents';

import { isRequestsLimitExceeded } from '../../models/conversations';
import { favourites } from '../../models/favourite/favourite.api';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { isMobile } from '../../utils/app/common';
import TemplateForm from './components/TemplateForm';
import { FormBuilderProvider } from './components/TemplateForm/useFormBuilder';
import TemplateList from './TemplatesList/TemplatesList';

let headerHeightValue = 0;
let footerHeightValue = 0;
const calcModalBodyHeight = (headerHeight: number, footerHeight: number) => {
  headerHeightValue = headerHeight;
  footerHeightValue = footerHeight;
  if (isMobile()) {
    return `calc(100dvh - ${headerHeight}px - ${footerHeight}px)`;
  }
  return `calc(100dvh - 3.5rem - ${headerHeight}px - ${footerHeight}px)`;
};

export function TemplatesView(props: {
  allTemplates: AgentType[];
  onTemplateSelect: (template: AgentType) => void;
  currentTemplate: AgentType | CreatedAgent | null;
  onCancelClick: () => void;
  loading: boolean;
  locale: string;
  userId: string | undefined;
  submit: (config: any) => Promise<void>;
  handleFileUpload: AgentFileUploader;
  isOpenFromTelegram: boolean;
  errorState: ValidationTemplate;
}) {
  const { userId, currentTemplate, isOpenFromTelegram, submit, onCancelClick } = props;

  const { t } = useTranslation();

  const modalFormWrapper = useRef<HTMLDivElement>(null);
  const onOpened = useCallback(() => {
    requestAnimationFrame(() => guideTourEvent$.next(`AssistantTour:Open:${props.currentTemplate?.template}`));
    if (modalFormWrapper.current) {
      modalFormWrapper.current.style.height = isMobile()
        ? calcModalBodyHeight(headerHeightValue, footerHeightValue)
        : '';
    }
  }, [props.currentTemplate?.template]);

  return (
    <>
      <TemplateList
        allTemplates={props.allTemplates}
        favorites={favourites.value}
        onTemplateSelect={props.onTemplateSelect}
        isOpenFromTelegram={props.isOpenFromTelegram}
      />
      {currentTemplate && (
        <FormBuilderProvider
          data={{
            userId,
            template: currentTemplate,
            isEdit: false,
            isOpenFromTelegram,
            submit,
            cancel: onCancelClick,
          }}
        >
          {({ formMethods, onSubmit }) => {
            return (
              <>
                <Modal
                  isOpen
                  onOpened={onOpened}
                  onCancelClick={props.onCancelClick}
                  onActionClick={onSubmit}
                  buttonSubmitText={t('Create')}
                  buttonSubmitTestId='TemplateForm.Btn.submit'
                  buttonSubmitDisabled={isRequestsLimitExceeded.value}
                  title={currentTemplate.info.title}
                  disableActionButtonAutoFocus
                  data-test-id={`Modal:${currentTemplate.template}`}
                  buttonCancelText={t('cancel')}
                  inProgress={props.loading}
                  naturalScrollbar={true}
                  className={cn(classes.customMobile, 'mobileBottomModal', {
                    [classes.telegramHideButtons]: props.isOpenFromTelegram,
                  })}
                  calcBodyHeight={calcModalBodyHeight}
                >
                  <div className={classes.modalFormWrapper} ref={modalFormWrapper}>
                    <TemplateForm
                      showWarningRequestsBanner={isRequestsLimitExceeded.value}
                      isOpenFromTelegram={props.isOpenFromTelegram}
                      formMethods={formMethods}
                      cancel={props.onCancelClick}
                      locale={props.locale}
                      userId={props.userId}
                      template={currentTemplate}
                      submit={onSubmit}
                      handleFileUpload={props.handleFileUpload}
                      errorState={props.errorState}
                    />
                  </div>
                </Modal>
              </>
            );
          }}
        </FormBuilderProvider>
      )}
    </>
  );
}
