// tslint:disable
// @ts-nocheck
/**
 * Apps adapter API
 * An API to work with LLM agent applications and user conversations with them.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var AppStatus;
(function (AppStatus) {
    AppStatus["READY"] = "READY";
    AppStatus["FAILED"] = "FAILED";
    AppStatus["BUILDING"] = "BUILDING";
})(AppStatus || (AppStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var EntityAction;
(function (EntityAction) {
    EntityAction["Allow"] = "allow";
    EntityAction["Redact"] = "redact";
    EntityAction["Deny"] = "deny";
})(EntityAction || (EntityAction = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessagePartType;
(function (MessagePartType) {
    MessagePartType["Text"] = "text";
    MessagePartType["Image"] = "image";
    MessagePartType["Audio"] = "audio";
    MessagePartType["File"] = "file";
    MessagePartType["Link"] = "link";
    MessagePartType["App"] = "app";
    MessagePartType["SystemAppUpdate"] = "systemAppUpdate";
    MessagePartType["AutoContextReset"] = "autoContextReset";
})(MessagePartType || (MessagePartType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageStatus;
(function (MessageStatus) {
    MessageStatus["Success"] = "success";
    MessageStatus["Error"] = "error";
    MessageStatus["Warn"] = "warn";
})(MessageStatus || (MessageStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageType;
(function (MessageType) {
    MessageType["Request"] = "request";
    MessageType["Response"] = "response";
    MessageType["System"] = "system";
})(MessageType || (MessageType = {}));
/**
 * @export
 * @enum {string}
 */
export var TypeEnum;
(function (TypeEnum) {
    TypeEnum["Prompt"] = "prompt";
})(TypeEnum || (TypeEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ResourceType;
(function (ResourceType) {
    ResourceType["AppFiles"] = "appFiles";
    ResourceType["UserFiles"] = "userFiles";
    ResourceType["Links"] = "links";
})(ResourceType || (ResourceType = {}));
/**
 * AppTemplatesApi - axios parameter creator
 * @export
 */
export const AppTemplatesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Returns a list of available app templates, depending on the language.
         * @summary Get available app templates
         * @param {string} [lang] User interface language.
         * @param {string} [env] Environment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTemplates(lang, env, options = {}) {
            const localVarPath = `/api/appsAdapter/templates/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (lang !== undefined) {
                localVarQueryParameter["lang"] = lang;
            }
            if (env !== undefined) {
                localVarQueryParameter["env"] = env;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint resolves the value of the HTML input accept field by first aggregating all file types from the original accept field and included groups. After compiling the list of accepted file types, it then removes any types specified in the excluded groups. The final output is a refined list of acceptable file types for the input field, ensuring that only the desired formats are returned.
         * @summary Resolve value of HTML input accept field, determined by included and excluded file groups
         * @param {Array<string>} include File groups to include
         * @param {string} [original] Base value of accept string, to combine with included and excluded file groups
         * @param {Array<string>} [exclude] File groups to exclude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedFiles(include, original, exclude, options = {}) {
            // verify required parameter 'include' is not null or undefined
            if (include === null || include === undefined) {
                throw new RequiredError("include", "Required parameter include was null or undefined when calling getAcceptedFiles.");
            }
            const localVarPath = `/api/appsAdapter/templates/acceptedFiles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (original !== undefined) {
                localVarQueryParameter["original"] = original;
            }
            if (include) {
                localVarQueryParameter["include"] = include;
            }
            if (exclude) {
                localVarQueryParameter["exclude"] = exclude;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an object where the key represents the files group name and the value is an array of accepted files for that group. The accepted files are determined by the HTML input accept field format, but are returned as an array rather than a comma-separated string.
         * @summary Get files groups determined by the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedFilesGroups(options = {}) {
            const localVarPath = `/api/appsAdapter/templates/acceptedFilesGroups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AppTemplatesApi - functional programming interface
 * @export
 */
export const AppTemplatesApiFp = function (configuration) {
    return {
        /**
         * Returns a list of available app templates, depending on the language.
         * @summary Get available app templates
         * @param {string} [lang] User interface language.
         * @param {string} [env] Environment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTemplates(lang, env, options) {
            const localVarAxiosArgs = AppTemplatesApiAxiosParamCreator(configuration).findTemplates(lang, env, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint resolves the value of the HTML input accept field by first aggregating all file types from the original accept field and included groups. After compiling the list of accepted file types, it then removes any types specified in the excluded groups. The final output is a refined list of acceptable file types for the input field, ensuring that only the desired formats are returned.
         * @summary Resolve value of HTML input accept field, determined by included and excluded file groups
         * @param {Array<string>} include File groups to include
         * @param {string} [original] Base value of accept string, to combine with included and excluded file groups
         * @param {Array<string>} [exclude] File groups to exclude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedFiles(include, original, exclude, options) {
            const localVarAxiosArgs = AppTemplatesApiAxiosParamCreator(configuration).getAcceptedFiles(include, original, exclude, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an object where the key represents the files group name and the value is an array of accepted files for that group. The accepted files are determined by the HTML input accept field format, but are returned as an array rather than a comma-separated string.
         * @summary Get files groups determined by the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedFilesGroups(options) {
            const localVarAxiosArgs = AppTemplatesApiAxiosParamCreator(configuration).getAcceptedFilesGroups(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AppTemplatesApi - factory interface
 * @export
 */
export const AppTemplatesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Returns a list of available app templates, depending on the language.
         * @summary Get available app templates
         * @param {string} [lang] User interface language.
         * @param {string} [env] Environment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTemplates(lang, env, options) {
            return AppTemplatesApiFp(configuration).findTemplates(lang, env, options)(axios, basePath);
        },
        /**
         * This endpoint resolves the value of the HTML input accept field by first aggregating all file types from the original accept field and included groups. After compiling the list of accepted file types, it then removes any types specified in the excluded groups. The final output is a refined list of acceptable file types for the input field, ensuring that only the desired formats are returned.
         * @summary Resolve value of HTML input accept field, determined by included and excluded file groups
         * @param {Array<string>} include File groups to include
         * @param {string} [original] Base value of accept string, to combine with included and excluded file groups
         * @param {Array<string>} [exclude] File groups to exclude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedFiles(include, original, exclude, options) {
            return AppTemplatesApiFp(configuration).getAcceptedFiles(include, original, exclude, options)(axios, basePath);
        },
        /**
         * Returns an object where the key represents the files group name and the value is an array of accepted files for that group. The accepted files are determined by the HTML input accept field format, but are returned as an array rather than a comma-separated string.
         * @summary Get files groups determined by the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptedFilesGroups(options) {
            return AppTemplatesApiFp(configuration).getAcceptedFilesGroups(options)(axios, basePath);
        },
    };
};
/**
 * AppTemplatesApi - object-oriented interface
 * @export
 * @class AppTemplatesApi
 * @extends {BaseAPI}
 */
export class AppTemplatesApi extends BaseAPI {
    /**
     * Returns a list of available app templates, depending on the language.
     * @summary Get available app templates
     * @param {string} [lang] User interface language.
     * @param {string} [env] Environment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTemplatesApi
     */
    findTemplates(lang, env, options) {
        return AppTemplatesApiFp(this.configuration).findTemplates(lang, env, options)(this.axios, this.basePath);
    }
    /**
     * This endpoint resolves the value of the HTML input accept field by first aggregating all file types from the original accept field and included groups. After compiling the list of accepted file types, it then removes any types specified in the excluded groups. The final output is a refined list of acceptable file types for the input field, ensuring that only the desired formats are returned.
     * @summary Resolve value of HTML input accept field, determined by included and excluded file groups
     * @param {Array<string>} include File groups to include
     * @param {string} [original] Base value of accept string, to combine with included and excluded file groups
     * @param {Array<string>} [exclude] File groups to exclude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTemplatesApi
     */
    getAcceptedFiles(include, original, exclude, options) {
        return AppTemplatesApiFp(this.configuration).getAcceptedFiles(include, original, exclude, options)(this.axios, this.basePath);
    }
    /**
     * Returns an object where the key represents the files group name and the value is an array of accepted files for that group. The accepted files are determined by the HTML input accept field format, but are returned as an array rather than a comma-separated string.
     * @summary Get files groups determined by the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppTemplatesApi
     */
    getAcceptedFilesGroups(options) {
        return AppTemplatesApiFp(this.configuration).getAcceptedFilesGroups(options)(this.axios, this.basePath);
    }
}
/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary changeAppFavoriteStatus
         * @param {string} appId
         * @param {AppChangeFavoriteStatusRequest} appChangeFavoriteStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options = {}) {
            // verify required parameter 'appId' is not null or undefined
            if (appId === null || appId === undefined) {
                throw new RequiredError("appId", "Required parameter appId was null or undefined when calling changeAppFavoriteStatus.");
            }
            // verify required parameter 'appChangeFavoriteStatusRequest' is not null or undefined
            if (appChangeFavoriteStatusRequest === null ||
                appChangeFavoriteStatusRequest === undefined) {
                throw new RequiredError("appChangeFavoriteStatusRequest", "Required parameter appChangeFavoriteStatusRequest was null or undefined when calling changeAppFavoriteStatus.");
            }
            const localVarPath = `/api/appsAdapter/apps/{appId}/favorite`.replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appChangeFavoriteStatusRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appChangeFavoriteStatusRequest !== undefined
                    ? appChangeFavoriteStatusRequest
                    : {})
                : appChangeFavoriteStatusRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If source app is favorite then new app will be added to favorites and source app will be removed
         * @summary copyApp
         * @param {string} appId
         * @param {AppCopyRequest} appCopyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyApp(appId, appCopyRequest, options = {}) {
            // verify required parameter 'appId' is not null or undefined
            if (appId === null || appId === undefined) {
                throw new RequiredError("appId", "Required parameter appId was null or undefined when calling copyApp.");
            }
            // verify required parameter 'appCopyRequest' is not null or undefined
            if (appCopyRequest === null || appCopyRequest === undefined) {
                throw new RequiredError("appCopyRequest", "Required parameter appCopyRequest was null or undefined when calling copyApp.");
            }
            const localVarPath = `/api/appsAdapter/apps/{appId}/copy`.replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appCopyRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appCopyRequest !== undefined ? appCopyRequest : {})
                : appCopyRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary createApp
         * @param {AppCreateRequest} appCreateRequest
         * @param {string} [force]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(appCreateRequest, force, options = {}) {
            // verify required parameter 'appCreateRequest' is not null or undefined
            if (appCreateRequest === null || appCreateRequest === undefined) {
                throw new RequiredError("appCreateRequest", "Required parameter appCreateRequest was null or undefined when calling createApp.");
            }
            const localVarPath = `/api/appsAdapter/apps/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (force !== undefined) {
                localVarQueryParameter["force"] = force;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appCreateRequest !== undefined ? appCreateRequest : {})
                : appCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findAppById
         * @param {string} appId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppById(appId, options = {}) {
            // verify required parameter 'appId' is not null or undefined
            if (appId === null || appId === undefined) {
                throw new RequiredError("appId", "Required parameter appId was null or undefined when calling findAppById.");
            }
            const localVarPath = `/api/appsAdapter/apps/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findApps
         * @param {string} [pageSize]
         * @param {string} [before]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findApps(pageSize, before, options = {}) {
            const localVarPath = `/api/appsAdapter/apps/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (pageSize !== undefined) {
                localVarQueryParameter["pageSize"] = pageSize;
            }
            if (before !== undefined) {
                localVarQueryParameter["before"] = before;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findFavoriteApps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFavoriteApps(options = {}) {
            const localVarPath = `/api/appsAdapter/apps/favorite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary prevalidateApp
         * @param {AppCreateRequest} appCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prevalidateApp(appCreateRequest, options = {}) {
            // verify required parameter 'appCreateRequest' is not null or undefined
            if (appCreateRequest === null || appCreateRequest === undefined) {
                throw new RequiredError("appCreateRequest", "Required parameter appCreateRequest was null or undefined when calling prevalidateApp.");
            }
            const localVarPath = `/api/appsAdapter/apps/prevalidate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appCreateRequest !== undefined ? appCreateRequest : {})
                : appCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateApp
         * @param {string} appId
         * @param {AppUpdateRequest} appUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp(appId, appUpdateRequest, options = {}) {
            // verify required parameter 'appId' is not null or undefined
            if (appId === null || appId === undefined) {
                throw new RequiredError("appId", "Required parameter appId was null or undefined when calling updateApp.");
            }
            // verify required parameter 'appUpdateRequest' is not null or undefined
            if (appUpdateRequest === null || appUpdateRequest === undefined) {
                throw new RequiredError("appUpdateRequest", "Required parameter appUpdateRequest was null or undefined when calling updateApp.");
            }
            const localVarPath = `/api/appsAdapter/apps/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appUpdateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appUpdateRequest !== undefined ? appUpdateRequest : {})
                : appUpdateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary changeAppFavoriteStatus
         * @param {string} appId
         * @param {AppChangeFavoriteStatusRequest} appChangeFavoriteStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * If source app is favorite then new app will be added to favorites and source app will be removed
         * @summary copyApp
         * @param {string} appId
         * @param {AppCopyRequest} appCopyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyApp(appId, appCopyRequest, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).copyApp(appId, appCopyRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary createApp
         * @param {AppCreateRequest} appCreateRequest
         * @param {string} [force]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(appCreateRequest, force, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).createApp(appCreateRequest, force, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findAppById
         * @param {string} appId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppById(appId, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).findAppById(appId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findApps
         * @param {string} [pageSize]
         * @param {string} [before]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findApps(pageSize, before, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).findApps(pageSize, before, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findFavoriteApps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFavoriteApps(options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).findFavoriteApps(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary prevalidateApp
         * @param {AppCreateRequest} appCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prevalidateApp(appCreateRequest, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).prevalidateApp(appCreateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary updateApp
         * @param {string} appId
         * @param {AppUpdateRequest} appUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp(appId, appUpdateRequest, options) {
            const localVarAxiosArgs = AppsApiAxiosParamCreator(configuration).updateApp(appId, appUpdateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary changeAppFavoriteStatus
         * @param {string} appId
         * @param {AppChangeFavoriteStatusRequest} appChangeFavoriteStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options) {
            return AppsApiFp(configuration).changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options)(axios, basePath);
        },
        /**
         * If source app is favorite then new app will be added to favorites and source app will be removed
         * @summary copyApp
         * @param {string} appId
         * @param {AppCopyRequest} appCopyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyApp(appId, appCopyRequest, options) {
            return AppsApiFp(configuration).copyApp(appId, appCopyRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary createApp
         * @param {AppCreateRequest} appCreateRequest
         * @param {string} [force]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(appCreateRequest, force, options) {
            return AppsApiFp(configuration).createApp(appCreateRequest, force, options)(axios, basePath);
        },
        /**
         *
         * @summary findAppById
         * @param {string} appId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppById(appId, options) {
            return AppsApiFp(configuration).findAppById(appId, options)(axios, basePath);
        },
        /**
         *
         * @summary findApps
         * @param {string} [pageSize]
         * @param {string} [before]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findApps(pageSize, before, options) {
            return AppsApiFp(configuration).findApps(pageSize, before, options)(axios, basePath);
        },
        /**
         *
         * @summary findFavoriteApps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFavoriteApps(options) {
            return AppsApiFp(configuration).findFavoriteApps(options)(axios, basePath);
        },
        /**
         *
         * @summary prevalidateApp
         * @param {AppCreateRequest} appCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prevalidateApp(appCreateRequest, options) {
            return AppsApiFp(configuration).prevalidateApp(appCreateRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary updateApp
         * @param {string} appId
         * @param {AppUpdateRequest} appUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp(appId, appUpdateRequest, options) {
            return AppsApiFp(configuration).updateApp(appId, appUpdateRequest, options)(axios, basePath);
        },
    };
};
/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     *
     * @summary changeAppFavoriteStatus
     * @param {string} appId
     * @param {AppChangeFavoriteStatusRequest} appChangeFavoriteStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options) {
        return AppsApiFp(this.configuration).changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options)(this.axios, this.basePath);
    }
    /**
     * If source app is favorite then new app will be added to favorites and source app will be removed
     * @summary copyApp
     * @param {string} appId
     * @param {AppCopyRequest} appCopyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    copyApp(appId, appCopyRequest, options) {
        return AppsApiFp(this.configuration).copyApp(appId, appCopyRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary createApp
     * @param {AppCreateRequest} appCreateRequest
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    createApp(appCreateRequest, force, options) {
        return AppsApiFp(this.configuration).createApp(appCreateRequest, force, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findAppById
     * @param {string} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    findAppById(appId, options) {
        return AppsApiFp(this.configuration).findAppById(appId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findApps
     * @param {string} [pageSize]
     * @param {string} [before]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    findApps(pageSize, before, options) {
        return AppsApiFp(this.configuration).findApps(pageSize, before, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findFavoriteApps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    findFavoriteApps(options) {
        return AppsApiFp(this.configuration).findFavoriteApps(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary prevalidateApp
     * @param {AppCreateRequest} appCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    prevalidateApp(appCreateRequest, options) {
        return AppsApiFp(this.configuration).prevalidateApp(appCreateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary updateApp
     * @param {string} appId
     * @param {AppUpdateRequest} appUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    updateApp(appId, appUpdateRequest, options) {
        return AppsApiFp(this.configuration).updateApp(appId, appUpdateRequest, options)(this.axios, this.basePath);
    }
}
/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary cancelMessageProcessing
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMessageProcessing(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling cancelMessageProcessing.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/cancel`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary clearUnreadChanges
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearUnreadChanges(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling clearUnreadChanges.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/clearUnreadChanges`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates conversation with new or existed app. See schema
         * @summary createConversation
         * @param {ConversationCreateRequest | ConversationAndAppCreateRequest} conversationCreateRequestConversationAndAppCreateRequest
         * @param {string} [force]
         * @param {boolean} [async]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options = {}) {
            // verify required parameter 'conversationCreateRequestConversationAndAppCreateRequest' is not null or undefined
            if (conversationCreateRequestConversationAndAppCreateRequest === null ||
                conversationCreateRequestConversationAndAppCreateRequest === undefined) {
                throw new RequiredError("conversationCreateRequestConversationAndAppCreateRequest", "Required parameter conversationCreateRequestConversationAndAppCreateRequest was null or undefined when calling createConversation.");
            }
            const localVarPath = `/api/appsAdapter/conversations/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (force !== undefined) {
                localVarQueryParameter["force"] = force;
            }
            if (async !== undefined) {
                localVarQueryParameter["async"] = async;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof conversationCreateRequestConversationAndAppCreateRequest !==
                "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(conversationCreateRequestConversationAndAppCreateRequest !==
                    undefined
                    ? conversationCreateRequestConversationAndAppCreateRequest
                    : {})
                : conversationCreateRequestConversationAndAppCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteConversationById
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationById(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling deleteConversationById.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteMessage
         * @param {string} conversationId
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(conversationId, messageId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling deleteMessage.");
            }
            // verify required parameter 'messageId' is not null or undefined
            if (messageId === null || messageId === undefined) {
                throw new RequiredError("messageId", "Required parameter messageId was null or undefined when calling deleteMessage.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/message/{messageId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findAssistant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssistant(options = {}) {
            const localVarPath = `/api/appsAdapter/conversations/assistant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findConversationById
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationById(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling findConversationById.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findConversationHistory
         * @param {string} conversationId
         * @param {number} [pageSize]
         * @param {number} [before]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationHistory(conversationId, pageSize, before, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling findConversationHistory.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/history`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (pageSize !== undefined) {
                localVarQueryParameter["pageSize"] = pageSize;
            }
            if (before !== undefined) {
                localVarQueryParameter["before"] = before;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findConversations
         * @param {boolean} [hasUnreadChanges]
         * @param {'created' | 'updated'} [orderBy]
         * @param {'asc' | 'desc'} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversations(hasUnreadChanges, orderBy, sort, options = {}) {
            const localVarPath = `/api/appsAdapter/conversations/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (hasUnreadChanges !== undefined) {
                localVarQueryParameter["hasUnreadChanges"] = hasUnreadChanges;
            }
            if (orderBy !== undefined) {
                localVarQueryParameter["orderBy"] = orderBy;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary resetConversation
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConversation(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling resetConversation.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/clear`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary sendMessage
         * @param {string} conversationId
         * @param {boolean} [async]
         * @param {any} [file]
         * @param {string} [fileId]
         * @param {string} [text]
         * @param {string} [toolResponses]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(conversationId, async, file, fileId, text, toolResponses, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling sendMessage.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/message`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (async !== undefined) {
                localVarQueryParameter["async"] = async;
            }
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            if (fileId !== undefined) {
                localVarFormParams.append("fileId", fileId);
            }
            if (text !== undefined) {
                localVarFormParams.append("text", text);
            }
            if (toolResponses !== undefined) {
                localVarFormParams.append("toolResponses", toolResponses);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary streamMessage
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamMessage(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling streamMessage.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/sse`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateConversation
         * @param {string} conversationId
         * @param {ConversationUpdateRequest} conversationUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversation(conversationId, conversationUpdateRequest, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling updateConversation.");
            }
            // verify required parameter 'conversationUpdateRequest' is not null or undefined
            if (conversationUpdateRequest === null ||
                conversationUpdateRequest === undefined) {
                throw new RequiredError("conversationUpdateRequest", "Required parameter conversationUpdateRequest was null or undefined when calling updateConversation.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof conversationUpdateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(conversationUpdateRequest !== undefined
                    ? conversationUpdateRequest
                    : {})
                : conversationUpdateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateConversationApp
         * @param {string} conversationId
         * @param {AppCreateRequest} appCreateRequest
         * @param {boolean} [async]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationApp(conversationId, appCreateRequest, async, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling updateConversationApp.");
            }
            // verify required parameter 'appCreateRequest' is not null or undefined
            if (appCreateRequest === null || appCreateRequest === undefined) {
                throw new RequiredError("appCreateRequest", "Required parameter appCreateRequest was null or undefined when calling updateConversationApp.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/app`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (async !== undefined) {
                localVarQueryParameter["async"] = async;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appCreateRequest !== undefined ? appCreateRequest : {})
                : appCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary cancelMessageProcessing
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMessageProcessing(conversationId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).cancelMessageProcessing(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary clearUnreadChanges
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearUnreadChanges(conversationId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).clearUnreadChanges(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates conversation with new or existed app. See schema
         * @summary createConversation
         * @param {ConversationCreateRequest | ConversationAndAppCreateRequest} conversationCreateRequestConversationAndAppCreateRequest
         * @param {string} [force]
         * @param {boolean} [async]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary deleteConversationById
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationById(conversationId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).deleteConversationById(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary deleteMessage
         * @param {string} conversationId
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(conversationId, messageId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).deleteMessage(conversationId, messageId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findAssistant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssistant(options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).findAssistant(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findConversationById
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationById(conversationId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).findConversationById(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findConversationHistory
         * @param {string} conversationId
         * @param {number} [pageSize]
         * @param {number} [before]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationHistory(conversationId, pageSize, before, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).findConversationHistory(conversationId, pageSize, before, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findConversations
         * @param {boolean} [hasUnreadChanges]
         * @param {'created' | 'updated'} [orderBy]
         * @param {'asc' | 'desc'} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversations(hasUnreadChanges, orderBy, sort, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).findConversations(hasUnreadChanges, orderBy, sort, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary resetConversation
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConversation(conversationId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).resetConversation(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary sendMessage
         * @param {string} conversationId
         * @param {boolean} [async]
         * @param {any} [file]
         * @param {string} [fileId]
         * @param {string} [text]
         * @param {string} [toolResponses]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(conversationId, async, file, fileId, text, toolResponses, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).sendMessage(conversationId, async, file, fileId, text, toolResponses, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary streamMessage
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamMessage(conversationId, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).streamMessage(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary updateConversation
         * @param {string} conversationId
         * @param {ConversationUpdateRequest} conversationUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversation(conversationId, conversationUpdateRequest, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).updateConversation(conversationId, conversationUpdateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary updateConversationApp
         * @param {string} conversationId
         * @param {AppCreateRequest} appCreateRequest
         * @param {boolean} [async]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationApp(conversationId, appCreateRequest, async, options) {
            const localVarAxiosArgs = ConversationsApiAxiosParamCreator(configuration).updateConversationApp(conversationId, appCreateRequest, async, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary cancelMessageProcessing
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMessageProcessing(conversationId, options) {
            return ConversationsApiFp(configuration).cancelMessageProcessing(conversationId, options)(axios, basePath);
        },
        /**
         *
         * @summary clearUnreadChanges
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearUnreadChanges(conversationId, options) {
            return ConversationsApiFp(configuration).clearUnreadChanges(conversationId, options)(axios, basePath);
        },
        /**
         * Creates conversation with new or existed app. See schema
         * @summary createConversation
         * @param {ConversationCreateRequest | ConversationAndAppCreateRequest} conversationCreateRequestConversationAndAppCreateRequest
         * @param {string} [force]
         * @param {boolean} [async]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options) {
            return ConversationsApiFp(configuration).createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options)(axios, basePath);
        },
        /**
         *
         * @summary deleteConversationById
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationById(conversationId, options) {
            return ConversationsApiFp(configuration).deleteConversationById(conversationId, options)(axios, basePath);
        },
        /**
         *
         * @summary deleteMessage
         * @param {string} conversationId
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(conversationId, messageId, options) {
            return ConversationsApiFp(configuration).deleteMessage(conversationId, messageId, options)(axios, basePath);
        },
        /**
         *
         * @summary findAssistant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssistant(options) {
            return ConversationsApiFp(configuration).findAssistant(options)(axios, basePath);
        },
        /**
         *
         * @summary findConversationById
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationById(conversationId, options) {
            return ConversationsApiFp(configuration).findConversationById(conversationId, options)(axios, basePath);
        },
        /**
         *
         * @summary findConversationHistory
         * @param {string} conversationId
         * @param {number} [pageSize]
         * @param {number} [before]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationHistory(conversationId, pageSize, before, options) {
            return ConversationsApiFp(configuration).findConversationHistory(conversationId, pageSize, before, options)(axios, basePath);
        },
        /**
         *
         * @summary findConversations
         * @param {boolean} [hasUnreadChanges]
         * @param {'created' | 'updated'} [orderBy]
         * @param {'asc' | 'desc'} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversations(hasUnreadChanges, orderBy, sort, options) {
            return ConversationsApiFp(configuration).findConversations(hasUnreadChanges, orderBy, sort, options)(axios, basePath);
        },
        /**
         *
         * @summary resetConversation
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConversation(conversationId, options) {
            return ConversationsApiFp(configuration).resetConversation(conversationId, options)(axios, basePath);
        },
        /**
         *
         * @summary sendMessage
         * @param {string} conversationId
         * @param {boolean} [async]
         * @param {any} [file]
         * @param {string} [fileId]
         * @param {string} [text]
         * @param {string} [toolResponses]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(conversationId, async, file, fileId, text, toolResponses, options) {
            return ConversationsApiFp(configuration).sendMessage(conversationId, async, file, fileId, text, toolResponses, options)(axios, basePath);
        },
        /**
         *
         * @summary streamMessage
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamMessage(conversationId, options) {
            return ConversationsApiFp(configuration).streamMessage(conversationId, options)(axios, basePath);
        },
        /**
         *
         * @summary updateConversation
         * @param {string} conversationId
         * @param {ConversationUpdateRequest} conversationUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversation(conversationId, conversationUpdateRequest, options) {
            return ConversationsApiFp(configuration).updateConversation(conversationId, conversationUpdateRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary updateConversationApp
         * @param {string} conversationId
         * @param {AppCreateRequest} appCreateRequest
         * @param {boolean} [async]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationApp(conversationId, appCreateRequest, async, options) {
            return ConversationsApiFp(configuration).updateConversationApp(conversationId, appCreateRequest, async, options)(axios, basePath);
        },
    };
};
/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     *
     * @summary cancelMessageProcessing
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    cancelMessageProcessing(conversationId, options) {
        return ConversationsApiFp(this.configuration).cancelMessageProcessing(conversationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary clearUnreadChanges
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    clearUnreadChanges(conversationId, options) {
        return ConversationsApiFp(this.configuration).clearUnreadChanges(conversationId, options)(this.axios, this.basePath);
    }
    /**
     * Creates conversation with new or existed app. See schema
     * @summary createConversation
     * @param {ConversationCreateRequest | ConversationAndAppCreateRequest} conversationCreateRequestConversationAndAppCreateRequest
     * @param {string} [force]
     * @param {boolean} [async]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options) {
        return ConversationsApiFp(this.configuration).createConversation(conversationCreateRequestConversationAndAppCreateRequest, force, async, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary deleteConversationById
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    deleteConversationById(conversationId, options) {
        return ConversationsApiFp(this.configuration).deleteConversationById(conversationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary deleteMessage
     * @param {string} conversationId
     * @param {string} messageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    deleteMessage(conversationId, messageId, options) {
        return ConversationsApiFp(this.configuration).deleteMessage(conversationId, messageId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findAssistant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    findAssistant(options) {
        return ConversationsApiFp(this.configuration).findAssistant(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findConversationById
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    findConversationById(conversationId, options) {
        return ConversationsApiFp(this.configuration).findConversationById(conversationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findConversationHistory
     * @param {string} conversationId
     * @param {number} [pageSize]
     * @param {number} [before]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    findConversationHistory(conversationId, pageSize, before, options) {
        return ConversationsApiFp(this.configuration).findConversationHistory(conversationId, pageSize, before, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findConversations
     * @param {boolean} [hasUnreadChanges]
     * @param {'created' | 'updated'} [orderBy]
     * @param {'asc' | 'desc'} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    findConversations(hasUnreadChanges, orderBy, sort, options) {
        return ConversationsApiFp(this.configuration).findConversations(hasUnreadChanges, orderBy, sort, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary resetConversation
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    resetConversation(conversationId, options) {
        return ConversationsApiFp(this.configuration).resetConversation(conversationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary sendMessage
     * @param {string} conversationId
     * @param {boolean} [async]
     * @param {any} [file]
     * @param {string} [fileId]
     * @param {string} [text]
     * @param {string} [toolResponses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    sendMessage(conversationId, async, file, fileId, text, toolResponses, options) {
        return ConversationsApiFp(this.configuration).sendMessage(conversationId, async, file, fileId, text, toolResponses, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary streamMessage
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    streamMessage(conversationId, options) {
        return ConversationsApiFp(this.configuration).streamMessage(conversationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary updateConversation
     * @param {string} conversationId
     * @param {ConversationUpdateRequest} conversationUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    updateConversation(conversationId, conversationUpdateRequest, options) {
        return ConversationsApiFp(this.configuration).updateConversation(conversationId, conversationUpdateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary updateConversationApp
     * @param {string} conversationId
     * @param {AppCreateRequest} appCreateRequest
     * @param {boolean} [async]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    updateConversationApp(conversationId, appCreateRequest, async, options) {
        return ConversationsApiFp(this.configuration).updateConversationApp(conversationId, appCreateRequest, async, options)(this.axios, this.basePath);
    }
}
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete.");
            }
            // verify required parameter 'externalInstanceId' is not null or undefined
            if (externalInstanceId === null || externalInstanceId === undefined) {
                throw new RequiredError("externalInstanceId", "Required parameter externalInstanceId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/external_instances/{externalInstanceId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"externalInstanceId"}}`, encodeURIComponent(String(externalInstanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut.");
            }
            // verify required parameter 'externalInstanceId' is not null or undefined
            if (externalInstanceId === null || externalInstanceId === undefined) {
                throw new RequiredError("externalInstanceId", "Required parameter externalInstanceId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/external_instances/{externalInstanceId}/depiction`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"externalInstanceId"}}`, encodeURIComponent(String(externalInstanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut.");
            }
            // verify required parameter 'externalInstanceId' is not null or undefined
            if (externalInstanceId === null || externalInstanceId === undefined) {
                throw new RequiredError("externalInstanceId", "Required parameter externalInstanceId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/external_instances/{externalInstanceId}/tools`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"externalInstanceId"}}`, encodeURIComponent(String(externalInstanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options = {}) {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError("conversationId", "Required parameter conversationId was null or undefined when calling apiAppsAdapterConversationsConversationIdExternalInstancesPost.");
            }
            const localVarPath = `/api/appsAdapter/conversations/{conversationId}/external_instances`.replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterExternalGet(options = {}) {
            const localVarPath = `/api/appsAdapter/external/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options) {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options) {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options) {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options) {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterExternalGet(options) {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).apiAppsAdapterExternalGet(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options) {
            return DefaultApiFp(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options)(axios, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options) {
            return DefaultApiFp(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options)(axios, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} externalInstanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options) {
            return DefaultApiFp(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options)(axios, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options) {
            return DefaultApiFp(configuration).apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterExternalGet(options) {
            return DefaultApiFp(configuration).apiAppsAdapterExternalGet(options)(axios, basePath);
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {string} conversationId
     * @param {string} externalInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options) {
        return DefaultApiFp(this.configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDelete(conversationId, externalInstanceId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {string} conversationId
     * @param {string} externalInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options) {
        return DefaultApiFp(this.configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdDepictionPut(conversationId, externalInstanceId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {string} conversationId
     * @param {string} externalInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options) {
        return DefaultApiFp(this.configuration).apiAppsAdapterConversationsConversationIdExternalInstancesExternalInstanceIdToolsPut(conversationId, externalInstanceId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options) {
        return DefaultApiFp(this.configuration).apiAppsAdapterConversationsConversationIdExternalInstancesPost(conversationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAppsAdapterExternalGet(options) {
        return DefaultApiFp(this.configuration).apiAppsAdapterExternalGet(options)(this.axios, this.basePath);
    }
}
/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary batchUploadFiles
         * @param {Array<any>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUploadFiles(files, options = {}) {
            const localVarPath = `/api/appsAdapter/files/batch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (files) {
                localVarFormParams.append("files", files.join(COLLECTION_FORMATS.csv));
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteByIds
         * @param {string} fileIds Comma-separated list of file IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIds(fileIds, options = {}) {
            // verify required parameter 'fileIds' is not null or undefined
            if (fileIds === null || fileIds === undefined) {
                throw new RequiredError("fileIds", "Required parameter fileIds was null or undefined when calling deleteByIds.");
            }
            const localVarPath = `/api/appsAdapter/files/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fileIds !== undefined) {
                localVarQueryParameter["fileIds"] = fileIds;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteFileById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileById(fileId, options = {}) {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError("fileId", "Required parameter fileId was null or undefined when calling deleteFileById.");
            }
            const localVarPath = `/api/appsAdapter/files/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary downloadById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadById(fileId, options = {}) {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError("fileId", "Required parameter fileId was null or undefined when calling downloadById.");
            }
            const localVarPath = `/api/appsAdapter/files/{fileId}/download`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary downloadByLink
         * @param {string} url URL of the file to download
         * @param {string} name Name of the file to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadByLink(url, name, options = {}) {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError("url", "Required parameter url was null or undefined when calling downloadByLink.");
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError("name", "Required parameter name was null or undefined when calling downloadByLink.");
            }
            const localVarPath = `/api/appsAdapter/files/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (url !== undefined) {
                localVarQueryParameter["url"] = url;
            }
            if (name !== undefined) {
                localVarQueryParameter["name"] = name;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findFileById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFileById(fileId, options = {}) {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError("fileId", "Required parameter fileId was null or undefined when calling findFileById.");
            }
            const localVarPath = `/api/appsAdapter/files/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary uploadFile
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file, options = {}) {
            const localVarPath = `/api/appsAdapter/files/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary batchUploadFiles
         * @param {Array<any>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUploadFiles(files, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).batchUploadFiles(files, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary deleteByIds
         * @param {string} fileIds Comma-separated list of file IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIds(fileIds, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).deleteByIds(fileIds, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary deleteFileById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileById(fileId, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).deleteFileById(fileId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary downloadById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadById(fileId, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).downloadById(fileId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary downloadByLink
         * @param {string} url URL of the file to download
         * @param {string} name Name of the file to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadByLink(url, name, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).downloadByLink(url, name, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findFileById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFileById(fileId, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).findFileById(fileId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary uploadFile
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file, options) {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).uploadFile(file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary batchUploadFiles
         * @param {Array<any>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUploadFiles(files, options) {
            return FilesApiFp(configuration).batchUploadFiles(files, options)(axios, basePath);
        },
        /**
         *
         * @summary deleteByIds
         * @param {string} fileIds Comma-separated list of file IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByIds(fileIds, options) {
            return FilesApiFp(configuration).deleteByIds(fileIds, options)(axios, basePath);
        },
        /**
         *
         * @summary deleteFileById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileById(fileId, options) {
            return FilesApiFp(configuration).deleteFileById(fileId, options)(axios, basePath);
        },
        /**
         *
         * @summary downloadById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadById(fileId, options) {
            return FilesApiFp(configuration).downloadById(fileId, options)(axios, basePath);
        },
        /**
         *
         * @summary downloadByLink
         * @param {string} url URL of the file to download
         * @param {string} name Name of the file to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadByLink(url, name, options) {
            return FilesApiFp(configuration).downloadByLink(url, name, options)(axios, basePath);
        },
        /**
         *
         * @summary findFileById
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFileById(fileId, options) {
            return FilesApiFp(configuration).findFileById(fileId, options)(axios, basePath);
        },
        /**
         *
         * @summary uploadFile
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file, options) {
            return FilesApiFp(configuration).uploadFile(file, options)(axios, basePath);
        },
    };
};
/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     *
     * @summary batchUploadFiles
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    batchUploadFiles(files, options) {
        return FilesApiFp(this.configuration).batchUploadFiles(files, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary deleteByIds
     * @param {string} fileIds Comma-separated list of file IDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    deleteByIds(fileIds, options) {
        return FilesApiFp(this.configuration).deleteByIds(fileIds, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary deleteFileById
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    deleteFileById(fileId, options) {
        return FilesApiFp(this.configuration).deleteFileById(fileId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary downloadById
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    downloadById(fileId, options) {
        return FilesApiFp(this.configuration).downloadById(fileId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary downloadByLink
     * @param {string} url URL of the file to download
     * @param {string} name Name of the file to download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    downloadByLink(url, name, options) {
        return FilesApiFp(this.configuration).downloadByLink(url, name, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findFileById
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    findFileById(fileId, options) {
        return FilesApiFp(this.configuration).findFileById(fileId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary uploadFile
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    uploadFile(file, options) {
        return FilesApiFp(this.configuration).uploadFile(file, options)(this.axios, this.basePath);
    }
}
/**
 * InternalAppsApi - axios parameter creator
 * @export
 */
export const InternalAppsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary findApps
         * @param {AppsListRequest} appsListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAppsList(appsListRequest, options = {}) {
            // verify required parameter 'appsListRequest' is not null or undefined
            if (appsListRequest === null || appsListRequest === undefined) {
                throw new RequiredError("appsListRequest", "Required parameter appsListRequest was null or undefined when calling getInternalAppsList.");
            }
            const localVarPath = `/api/appsAdapter/internal/apps/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof appsListRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(appsListRequest !== undefined ? appsListRequest : {})
                : appsListRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * InternalAppsApi - functional programming interface
 * @export
 */
export const InternalAppsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary findApps
         * @param {AppsListRequest} appsListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAppsList(appsListRequest, options) {
            const localVarAxiosArgs = InternalAppsApiAxiosParamCreator(configuration).getInternalAppsList(appsListRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * InternalAppsApi - factory interface
 * @export
 */
export const InternalAppsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary findApps
         * @param {AppsListRequest} appsListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAppsList(appsListRequest, options) {
            return InternalAppsApiFp(configuration).getInternalAppsList(appsListRequest, options)(axios, basePath);
        },
    };
};
/**
 * InternalAppsApi - object-oriented interface
 * @export
 * @class InternalAppsApi
 * @extends {BaseAPI}
 */
export class InternalAppsApi extends BaseAPI {
    /**
     *
     * @summary findApps
     * @param {AppsListRequest} appsListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalAppsApi
     */
    getInternalAppsList(appsListRequest, options) {
        return InternalAppsApiFp(this.configuration).getInternalAppsList(appsListRequest, options)(this.axios, this.basePath);
    }
}
/**
 * InternalConversationsApi - axios parameter creator
 * @export
 */
export const InternalConversationsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary findConversations
         * @param {ConversationsListRequest} conversationsListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalConversationsList(conversationsListRequest, options = {}) {
            // verify required parameter 'conversationsListRequest' is not null or undefined
            if (conversationsListRequest === null ||
                conversationsListRequest === undefined) {
                throw new RequiredError("conversationsListRequest", "Required parameter conversationsListRequest was null or undefined when calling getInternalConversationsList.");
            }
            const localVarPath = `/api/appsAdapter/internal/conversations/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof conversationsListRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(conversationsListRequest !== undefined
                    ? conversationsListRequest
                    : {})
                : conversationsListRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * InternalConversationsApi - functional programming interface
 * @export
 */
export const InternalConversationsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary findConversations
         * @param {ConversationsListRequest} conversationsListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalConversationsList(conversationsListRequest, options) {
            const localVarAxiosArgs = InternalConversationsApiAxiosParamCreator(configuration).getInternalConversationsList(conversationsListRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * InternalConversationsApi - factory interface
 * @export
 */
export const InternalConversationsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary findConversations
         * @param {ConversationsListRequest} conversationsListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalConversationsList(conversationsListRequest, options) {
            return InternalConversationsApiFp(configuration).getInternalConversationsList(conversationsListRequest, options)(axios, basePath);
        },
    };
};
/**
 * InternalConversationsApi - object-oriented interface
 * @export
 * @class InternalConversationsApi
 * @extends {BaseAPI}
 */
export class InternalConversationsApi extends BaseAPI {
    /**
     *
     * @summary findConversations
     * @param {ConversationsListRequest} conversationsListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalConversationsApi
     */
    getInternalConversationsList(conversationsListRequest, options) {
        return InternalConversationsApiFp(this.configuration).getInternalConversationsList(conversationsListRequest, options)(this.axios, this.basePath);
    }
}
/**
 * LinksApi - axios parameter creator
 * @export
 */
export const LinksApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} linkIds Comma-separated list of link IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterLinksDelete(linkIds, options = {}) {
            // verify required parameter 'linkIds' is not null or undefined
            if (linkIds === null || linkIds === undefined) {
                throw new RequiredError("linkIds", "Required parameter linkIds was null or undefined when calling apiAppsAdapterLinksDelete.");
            }
            const localVarPath = `/api/appsAdapter/links/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (linkIds !== undefined) {
                localVarQueryParameter["linkIds"] = linkIds;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary batchCreateLinks
         * @param {LinkBatchCreateRequest} linkBatchCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateLinks(linkBatchCreateRequest, options = {}) {
            // verify required parameter 'linkBatchCreateRequest' is not null or undefined
            if (linkBatchCreateRequest === null ||
                linkBatchCreateRequest === undefined) {
                throw new RequiredError("linkBatchCreateRequest", "Required parameter linkBatchCreateRequest was null or undefined when calling batchCreateLinks.");
            }
            const localVarPath = `/api/appsAdapter/links/batch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof linkBatchCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(linkBatchCreateRequest !== undefined ? linkBatchCreateRequest : {})
                : linkBatchCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary createLink
         * @param {LinkCreateRequest} linkCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink(linkCreateRequest, options = {}) {
            // verify required parameter 'linkCreateRequest' is not null or undefined
            if (linkCreateRequest === null || linkCreateRequest === undefined) {
                throw new RequiredError("linkCreateRequest", "Required parameter linkCreateRequest was null or undefined when calling createLink.");
            }
            const localVarPath = `/api/appsAdapter/links/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof linkCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(linkCreateRequest !== undefined ? linkCreateRequest : {})
                : linkCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteLinkById
         * @param {string} linkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkById(linkId, options = {}) {
            // verify required parameter 'linkId' is not null or undefined
            if (linkId === null || linkId === undefined) {
                throw new RequiredError("linkId", "Required parameter linkId was null or undefined when calling deleteLinkById.");
            }
            const localVarPath = `/api/appsAdapter/links/{linkId}`.replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findLinkById
         * @param {string} linkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinkById(linkId, options = {}) {
            // verify required parameter 'linkId' is not null or undefined
            if (linkId === null || linkId === undefined) {
                throw new RequiredError("linkId", "Required parameter linkId was null or undefined when calling findLinkById.");
            }
            const localVarPath = `/api/appsAdapter/links/{linkId}`.replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary findLinksByIds
         * @param {string} linkIds Comma-separated list of link IDs to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinksByIds(linkIds, options = {}) {
            // verify required parameter 'linkIds' is not null or undefined
            if (linkIds === null || linkIds === undefined) {
                throw new RequiredError("linkIds", "Required parameter linkIds was null or undefined when calling findLinksByIds.");
            }
            const localVarPath = `/api/appsAdapter/links/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (linkIds !== undefined) {
                localVarQueryParameter["linkIds"] = linkIds;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LinksApi - functional programming interface
 * @export
 */
export const LinksApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} linkIds Comma-separated list of link IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterLinksDelete(linkIds, options) {
            const localVarAxiosArgs = LinksApiAxiosParamCreator(configuration).apiAppsAdapterLinksDelete(linkIds, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary batchCreateLinks
         * @param {LinkBatchCreateRequest} linkBatchCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateLinks(linkBatchCreateRequest, options) {
            const localVarAxiosArgs = LinksApiAxiosParamCreator(configuration).batchCreateLinks(linkBatchCreateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary createLink
         * @param {LinkCreateRequest} linkCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink(linkCreateRequest, options) {
            const localVarAxiosArgs = LinksApiAxiosParamCreator(configuration).createLink(linkCreateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary deleteLinkById
         * @param {string} linkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkById(linkId, options) {
            const localVarAxiosArgs = LinksApiAxiosParamCreator(configuration).deleteLinkById(linkId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findLinkById
         * @param {string} linkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinkById(linkId, options) {
            const localVarAxiosArgs = LinksApiAxiosParamCreator(configuration).findLinkById(linkId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary findLinksByIds
         * @param {string} linkIds Comma-separated list of link IDs to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinksByIds(linkIds, options) {
            const localVarAxiosArgs = LinksApiAxiosParamCreator(configuration).findLinksByIds(linkIds, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LinksApi - factory interface
 * @export
 */
export const LinksApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} linkIds Comma-separated list of link IDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppsAdapterLinksDelete(linkIds, options) {
            return LinksApiFp(configuration).apiAppsAdapterLinksDelete(linkIds, options)(axios, basePath);
        },
        /**
         *
         * @summary batchCreateLinks
         * @param {LinkBatchCreateRequest} linkBatchCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateLinks(linkBatchCreateRequest, options) {
            return LinksApiFp(configuration).batchCreateLinks(linkBatchCreateRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary createLink
         * @param {LinkCreateRequest} linkCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink(linkCreateRequest, options) {
            return LinksApiFp(configuration).createLink(linkCreateRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary deleteLinkById
         * @param {string} linkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkById(linkId, options) {
            return LinksApiFp(configuration).deleteLinkById(linkId, options)(axios, basePath);
        },
        /**
         *
         * @summary findLinkById
         * @param {string} linkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinkById(linkId, options) {
            return LinksApiFp(configuration).findLinkById(linkId, options)(axios, basePath);
        },
        /**
         *
         * @summary findLinksByIds
         * @param {string} linkIds Comma-separated list of link IDs to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinksByIds(linkIds, options) {
            return LinksApiFp(configuration).findLinksByIds(linkIds, options)(axios, basePath);
        },
    };
};
/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
export class LinksApi extends BaseAPI {
    /**
     *
     * @param {string} linkIds Comma-separated list of link IDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    apiAppsAdapterLinksDelete(linkIds, options) {
        return LinksApiFp(this.configuration).apiAppsAdapterLinksDelete(linkIds, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary batchCreateLinks
     * @param {LinkBatchCreateRequest} linkBatchCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    batchCreateLinks(linkBatchCreateRequest, options) {
        return LinksApiFp(this.configuration).batchCreateLinks(linkBatchCreateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary createLink
     * @param {LinkCreateRequest} linkCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    createLink(linkCreateRequest, options) {
        return LinksApiFp(this.configuration).createLink(linkCreateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary deleteLinkById
     * @param {string} linkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    deleteLinkById(linkId, options) {
        return LinksApiFp(this.configuration).deleteLinkById(linkId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findLinkById
     * @param {string} linkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    findLinkById(linkId, options) {
        return LinksApiFp(this.configuration).findLinkById(linkId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary findLinksByIds
     * @param {string} linkIds Comma-separated list of link IDs to find
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    findLinksByIds(linkIds, options) {
        return LinksApiFp(this.configuration).findLinksByIds(linkIds, options)(this.axios, this.basePath);
    }
}
/**
 * ResourcesApi - axios parameter creator
 * @export
 */
export const ResourcesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary searchResources
         * @param {ResourceSearchRequest} resourceSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchResources(resourceSearchRequest, options = {}) {
            // verify required parameter 'resourceSearchRequest' is not null or undefined
            if (resourceSearchRequest === null ||
                resourceSearchRequest === undefined) {
                throw new RequiredError("resourceSearchRequest", "Required parameter resourceSearchRequest was null or undefined when calling searchResources.");
            }
            const localVarPath = `/api/appsAdapter/resources/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof resourceSearchRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(resourceSearchRequest !== undefined ? resourceSearchRequest : {})
                : resourceSearchRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary searchResources
         * @param {ResourceSearchRequest} resourceSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchResources(resourceSearchRequest, options) {
            const localVarAxiosArgs = ResourcesApiAxiosParamCreator(configuration).searchResources(resourceSearchRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary searchResources
         * @param {ResourceSearchRequest} resourceSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchResources(resourceSearchRequest, options) {
            return ResourcesApiFp(configuration).searchResources(resourceSearchRequest, options)(axios, basePath);
        },
    };
};
/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI {
    /**
     *
     * @summary searchResources
     * @param {ResourceSearchRequest} resourceSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    searchResources(resourceSearchRequest, options) {
        return ResourcesApiFp(this.configuration).searchResources(resourceSearchRequest, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary healthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
}
/**
 * ToolsApi - axios parameter creator
 * @export
 */
export const ToolsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dalleGenerateImage(options = {}) {
            const localVarPath = `/api/appsAdapter/tools/dalle/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdImg2img(options = {}) {
            const localVarPath = `/api/appsAdapter/tools/sd/img2img`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdInterrogate(options = {}) {
            const localVarPath = `/api/appsAdapter/tools/sd/interrogate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdTxt2Img(options = {}) {
            const localVarPath = `/api/appsAdapter/tools/sd/txt2img`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ToolsApi - functional programming interface
 * @export
 */
export const ToolsApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dalleGenerateImage(options) {
            const localVarAxiosArgs = ToolsApiAxiosParamCreator(configuration).dalleGenerateImage(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdImg2img(options) {
            const localVarAxiosArgs = ToolsApiAxiosParamCreator(configuration).sdImg2img(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdInterrogate(options) {
            const localVarAxiosArgs = ToolsApiAxiosParamCreator(configuration).sdInterrogate(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdTxt2Img(options) {
            const localVarAxiosArgs = ToolsApiAxiosParamCreator(configuration).sdTxt2Img(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ToolsApi - factory interface
 * @export
 */
export const ToolsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dalleGenerateImage(options) {
            return ToolsApiFp(configuration).dalleGenerateImage(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdImg2img(options) {
            return ToolsApiFp(configuration).sdImg2img(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdInterrogate(options) {
            return ToolsApiFp(configuration).sdInterrogate(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdTxt2Img(options) {
            return ToolsApiFp(configuration).sdTxt2Img(options)(axios, basePath);
        },
    };
};
/**
 * ToolsApi - object-oriented interface
 * @export
 * @class ToolsApi
 * @extends {BaseAPI}
 */
export class ToolsApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    dalleGenerateImage(options) {
        return ToolsApiFp(this.configuration).dalleGenerateImage(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    sdImg2img(options) {
        return ToolsApiFp(this.configuration).sdImg2img(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    sdInterrogate(options) {
        return ToolsApiFp(this.configuration).sdInterrogate(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    sdTxt2Img(options) {
        return ToolsApiFp(this.configuration).sdTxt2Img(options)(this.axios, this.basePath);
    }
}
