import { useCallback, useEffect, useState } from 'react';

import { useToggle } from '@just-ai/just-ui';
import { AxiosResponse } from 'axios';

import apiClient from '../../api/client';
import { Conversation } from '../../types/chat';
import { isDev } from '../../utils/app/common';

export const useFileNameHeader = (selectedConversation?: Conversation) => {
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoadingTrue, setIsLoadingFalse] = useToggle();

  const getFileName = useCallback((headers: AxiosResponse['headers']) => {
    if (headers['content-disposition']) {
      const regexFileName = headers['content-disposition'].match(/filename="(.*)"$/);
      if (regexFileName?.[1]) {
        setFileName(decodeURIComponent(regexFileName?.[1]));
        return;
      }
      setFileName('<undefined>');
    }
  }, []);

  useEffect(() => {
    const documentParam = selectedConversation?.app?.params?.['document'];

    if (!isLoading && !fileName && documentParam && typeof documentParam === 'string') {
      const filePath = new URL(documentParam);

      setIsLoadingTrue();

      apiClient
        .head<{}>(isDev() ? filePath.pathname : documentParam, { withCredentials: true })
        .then(data => getFileName(data.headers))
        .catch(error => getFileName(error.response.headers))
        .finally(() => setIsLoadingFalse());
    }
  }, [fileName, getFileName, isLoading, selectedConversation, setIsLoadingFalse, setIsLoadingTrue]);

  return fileName;
};
