import React, { memo, useEffect, useRef } from 'react';

import { Modal } from '@just-ai/just-ui';
import localize, { t } from 'localization';
import { getDefaultLocale, GuideTourStepper } from 'modules/GuideTourStepper';
import { useSkipGuideTour } from 'modules/GuideTourStepper/hooks';
import { GuideStep } from 'modules/GuideTourStepper/types';

import { isDemoMode } from '../../api/client';
import { isAlpha } from '../../isAlpha';
import { tourLocalization } from './tour.loc';
import { tourRestart$ } from './tourRestart';

localize.addTranslations(tourLocalization);
const getGreetingJGraphTour = () =>
  (
    [
      {
        target: '#root',
        dataTestId: 'AssistantTour:TourStep_1_initial',
        title: isAlpha
          ? t('AssistantTour:Step:1:Title', { productName: 'Smarty' })
          : t('AssistantTour:Step:1_Jay:Title', { productName: 'Jay Copilot' }),
        content: t('AssistantTour:Step:1', {
          productName: isAlpha ? 'Smarty' : 'Jay Copilot',
        }),
        placement: 'center',
        disableBeacon: true,
        zIndex: 1052,
        styles: {
          overlay: {
            zIndex: 1051,
          },
          tooltip: {
            zIndex: 1052,
          },
          tooltipArrow: {
            opacity: 0,
          },
        },
        locale: {
          next: t('AssistantTour:Step:1:Next'),
          skip: t('AssistantTour:Step:1:Close'),
        },
      },
      {
        target: '[data-test-id="copywriter"]',
        dataTestId: 'AssistantTour:TourStep_2_Copywriter_Card',
        placement: 'left-start',
        spotlightClicks: true,
        hideFooter: true,
        /* beforeStep: () => {
        amplitudeInstance.logEvent('J-Graph Tutorial Started');
      },*/
        content: (
          <p
            dangerouslySetInnerHTML={{
              __html: t('AssistantTour:Step:2', {
                productName: isAlpha ? 'Smarty' : 'Jay',
                appName: isAlpha ? t('AssistantTour:Step:2-app-alfa') : t('AssistantTour:Step:2-app-copilot'),
              }),
            }}
          />
        ),
      },
      {
        //Modal:copywriter
        waitEvent: 'AssistantTour:Open:copywriter',
        dataTestId: 'AssistantTour:TourStep_3_Copywriter_Modal',
        target: '[data-test-id="Modal:copywriter"]',
        content: t('AssistantTour:Step:3'),
        placement: 'left',
        zIndex: 1052,
        spotlightClicks: true,
        hideFooter: true,
        styles: {
          overlay: {
            zIndex: 1051,
            opacity: 0,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      {
        waitEvent: 'ChatOpened:copywriter',
        dataTestId: 'AssistantTour:TourStep_4_Copywriter_Created',
        target: '#root',
        content: t('AssistantTour:Step:4'),
        placement: 'center',
        disableBeacon: true,
        hideFooter: true,
        styles: {
          tooltipArrow: {
            opacity: 0,
          },
          overlay: {
            zIndex: 1051,
            opacity: 0,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      {
        waitEvent: 'GetResponse:copywriter',
        hideOnEvent: 'ChatInput:send:copywriter',
        dataTestId: 'AssistantTour:TourStep_5',
        target: '[data-test-id="NewMessageInputWrapper:copywriter"]',
        content: t('AssistantTour:Step:5'),
        spotlightClicks: true,
        hideFooter: true,
        placement: 'top',
        zIndex: 1052,
        styles: {
          overlay: {
            zIndex: 1051,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      {
        waitEvent: 'GetResponse:copywriter',
        dataTestId: 'AssistantTour:TourStep_6',
        hideOnEvent: 'TemplateForm:Edit:copywriter',
        target: '[data-test-id="TemplateForm.wrapper:copywriter"]',
        content: t('AssistantTour:Step:6'),
        placement: 'left-start',
        hideFooter: true,
        spotlightClicks: true,
      },
      {
        waitEvent: 'GetResponse:copywriter',
        dataTestId: 'AssistantTour:TourStep_7',
        target: '[data-test-id="GoToChooseNewTemplate"]',
        content: isAlpha ? t('AssistantTour:Step:7') : t('AssistantTour_Jay:Step:7'),
        placement: 'right',
        spotlightClicks: true,
        hideFooter: true,
        styles: {
          overlay: {
            zIndex: 1051,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      {
        waitEvent: 'GoToMainFromSidebarButton',
        dataTestId: 'AssistantTour:TourStep_8',
        target: '[data-test-id="painter"]',
        content: isAlpha ? t('AssistantTour:Step:8') : t('AssistantTour_Jay:Step:8'),

        spotlightClicks: true,
        hideFooter: true,
        placement: 'left',
      },
      {
        waitEvent: 'AssistantTour:Open:painter',
        dataTestId: 'AssistantTour:TourStep_9',
        target: '[data-test-id="Modal:painter"]',
        content: t('AssistantTour:Step:9'),
        placement: 'left',

        spotlightClicks: true,
        hideFooter: true,
        zIndex: 1052,
        styles: {
          overlay: {
            zIndex: 1051,
            opacity: 0,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      {
        waitEvent: 'GetResponse:painter',
        dataTestId: 'AssistantTour:TourStep_10',
        target: '[data-test-id="NewMessageInputWrapper:painter"]',
        content: t('AssistantTour:Step:10'),
        placement: 'top',
        hideFooter: true,
        zIndex: 1052,
        styles: {
          overlay: {
            zIndex: 1051,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      {
        dataTestId: 'AssistantTour:TourStep_11',
        target: '[data-test-id="Conversations:Container"]',
        content: t('AssistantTour:Step:11'),
        placement: 'right',
        hideFooter: true,
        styles: {
          overlay: {
            zIndex: 1051,
          },
          tooltip: {
            zIndex: 1052,
          },
        },
      },
      ...(isAlpha
        ? [
            {
              dataTestId: 'AssistantTour:TourStep_12',
              target: '[data-test-id="GoToChooseNewTemplate"]',
              content: t('AssistantTour:Step:12'),
              placement: 'right',

              spotlightClicks: true,
              hideFooter: true,
              zIndex: 1052,
              styles: {
                overlay: {
                  zIndex: 1051,
                },
                tooltip: {
                  zIndex: 1052,
                },
              },
            },
            {
              waitEvent: 'GoToMainFromSidebarButton',
              dataTestId: 'AssistantTour:TourStep_13',
              target: '[data-test-id="directLLM"]',
              content: <p dangerouslySetInnerHTML={{ __html: t('AssistantTour:Step:13') }} />,
              placement: 'left',
              hideFooter: true,
              zIndex: 1052,
              styles: {
                overlay: {
                  zIndex: 1051,
                },
                tooltip: {
                  zIndex: 1052,
                },
              },
            },
          ]
        : [
            {
              dataTestId: 'AssistantTour:TourStep_12',
              target: '[data-test-id="JayAssistant"]',
              content: <p dangerouslySetInnerHTML={{ __html: t('AssistantTour_Jay:Step:12') }} />,
              placement: 'right',
              hideFooter: true,
              zIndex: 1052,
              styles: {
                overlay: {
                  zIndex: 1051,
                },
                tooltip: {
                  zIndex: 1052,
                },
              },
            },
          ]),
    ] as GuideStep[]
  ).map((step, index, steps) => {
    const nextStep = steps[index + 1];
    if (!nextStep || !nextStep.waitEvent) return step;
    step.goNextOnNextEvent = nextStep.waitEvent;
    return step;
  });

const AssistantTour = memo(() => {
  const guideSteps = useRef<GuideStep[]>(getGreetingJGraphTour());
  const { skipStatus, skip, skipForever, isShowSkipForeverModal, closeSkipForeverModal, unSkip } = useSkipGuideTour();

  useEffect(() => {
    const sub = tourRestart$.subscribe(value => {
      if (!value) return;
      guideSteps.current = getGreetingJGraphTour();
      unSkip();
    });

    return () => sub.unsubscribe();
  }, [unSkip]);

  if (!skipStatus || skipStatus === 'skippedForever' || isDemoMode.value) return null;

  return (
    <>
      <GuideTourStepper
        guideSteps={guideSteps.current}
        skip={skip}
        skipForever={skipForever}
        locale={{ ...getDefaultLocale(), skip: t('AssistantTour:Step:1:Skip') }}
      />

      <Modal
        isOpen={isShowSkipForeverModal}
        onCancelClick={skipForever}
        onActionClick={closeSkipForeverModal}
        title={t('AssistantTour:SkipForeverModal:Title')}
        buttonSubmitText={t('AssistantTour:SkipForeverModal:SubmitText')}
        buttonCancelText={t('AssistantTour:SkipForeverModal:CancelText')}
      >
        <p>{t('AssistantTour:SkipForeverModal:Text')}</p>
      </Modal>
    </>
  );
});
AssistantTour.displayName = 'MemoAssistantTour';

export default AssistantTour;
