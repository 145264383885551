import { FileResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { signal } from '@preact/signals-react';

export const appHasUnsavedChanges = signal<boolean>(false);

export const settingsModalIsOpenedFromChat = signal<boolean>(false);

export const showModalFromChatInput = signal<boolean>(false);

export const resetContextSilent = signal<boolean>(false);

export const chatFileFromFileService = signal<FileResponse | null>(null);

export const chatTextAppendSignal = signal<string | null>(null);
