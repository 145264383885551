import React from 'react';

import { TextAreaLikeElement, useTranslation } from '@just-ai/just-ui';
import { useController } from 'react-hook-form';

import { FormBuilderField } from '../../types';

const numberOfDigits = (number: number) => number.toString().length;

const PromptCharacterCounter = ({ maxLength, text }: { maxLength: FormBuilderField['maxLength']; text?: string }) => {
  const minWidth = `${
    maxLength
      ? numberOfDigits(maxLength) * 2 + 1 // * 2 because the number appears twice, + 1 for the slash
      : numberOfDigits(text?.length ?? 0)
  }ch`;

  return (
    <div className='text-muted margin-bottom-8 flex align-self-end justify-end' style={{ minWidth }}>
      {text?.length ?? 0}
      {maxLength && `/${maxLength}`}
    </div>
  );
};

const Prompt = ({
  control,
  required,
  name,
  label,
  hint,
  maxLength,
  placeholder,
  dropDownHint,
  count,
  disabled,
  error,
}: FormBuilderField) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({ name, control, rules: { required } });
  return (
    <div className='flex flex-col' data-test-id={`TemplateForm.InputText.prompt-${name}-wrapper`}>
      <div className='d-flex flex-row justify-between'>
        <label htmlFor={name}>{label}</label>
        {count && <PromptCharacterCounter maxLength={maxLength} text={field.value} />}
      </div>
      <TextAreaLikeElement
        maxRows={5}
        minRows={4}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        value={field.value || ''}
        richValue={field.value || ''}
        dataTestId={`TemplateForm.InputText.prompt-${name}`}
        onChange={field.onChange}
        dropDownHint={dropDownHint}
        hint={hint}
        errorText={fieldState.invalid ? t('This field is required') : undefined}
        invalid={error}
      />
    </div>
  );
};

Prompt.displayName = 'Prompt';

export default React.memo(Prompt);
