import React, { memo } from 'react';

import { DropDownHint, Range } from '@just-ai/just-ui';
import cn from 'classnames';
import { useController } from 'react-hook-form';

import { FormBuilderField } from '../../types';
import classes from './Slider.module.scss';

export const FormSlider = memo(
  ({
    control,
    name,
    hint,
    step,
    min,
    max,
    label,
    required,
    value: defaultValue,
    visibleStep,
    dropDownHint,
    disabled,
  }: FormBuilderField) => {
    const { field } = useController({ name, control, rules: { required } });

    //TODO disabled state

    return (
      <div className='flex flex-col' key={name} data-test-id={`TemplateForm.Slider.${name}-wrapper`}>
        <label className='flex justify-between'>
          <span>{label}</span>
          <b>{field.value ?? defaultValue}</b>
        </label>
        <Range
          className={cn(classes.slider)}
          containerClassName={cn('flex-column-reverse', classes.slider)}
          rangeClassName='relative z-index-auto top-unset'
          min={min}
          max={max}
          step={step}
          value={field.value}
          visibleStep={visibleStep}
          visibleStepLabel={(value: number) => `${value}`}
          name={name}
          onChange={field.onChange}
          data-test-id={`TemplateForm.Slider.${name}`}
          disabled={disabled}
        />

        {hint && <small className='text-muted hint' dangerouslySetInnerHTML={{ __html: hint }} />}
        {dropDownHint ? <DropDownHint dropDownHint={dropDownHint} /> : null}
      </div>
    );
  }
);

FormSlider.displayName = 'memo(FormSlider)';
