import { useCallback, useState, useEffect, useRef } from 'react';

import { MediaRecorder } from 'extendable-media-recorder';

import { MediaRecorderEvent, Recorder } from '../types/recorder';

const initialState: Recorder = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  micDisabled: false,
  recPaused: false,
};

function useAudioRecorder() {
  const [recorderState, setRecorderState] = useState<Recorder>(initialState);

  const localAudio = useRef<Blob | null>(null);

  const startRecording = useCallback(async () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then(stream => {
        setRecorderState(prevState => {
          return {
            ...prevState,
            initRecording: true,
            mediaStream: stream,
          };
        });
      })
      .catch(error => {
        setMicDisabled(true);
      });
  }, []);

  const setMicDisabled = (value: boolean) => {
    setRecorderState(prevState => ({ ...prevState, micDisabled: value }));
  };

  const saveRecording = useCallback(() => {
    const recorder = recorderState.mediaRecorder;
    recorderState.mediaStream?.getTracks()[0]?.stop();
    if (recorder && recorder.state !== 'inactive') recorder.stop();
  }, [recorderState.mediaRecorder, recorderState.mediaStream]);

  const cancelRecording = useCallback(() => {
    const recorder = recorderState.mediaRecorder;
    recorderState.mediaStream?.getTracks()[0]?.stop();
    if (recorder && recorder.state !== 'inactive') recorder.onstop = () => {};
    localAudio.current = null;
    setRecorderState(initialState);
  }, [recorderState.mediaRecorder, recorderState.mediaStream]);

  useEffect(() => {
    const MAX_RECORDER_TIME = 1;
    let recordingInterval: NodeJS.Timer | null = null;

    if (recorderState.initRecording)
      recordingInterval = setInterval(() => {
        setRecorderState((prevState: Recorder) => {
          if (prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0) {
            typeof recordingInterval === 'number' && clearInterval(recordingInterval);
            prevState.mediaRecorder?.pause();
            return { ...prevState, recPaused: true };
          }

          if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59)
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };
          else if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
          else return prevState;
        });
      }, 1000);
    else typeof recordingInterval === 'number' && clearInterval(recordingInterval);

    return () => {
      typeof recordingInterval === 'number' && clearInterval(recordingInterval);
    };
  });

  useEffect(() => {
    setRecorderState(prevState => {
      if (prevState.mediaStream) {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream, {
            mimeType: 'audio/wav',
          }),
        };
      } else return prevState;
    });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks: Blob[] = [];

    if (recorder && recorder.state === 'inactive') {
      recorder.start();

      recorder.ondataavailable = (e: MediaRecorderEvent) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        chunks = [];

        localAudio.current = blob;

        setRecorderState(initialState);
      };
    }

    return () => {
      if (recorder) recorder.stop();
    };
  }, [recorderState.mediaRecorder]);

  return {
    recorderState,
    startRecording,
    cancelRecording,
    saveRecording,
    localAudio,
    setMicDisabled,
  };
}

export default useAudioRecorder;
