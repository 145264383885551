import React, { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { safeJsExpressionForFormBuilder } from '../../jsExpression';
import { FormBuilderProps } from './FormBuilder';

export function TemplateFormFieldController<TProps extends FormBuilderProps>(
  WrappedComponent: React.ComponentType<TProps>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithController = (props: FormBuilderProps) => {
    const { param, data } = props;
    const { expression, type, name, dependsOn } = param;

    const methods = useFormContext();

    const { register, unregister, getValues, setValue, watch } = methods;
    const [isShown, setIsShown] = useState(true);

    const checkIf = useCallback(() => {
      if (param.if) {
        setIsShown(
          !!safeJsExpressionForFormBuilder(param.if, {
            $params: { ...getValues() },
            $data: data as object,
          })
        );
      }
    }, [data, getValues, param.if]);

    useEffect(() => {
      checkIf();
      const sub = watch(() => {
        checkIf();
      });
      return () => sub.unsubscribe();
    }, [checkIf, watch]);

    const setValueToField = useCallback(() => {
      if (!isShown || type === 'group' || type === 'link') return;

      const formValues = getValues();

      let value = '';
      switch (true) {
        case !param.value && Boolean(expression && formValues && Object.keys(formValues).length):
          value = safeJsExpressionForFormBuilder(expression, { $params: formValues, $data: props.data as object });
          break;
        case param.value === undefined:
          value = '';
          break;
        case !expression && formValues[name] !== undefined:
          value = formValues[name];
          break;
        default:
          value = param.value;
          break;
      }

      if (['select', 'selectWithAudio'].includes(param.type) && param.required) {
        try {
          const calculatedOptions = param.options
            ? Array.isArray(param.options)
              ? param.options
              : safeJsExpressionForFormBuilder(param.options, {
                  $params: formValues,
                  $data: props.data as object,
                })
            : [];
          if (!calculatedOptions.includes(value)) {
            value = expression
              ? safeJsExpressionForFormBuilder(expression, { $params: formValues, $data: props.data as object })
              : calculatedOptions[0] || '';
          }
        } catch (e) {}
      }

      setValue(name, formValues[name] || value);
    }, [
      expression,
      getValues,
      isShown,
      name,
      param.options,
      param.required,
      param.type,
      param.value,
      props.data,
      setValue,
      type,
    ]);

    useEffect(() => {
      if (!['link', 'group'].includes(param.type)) setValueToField();
    }, [param.type, setValueToField, type]);

    useEffect(() => {
      if (['link', 'group'].includes(param.type) || !(expression && dependsOn)) return;
      const sub = watch((_, { name, type }) => {
        if (name !== dependsOn) return;
        setValueToField();
      });
      return () => sub.unsubscribe();
    }, [dependsOn, expression, param.type, setValueToField, watch]);

    useEffect(() => {
      if (isShown && !['link', 'group'].includes(param.type)) register(name);
      else unregister(name);
    }, [isShown, name, param.type, register, unregister]);

    if (!isShown) return null;

    return <WrappedComponent {...(props as TProps)} {...props.param} />;
  };

  ComponentWithController.displayName = `withTemplateFormController(${displayName})`;

  return ComponentWithController;
}
