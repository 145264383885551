import React, { useEffect } from 'react';

import { IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import { chatFileFromFileService } from './signals/ChatUpdateSignal';
import styles from './style.module.scss';

type Props = {
  files: File[] | null;
  handleFileRemove: (file: File) => void;
};

export default function Attachments(props: Props) {
  const { files, handleFileRemove } = props;

  return (
    <div className='flex gap-4 pl-2 margin-bottom-8'>
      {files?.map(file => (
        <div className={cn('flex items-center gap-4', styles.chat__fileWrapper)} key={file.name}>
          <p className='text-ellipsis' title={file.name}>
            {file.name}
          </p>
          <IconButton
            name='falTimes'
            flat
            size='sm'
            color='secondary'
            className={styles.chat__fileWrapper_deleteButton}
            onClick={() => handleFileRemove(file)}
          />
        </div>
      ))}
    </div>
  );
}
export function AttachmentFileFromFileService() {
  const handleFileRemove = () => {
    chatFileFromFileService.value = null;
  };

  useEffect(() => {
    return () => {
      chatFileFromFileService.value = null;
    };
  }, []);
  if (chatFileFromFileService.value) {
    return (
      <div className='flex gap-4 pl-2 margin-bottom-8'>
        <div className={cn('flex items-center gap-4', styles.chat__fileWrapper)}>
          <p className='text-ellipsis' title={chatFileFromFileService.value.name}>
            {chatFileFromFileService.value.name}
          </p>
          <IconButton
            name='falTimes'
            flat
            size='sm'
            color='secondary'
            className={styles.chat__fileWrapper_deleteButton}
            onClick={() => handleFileRemove()}
          />
        </div>
      </div>
    );
  }
  return null;
}
