import { createContext, useContext } from 'react';

import { ErrorResponse, FileResponse, LinkResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { AxiosResponse } from 'axios';

const initialContext: {
  getResources: () => unknown;
  deleteFilesHandle: (fileIds: string[]) => Promise<unknown>;
  batchUploadFilesHandle: (files: File[]) => Promise<AxiosResponse<(FileResponse | ErrorResponse)[]>>;
  uploadFileHandle: (file: File) => Promise<AxiosResponse<FileResponse>>;
  deleteFileHandle: (fileId: string) => Promise<AxiosResponse<unknown>>;
  batchCreateLinks: (urls: string[]) => Promise<AxiosResponse<(ErrorResponse | LinkResponse)[]>>;
  deleteLinks: (linksIds: string[]) => Promise<AxiosResponse<unknown>>;
  deleteLink: (linkId: string) => Promise<AxiosResponse<unknown>>;
} = {
  getResources: () => {},
  deleteFilesHandle: fileIds => Promise.reject('<boilerplate>'),
  batchUploadFilesHandle: files => Promise.reject('<boilerplate>'),
  uploadFileHandle: files => Promise.reject('<boilerplate>'),
  deleteFileHandle: fileId => Promise.reject('<boilerplate>'),
  batchCreateLinks: urls => Promise.reject('<boilerplate>'),
  deleteLinks: linksIds => Promise.reject('<boilerplate>'),
  deleteLink: linkId => Promise.reject('<boilerplate>'),
};
const FilesUploadContext = createContext(initialContext);

export const FilesUploadProvider = FilesUploadContext.Provider;

export const useFileUploadProvider = () => useContext(FilesUploadContext);
