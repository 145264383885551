import React, { memo } from 'react';

import { Button, useToggle, useTranslation } from '@just-ai/just-ui';

import { ModalUploadFiles } from './ModalUploadFiles';

export const FilesUpload = memo(() => {
  const { t } = useTranslation();
  const [isModalOpen, setOpenModal, setCloseModal] = useToggle(false);
  return (
    <>
      <Button iconLeft='farFileUpload' color='secondary' outline onClick={setOpenModal}>
        {t('Files:Page:button-upload')}
      </Button>
      <ModalUploadFiles isModalOpen={isModalOpen} setCloseModal={setCloseModal} />
    </>
  );
});
FilesUpload.displayName = 'memo(FilesUpload)';
