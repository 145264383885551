import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import { findConversationIndex } from '../../../models/conversations';
import { setConversationsValue } from '../../../models/conversations/signals';
import { ToolCallMessagePart } from '../../../types/chat';
import { useCanvasHolder } from '../CanvasHolderContext/CanvasHolderContext';
import { useShowUiToolResponses } from '../useShowUiToolResponses.hook';

export const ToolCallMessage: FC<{ content: ToolCallMessagePart['toolCalls']; selectedConversationId: string }> = memo(
  ({ content, selectedConversationId }) => {
    const showUiCalls = useMemo(
      () => content.filter(contentItem => contentItem.function.name === 'show_ui'),
      [content]
    );
    const sendFileToIframeMsgs = useMemo(
      () => content.filter(contentItem => contentItem.function.name === 'setFile'),
      [content]
    );

    const { toolCalls, sendToolResponseMessageToChat, setFileInIframe } = useCanvasHolder();

    const getShowUiToolResponses = useShowUiToolResponses(showUiCalls, selectedConversationId);

    const showUi = useCallback(async () => {
      const { toolResponses } = await getShowUiToolResponses();
      if (toolResponses.length > 0) sendToolResponseMessageToChat(toolResponses);
    }, [getShowUiToolResponses, sendToolResponseMessageToChat]);

    const setFileInIframeHandler = useCallback(async () => {
      if (!sendFileToIframeMsgs.length) return;
      for (let sendFileToIframeMsg of sendFileToIframeMsgs) {
        try {
          const parsedArgs = JSON.parse(sendFileToIframeMsg.function.arguments);
          const { uri, type, name } = JSON.parse(sendFileToIframeMsg.function.arguments);
          await setFileInIframe({ uri, type, name }, sendFileToIframeMsg, parsedArgs.externalInstanceId);
        } catch (e) {}
      }
    }, [sendFileToIframeMsgs, setFileInIframe]);
    //force set status to "building", to avoid user write in chat before iframe response
    const setConversationStatusBuilding = useCallback(() => {
      setConversationsValue(storeConversations => {
        const conversationIndex = findConversationIndex(selectedConversationId);
        if (conversationIndex > -1) {
          storeConversations[conversationIndex].status = 'BUILDING';
        }
      });
    }, [selectedConversationId]);

    useEffect(() => {
      setConversationStatusBuilding();
      if (showUiCalls.length) showUi();
      if (sendFileToIframeMsgs.length) setFileInIframeHandler();
      const callsWithoutSpecial = content.filter(
        contentItem => !['show_ui', 'setFile'].includes(contentItem.function.name)
      );
      if (callsWithoutSpecial.length > 0) {
        toolCalls(callsWithoutSpecial);
      }
    }, [
      content,
      sendFileToIframeMsgs.length,
      setConversationStatusBuilding,
      setFileInIframeHandler,
      getShowUiToolResponses,
      showUiCalls.length,
      toolCalls,
    ]);

    return null;
  }
);
