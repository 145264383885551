import { useCallback, useEffect, useRef } from 'react';

import { FileResponse, ResourceSearchRequest, ResourceType } from '@just-ai/api/dist/generated/AppsAdapter';
import AppsAdapterService from '@just-ai/api/dist/services/AppsAdapterService';
import { AppLogger } from '@just-ai/logger';
import { computed } from '@preact/signals-react';
import { cloneDeep, merge } from 'lodash';

import { batchUploadFiles, uploadFile } from './api';
import { defaultUserFilesFilters } from './consts';
import { resourcesLoaded, resourcesLoading, setResourcesForTab, updateTotals } from './signals';
import apiClient from '../../api/client';
import { useTabs } from '../../components/Files/FilesTabs';
import { defaultFilters, filters, setTabFilters } from '../../pages/Files/FIlters.Contorller';

export const useResources = ({
  initialLoad,
  initialActiveTab,
  initialFilters,
}: {
  initialLoad: boolean;
  initialActiveTab: ResourceType;
  initialFilters?: {
    filters?: Partial<ResourceSearchRequest['filters']>;
    sort?: Partial<ResourceSearchRequest['sort']>;
    page?: ResourceSearchRequest['page'];
  };
}) => {
  const wasLoaded = useRef(false);
  const appsAdapterApi = useRef(new AppsAdapterService(apiClient));

  const lastFilters = useRef<ResourceSearchRequest | null>(null);

  const [activeTab, onTabChangeHandle, tabs] = useTabs(initialActiveTab);

  const getResources = useCallback((filters: ResourceSearchRequest | null = lastFilters.current) => {
    if (!filters) return;
    lastFilters.current = filters;
    const filtersForRequest = cloneDeep(filters);
    setTabFilters(filters.filters.type, filters);
    resourcesLoading.value = true;
    if (filtersForRequest.filters.formats) {
      filtersForRequest.filters.formats = filtersForRequest.filters.formats
        .map(format => format.split(',').map(format => format.trim()))
        .flat();
    }
    return appsAdapterApi.current
      .getResources(filtersForRequest)
      .then(({ data }) => {
        if (JSON.stringify(lastFilters.current) === JSON.stringify(defaultFilters[filters.filters.type])) {
          updateTotals(data.totals);
        }
        setResourcesForTab(filters.filters.type, data);
        resourcesLoaded.value = true;
      })
      .catch(AppLogger.createErrorHandler('', { forToast: true }))
      .finally(() => {
        resourcesLoading.value = false;
      });
  }, []);

  const postAction = useCallback(() => {
    if (Date.now() < new Date(filters.value[activeTab]?.filters?.periodTo || 0).getTime()) {
      getResources();
    }
  }, [activeTab, getResources]);

  const batchUploadFilesHandle = useCallback(
    async (files: File[]) => {
      return batchUploadFiles(files).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );

  const uploadFileHandle = useCallback(
    async (file: File) => {
      return uploadFile(file).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );

  const deleteFileHandle = useCallback(
    async (fileId: FileResponse['id']) => {
      return appsAdapterApi.current.deleteFileById(fileId).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );

  const deleteFilesHandle = useCallback(
    async (fileIds: FileResponse['id'][]) => {
      return appsAdapterApi.current.deleteByIds(fileIds).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );

  const batchCreateLinks = useCallback(
    (urls: string[]) => {
      return appsAdapterApi.current.batchCreateLinks(urls).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );
  const deleteLinks = useCallback(
    (linksIds: string[]) => {
      return appsAdapterApi.current.apiAppsAdapterLinksDelete(linksIds).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );
  const deleteLink = useCallback(
    (linkId: string) => {
      return appsAdapterApi.current.deleteLink(linkId).then(data => {
        postAction();
        return data;
      });
    },
    [postAction]
  );

  useEffect(() => {
    if (initialLoad && !wasLoaded.current) {
      wasLoaded.current = true;
      getResources(merge(cloneDeep(defaultUserFilesFilters), initialFilters));
    }
  }, [getResources, initialFilters, initialLoad]);

  useEffect(() => {
    let requestFilters = merge(
      cloneDeep(defaultUserFilesFilters),
      { filters: { type: activeTab } },
      filters.value[activeTab]
    );
    if (!resourcesLoading.value) {
      getResources(requestFilters);
    }
  }, [activeTab, getResources]);

  return {
    batchCreateLinks,
    deleteLinks,
    deleteLink,
    activeTab,
    onTabChangeHandle,
    tabs,
    getResources,
    batchUploadFilesHandle,
    uploadFileHandle,
    deleteFileHandle,
    deleteFilesHandle,
  };
};
