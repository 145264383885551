import React, { useCallback, useRef, useEffect } from 'react';

import { useTranslation, DatePicker, useToggle } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import cn from 'classnames';

import styles from './style.module.scss';

export const AnalyticsDatePicker = ({
  dates,
  setDates,
  buttonText,
}: {
  dates: Range;
  setDates: (value: Range) => void;
  buttonText?: string;
}) => {
  const { getLocale, t } = useTranslation();
  const datePicker = useRef<HTMLDivElement | null>(null);
  const [open, , setClose, toggle] = useToggle(false);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (datePicker.current && !datePicker.current.contains(event.target as Node)) {
        setClose();
      }
    },
    [setClose]
  );

  const onDateChange = datePeriod => {
    if (!datePeriod || datePeriod instanceof Date) return;
    return setDates(datePeriod);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return document.removeEventListener('mousedown', handleClickOutside);
  }, [open, handleClickOutside]);

  return (
    <div className={styles.AnalyticsDatePicker} ref={datePicker}>
      <div className='font-size-12 cursor-pointer'>
        <p className={cn(styles.AnalyticsDatePicker__text)} onClick={toggle}>
          {buttonText || t('Analytics:period')}{' '}
          <span className={cn(styles.AnalyticsDatePicker__dates)}>
            {`${dates.startDate.toLocaleDateString(getLocale())} - ${dates.endDate.toLocaleDateString(getLocale())}`}
          </span>
        </p>
      </div>
      <DatePicker
        data-test-id='Account:Analytics:DatePicker'
        key={`datepicker-${dates.startDate}-${dates.endDate}`}
        withTime={false}
        months={2}
        defaultValue={open ? dates : undefined}
        locale={getLocale() === 'ru' ? 'ru' : 'en'}
        onChange={onDateChange}
        calendarClassName={styles.AnalyticsDatePicker__calendar}
        rootClassName={styles.AnalyticsDatePicker__datepicker}
        hideInputs
        maxRangeInMonths={3}
        openCalendar={open}
      />
    </div>
  );
};
