import { effect } from '@preact/signals-react';

import { isDev } from '../app/common';

const devTools = isDev() ? window.__REDUX_DEVTOOLS_EXTENSION__ : undefined;
let subscription = undefined;
if (devTools) {
  subscription = devTools.connect({ name: 'App store' });
}
export const reduxConnectSignal = (storeName, signal) => {
  if (subscription) {
    let isEventFromDevtools = false;
    subscription.init('_INIT_');
    effect(() => {
      if (!isEventFromDevtools) {
        const value = signal.value;
        subscription.send(`Change ${storeName}`, value);
      }
    });

    subscription.subscribe(evt => {
      if (evt.type === 'DISPATCH') {
        //TODO think about history
        console.log(JSON.parse(evt.state));
        //   isEventFromDevtools = true;
        //   signal.value = JSON.parse(evt.state);
        //   isEventFromDevtools = false;
      }
    });
  }
};
