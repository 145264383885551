import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Conversation } from '@just-ai/api/dist/generated/CopilotGateway';
import { Spinner, usePromiseProcessing } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import useApiService from 'services/useApiService';

import styles from './styles.module.scss';
import { Message } from '../../../../types/chat';
import { processHistory } from '../../../../utils/app/conversation';
import { ChatMessage } from '../../../Chat/ChatMessage';
import { DrawerData } from '../../tabs/AnalyticsTab';

export const ChatHistoryWrapper = ({
  conversation,
  userData,
}: {
  conversation: Conversation;
  userData: DrawerData;
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const { getChatHistory } = useApiService();

  const handleHistoryFetch = useCallback(
    async (oldestTimestamp?: number) => {
      if (!conversation) return;
      const { data: newHistory } = await getChatHistory(conversation.id, oldestTimestamp, String(userData.id));
      const updatedHistory = processHistory(newHistory.messages);
      setChatHistory(prevHistory => [...updatedHistory, ...prevHistory]);
    },
    [conversation, getChatHistory, userData.id]
  );

  const [{ loading }, getSelectedChatHistory] = usePromiseProcessing(handleHistoryFetch, {
    deps: [handleHistoryFetch],
    onError: exception => AppLogger.error({ message: 'failed to initially load chat history', exception }),
  });

  const [{ loading: loadingMoreMessages }, fetchMoreMessages] = usePromiseProcessing(handleHistoryFetch, {
    deps: [handleHistoryFetch],
    onError: exception => AppLogger.error({ message: 'failed to fetch more messages on scroll', exception }),
  });

  useEffect(() => {
    getSelectedChatHistory();
  }, [getSelectedChatHistory]);

  const handleScrollTop = useCallback(() => {
    if (chatContainerRef.current && !loadingMoreMessages) {
      const { scrollTop } = chatContainerRef.current;
      if (scrollTop === 0) {
        fetchMoreMessages(chatHistory.length ? chatHistory[0].createdAt : 0);
      }
    }
  }, [chatHistory, fetchMoreMessages, loadingMoreMessages]);

  return (
    <div className={styles.ChatHistory__container}>
      <div className={styles.ChatHistory__header}>
        <h3>{conversation.name}</h3>
      </div>
      <div
        ref={chatContainerRef}
        onScroll={() => {
          handleScrollTop();
        }}
        onWheel={event => {
          if (event.currentTarget.scrollTop === 0) {
            handleScrollTop();
          }
        }}
        className={styles.ChatHistory__scrollWrapper}
      >
        {loading && <Spinner />}
        <div className={styles.ChatHistory__histWrapper}>
          {chatHistory.map((message, index, history) => {
            const previousMessage = index === 0 ? null : history[index - 1];
            const nextMessage = index < history.length - 1 ? history[index + 1] : undefined;
            return (
              <ChatMessage
                key={message.id}
                isLastMessage={index >= history.length - 1}
                isMessageStreaming={false}
                messageContent={message.content}
                messageCreatedAt={message.createdAt}
                entities={message.entities}
                messageId={message.id ?? `${index}`}
                messageType={message.type}
                previousMessageId={previousMessage?.id}
                previousMessageType={previousMessage?.type}
                prevMessageCreatedAt={previousMessage?.createdAt}
                selectedConversationId={conversation.id}
                selectedConversationName={conversation.name}
                dataTestId={`Chat.Message.${index}`}
                tokensSpent={message.meta?.tokensSpent}
                nextMessage={nextMessage}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
