import React, { FC, useCallback } from 'react';

import { Icon, Spinner, Tooltip, useId } from '@just-ai/just-ui';

import { UPLOAD_STATUSES, UploadSource } from './types';
import { useFileUploadProvider } from '../../../pages/Files/FilesUploadProvider';

type UploadFileRowProp = { file: UploadSource; onDeleteFile: (id: string) => unknown };

export const ModalUploadFileRow: FC<UploadFileRowProp> = ({ file, onDeleteFile }) => {
  const uuid = useId();
  const { deleteFileHandle } = useFileUploadProvider();
  const onDeleteHandle = useCallback(() => {
    if (file.id) {
      deleteFileHandle(file.id);
      onDeleteFile(file.id);
    }
  }, [deleteFileHandle, file.id, onDeleteFile]);

  return (
    <div className='flex flex-row justify-content-between'>
      <div className='flex gap-8'>
        <div>
          {(file.status === UPLOAD_STATUSES.LOADING || file.status === UPLOAD_STATUSES.READY) && (
            <Spinner size='md' inline />
          )}
          {file.status === UPLOAD_STATUSES.SUCCESS && <Icon name='faCheckCircle' color='success' size='md' />}
          {file.status === UPLOAD_STATUSES.ERROR && (
            <>
              <Icon name='farExclamationTriangle' color='danger' size='md' id={uuid} />
              <Tooltip target={uuid} placement='bottom' trigger='hover' positionFixed>
                {file.error || 'Error'}
              </Tooltip>
            </>
          )}
        </div>
        <div>{file.name}</div>
      </div>
      <div>
        {file.status === UPLOAD_STATUSES.SUCCESS && (
          <Icon name='farTrashAlt' className='cursor-pointer' onClick={onDeleteHandle} />
        )}
      </div>
    </div>
  );
};
