import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import axios from 'axios';
import { TemplatesView } from 'components/Agents/TemplatesView';
import { createTelegramUserTemplate } from 'models/agents';

import { useTelegramContext } from '../../contexts/TelegramContextProvider';
import { t } from '../../localization';
import { addAlert } from '../../models/alerts';
import { templates } from '../../models/templates';
import useApiService from '../../services/useApiService';
import { AgentType, CreatedAgent, ValidationTemplate } from '../../types/agents';
import { GA } from '../../utils/app/common';
import styles from './styles.module.scss';

type TemplatesListProps = {};

const telegramCss = `body {overflow: auto !important;}`;

export const TelegramTemplatesList = memo<TemplatesListProps>(() => {
  const { locale, userId } = useTelegramContext();
  const [selectedTemplate, setSelectedTemplate] = useState<AgentType | CreatedAgent | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState<ValidationTemplate>(null);
  const { uploadFile, prevalidateApp } = useApiService();
  const cancelRefCreating = useRef<AbortController>();

  const templatesList = templates.value?.templatesList as AgentType[];

  const handleSelectTemplate = useCallback((template: AgentType) => {
    setSelectedTemplate(template);
  }, []);

  const handleAgentCreate = useCallback(
    async (config: any) => {
      setLoading(true);
      try {
        cancelRefCreating.current = new AbortController();
        const { data: checkResult } = await prevalidateApp(config, cancelRefCreating.current);
        if (!!checkResult.deniedEntities?.length) {
          setErrorState(checkResult.deniedEntities as ValidationTemplate);
          return;
        }
        const data = await createTelegramUserTemplate(config);
        GA('App_launch', config.template);
        window.Telegram?.WebApp.sendData(data);

        setSelectedTemplate(null);
      } catch (error) {
        window.Telegram?.WebApp.MainButton.hideProgress();
        if (axios.isAxiosError(error)) {
          window.Telegram?.WebApp.showAlert(error?.response?.data);
        }
      } finally {
        setLoading(false);
      }
    },
    [prevalidateApp]
  );

  const handleFileUpload = useCallback(
    async (data: File) => {
      setLoading(true);
      try {
        const { data: resp } = await uploadFile(data);
        return { fileId: resp.id, fileName: resp.name, url: resp.url };
      } catch (error) {
        addAlert(t('fileUploadErr') + ' ' + (error as AxiosError).message);
      } finally {
        setLoading(false);
      }
    },
    [uploadFile]
  );

  const cancelTemplate = () => {
    setSelectedTemplate(null);
    setLoading(false);
  };

  useEffect(() => {
    if (!selectedTemplate) {
      window.Telegram?.WebApp.MainButton.hide();
      window.Telegram?.WebApp.BackButton.hide();
    }
  }, [selectedTemplate]);

  return (
    <div className={styles.telegram}>
      <style>{telegramCss}</style>
      <TemplatesView
        isOpenFromTelegram={true}
        locale={locale}
        allTemplates={templatesList}
        onTemplateSelect={handleSelectTemplate}
        currentTemplate={selectedTemplate}
        onCancelClick={cancelTemplate}
        loading={loading}
        userId={userId}
        submit={handleAgentCreate}
        handleFileUpload={handleFileUpload}
        errorState={errorState}
      />
    </div>
  );
});
