import { Signal, signal } from '@preact/signals-react';
import { Draft, produce } from 'immer';

export type NotWrappable = string | number | bigint | symbol | boolean | Function | null | undefined;

export type ImmerSetter<Type> = <Uniform extends Type>(
  value:
    | (Uniform extends Function ? never : Uniform)
    | (Uniform extends NotWrappable ? never : (draft: Draft<Uniform>) => void)
) => void;

export type ImmerSignal<Type> = [value: Signal<Type>, set: ImmerSetter<Type>];

export function createImmerSignal<Type>(initialValue: Type): ImmerSignal<Type> {
  const signalV = signal<Type>(initialValue);

  const setter = (value: Type) => {
    signalV.value = value;
  };

  const immerSetter: ImmerSetter<Type> = (cb: any) => {
    if (typeof cb !== 'function') {
      return setter(cb);
    } else {
      return setter(
        produce(signalV.value, (draft: Draft<Type>) => {
          cb(draft);
        })
      );
    }
  };

  return [signalV, immerSetter];
}
