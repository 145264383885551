import React, { FC, memo } from 'react';

import { FileResponse, LinkResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { Modal, useTranslation } from '@just-ai/just-ui';

import { FilesPage } from './Files';
import { chatFileFromFileService, chatTextAppendSignal } from '../../components/Chat/signals/ChatUpdateSignal';

export const ChatHistoryFilesModal: FC<{
  isOpen: boolean;
  formats?: string[];
  conversationId: string;
  onClose: () => void;
}> = memo(({ isOpen, formats, conversationId, onClose: handleClose }) => {
  const { t } = useTranslation();

  const onTableRowClick = (source: FileResponse | LinkResponse) => {
    if ('contentType' in source) {
      chatFileFromFileService.value = source;
    } else {
      chatTextAppendSignal.value = source.url;
    }
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickCloseIcon={handleClose}
      title={t('Files:Page:title')}
      size='xl'
      naturalScrollbar
      onClosed={handleClose}
    >
      <FilesPage
        hideTos
        hideHeader
        hideUploadButtons
        hideAppsFilter
        noPagePadding
        hideTabs
        hideTableRowSelection
        hideTableRowDropdown
        tableRowOnClick={onTableRowClick}
        filtersParam={{
          filters: {
            formats,
            conversationId,
          },
        }}
        pageStyle={{ height: '100%', minHeight: 320 }}
      />
    </Modal>
  );
});
