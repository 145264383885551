export async function createFileFromBase64(
  dataUrl: string,
  fileName: string = 'file',
  fileType?: string
): Promise<File> {
  const type = fileType || dataUrl.match(/^data:(.+);base64/)?.[1];
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName + `.${type?.split('/')[1] || 'png'}`, { type });
}

export async function createFileFromArrayBuffer(
  dataArrayBuffer: ArrayBuffer,
  fileName: string = 'file',
  fileType: string
): Promise<File> {
  return new File([new Blob([dataArrayBuffer], { type: fileType })], fileName + `.${fileType}`, { type: fileType });
}
