export const tourLocalization = {
  'AssistantTour:Step:1:Title': {
    eng: 'Get started with #{config.productName}',
    ru: 'Знакомство со #{config.productName}',
  },
  'AssistantTour:Step:1_Jay:Title': {
    eng: 'Get started with #{config.productName}',
    ru: 'Знакомство с #{config.productName}',
  },
  'AssistantTour:Step:1:Next': {
    eng: 'Go ahead',
    ru: 'Начать обучение',
  },
  'AssistantTour:Step:1:Close': {
    eng: 'No thanks',
    ru: 'Нет, спасибо',
  },
  'AssistantTour:Step:1:Skip': {
    eng: 'Postpone onboarding',
    ru: 'Отложить обучение',
  },
  'AssistantTour:Step:1': {
    eng: 'With #{config.productName}, you can use modern artificial intelligence in your daily tasks. This will save your time and increase your work efficiency. Would you like to start onboarding?',
    ru: 'С помощью #{config.productName} вы можете использовать современный искусственный интеллект в своих повседневных задачах. Это сэкономит ваше время и повысит эффективность работы. Хотите ознакомиться с сервисом?',
  },
  'AssistantTour:Step:2': {
    eng: '#{config.productName} applications can help with your tasks using artificial intelligence. Let’s explore applications’ functionality with {appName} as an example. Please launch it.',
    ru: 'В #{config.productName} есть приложения для решения прикладных задач с помощью искусственного интеллекта. Давайте посмотрим, как они работают на примере приложения {appName}. Запустите его.',
  },
  'AssistantTour:Step:2-app-copilot': {
    eng: '“Copywriter”',
    ru: '«Копирайтер»',
  },
  'AssistantTour:Step:2-app-alfa': {
    eng: '“Writing texts”',
    ru: '«Написание текстов»',
  },
  'AssistantTour:Step:3': {
    eng: 'Select the text type and enter the topic on which you want to write a text. These parameters will be used in a request to an AI model to generate a response. Then click “Create”.',
    ru: 'Выберите вид текста и введите тему, на которую нужно написать текст. Эти параметры будут использоваться в запросе к нейросети для генерации ответа. Затем нажмите «Создать».',
  },
  'AssistantTour:Step:4': {
    eng: 'Now you’re in the chat with the application. AI is now preparing a response to your request. Let’s wait for the result.',
    ru: 'Вы перешли в чат с приложением. Сейчас нейросеть готовит ответ на ваш запрос. Давайте дождемся результата.',
  },
  'AssistantTour:Step:5': {
    eng: 'You can chat with the application to ask for changes to the result. For example, ask the application to expand the text.',
    ru: 'Вы можете общаться с приложением в чате, чтобы попросить изменить результат. Например, напишите приложению дополнить текст.',
  },
  'AssistantTour:Step:6': {
    eng: 'Some application settings can be updated during the conversation. Please try changing the text type or topic, and then click “Update”.',
    ru: 'Настройки некоторых приложений можно обновлять в ходе диалога. Попробуйте изменить вид текста или тему, а затем нажмите «Обновить».',
  },
  'AssistantTour:Step:7': {
    eng: 'Now let’s work with other applications. Click “Applications”.',
    ru: 'Теперь давайте поработаем с другими приложениями. Нажмите «Приложения».',
  },
  'AssistantTour_Jay:Step:7': {
    eng: 'Now let’s work with another application. Click “Applications”.',
    ru: 'Теперь давайте поработаем с другим приложением. Нажмите «Приложения».',
  },
  'AssistantTour:Step:8': {
    eng: 'Launch “Design and illustration”.',
    ru: 'Запустите приложение «Дизайн и иллюстрации».',
  },
  'AssistantTour_Jay:Step:8': {
    eng: 'Launch “Illustrator”.',
    ru: 'Запустите приложение «Иллюстратор».',
  },
  'AssistantTour:Step:9': {
    eng: 'Choose a style and write what should be on the picture. Then click “Create”.',
    ru: 'Выберите стиль и напишите, что должно быть на картинке. Затем нажмите «Создать».',
  },
  'AssistantTour:Step:10': {
    eng: 'You can ask in the chat to make changes to the result. For example, if you want to add details or make the picture black and white.',
    ru: 'Вы можете попросить в чате внести изменения в получившийся результат. Например, если хотите добавить деталей или сделать картинку черно-белой.',
  },
  'AssistantTour:Step:11': {
    eng: 'On the left, you can select the desired chat if you want to return to one of the previous applications.',
    ru: 'Слева вы можете выбрать нужный чат, если хотите вернуться к одному из предыдущих приложений.',
  },
  'AssistantTour:Step:12': {
    eng: 'The final step is to learn how to work directly with AI. For example, to test the idea of your application.',
    ru: 'Последний шаг — узнаем, как работать с нейросетью напрямую. Например, чтобы проверить идею вашего приложения.',
  },
  'AssistantTour_Jay:Step:12': {
    eng: 'You can also access Assistant Jay directly. It will help you figure out which application is best to use. Or just talk to it about any topic you want.<br/><br/>That’s all! Thanks for your attention and good luck in working with AI!',
    ru: 'Вы также можете обратиться напрямую к умному Ассистенту Jay. Он поможет вам разобраться, какое приложение лучше использовать, и вообще — он отличный собеседник.<br/><br/>На этом все! Спасибо за внимание и успехов в работе с нейросетями!',
  },
  'AssistantTour:Step:13': {
    eng: 'You can communicate with AI directly in this application. For example, send your ideas for new applications to evaluate them.<br/>It’s easy. Especially if you take a short course on working with AI, which is available to all participants of the Case Championship.<br/><br/>That’s all! Thank you for your attention and good luck working with AI!',
    ru: 'В этом приложении вы можете общаться с нейросетью напрямую. Например, давать ей свои задания, чтобы проверить идеи новых приложений.<br/>Это совсем несложно. Особенно если пройдете краткий курс по работе с нейросетями, который доступен всем участникам Кейс-Чемпионата.<br/><br/>На этом все! Спасибо за внимание и успехов в работе с нейросетями!',
  },
  'AssistantTour:SkipForeverModal:Text': {
    eng: 'Do you want to take the tutorial another time?',
    ru: 'Вы хотите пройти обучение в другой раз?',
  },
  'AssistantTour:SkipForeverModal:Title': {
    eng: 'Postpone onboarding',
    ru: 'Отложить обучение',
  },
  'AssistantTour:SkipForeverModal:SubmitText': {
    eng: 'Yes',
    ru: 'Да',
  },
  'AssistantTour:SkipForeverModal:CancelText': {
    eng: 'No, don’t offer again',
    ru: 'Нет, больше не предлагать',
  },
};
