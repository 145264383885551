import React, { useEffect, useState, useMemo } from 'react';

import { ExpenseField } from '@just-ai/api/dist/generated/CopilotGateway';
import { Spinner, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import { Bar } from 'react-chartjs-2';
import useApiService from 'services/useApiService';

import { useAppContext } from '../../../../contexts/appContext';
import { createChartLabel, getDaysArray } from '../utils';
import styles from './styles.module.scss';

export const BarChart = ({ dates }: { dates: Range }) => {
  const [expensesData, setExpensesData] = useState<{ date: Date; dateString: string; value: number }[]>();
  const { getExpensesHistory } = useApiService();
  const { state } = useAppContext();

  const { t, getLocale } = useTranslation();

  const [{ loading }, getChartData] = usePromiseProcessing(
    async () => {
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.Date],
        sort: ['date', 'asc'],
        locale: state.locale,
        size: 100,
      });
      const daysWithExpensesArray = getDaysArray(dates.startDate, dates.endDate).map(day => ({
        date: day,
        dateString: new Date(day).toLocaleString(state.locale, {
          month: 'long',
          day: 'numeric',
        }),
        value: 0,
      }));

      data.expenses.forEach(item => {
        const itemDate = item.date;
        const dayWithExpencesIndex =
          itemDate &&
          daysWithExpensesArray.findIndex(
            day => day.date.setHours(0, 0, 0, 0) === new Date(itemDate).setHours(0, 0, 0, 0)
          );
        if (dayWithExpencesIndex) {
          daysWithExpensesArray[dayWithExpencesIndex] = {
            ...daysWithExpensesArray[dayWithExpencesIndex],
            value: item.tokenAmount,
          };
        }
      });
      setExpensesData(daysWithExpensesArray);
    },
    {
      deps: [getExpensesHistory, dates],
    }
  );

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  const chartData = useMemo(() => {
    return {
      labels: expensesData?.map(item => item.dateString),
      datasets: [
        {
          data: expensesData?.map(item => item.value),
          fill: true,
          backgroundColor: '#7FDB83',
          borderColor: '#7FDB83',
          hoverBackgroundColor: '#7FDB83',
        },
      ],
    };
  }, [expensesData]);

  if (loading) {
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );
  }

  if (!chartData.labels?.length) {
    return <div className='flex items-center justify-center p-5'>{t('Analytics:Expenses:Empty')}</div>;
  }

  return (
    <div className={styles.BarChart}>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          tooltips: {
            displayColors: false,
            backgroundColor: '#6A7178',
            callbacks: {
              label: createChartLabel,
            },
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                  drawBorder: false,
                },
                ticks: {
                  padding: 16,
                  beginAtZero: true,
                  callback: value => value.toLocaleString(getLocale(), { maximumFractionDigits: 3 }),
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                  zeroLineColor: 'transparent',
                },
              },
            ],
          },
        }}
        legend={{ display: false }}
      />
    </div>
  );
};
