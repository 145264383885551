import React, { FC } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';
import { useAppContext } from '../../contexts/appContext';

type Props = {
  withLicense?: boolean;
};

export const ContentContainer: FC<Props> = ({ children, withLicense }) => {
  const {
    state: { lightMode },
  } = useAppContext();

  const { t } = useTranslation();

  return (
    <div className={cn(styles.contentContainer)}>
      <div className={cn('flex flex-1', styles.contentContainer_main)}>
        <div className={cn(`relative flex-1`, lightMode, styles.contentContainer_main_wrapper)}>{children}</div>
      </div>
      {withLicense && appOptions.value?.product?.tos && (
        <div className={styles.contentContainer__footer}>
          <a
            data-test-id='Account:ToSLink'
            href={appOptions.value?.product?.tos}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('license')}
          </a>
        </div>
      )}
    </div>
  );
};
export const ContentContainerWithRightBar: FC<{ isCanvasApp: boolean; fullWidth: boolean }> = ({
  children,
  isCanvasApp,
  fullWidth,
}) => {
  return (
    <div
      className={cn(styles.contentContainer_withRightbar, {
        [styles.contentContainer_withRightbar_fullWidth]: fullWidth,
        [styles.contentContainer_withRightbar_fullHeight]: isCanvasApp,
        [styles.contentContainer_withRightbar_isCanvasApp]: isCanvasApp,
      })}
    >
      {children}
    </div>
  );
};

export const ContentContainerInner: FC<{ fullWidth: boolean; isCanvasApp: boolean }> = ({
  children,
  fullWidth,
  isCanvasApp,
}) => {
  const {
    state: { lightMode },
  } = useAppContext();
  return (
    <div
      className={cn('flex flex-1', styles.contentContainer_withRightbar_main, {
        [styles.contentContainer_withRightbar_fullWidth]: fullWidth,
        [styles.contentContainer_withRightbar_fullHeight]: isCanvasApp,
        [styles.contentContainer_withRightbar_isCanvasApp]: isCanvasApp,
      })}
    >
      <div className={cn(`relative flex-1 overflow-hidden ${lightMode}`)}>{children}</div>
    </div>
  );
};
