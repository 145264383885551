import deepmerge from 'deepmerge';
import is from 'is-lite';

import DEFAULTS from '../config/defaults';
import getStyles from '../styles';
import { StepProps, JoyrideProps } from '../types';
import { getElement, hasCustomScrollParent } from './dom';
import { log } from './helpers';

function getTourProps(props: any): any {
  const sharedTourProps = [
    'beaconComponent',
    'disableCloseOnEsc',
    'disableOverlay',
    'disableOverlayClose',
    'disableScrolling',
    'disableScrollParentFix',
    'floaterProps',
    'hideBackButton',
    'hideCloseButton',
    'locale',
    'showProgress',
    'showSkipButton',
    'spotlightClicks',
    'spotlightPadding',
    'styles',
    'tooltipComponent',
  ];

  return Object.keys(props)
    .filter(d => sharedTourProps.includes(d))
    .reduce((acc, i) => {
      acc[i] = props[i]; //eslint-disable-line react/destructuring-assignment

      return acc;
    }, {} as any);
}

export function getMergedStep(step: StepProps, props: JoyrideProps): StepProps | null {
  if (!step) return null;

  const mergedStep = deepmerge.all([getTourProps(props), DEFAULTS.step, step], {
    isMergeableObject: is.plainObject,
  });
  const mergedStyles = getStyles(deepmerge(props.styles || {}, step.styles || {}));
  // @ts-ignore
  const scrollParent = hasCustomScrollParent(getElement(step.target), mergedStep.disableScrollParentFix);
  // @ts-ignore
  const floaterProps = deepmerge.all([props.floaterProps || {}, DEFAULTS.floaterProps, mergedStep.floaterProps || {}]);

  // @ts-ignore
  floaterProps.offset = mergedStep.offset;
  // @ts-ignore
  floaterProps.styles = deepmerge(floaterProps.styles || {}, mergedStyles.floaterStyles || {});

  delete mergedStyles.floaterStyles;

  // @ts-ignore
  floaterProps.offset += props.spotlightPadding || step.spotlightPadding || 0;

  if (step.placementBeacon) {
    // @ts-ignore
    floaterProps.wrapperOptions.placement = step.placementBeacon;
  }

  if (scrollParent) {
    // @ts-ignore
    floaterProps.options.preventOverflow.boundariesElement = 'window';
  }

  // @ts-ignore
  return {
    ...mergedStep,
    // @ts-ignore
    locale: deepmerge.all([DEFAULTS.locale, props.locale || {}, mergedStep.locale || {}]),
    floaterProps,
    styles: mergedStyles,
  };
}

/**
 * Validate if a step is valid
 *
 * @param {Object} step - A step object
 * @param {boolean} debug
 *
 * @returns {boolean} - True if the step is valid, false otherwise
 */
export function validateStep(step: StepProps, debug: boolean = false): boolean {
  if (!is.plainObject(step)) {
    log({
      title: 'validateStep',
      data: 'step must be an object',
      warn: true,
      debug,
    });
    return false;
  }

  if (!step.target) {
    log({
      title: 'validateStep',
      data: 'target is missing from the step',
      warn: true,
      debug,
    });
    return false;
  }

  return true;
}

/**
 * Validate if steps is valid
 *
 * @param {Array} steps - A steps array
 * @param {boolean} debug
 *
 * @returns {boolean} - True if the steps are valid, false otherwise
 */
export function validateSteps(steps: Array<Object>, debug: boolean = false): boolean {
  if (!is.array(steps)) {
    log({
      title: 'validateSteps',
      data: 'steps must be an array',
      warn: true,
      debug,
    });

    return false;
  }

  // @ts-ignore
  return steps.every(d => validateStep(d, debug));
}
