import {
  FileResponse,
  LinkResponse,
  ResourceSearchResponse,
  ResourceType,
} from '@just-ai/api/dist/generated/AppsAdapter';
import { computed, signal } from '@preact/signals-react';

import { reduxConnectSignal } from '../../utils/reduxConnector';
import { createImmerSignal } from '../hooks';

export const [filesAndLinks, setResources] = createImmerSignal<{
  totals: ResourceSearchResponse['totals'];
  resources: Record<ResourceType, ResourceSearchResponse['resources']>;
}>({
  totals: {
    appFiles: 0,
    userFiles: 0,
    links: 0,
  },
  resources: {
    [ResourceType.AppFiles]: [] as FileResponse[],
    [ResourceType.UserFiles]: [] as FileResponse[],
    [ResourceType.Links]: [] as LinkResponse[],
  },
});
export const resourcesLoaded = signal(false);
export const resourcesLoading = signal(false);
reduxConnectSignal(
  'Resources',
  computed(() => ({
    files: filesAndLinks.value,
    filesLoaded: resourcesLoaded.value,
    filesLoading: resourcesLoading.value,
  }))
);

export const setResourcesForTab = (tab: ResourceType, response: ResourceSearchResponse) => {
  setResources(prevResources => {
    prevResources.resources[tab] = response.resources;
    prevResources.totals[tab] = response.totals[tab];
  });
};

export const updateTotals = (totals: ResourceSearchResponse['totals']) => {
  setResources(prevResources => {
    prevResources.totals = totals;
  });
};
