import React from 'react';

import { Conversation } from '../../../types/chat';
import ConversationComponent from './Conversation';

interface Props {
  conversations: Conversation[];
  toggleSidebar?: () => unknown;
}

export const Conversations = ({ conversations, toggleSidebar }: Props) => {
  const restChats = Array.isArray(conversations)
    ? conversations.filter(conv => conv?.app?.template !== 'toolAssistant')
    : [];

  return (
    <div className='flex w-full flex-col gap-1 flex-grow-1' data-test-id='Conversations:Container'>
      {restChats.map((conversation, index) => (
        <ConversationComponent
          key={conversation.id}
          conversationId={conversation.id}
          conversationHasUnreadChanges={conversation.hasUnreadChanges}
          conversationMessageIsStreaming={conversation.messageIsStreaming}
          conversationStatus={conversation.status}
          conversationName={conversation.name}
          conversationTemplate={conversation.app.template}
          conversationIcon={conversation?.config?.info?.icon}
          toggleSidebar={toggleSidebar}
          index={index}
        />
      ))}
    </div>
  );
};
