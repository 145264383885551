import React, { useCallback, useState } from 'react';

import { DropDownHint, FileUpload, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../types';
import styles from './style.module.scss';

const FileField = ({
  name,
  label,
  hint,
  isEdit,
  accept,
  acceptText,
  handleFileUpload,
  control,
  required,
  maxFileSize,
  dropDownHint,
  disabled,
}: FormBuilderField) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    rules: { required: required && t('This field is required') },
  });
  const methods = useFormContext();

  const [fileName, setFileName] = useState<string | undefined>();

  const localFileUpload = useCallback(
    async (files: FileList) => {
      if (!files) return;
      const file = files[0];
      if (maxFileSize && file.size / 1024 / 1024 > maxFileSize) {
        methods.setError(name, { type: 'other', message: t('fileSizeError') });
        return;
      }
      const resp = await handleFileUpload(file);
      if (!resp) return;
      methods.clearErrors(name);
      field.onChange(resp.url);
      setFileName(resp.fileName);
    },
    [maxFileSize, handleFileUpload, field, methods, name, t]
  );

  if (isEdit) return null;

  return (
    <div data-test-id={`TemplateForm.File.${name}-wrapper`}>
      <label>{label}</label>
      <FileUpload
        disabled={disabled}
        accept={accept}
        onChange={localFileUpload}
        data-test-id={`TemplateForm.File.${name}`}
      >
        {field.value ? (
          <p className={cn('text-ellipsis', styles.FileField__fileName)}>{fileName || field.value}</p>
        ) : (
          <>
            <p>{t('fileUpload')}</p>
            {acceptText && (
              <small className='text-muted'>
                {t('fileAccepted')}
                {acceptText}
              </small>
            )}
            {maxFileSize && <small className='text-muted'>{t('fileSize', { size: maxFileSize })}</small>}
          </>
        )}
      </FileUpload>
      {fieldState.invalid ? (
        <small className='font-color-danger font-size-12'>{fieldState.error?.message}</small>
      ) : (
        hint && <small className='text-muted' dangerouslySetInnerHTML={{ __html: hint }} />
      )}
      {dropDownHint ? <DropDownHint dropDownHint={dropDownHint} /> : null}
    </div>
  );
};

FileField.displayName = 'FileField';

export default React.memo(FileField);
