import deepmerge from 'deepmerge';

import { hexToRGB } from './modules/helpers';

const defaultOptions = {
  arrowColor: '#fff',
  backgroundColor: '#fff',
  beaconSize: 36,
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: '#f04',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: '#333',
  zIndex: 100,
};

const buttonBase = {
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  color: '#555',
  cursor: 'pointer',
  fontSize: 16,
  lineHeight: 1,
  padding: 8,
  WebkitAppearance: 'none',
};

const spotlight = {
  borderRadius: 4,
  position: 'absolute',
};

export default function getStyles(stepStyles = {}) {
  const options = deepmerge(defaultOptions, stepStyles.options || {});
  let width = 290;

  if (window.innerWidth > 480) {
    width = 380;
  }

  if (options.width) {
    if (window.innerWidth < options.width) {
      width = window.innerWidth - 30;
    } else {
      width = options.width; //eslint-disable-line prefer-destructuring
    }
  }

  const overlay = {
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: options.zIndex,
  };

  const defaultStyles = {
    beacon: {
      ...buttonBase,
      display: 'inline-block',
      height: options.beaconSize,
      position: 'relative',
      width: options.beaconSize,
      zIndex: options.zIndex,
    },
    beaconInner: {
      animation: 'joyride-beacon-inner 1.2s infinite ease-in-out',
      backgroundColor: options.primaryColor,
      borderRadius: '50%',
      display: 'block',
      height: '50%',
      left: '50%',
      opacity: 0.7,
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
    beaconOuter: {
      animation: 'joyride-beacon-outer 1.2s infinite ease-in-out',
      backgroundColor: `rgba(${hexToRGB(options.primaryColor).join(',')}, 0.2)`,
      border: `2px solid ${options.primaryColor}`,
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'block',
      height: '100%',
      left: 0,
      opacity: 0.9,
      position: 'absolute',
      top: 0,
      transformOrigin: 'center',
      width: '100%',
    },
    tooltip: {
      backgroundColor: options.backgroundColor,
      borderRadius: 12,
      boxSizing: 'border-box',
      color: options.textColor,
      fontSize: 16,
      maxWidth: '100%',
      padding: '16px 0 16px 0',
      position: 'relative',
      filter:
        'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6px 12px rgba(12, 43, 66, 0.16)) drop-shadow(0px 0px 1px rgba(17, 60, 92, 0.06))',
      width,
    },
    tooltipContainer: {
      lineHeight: 1.4,
    },
    tooltipTitle: {
      fontSize: 18,
      margin: 0,
    },
    tooltipContent: {
      color: 'var(--gray-800)',
      fontSize: 14,
      lineHeight: '20px',
    },
    tooltipFooter: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 16px 0 16px',
      marginTop: 15,
    },
    tooltipArrow: {},
    tooltipFooterSpacer: {
      flex: 1,
    },
    buttonNext: {},
    buttonBack: {
      ...buttonBase,
      color: options.primaryColor,
      marginLeft: 'auto',
      marginRight: 5,
    },
    buttonClose: {
      ...buttonBase,
      color: 'var(--gray-800)',
      height: 24,
      width: 24,
      padding: 0,
      position: 'absolute',
      right: 4,
      top: 4,
    },
    buttonSkip: {
      fontSize: 14,
    },
    overlay: {
      ...overlay,
      backgroundColor: options.overlayColor,
      mixBlendMode: 'hard-light',
    },
    overlayLegacy: {
      ...overlay,
    },
    overlayLegacyCenter: {
      ...overlay,
      backgroundColor: options.overlayColor,
    },
    spotlight: {
      ...spotlight,
      backgroundColor: 'gray',
    },
    spotlightLegacy: {
      ...spotlight,
      boxShadow: `0 0 0 9999px ${options.overlayColor}, ${options.spotlightShadow}`,
    },
    floaterStyles: {
      arrow: {
        color: options.arrowColor,
      },
      options: {
        zIndex: options.zIndex + 100,
      },
    },
    options,
  };

  return deepmerge(defaultStyles, stepStyles);
}
