import { useState } from 'react';

import { useTranslation } from '@just-ai/just-ui';

type Object = Record<string, any>;

type Options = {
  maxCount?: number;
};

export type Selected = {
  selected: string[];
  isIncludes: (obj: Object) => boolean;
  selectedHandler: (obj: Object) => void;
  clearSelected: () => void;
  allHandler: () => void;
  isCleared: boolean;
  isAllSelected: boolean;
  setSelected: (newIds: string[]) => void;
};

const useSelected = (idKey = 'id', data: Object[], options?: Options) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);

  function getId(obj: Object) {
    return obj[idKey].toString();
  }

  function isIncludes(obj: Object) {
    return selected.includes(getId(obj));
  }

  function selectedHandler(obj: Object) {
    const isInc = isIncludes(obj);
    if (!isInc && options && selected?.length === options?.maxCount) {
      alert(`${t('selectCountError')} ${options?.maxCount}`);
      return;
    }

    setSelected(isInc ? selected.filter(el => getId(obj) !== el) : [...selected, getId(obj)]);
  }

  let isCleared = !selected?.length;
  let isAllSelected = selected?.length === data.length && data.length > 0;

  function clearSelected() {
    setSelected([]);
  }
  function allHandler() {
    if (!isAllSelected) {
      const newArr: string[] = [];
      for (let item of data) {
        newArr.push(getId(item) as string);
      }
      setSelected(newArr);
      return;
    }
    if (isCleared) {
      const newArr: string[] = [];
      for (let item of data) {
        newArr.push(getId(item) as string);
      }
      setSelected(newArr);
    } else {
      clearSelected();
    }
  }

  return { selected, isIncludes, selectedHandler, clearSelected, allHandler, isCleared, isAllSelected, setSelected };
};

export default useSelected;
