import React, { FC, useCallback, useMemo, useRef } from 'react';

import { Icon } from '@just-ai/just-ui';
import { v4 as uuidv4 } from 'uuid';

type SAFileUploaderProps = {
  onChange: (files?: FileList | null) => unknown;
  isUploading: boolean;
  cancelUpload: () => unknown;
  accept?: string;
  disabled?: boolean;
};

export const SAFileUploader: FC<SAFileUploaderProps> = ({ onChange, isUploading, cancelUpload, accept, disabled }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const uuid = useMemo(() => uuidv4().replaceAll('-', '_'), []);

  const handleFileUpload = useCallback(() => {
    onChange(inputFileRef.current?.files);
  }, [onChange]);

  if (isUploading)
    return (
      <label
        style={{ zIndex: 1 }}
        className='bg-white absolute top-4 right-4 mb-0 padding-7 cursor-pointer color-gray-400 hover-color-gray-600 bg-hover-color-gray-100 border-radius-4'
        onClick={cancelUpload}
      >
        <Icon name='faStop' />
      </label>
    );

  return (
    <>
      <label
        htmlFor={`id_${uuid}`}
        className='bg-white absolute top-4 right-4 mb-0 padding-7 cursor-pointer color-gray-400 hover-color-gray-600 bg-hover-color-gray-100 border-radius-4'
        onClick={e => e.stopPropagation()}
      >
        <Icon name='farUpload' />
      </label>
      <input
        id={`id_${uuid}`}
        type='file'
        disabled={disabled}
        accept={accept}
        ref={inputFileRef}
        className='absolute'
        style={{ left: -10000, top: -10000 }}
        onChange={handleFileUpload}
      />
    </>
  );
};
