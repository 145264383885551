import React, { FC, useEffect, useRef, useState } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { ContentContainer } from 'pages/FullContainer/ContentContainer';
import { v4 as uuidv4 } from 'uuid';

import { botName } from '../../botName';
import { dropCurrentUser } from '../../models/currentUser';
import styles from '../Chat/style.module.scss';

export const Login: FC = () => {
  const { t } = useTranslation();
  const [tokenFromLS, setTokenFromLS] = useState<string>('');
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    dropCurrentUser();
    let token = localStorage.getItem('token');
    if (token) {
      setTokenFromLS(token);
    } else {
      token = `web${uuidv4()}`;
      localStorage.setItem('token', token);
      setTokenFromLS(token);
    }
  }, []);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <ContentContainer>
      <div className={cn('mx-auto flex h-full flex-col justify-center items-center', styles.chat__loginWrapper)}>
        <h1>{t('welcome', { productName: t('defaultProductName'), brand: t('defaultBrand') })}</h1>
        <p className='mt-4'>{t('welcomeInfo')}</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          className={cn('mt-6', styles.chat__loginBtn)}
          href={`https://t.me/${botName}?start=token${tokenFromLS}`}
          target='_blank'
          rel='noreferrer'
        />
      </div>
    </ContentContainer>
  );
};
