import React from 'react';

import { DropDownHint, SwitchButton } from '@just-ai/just-ui';
import { useController } from 'react-hook-form';

import { FormBuilderField } from '../../types';

const Switch = ({ control, name, required, label, hint, dropDownHint, disabled }: FormBuilderField) => {
  const { field } = useController({ name, control, rules: { required } });

  return (
    <div className='flex flex-col pl-1' data-test-id={`TemplateForm.Switch.${name}-wrapper`}>
      <SwitchButton
        color='primary'
        id={`switch-toggle_${name}`}
        label={label}
        name={name}
        value={!!field.value}
        data-test-id={`TemplateForm.Switch.${name}`}
        onChange={field.onChange}
        disabled={disabled}
      />
      {hint ? <span className='hint' dangerouslySetInnerHTML={{ __html: hint }} /> : null}
      {dropDownHint ? <DropDownHint dropDownHint={dropDownHint} /> : null}
    </div>
  );
};

Switch.displayName = 'Switch';

export default React.memo(Switch);
