import React, { memo, useEffect, useState } from 'react';

import { ApiKeyIdentifier } from '@just-ai/api/dist/generated/CopilotGateway';
import { Button, Icon, Spinner, usePromiseProcessing, useTranslation, Badge } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { useLocation } from 'react-router';

import { JGuardKeyStatus } from '..';
import { appOptions } from '../../../api/cc.api';
import { useAppContext } from '../../../contexts/appContext';
import {
  jGuardFailedToProtect,
  jGuardModalShown,
  setJGuardFailureToProtect,
  setJGuardModalShown,
} from '../../../models/conversations/signals';
import { currentUser, hasPermission, hasFeature, updateCurrentUserLimit } from '../../../models/currentUser';
import useApiService from '../../../services/useApiService';
import AccountBalance from '../AccountBalance';
import AccountInfo from '../AccountInfo';
import AccountLimit from '../AccountLimit';
import ApiKeyModal from '../GenerateApiKeyModal';
import JGuardKeyModal from '../JGuardKeyModal';
import PromocodesBlock from '../Promocodes';
import styles from '../style.module.scss';

export const MainTab = memo(() => {
  const { t } = useTranslation();

  const location = useLocation<{ toJGuard?: boolean }>();

  const {
    dispatch,
    state: { balance },
  } = useAppContext();
  const { getBalance, getUserApiKeys, validateCurrentJGuardKey, getCurrentJGuardKey, getCurrentUserLimits } =
    useApiService();

  const [userApikey, setUserApiKey] = useState<ApiKeyIdentifier[] | undefined>();

  const [apiKeyModalShown, setApiKeyModalShown] = useState(false);

  const [jGuardStatus, setJGuardStatus] = useState<JGuardKeyStatus>({ keyStatus: 'off', emailSent: false });

  const [{ loading }, getAccountData] = usePromiseProcessing(
    async () => {
      const balanceRes =
        hasPermission('COPILOT_VIEW_BALANCES') && appOptions.value?.billingEnabled
          ? await getBalance()
          : { balances: [] };
      try {
        const limitsRes = await getCurrentUserLimits();
        updateCurrentUserLimit(limitsRes?.data?.limit);
      } catch (error) {
        AppLogger.error({ message: 'Failed to fetch user limits', exception: error as Error });
      }
      if (
        currentUser.value?.userId &&
        hasFeature('copilot_api_key_admin') &&
        hasPermission('COPILOT_MANAGE_API_KEYS')
      ) {
        try {
          const { data: apiKeysRes } = await getUserApiKeys(currentUser.value.userId);

          setUserApiKey(apiKeysRes.values?.filter(key => key.status === 'ACTIVE'));
        } catch (error) {}
      }
      const { data: isKeyValid } = await validateCurrentJGuardKey();
      setJGuardFailureToProtect(!isKeyValid);
      if (hasFeature('dataguard_access') && hasPermission('COPILOT_VIEW_BALANCES')) {
        try {
          const { data: jGuardStatusRes } = await getCurrentJGuardKey();
          setJGuardStatus({
            keyStatus: jGuardStatusRes.enabled ? 'on' : 'off',
            emailSent: jGuardStatusRes.useEmailForAudit || false,
          });
          if (jGuardFailedToProtect.value) {
            setJGuardStatus({ keyStatus: 'error', emailSent: false });
          }
          if (location.state?.toJGuard) {
            setJGuardModalShown(true);
          }
        } catch (error) {
          if ((error as AxiosError).code) {
            AppLogger.error({
              message: `Error getting jguard data`,
              exception: error as AxiosError,
            });
          }
          console.error('error getting jguard data', error);
          setJGuardStatus({ keyStatus: 'error', emailSent: false });
        }
      }
      const summaryBalance = balanceRes.balances
        ?.filter(balance => balance.tokenType !== 'onboarding')
        .reduce((acc, curr) => {
          return acc + (curr.tokenAmount || 0);
        }, 0);
      dispatch({ field: 'balance', value: summaryBalance || 0 });
    },
    { deps: [getBalance, getCurrentUserLimits, getCurrentJGuardKey] }
  );

  useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  const updateUserKeys = (newKey: string) => {
    setUserApiKey(prevKeys =>
      prevKeys ? [...prevKeys, { idPrefix: newKey, label: newKey }] : [{ idPrefix: newKey, label: newKey }]
    );
  };

  if (loading)
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );

  return (
    <div className='flex w-full flex-column items-start gap-8'>
      {hasPermission('COPILOT_VIEW_BALANCES') && appOptions.value?.billingEnabled ? (
        <AccountBalance balance={balance} />
      ) : currentUser.value?.accountLimit?.tokenLimit ? (
        <AccountLimit />
      ) : null}
      <AccountInfo />
      {!currentUser.value?.organization && <PromocodesBlock />}
      {hasFeature('dataguard_access') && hasPermission('COPILOT_VIEW_BALANCES') && (
        <div className={cn(styles.accountPage__block, styles.accountPage__block__jguard, 'flex flex-column gap-16')}>
          <div className='flex items-center justify-between'>
            <p className={cn(styles.accountPage__block__header, 'mb-0')}>{t('jayGuard')}</p>
            <div className='flex items-center gap-8'>
              <Badge
                pastel
                color={jGuardStatus.keyStatus === 'error' ? 'red' : jGuardStatus.keyStatus === 'off' ? 'grey' : 'green'}
                text={t(`jayGuard:status:${jGuardStatus.keyStatus}`)}
                data-test-id='Account.jGuardStatus'
              />
              <Icon
                name='farShieldAlt'
                color={
                  jGuardStatus.keyStatus === 'error'
                    ? 'danger'
                    : jGuardStatus.keyStatus === 'off'
                      ? 'secondary'
                      : 'success'
                }
              />
            </div>
          </div>
          <Button
            data-test-id='Account.jGuardSettings'
            color='secondary'
            outline
            onClick={() => setJGuardModalShown(true)}
          >
            {t('settings')}
          </Button>
          {jGuardModalShown.value && (
            <JGuardKeyModal
              keyStatusProp={jGuardStatus}
              setKeyStatus={setJGuardStatus}
              closeModal={() => setJGuardModalShown(false)}
            />
          )}
        </div>
      )}

      {hasFeature('copilot_api_key_admin') && hasPermission('COPILOT_MANAGE_API_KEYS') && (
        <div
          className={cn(styles.accountPage__block, styles.accountPage__block__keys)}
          data-test-id='Account.KeysBlock'
        >
          <p className='text-secondary'>
            {t('apiKeys')}
            {!!userApikey?.length && (
              <span className={styles.accountPage__badge} data-test-id='Account.KeysNum'>
                {userApikey?.length}
              </span>
            )}
          </p>
          <Button color='primary' flat onClick={() => setApiKeyModalShown(true)} data-test-id='Account.GenerateKey'>
            {t('generate')}
          </Button>
          {apiKeyModalShown && (
            <ApiKeyModal setUserApiKey={updateUserKeys} closeModal={() => setApiKeyModalShown(false)} />
          )}
        </div>
      )}
    </div>
  );
});
