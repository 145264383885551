import React, { memo, useCallback } from 'react';

import { Spinner, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { isTovie } from '../../../isTovie';
import { getUserChatHistory } from '../../../models/conversations/apiHooks';
import { conversationLink, goToConversation } from '../../../routes';
import { Conversation } from '../../../types/chat';
import { isMobile } from '../../../utils/app/common';
import { hideAgentSettingsMobileToggle } from '../../Settings/AgentSettingsSidebar';
import { ConversationDelete } from './ConversationDelete';
import styles from './style.module.scss';

interface Props {
  conversationId: Conversation['id'];
  conversationTemplate: Conversation['app']['template'];
  conversationMessageIsStreaming: Conversation['messageIsStreaming'];
  conversationHasUnreadChanges: Conversation['hasUnreadChanges'];
  conversationName: Conversation['name'];
  conversationStatus: Conversation['status'];
  conversationIcon?: Conversation['config']['info']['icon'];
  toggleSidebar?: () => unknown;
  index?: number;
}

export const ConversationComponent = memo(
  ({
    conversationId,
    conversationName,
    conversationIcon,
    conversationHasUnreadChanges,
    conversationMessageIsStreaming,
    conversationStatus,
    conversationTemplate,
    toggleSidebar,
    index,
  }: Props) => {
    const { t } = useTranslation();

    const goToConversationHandle = useCallback(async () => {
      goToConversation(conversationId);
      if (conversationHasUnreadChanges) {
        await getUserChatHistory(conversationId, conversationTemplate);
      }
      if (isMobile()) {
        hideAgentSettingsMobileToggle();
        toggleSidebar?.();
      }
    }, [conversationHasUnreadChanges, conversationId, conversationTemplate, toggleSidebar]);

    if (conversationTemplate === 'toolAssistant') {
      if (isTovie) return null;
      return (
        <NavLink
          component={({ children, navigate, ...props }) => <div {...props}>{children}</div>}
          to={conversationLink(conversationId)}
          activeClassName={styles['selected--main']}
          data-test-id='JayAssistant'
          className={cn(
            styles.navLink,
            styles['navLink--main'],
            'relative flex items-center text-decoration-none w-100 overflow-hidden'
          )}
        >
          <div
            className='flex cursor-pointer flex-grow-1 font-size-16 justify-between rounded-lg p-3 transition-colors align-items-center overflow-hidden'
            onClick={goToConversationHandle}
          >
            {t('assistantJay')}
            {conversationStatus === 'BUILDING' || conversationMessageIsStreaming ? (
              <Spinner size={isMobile() ? 'lg' : 'md'} inline />
            ) : null}
            {conversationHasUnreadChanges ? <div className={styles.tip} /> : null}
          </div>
        </NavLink>
      );
    }

    return (
      <NavLink
        component={({ children, navigate, ...props }) => <div {...props}>{children}</div>}
        to={conversationLink(conversationId)}
        activeClassName={styles.selected}
        data-test-id={`Conversation.${index || 0}`}
        className={cn(styles.navLink, 'relative text-decoration-none w-100 overflow-hidden cursor-pointer')}
      >
        <div
          className={cn(
            'flex cursor-pointer flex-grow-1 justify-start rounded-lg p-3 text-sm transition-colors overflow-hidden'
          )}
          onClick={goToConversationHandle}
          role='button'
        >
          <div className={cn(`flex align-items-center text-left  w-100`, styles.chatName)}>
            <div className='margin-right-6 font-size-16 relative'>
              {conversationStatus === 'BUILDING' || conversationMessageIsStreaming ? (
                <Spinner size={isMobile() ? 'lg' : 'md'} inline />
              ) : (
                conversationIcon || `💬`
              )}
              {conversationHasUnreadChanges ? <div className={cn(styles.tip, styles.tip_absolute)} /> : null}
            </div>
            <div className='font-size-14 text-ellipsis overflow-hidden'>
              {conversationHasUnreadChanges ? <b>{conversationName}</b> : conversationName}
            </div>
          </div>
        </div>

        <ConversationDelete
          conversationMessageIsStreaming={conversationMessageIsStreaming}
          conversationId={conversationId}
        />
      </NavLink>
    );
  }
);

ConversationComponent.displayName = 'memo(ConversationComponent)';

export default ConversationComponent;
