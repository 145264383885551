import React, { memo, SVGProps } from 'react';

import SVGUniqueID from 'react-svg-unique-id';

const CailaIcon = memo((props: SVGProps<SVGSVGElement>) => {
  return (
    <SVGUniqueID>
      <svg {...props} width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20.7883 5.01226L16.8371 2.74396L12.6661 0.329188C11.9343 -0.109729 10.9832 -0.109729 10.1781 0.329188L6.08045 2.67072L1.91002 5.08549C1.10493 5.52441 0.666016 6.40272 0.666016 7.28056V16.7927C0.666016 17.7437 1.2514 18.5488 2.05648 18.9877L6.0077 21.3293L10.1786 23.6708C10.9105 24.1097 11.8615 24.1097 12.6666 23.6708L16.7643 21.3293L20.9347 18.9145C21.7398 18.4756 22.1787 17.6705 22.1787 16.7927V7.20732C22.1783 6.25626 21.5929 5.45117 20.7883 5.01226ZM18.85 17.9167H17.0982C15.6239 19.3313 13.6268 20.2052 11.4226 20.2052C9.21791 20.2052 7.22086 19.3313 5.74657 17.9167H3.99475C2.7522 17.9167 1.97554 16.5715 2.59706 15.4957L3.46911 13.9852C3.3106 13.3483 3.21713 12.6858 3.21713 11.9998C3.21713 8.25138 5.73356 5.09657 9.16732 4.11563L10.0249 2.63025C10.646 1.5544 12.1993 1.5544 12.8208 2.63025L13.6784 4.11563C17.1122 5.09657 19.6286 8.2509 19.6286 11.9998C19.6286 12.6858 19.5351 13.3483 19.3766 13.9852L20.2487 15.4957C20.8692 16.5715 20.0926 17.9167 18.85 17.9167Z'
          fill='#FE010D'
        />
        <path
          d='M13.6771 4.11649L12.8195 2.63111C12.1984 1.55526 10.6451 1.55526 10.0236 2.63111L9.16602 4.11649C9.88341 3.91173 10.6384 3.79513 11.4213 3.79513C12.2042 3.79513 12.9597 3.91124 13.6771 4.11649Z'
          fill='#F6D0D2'
        />
        <path
          d='M3.46954 13.9883L2.59749 15.4987C1.97646 16.5746 2.75263 17.9197 3.99519 17.9197H5.747C4.64947 16.8665 3.84872 15.5122 3.46954 13.9883Z'
          fill='#F6D0D2'
        />
        <path
          d='M19.3751 13.9883C18.9955 15.5122 18.1952 16.8665 17.0977 17.9197H18.8495C20.092 17.9197 20.8687 16.5746 20.2472 15.4987L19.3751 13.9883Z'
          fill='#F6D0D2'
        />
        <path
          d='M3.2168 12.0008C3.2168 12.6869 3.31026 13.3494 3.46878 13.9863L9.16699 4.11719C5.73322 5.09764 3.2168 8.25245 3.2168 12.0008Z'
          fill='#FD7775'
        />
        <path
          d='M11.4221 20.2065C13.6264 20.2065 15.6234 19.3325 17.0977 17.918H5.74609C7.22039 19.3325 9.21744 20.2065 11.4221 20.2065Z'
          fill='#FD7775'
        />
        <path
          d='M19.6279 11.9974C19.6279 8.24903 17.1115 5.09422 13.6777 4.11328L19.376 13.9824C19.5345 13.346 19.6279 12.6835 19.6279 11.9974Z'
          fill='#FD7775'
        />
        <path
          d='M19.3758 13.9835L13.6775 4.11433C12.9602 3.90956 12.2052 3.79297 11.4223 3.79297C10.6393 3.79297 9.88436 3.90956 9.16696 4.11433L3.46875 13.9835C3.84792 15.5074 4.64867 16.8617 5.74621 17.9149H17.0978C18.1954 16.8617 18.9961 15.5074 19.3758 13.9835Z'
          fill='white'
        />
        <path
          d='M9.80075 11.4843C10.3832 11.4843 10.8554 11.0121 10.8554 10.4297C10.8554 9.84719 10.3832 9.375 9.80075 9.375C9.21828 9.375 8.74609 9.84719 8.74609 10.4297C8.74609 11.0121 9.21828 11.4843 9.80075 11.4843Z'
          fill='#FD7775'
        />
        <path
          d='M13.0449 11.4843C13.6274 11.4843 14.0995 11.0121 14.0995 10.4297C14.0995 9.84719 13.6274 9.375 13.0449 9.375C12.4624 9.375 11.9902 9.84719 11.9902 10.4297C11.9902 11.0121 12.4624 11.4843 13.0449 11.4843Z'
          fill='#FD7775'
        />
        <path
          d='M8.33004 14.457C8.91251 14.457 9.3847 13.9848 9.3847 13.4023C9.3847 12.8198 8.91251 12.3477 8.33004 12.3477C7.74757 12.3477 7.27539 12.8198 7.27539 13.4023C7.27539 13.9848 7.74757 14.457 8.33004 14.457Z'
          fill='#FD7775'
        />
        <path
          d='M11.4218 14.457C12.0043 14.457 12.4765 13.9848 12.4765 13.4023C12.4765 12.8198 12.0043 12.3477 11.4218 12.3477C10.8394 12.3477 10.3672 12.8198 10.3672 13.4023C10.3672 13.9848 10.8394 14.457 11.4218 14.457Z'
          fill='#FD7775'
        />
        <path
          d='M14.5136 14.457C15.0961 14.457 15.5683 13.9848 15.5683 13.4023C15.5683 12.8198 15.0961 12.3477 14.5136 12.3477C13.9312 12.3477 13.459 12.8198 13.459 13.4023C13.459 13.9848 13.9312 14.457 14.5136 14.457Z'
          fill='#FD7775'
        />
      </svg>
    </SVGUniqueID>
  );
});

CailaIcon.displayName = 'CailaIcon';

export default CailaIcon;
