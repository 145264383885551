import React, { useEffect, useRef } from 'react';

import cn from 'classnames';
import { createPortal } from 'react-dom';

import useMountTransition from '../../hooks/useMountTransition';
import styles from './styles.module.scss';

const createPortalRoot = () => {
  const drawerRoot = document.createElement('div');
  drawerRoot.setAttribute('id', 'drawer-root');
  return drawerRoot;
};

type DrawerProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

export const Drawer = ({ isOpen, children, onClose }: DrawerProps) => {
  const portalRootRef = useRef(document.getElementById('drawer-root') || createPortalRoot());
  const bodyRef = useRef(document.querySelector('body'));
  const isTransitioning = useMountTransition(isOpen, 300);

  useEffect(() => {
    if (!bodyRef.current) return;

    bodyRef.current.appendChild(portalRootRef.current);
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current;

    return () => {
      portal.remove();
      bodyEl.style.overflow = '';
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={cn(styles.Drawer, { [styles.open]: isOpen, [styles.in]: isTransitioning })}>
      <div className={cn(styles.Drawer__content, styles.right)} role='dialog'>
        {children}
      </div>
      <div className={styles.Drawer__backdrop} onClick={onClose} />
    </div>,
    portalRootRef.current
  );
};
