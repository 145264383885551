import React, { memo, SVGProps } from 'react';

import SVGUniqueID from 'react-svg-unique-id';

const ShieldGradientIcon = memo((props: SVGProps<SVGSVGElement>) => {
  return (
    <SVGUniqueID>
      <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M22.2012 3.92617L13.2012 0.176175C12.927 0.0624462 12.6331 0.00390625 12.3363 0.00390625C12.0395 0.00390625 11.7456 0.0624462 11.4715 0.176175L2.47148 3.92617C1.63242 4.27305 1.08398 5.09336 1.08398 6.00274C1.08398 15.3074 6.45117 21.7387 11.4668 23.8293C12.0199 24.059 12.6434 24.059 13.1965 23.8293C17.2137 22.1559 23.584 16.3762 23.584 6.00274C23.584 5.09336 23.0355 4.27305 22.2012 3.92617ZM12.3387 20.923L12.334 3.06367L20.5793 6.49961C20.4246 13.5965 16.7309 18.7387 12.3387 20.923Z'
          fill='url(#paint0_linear_11308_4844)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_11308_4844'
            x1='24.2491'
            y1='9.51966'
            x2='1.24782'
            y2='14.3333'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.02' stop-color='#FE6DA4' />
            <stop offset='1' stop-color='#AD8BE3' />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  );
});

ShieldGradientIcon.displayName = 'ShieldGradientIcon';

export default ShieldGradientIcon;
