import { useCallback } from 'react';

import { useCanvasHolder } from './CanvasHolderContext/CanvasHolderContext';
import useApiService from '../../services/useApiService';
import { ToolCallMessagePart, ToolResponseItem } from '../../types/chat';
import { isDev } from '../../utils/app/common';

export const useShowUiToolResponses = (showUiCalls: ToolCallMessagePart['toolCalls'], conversationId: string) => {
  const { createExternalInstance } = useApiService();
  const { setCanvasInstance, setIsCanvasChat } = useCanvasHolder();

  return useCallback(async () => {
    const results = { toolResponses: [], instanceIds: [] } as {
      toolResponses: ToolResponseItem[];
      instanceIds: string[];
    };

    if (!showUiCalls.length) {
      return results;
    }

    setIsCanvasChat(true, conversationId);

    for (const showUiCall of showUiCalls) {
      try {
        const parsedArgs = JSON.parse(showUiCall.function.arguments);
        const instanceId = await createExternalInstance(conversationId, parsedArgs.externalAppId);
        const toolResponse = await new Promise<ToolResponseItem>(resolve => {
          setCanvasInstance(instanceId, parsedArgs.externalAppId, {
            showUiData: {
              onLoadCallback: () =>
                resolve({
                  name: showUiCall.function.name,
                  toolCallId: showUiCall.id!,
                  response: 'ui is successfully shown',
                }),
              fileUrl: parsedArgs.fileUrl
                ? isDev()
                  ? window.location.origin + new URL(parsedArgs.fileUrl).pathname
                  : parsedArgs.fileUrl
                : parsedArgs.fileUrl,
            },
          });
        });
        results.instanceIds.push(instanceId);
        results.toolResponses.push(toolResponse);
      } catch (e) {
        results.toolResponses.push({
          name: showUiCall.function.name,
          toolCallId: showUiCall.id!,
          response: 'ui is not shown',
        });
      }
    }

    return results;
  }, [conversationId, showUiCalls, createExternalInstance, setCanvasInstance, setIsCanvasChat]);
};
