import React, { FC } from 'react';

import { ResourceType } from '@just-ai/api/dist/generated/AppsAdapter';
import { Button, Spinner, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';

import { useFileUploadProvider } from '../../../pages/Files/FilesUploadProvider';
import { Selected } from '../FilesTable/useSelected';

export const DeleteSelected: FC<{
  activeTab: ResourceType;
  linkSelected: Selected;
  genFilesSelected: Selected;
  uploadedFilesSelected: Selected;
}> = ({ activeTab, genFilesSelected, uploadedFilesSelected, linkSelected }) => {
  const { t } = useTranslation();
  const { deleteLinks, deleteFilesHandle } = useFileUploadProvider();

  const [{ loading: deleting }, deleteFunc] = usePromiseProcessing(
    activeTab === ResourceType.Links ? deleteLinks : deleteFilesHandle,
    { deps: [activeTab] }
  );

  const deleteSelected = async () => {
    if (activeTab === ResourceType.Links) {
      await deleteFunc(linkSelected.selected);
      linkSelected.clearSelected();
      return;
    }
    const currentSelected = activeTab === ResourceType.AppFiles ? genFilesSelected : uploadedFilesSelected;
    await deleteFunc(currentSelected.selected);
    currentSelected.clearSelected();
  };

  if (
    (activeTab === ResourceType.AppFiles && genFilesSelected.selected.length > 0) ||
    (activeTab === ResourceType.UserFiles && uploadedFilesSelected.selected.length > 0) ||
    (activeTab === ResourceType.Links && linkSelected.selected.length > 0)
  )
    return (
      <Button
        size='sm'
        color='danger'
        style={{ padding: '0.3rem 0.5rem !important', gap: 4 }}
        onClick={deleteSelected}
        disabled={deleting}
      >
        {t('Files:FilterButton:delete')} {deleting && <Spinner inline size='sm' color='#888e93' />}
      </Button>
    );

  return null;
};
