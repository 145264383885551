import { useRef, useEffect, RefObject } from 'react';

import { Subject } from 'rxjs';

import { GuideStep } from './types';

export const guideTourEvent$ = new Subject<string>();

export function useGuideTourEventsSubscribe(
  currentStep: RefObject<GuideStep | null>,
  onStepEventReceived: (event: string) => void
) {
  const onStepEventReceivedRef = useRef(onStepEventReceived);
  useEffect(() => {
    onStepEventReceivedRef.current = onStepEventReceived;
  }, [onStepEventReceived]);

  useEffect(() => {
    const sub = guideTourEvent$.subscribe(eventName => {
      const event = currentStep.current?.waitEvent || currentStep.current?.goNextOnNextEvent;

      if (!event) return;

      const events = event
        .trim()
        .split(' ')
        .map(event => event.split('||'));

      if (events[0].includes(eventName)) {
        events.shift();
        if (events.length === 0) {
          if (currentStep.current) currentStep.current.waitEvent = undefined;
          onStepEventReceivedRef.current(eventName);
        } else {
          currentStep.current.waitEvent = events.map(event => event.join('||')).join(' ');
        }
        return;
      }
      if ([currentStep.current.goNextOnNextEvent, currentStep.current.hideOnEvent].includes(eventName)) {
        onStepEventReceivedRef.current(eventName);
        return;
      }
      if (!events[0].includes(eventName)) return;
      onStepEventReceivedRef.current(eventName);
    });

    const justwidgetToggleCallback = (event: Event & { detail?: boolean }) => {
      guideTourEvent$.next(event.detail ? 'TestWidget:Opened' : 'TestWidget:Closed');
    };
    document.addEventListener('justwidget_toggle', justwidgetToggleCallback);

    return () => {
      sub.unsubscribe();
      document.removeEventListener('justwidget_toggle', justwidgetToggleCallback);
    };
  }, [currentStep]);
}
