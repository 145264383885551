import React from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';

import ChatHeaderDesktopContextControlsView from './ChatHeaderDesktopContextControlsView';
import { Conversation } from '../../../../types/chat';

type Props = {
  selectedConversation: Conversation;
  isClearModalShown: boolean;
  isClearingInProgress: boolean;
  onClearButtonClick: () => unknown;
  onConfirmClearAction: () => unknown;
  onCancelClearAction: () => unknown;
};

export default function ChatHeaderDesktopContextControls({
  selectedConversation,
  isClearModalShown,
  isClearingInProgress,
  onClearButtonClick: handleClearButtonClick,
  onConfirmClearAction: handleConfirmClearAction,
  onCancelClearAction: handleCancelClearAction,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <ChatHeaderDesktopContextControlsView
        contextValue={selectedConversation.contextValue}
        messageIsStreaming={selectedConversation.messageIsStreaming}
        handleClearHistoryClick={handleClearButtonClick}
      />
      <Modal
        isOpen={isClearModalShown}
        inProgress={isClearingInProgress}
        className='mobileBottomModal'
        title={t('ClearHistoryModal:Title')}
        buttonSubmitText={t('clear')}
        onCancelClick={handleCancelClearAction}
        onActionClick={handleConfirmClearAction}
        buttonCancelColor='secondary'
      >
        <p className='mb-0'>{t('ClearHistoryModal:Text')}</p>
      </Modal>
    </>
  );
}
