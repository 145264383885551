import { useEffect } from 'react';

import { ConversationResponseApp } from '@just-ai/api/dist/generated/AppsAdapter';
import { usePromiseProcessing } from '@just-ai/just-ui';
import { computed, signal } from '@preact/signals-react';
import axios from 'axios';

import apiClient from '../../api/client';
import { appsAdapterService } from '../../services/service';
import { reduxConnectSignal } from '../../utils/reduxConnector';
import { addAlert } from '../alerts';
import { setConversationsValue } from '../conversations/signals';
import { rawConversationToConversation } from '../conversations/utils';
import { createImmerSignal } from '../hooks';
import { templates } from '../templates';

export type TFavouriteElement = ConversationResponseApp & {
  params: Record<string, Record<string, any>>;
  description?: string;
};

export const [favourites, setFavouritesValue] = createImmerSignal<TFavouriteElement[]>([]);
export const favouritesLoaded = signal(false);
reduxConnectSignal(
  'Favourite',
  computed(() => ({ favourites: favourites.value, favouritesLoaded: favouritesLoaded.value }))
);

export const creatingNewAgentFromFavorite = signal(false);

export const getFavourite = async () => {
  try {
    const { data } = await appsAdapterService.findFavoriteApps();
    setFavouritesValue(data.apps as TFavouriteElement[]);
    return data.apps;
  } catch (e) {
    if (axios.isAxiosError(e)) addAlert(JSON.stringify(e.message));
    else addAlert(JSON.stringify(e, null, 2));
  }
};

/**
 * appId: лежит в поле "app.id" объекта ConversationResponse || templateId для старого апи
 * */
export const toggleFavourite = async ({
  appId,
  favorite,
  name,
  description,
}: {
  appId: string;
  favorite: boolean;
  name?: string;
  description?: string;
}) => {
  const { data } = await appsAdapterService.changeAppFavoriteStatus(appId, { favorite, name, description });
  setFavouritesValue(prevFavourite => {
    if (data?.favorite) {
      prevFavourite.push(data as TFavouriteElement);
    } else {
      prevFavourite.splice(
        prevFavourite.findIndex(fav => fav.id === appId),
        1
      );
    }
  });
  return data;
};

export const createNewDialogFromFavourite = async ({ appId, name }: { appId: string; name: string }) => {
  const { data: conversation } = await appsAdapterService.createConversation({ name, app: { id: appId } }, true);
  setConversationsValue(prevConversations => {
    const chatTemplate = templates.value.templatesMap[conversation.app.template] || conversation.app;

    prevConversations.splice(0, 0, rawConversationToConversation(conversation, chatTemplate));
  });
  return conversation;
};

export const copyFavorite = async ({
  appId,
  params,
  name,
  description = '',
}: {
  appId: string;
  params: any;
  name: string;
  description: string;
}) => {
  const { data: newFavorite } = await apiClient.post<TFavouriteElement>(`/api/appsAdapter/apps/${appId}/copy`, {
    name,
    params,
    description,
  });
  const oldIndexOfEditedApp = favourites.value.findIndex(fav => fav.id === appId);
  await toggleFavourite({ appId, favorite: false });
  setFavouritesValue(prevFavourite => {
    prevFavourite.splice(oldIndexOfEditedApp, 0, newFavorite);
  });
  return newFavorite;
};

export const useFavorite = (shouldInitialLoad?: boolean) => {
  const [{ result: resultFavourite, error: errorFavourite, loading: loadingFavourite }, getFavoriteHandle] =
    usePromiseProcessing(getFavourite, {
      throwOnError: true,
    });

  useEffect(() => {
    shouldInitialLoad && getFavoriteHandle();
  }, [shouldInitialLoad, getFavoriteHandle]);

  return {
    resultFavourite,
    errorFavourite,
    loadingFavourite,
    getFavoriteHandle,
  };
};
