import React, { memo, useCallback, useEffect } from 'react';

import { Button, DropDownHint, useTranslation } from '@just-ai/just-ui';
import { useController, useFieldArray } from 'react-hook-form';

import { FormBuilderField } from '../../types';
import { StringArrayItem } from './StringArrayItem.field';

export const StringArray = memo(
  ({
    control,
    name,
    label,
    required,
    placeholder,
    hint,
    maxFileSize,
    maxResources = Infinity,
    accept,
    dropDownHint,
    disabled,
  }: FormBuilderField) => {
    const { t } = useTranslation();
    const { fieldState } = useController({ name, control });
    const { fields, append, remove, update } = useFieldArray({ name, control, rules: { required } });

    const onClickAdd = useCallback(() => {
      append('');
    }, [append]);

    const onRemoveItem = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove]
    );

    const dropState = useCallback(
      (index: number) => {
        update(index, ['']);
      },
      [update]
    );

    useEffect(() => {
      if (fields.length === 0) onClickAdd();
    }, [fields, onClickAdd]);

    return (
      <div className='form-group mb-0' data-test-id={`TemplateForm.StringArray.${name}`}>
        <label>{label}</label>
        {fields?.map((fieldValue, index) => (
          <StringArrayItem
            name={name}
            key={fieldValue.id}
            placeholder={placeholder}
            onRemove={onRemoveItem}
            index={index}
            invalid={fieldState.invalid}
            required={!!required}
            dataTestId={`TemplateForm.StringArray.${name}.Item.${index}`}
            maxFileSize={maxFileSize}
            dropState={dropState}
            accept={accept}
            disabled={disabled}
          />
        ))}
        {hint && <small className='text-muted hint' dangerouslySetInnerHTML={{ __html: hint }} />}
        {dropDownHint ? <DropDownHint dropDownHint={dropDownHint} /> : null}

        {maxResources > fields.length ? (
          <div>
            <Button
              data-test-id={`TemplateForm.StringArray.${name}.Add`}
              color='primary'
              iconLeft='farPlus'
              iconRight=''
              flat
              onClick={onClickAdd}
              size='md'
              disabled={disabled}
            >
              {t('StringArray:AddField')}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
);

StringArray.displayName = 'memo(StringArray)';
