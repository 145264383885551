import { AppCreateRequest } from '@just-ai/api/dist/generated/AppsAdapter';
import fastChunkString from '@shelf/fast-chunk-string';
import { concatMap, delay, of, Subject } from 'rxjs';

import apiClient from '../../api/client';
import { t } from '../../localization';
import { appsAdapterService } from '../../services/service';
import { Message } from '../../types/chat';
import { createDefaultMsg, processConversationContext, processHistory } from '../../utils/app/conversation';
import { findConversation, findConversationIndex } from './index';
import { setConversationsValue } from './signals';

export const deleteConversation = async (chatId: string) => {
  return await appsAdapterService.deleteUserChat(chatId);
};

export const getUserChatHistory = async (conversationId: string, configTemplate: string) => {
  const { data: fullConversationData } = await appsAdapterService.getUserChat(conversationId);
  const processedHistory = processHistory(fullConversationData.history);
  let processedContext = processConversationContext(processedHistory);
  const defaultMsg: Message = createDefaultMsg(
    [
      {
        type: 'text',
        text: t(`prompt_${configTemplate}`, {
          productName: t('defaultProductName'),
        }),
      },
    ],
    conversationId,
    'system'
  );
  setConversationsValue(prevConversations => {
    const changeConversationIndex = findConversationIndex(conversationId);
    if (prevConversations[changeConversationIndex].streamSubscription) {
      prevConversations[changeConversationIndex].streamSubscription?.unsubscribe();
      prevConversations[changeConversationIndex].streamSubscription = undefined;
    }
    prevConversations[changeConversationIndex] = {
      ...prevConversations[changeConversationIndex],
      app: {
        ...prevConversations[changeConversationIndex].app,
        status: fullConversationData.app.status,
      },
      updatedAt: fullConversationData.updatedAt,
      status: fullConversationData.status,
      hasUnreadChanges: false,
      messageIsStreaming: fullConversationData.status !== 'READY',
      history:
        processedHistory.length > 0 ? processedHistory : fullConversationData.status === 'READY' ? [defaultMsg] : [],
      contextValue: processedContext,
      isCanvasChat: Object.keys(fullConversationData.externalInstances || {}).length > 0,
    };
  });
};

export const getConversationHistory = (conversationId: string, oldestMessageTimestamp: number) => {
  return appsAdapterService.getChatHistory(conversationId, oldestMessageTimestamp);
};

export const updateUserChat = async (conversationId: string, updateData: AppCreateRequest) => {
  const updateResponse = await appsAdapterService.updateUserChatApp(conversationId, updateData, true);
  streamMessage(conversationId);
  return updateResponse;
};

export const streamMessage = async (conversationId: string) => {
  const abortSignal = new AbortController();
  const response = await fetch(`/api/appsAdapter/conversations/${conversationId}/sse`, {
    method: 'GET',
    signal: abortSignal.signal,
    headers: apiClient.defaults.headers as Record<string, string>,
  });

  const reader = response.body?.getReader();
  const decoder = new TextDecoder('utf-8');

  if (!reader) return;
  if (response.status === 200) {
    let conversationIndexToUpdate = findConversationIndex(conversationId);
    let conversation = findConversation(conversationId);
    if (!conversation?.messageIsStreaming) {
      abortSignal.abort();
      return;
    }
    setConversationsValue(prevConversations => {
      if (prevConversations[conversationIndexToUpdate].messageIsStreaming) {
        prevConversations[conversationIndexToUpdate].history.push(
          createDefaultMsg([{ type: 'text', text: '' }], conversationId, 'response')
        );
      }
    });
    let storedMessages: string[] = [];
    let messageSubject = new Subject();

    const subscription = messageSubject.pipe(concatMap(message => of(message).pipe(delay(25)))).subscribe(message => {
      let conversationIndexToUpdateInPipe = findConversationIndex(conversationId);
      setConversationsValue(prevConversations => {
        const lastMessageToUpdate =
          prevConversations[conversationIndexToUpdateInPipe].history[
            prevConversations[conversationIndexToUpdateInPipe].history.length - 1
          ];
        if (lastMessageToUpdate && lastMessageToUpdate.content[0]) {
          lastMessageToUpdate.content[0]['text'] += message;
        }
      });
    });
    setConversationsValue(prevConversations => {
      prevConversations[conversationIndexToUpdate].streamSubscription = subscription;
    });
    const storeAndEmitMessages = (newMessages: string[]) => {
      storedMessages = [...storedMessages, ...newMessages];
      storedMessages.forEach(message => messageSubject.next(message));
      storedMessages = [];
    };
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      let decodeValue = decoder.decode(value).replaceAll('\\n', '\n');
      storeAndEmitMessages(fastChunkString(decodeValue, { size: 2, unicodeAware: false }));
    }
  }
};
