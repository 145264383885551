import React, { FC, memo } from 'react';

import {
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  IconButton,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './styles.module.scss';

type TFileRowDropdownProps = {
  openInNewTab?: () => void;
  copyLink?: () => void;
  downLoad?: () => void;
  openInAssistant?: () => void;
  openInDialog?: () => void;
  deleteItem?: () => void;
  dialogWasDeleted?: boolean;
};

export const FileRowDropdown: FC<TFileRowDropdownProps> = memo(
  ({ openInNewTab, copyLink, downLoad, openInAssistant, openInDialog, deleteItem, dialogWasDeleted }) => {
    const { t } = useTranslation();

    return (
      <DropdownButton direction='down'>
        <DropdownToggle color='none' tag='div'>
          <IconButton className={cn(styles.file_button)} name='faEllipsisV' size='sm' outline flat color='secondary' />
        </DropdownToggle>
        <DropdownMenu className={cn('border-0', styles.dropdownMenu)} positionFixed={true} tag='div' right={true}>
          {openInNewTab && (
            <div className={styles.dropdownMenu__Item} onClick={openInNewTab}>
              <Icon name='farExternalLink' color='secondary' size='md' />
              {t('FilesPage:FileRow:Dropdown:NewTab')}
            </div>
          )}
          {copyLink && (
            <div className={styles.dropdownMenu__Item} onClick={copyLink}>
              <Icon name='farLink' color='secondary' size='md' />
              {t('FilesPage:FileRow:Dropdown:CopyLink')}
            </div>
          )}
          {downLoad && (
            <div className={styles.dropdownMenu__Item} onClick={downLoad}>
              <Icon name='farArrowToBottom' color='secondary' size='md' />
              {t('FilesPage:FileRow:Dropdown:Download')}
            </div>
          )}
          {openInAssistant && (
            <div className={styles.dropdownMenu__Item} onClick={openInAssistant}>
              <div className={styles.dropdownMenu__Item__iconPlaceholder} />
              {t('FilesPage:FileRow:Dropdown:OpenInAssistant')}
            </div>
          )}
          {openInDialog && (
            <div className={styles.dropdownMenu__Item} onClick={openInDialog}>
              <div className={styles.dropdownMenu__Item__iconPlaceholder} />
              <div className='d-flex flex-column gap-0'>
                <div>{t('FilesPage:FileRow:Dropdown:OpenInDialog')}</div>
                <div className='text-muted font-size-12 line-height-18'>
                  {t('FilesPage:FileRow:Dropdown:OpenInDialog:subtext')}
                </div>
              </div>
            </div>
          )}
          {deleteItem && (
            <div className={cn(styles.dropdownMenu__Item, styles.dropdownMenu__Item__danger)} onClick={deleteItem}>
              <Icon name='farTrashAlt' color='danger' size='md' />
              {t('FilesPage:FileRow:Dropdown:Delete')}
            </div>
          )}
          {dialogWasDeleted && (
            <>
              <DropdownItem divider />
              <div className={cn(styles.dropdownMenu__Item, styles.dropdownMenu__Item__muted)}>
                <div className={styles.dropdownMenu__Item__iconPlaceholder} />
                {t('FilesPage:FileRow:Dropdown:DialogWasDeleted')}
              </div>
            </>
          )}
        </DropdownMenu>
      </DropdownButton>
    );
  }
);
