import React, { FC, memo, useCallback, useRef, useState } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { pdfjs, Document, Page } from 'react-pdf';
import { AutoSizer, List as VList } from 'react-virtualized';

import { isDev } from '../../../utils/app/common';
import { isOpenAgentSettings } from '../../Settings/AgentSettingsSidebar';
import styles from '../../Settings/style.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `${window.location.protocol}//${window.location.host}/pdf.worker.js`
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export const FileSettings: FC<{
  document?: string;
}> = memo(({ document }) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(styles.agentSettings__wrapper, styles.agentSettings_fileViewer, {
        visible: isOpenAgentSettings.value,
      })}
    >
      <Document
        file={isDev() ? new URL(document || '').pathname : document}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        error={t('Chat:FileSettings:error')}
        loading={t('Chat:FileSettings:loading')}
        noData={t('Chat:FileSettings:noFile')}
      >
        <AutoSizer>
          {({ height, width }) => (
            <VList
              width={width}
              height={height}
              rowCount={numPages}
              rowHeight={height}
              rowRenderer={({ index, key, style }) => (
                <div key={key} style={style}>
                  <Page pageNumber={index + 1} key={key} width={width} />
                </div>
              )}
            />
          )}
        </AutoSizer>
      </Document>
    </div>
  );
});

FileSettings.displayName = 'memo(FileSettings)';
