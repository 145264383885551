import React, { FC, memo } from 'react';

import { useTranslation } from '@just-ai/just-ui';

import assistantImage from '../../images/AssistantIcon.svg';
import { templates } from '../../models/templates';

export const FileTemplateName: FC<{ template?: string }> = memo(({ template }) => {
  const { t } = useTranslation();
  if (template && templates.value?.templatesMap[template]) {
    return (
      <div className='d-flex gap-4 align-items-center'>
        <div>{templates.value.templatesMap[template].info.icon}</div>
        <div>{templates.value.templatesMap[template].info.title}</div>
      </div>
    );
  }
  return (
    <div className='d-flex gap-4 align-items-center'>
      <img src={assistantImage} alt='Assistant Jay logo' />
      <div>{t('assistantJay')}</div>
    </div>
  );
});
FileTemplateName.displayName = 'memo(FileTemplateName)';
