export const maxFileSizeMb = 200;
export const bytesInMb = 1000 * 1000;
export const maxFileSize = maxFileSizeMb * bytesInMb;
export const acceptFileFormat =
  'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, application/json, text/html, .md, .xml, .yml, .yaml, .avif, audio/*, video/*, image/*';

export enum UPLOAD_STATUSES {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  READY = 'READY',
}

export type UploadSource = {
  name: string;
  size: number;
  file: File;
  status: keyof typeof UPLOAD_STATUSES;
  error?: string;
  id?: string;
};
