import React from 'react';

import { Item, Gallery } from 'react-photoswipe-gallery';

import 'photoswipe/dist/photoswipe.css';
import ImageShowUiButton from './ImageShowUiButton';

type ImageGalleryProps = {
  galleryId: string;
  images: { largeURL: string; thumbnailURL: string }[];
  errorHandler?: () => void;
  withExistingGallery?: boolean;
  selectedConversationId?: string;
  isMainChat?: boolean;
};

export default function ImageGallery({
  galleryId,
  images,
  errorHandler,
  withExistingGallery,
  selectedConversationId,
  isMainChat,
}: ImageGalleryProps) {
  const galleryItems = (
    <>
      {images.map((image, index) => (
        <>
          <Item
            key={galleryId + '-' + index}
            original={image.largeURL}
            thumbnail={image.thumbnailURL}
            width={0.75 * window.innerWidth}
            height={0.75 * window.innerWidth}
            alt=''
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                className='cursor-pointer'
                onClick={open}
                alt=''
                src={image.thumbnailURL}
                onError={errorHandler}
              />
            )}
          </Item>
          {!!selectedConversationId && !!isMainChat && (
            <ImageShowUiButton imageUrl={image.largeURL} selectedConversationId={selectedConversationId} />
          )}
        </>
      ))}
    </>
  );

  return withExistingGallery ? (
    galleryItems
  ) : (
    <Gallery id={'#' + galleryId} withDownloadButton>
      {galleryItems}
    </Gallery>
  );
}
