export const linksAddModalLocalization = {
  'ModalAddLinks:Title': {
    ru: 'Добавить ссылки',
  },
  'ModalAddLinks:addLink': {
    ru: '+ Добавить еще',
  },
  'ModalAddLinks:LinkIsInvalid': {
    ru: 'Не является ссылкой. Ссылка должна начинаться на http:// или https://',
  },
  'ModalAddLinks:Placeholder': {
    ru: 'https://example.com',
  },
  '': {
    ru: '',
  },
};
