import React, { memo, SVGProps } from 'react';

import SVGUniqueID from 'react-svg-unique-id';

const JTIcon = memo((props: SVGProps<SVGSVGElement>) => {
  return (
    <SVGUniqueID>
      <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_8717_9393)'>
          <path
            d='M9.30632 23.2039C8.35727 23.1478 7.44797 22.7188 6.80044 21.9937L1.4714 16.0142C0.611406 15.05 0.330747 13.6881 0.739277 12.4661L3.26916 4.87984C3.67791 3.65424 4.72017 2.73159 5.98869 2.47374L13.8478 0.863378C15.1166 0.601995 16.4394 1.04126 17.2995 2.00548L22.6284 7.98487C23.4885 8.94909 23.7692 10.311 23.3606 11.5331L20.8305 19.1228C20.4218 20.3485 19.3797 21.2675 18.111 21.529L10.2519 23.1394C9.93639 23.2057 9.6201 23.2259 9.30964 23.2076L9.30632 23.2039Z'
            fill='url(#paint0_linear_8717_9393)'
          />
        </g>
        <path
          d='M15.2152 15.442C15.2152 15.6098 15.1714 15.7265 15.0838 15.7922C15.0036 15.8579 14.8868 15.8907 14.7336 15.8907C14.5804 15.8907 14.46 15.8579 14.3725 15.7922C14.2922 15.7265 14.2521 15.6098 14.2521 15.442V9.06146H11.9647C11.8115 9.06146 11.702 9.02498 11.6364 8.95202C11.578 8.87906 11.5488 8.77326 11.5488 8.63463C11.5488 8.49601 11.578 8.39386 11.6364 8.32819C11.702 8.25523 11.8115 8.21875 11.9647 8.21875H17.5025C17.6558 8.21875 17.7615 8.25523 17.8199 8.32819C17.8856 8.39386 17.9184 8.49601 17.9184 8.63463C17.9184 8.77326 17.8856 8.87906 17.8199 8.95202C17.7615 9.02498 17.6558 9.06146 17.5025 9.06146H15.2152V15.442Z'
          fill='white'
        />
        <path
          d='M7.92374 15.1669C8.44177 15.1669 8.80294 15.0209 9.00723 14.7291C9.21882 14.43 9.32461 13.9666 9.32461 13.3392V8.58934C9.32461 8.42153 9.36474 8.30479 9.445 8.23912C9.53256 8.17346 9.65294 8.14062 9.80616 8.14062C9.95938 8.14062 10.0761 8.17346 10.1564 8.23912C10.2439 8.30479 10.2877 8.42153 10.2877 8.58934V13.3282C10.2877 14.2548 10.0798 14.9334 9.66389 15.3639C9.2553 15.787 8.63877 15.9986 7.8143 15.9986C7.45679 15.9986 7.15399 15.9512 6.90592 15.8564C6.65785 15.7615 6.44991 15.6484 6.2821 15.5171C6.12158 15.3785 5.99025 15.2325 5.8881 15.0793C5.78595 14.9188 5.7057 14.7729 5.64733 14.6415C5.58166 14.4883 5.54883 14.3497 5.54883 14.2257C5.54883 14.0651 5.6072 13.9521 5.72394 13.8864C5.84797 13.8134 5.96836 13.7769 6.0851 13.7769C6.20184 13.7769 6.28939 13.8134 6.34776 13.8864C6.40613 13.9593 6.4645 14.0542 6.52287 14.1709C6.58854 14.3023 6.6615 14.43 6.74176 14.554C6.82931 14.6707 6.92781 14.7765 7.03725 14.8714C7.1467 14.9589 7.27073 15.0319 7.40936 15.0903C7.55528 15.1413 7.72675 15.1669 7.92374 15.1669Z'
          fill='white'
        />
        <defs>
          <linearGradient
            id='paint0_linear_8717_9393'
            x1='24.2249'
            y1='9.68119'
            x2='0.913469'
            y2='15.0157'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.02' stop-color='#FE6DA4' />
            <stop offset='1' stop-color='#AD8BE3' />
          </linearGradient>
          <clipPath id='clip0_8717_9393'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  );
});

export default JTIcon;
