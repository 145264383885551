import React, { memo, useMemo } from 'react';

import { TemplateItem } from 'components/Agents/TemplatesList/TemplateItem';
import { cloneDeep } from 'lodash';

import appHistory from '../../appHistory';
import { templates } from '../../models/templates';
import { AgentType } from '../../types/agents';
import { AgentTemplateResponse } from '../../types/chat';

type Props = {
  agent: AgentTemplateResponse;
};

export const TemplateMessage = memo(function TemplateMessage({ agent }: Props) {
  const { templatesMap } = templates.value;

  const targetTemplate = useMemo(() => {
    const key = Object.keys(templatesMap).find(key => key === agent.template);
    const rawData = key ? cloneDeep(templatesMap[key]) : undefined;
    const paramsWithValues = rawData?.params;
    Object.keys(agent.params).forEach(existingKey => {
      if (paramsWithValues && paramsWithValues[existingKey]) {
        paramsWithValues[existingKey]['value'] = agent.params[existingKey];
      }
    });
    return { template: key, ...rawData, params: paramsWithValues };
  }, [agent.params, agent.template, templatesMap]);

  const handleTemplateClick = (template: AgentType) => {
    appHistory.push('/', { template });
  };

  if (!targetTemplate) return null;

  return (
    <div>
      <TemplateItem onTemplateSelect={handleTemplateClick} template={targetTemplate as AgentType} />
    </div>
  );
});

export default TemplateMessage;
