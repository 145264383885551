import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, SearchInput, useTranslation, Modal } from '@just-ai/just-ui';
import cn from 'classnames';

import { FavoriteItem } from './FavoriteItem';
import styles from './style.module.scss';
import { TemplateItem } from './TemplateItem';
import { TemplatesListLocalization } from './TemplatesList.loc';
import { isAlpha } from '../../../isAlpha';
import localize from '../../../localization';
import { TFavouriteElement } from '../../../models/favourite/favourite.api';
import { AgentType, CreatedAgent } from '../../../types/agents';
import { Category, localCategories, localCategoriesMap } from '../Categories';

localize.addTranslations(TemplatesListLocalization);

export type Props = {
  allTemplates: AgentType[];
  favorites: TFavouriteElement[];
  onTemplateSelect: (template: AgentType) => void;
  isOpenFromTelegram: boolean;
};

function TemplateList({ allTemplates = [], onTemplateSelect, favorites = [], isOpenFromTelegram }: Props) {
  const { t, tWithCheck } = useTranslation();

  const preparedTemplates = useMemo(() => {
    if (!isOpenFromTelegram)
      return allTemplates.filter(template =>
        template.categories.some(templateCategory => localCategoriesMap.includes(templateCategory))
      );
    return allTemplates.filter(
      template =>
        !template.requiredFeature &&
        template.categories.some(templateCategory => localCategoriesMap.includes(templateCategory))
    );
  }, [allTemplates, isOpenFromTelegram]);

  const [selectedTab, setSelectedTab] = useState<Category>(localCategories[0]);
  const [search, setSearch] = useState('');
  const [categoriesListModalOpen, setCategoriesListModalOpen] = useState(false);

  const [filteredTemplates, setFilteredTemplates] = useState(() => {
    return preparedTemplates.filter(template =>
      selectedTab.value === 'all' ? true : template.categories?.includes(selectedTab.value)
    );
  });

  useEffect(() => {
    setFilteredTemplates(
      preparedTemplates.filter(template =>
        !!template.categories && selectedTab.value === 'all' ? true : template.categories?.includes(selectedTab.value)
      )
    );
  }, [preparedTemplates, selectedTab]);

  useEffect(() => {
    if (search) {
      setSelectedTab(localCategories[0]);
    }
  }, [search]);

  const setSearchHandler = useCallback((value: string) => {
    setSearch(value.trimStart());
  }, []);

  const mobileCategoryString = (category: Category) => {
    if (isAlpha) {
      return (
        tWithCheck(`TemplateList:Category:${category.value}--alpha-sm`) ||
        tWithCheck(`TemplateList:Category:${category.value}--alpha`) ||
        t(`TemplateList:Category:${category.value}`)
      );
    }
    return tWithCheck(`TemplateList:Category:${category.value}-sm`) || t(`TemplateList:Category:${category.value}`);
  };

  const handleSelectCategory = (category: Category) => () => {
    setSearch('');
    setSelectedTab(category);
    setCategoriesListModalOpen(false);
  };

  const popularCategory = localCategories.find(category => category.value === 'popular') as Category;
  const favoriteCategory = localCategories.find(category => category.value === 'favor') as Category;

  return (
    <div className={styles.templateList__wrapper} style={isOpenFromTelegram ? { paddingBottom: 60 } : {}}>
      <div
        className={cn(
          styles.templateList__search,
          isOpenFromTelegram ? styles.templateList__search_telegram : styles.templateList__search_web
        )}
      >
        <SearchInput
          alwaysOpen
          clearable={Boolean(search)}
          autoFocus
          onChange={setSearchHandler}
          value={search}
          size='lg'
          placeholder={t('SearchApps')}
        />
      </div>
      <div className={cn(styles.templateList__btns, { 'bottom-0': isOpenFromTelegram })}>
        <Button
          color='secondary'
          flat
          iconLeft={popularCategory.icon}
          wrapperIconClassName={styles.iconWrapper}
          className={cn(styles.templateList__categoryButton, 'd-sm-none', {
            [styles.templateList__categoryButton_active]: selectedTab.value === popularCategory.value,
          })}
          onClick={handleSelectCategory(popularCategory)}
        />
        <Button
          color='secondary'
          flat
          iconLeft={favoriteCategory.icon}
          wrapperIconClassName={styles.iconWrapper}
          className={cn(styles.templateList__categoryButton, 'd-sm-none', {
            [styles.templateList__categoryButton_active]: selectedTab.value === favoriteCategory.value,
          })}
          onClick={handleSelectCategory(favoriteCategory)}
        />
        <Button
          color='secondary'
          flat
          iconLeft='farEllipsisH'
          wrapperIconClassName={styles.iconWrapper}
          className={cn(styles.templateList__categoryButton, 'w-full d-sm-none')}
          onClick={() => setCategoriesListModalOpen(true)}
        >
          <span className='d-sm-none text-nowrap'>{t('TemplateList:Category:allCategories')}</span>
        </Button>
        {localCategories.map(category => (
          <Button
            key={category.value}
            color='secondary'
            flat
            iconLeft={category.icon}
            wrapperIconClassName={styles.iconWrapper}
            data-test-id={`TemplateList:category:${category.value}`}
            className={cn(styles.templateList__categoryButton, 'justify-start d-none d-sm-flex', {
              [styles.templateList__categoryButton_active]: selectedTab.value === category.value,
            })}
            onClick={handleSelectCategory(category)}
          >
            <span className='d-none d-sm-flex'>
              {tWithCheck(
                isAlpha ? `TemplateList:Category:${category.value}--alpha` : `TemplateList:Category:${category.value}`
              ) || t(`TemplateList:Category:${category.value}`)}
            </span>
            <span className='d-sm-none text-nowrap'>{mobileCategoryString(category)}</span>
          </Button>
        ))}
      </div>
      <div className={styles.templateList__list} style={isOpenFromTelegram ? { paddingTop: 60 } : {}}>
        {selectedTab && (
          <h2 className='d-sm-none mb-3'>
            {tWithCheck(
              isAlpha
                ? `TemplateList:Category:${selectedTab.value}--alpha`
                : `TemplateList:Category:${selectedTab.value}`
            ) || t(`TemplateList:Category:${selectedTab.value}`)}
          </h2>
        )}
        {search.length ? (
          <>
            {preparedTemplates
              .filter(
                template =>
                  !!template.categories &&
                  (template.info.title.toLowerCase().includes(search.trimStart().toLowerCase()) ||
                    template.info.description.toLowerCase().includes(search.trimStart().toLowerCase()))
              )
              .map(template => (
                <TemplateItem
                  key={`template-${template.template}-${(template as CreatedAgent).id || ''}`}
                  onTemplateSelect={onTemplateSelect}
                  template={template}
                />
              ))}
          </>
        ) : (
          <>
            {selectedTab.value !== 'favor' ? (
              <>
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map(template => (
                    <TemplateItem
                      key={`template-${template.template}-${(template as CreatedAgent).id || ''}`}
                      onTemplateSelect={onTemplateSelect}
                      template={template}
                    />
                  ))
                ) : (
                  <div className='flex justify-content-center' style={{ gridColumn: '1/-1' }}>
                    <p className='text-muted font-size-16'>{t('TemplatesList:categoryEmpty')}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                {favorites.length ? (
                  <>
                    {favorites.map(favorite => {
                      const favTemplate = allTemplates.find(templ => templ.template === favorite.template);
                      if (!favTemplate) return null;
                      return <FavoriteItem key={favorite.id} favorite={favorite} favTemplate={favTemplate} />;
                    })}
                  </>
                ) : (
                  <div className='flex justify-content-center' style={{ gridColumn: '1/-1' }}>
                    <p data-test-id='NoFavourite.text' className='text-muted font-size-16'>
                      {t('NoFavourite')}
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Modal
        title={t(`TemplateList:CategoryModal:allCategories`)}
        isOpen={categoriesListModalOpen}
        onClickCloseIcon={() => setCategoriesListModalOpen(false)}
        className='mobileBottomModal'
      >
        <div className='flex flex-column gap-8'>
          {localCategories.map(category => (
            <Button
              key={category.value}
              color='secondary'
              flat
              iconLeft={category.icon}
              wrapperIconClassName={styles.iconWrapper}
              className={cn(styles.templateList__categoryButton, 'justify-start w-full', {
                [styles.templateList__categoryButton_active]: selectedTab.value === category.value,
              })}
              onClick={handleSelectCategory(category)}
            >
              <span>
                {tWithCheck(
                  isAlpha ? `TemplateList:Category:${category.value}--alpha` : `TemplateList:Category:${category.value}`
                ) || t(`TemplateList:Category:${category.value}`)}
              </span>
            </Button>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default TemplateList;
