import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@just-ai/just-ui';
import cn from 'classnames';
import { clamp } from 'lodash';
import ReactPaginate from 'react-paginate';

import styles from './styles.module.scss';

interface PaginationProps {
  page: number;
  size: number;
  totalCount: number;
  changePage: (page: number) => void;
}

const Pagination = ({ page, size, totalCount, changePage }: PaginationProps) => {
  const [innerPage, setInnerPage] = useState(page);
  const onChangePage = useCallback(
    (val?: number) => {
      const value = val ?? innerPage;
      if (page !== value) {
        changePage(value);
      }
    },
    [changePage, innerPage, page]
  );
  const onPageClick = useCallback(
    (data: { selected: number }) => {
      setInnerPage(data.selected);
      onChangePage(data.selected);
    },
    [onChangePage]
  );

  const pagesCount = size === 0 ? 0 : Math.ceil(totalCount / size);

  useEffect(() => {
    const clampedVal = clamp(innerPage, 0, pagesCount - 1);
    if (clampedVal !== innerPage) setInnerPage(clampedVal);
  }, [innerPage, pagesCount]);

  return (
    <div className={styles.Pagination}>
      {pagesCount > 0 && (
        <ReactPaginate
          pageCount={pagesCount}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          onPageChange={onPageClick}
          forcePage={page}
          containerClassName={styles.Pagination_paginator}
          pageClassName={styles.Pagination_page}
          pageLinkClassName={styles.Pagination_page_link}
          breakClassName={styles.Pagination_page}
          breakLinkClassName={styles.Pagination_page_link}
          activeClassName={styles.Pagination_active}
          previousClassName={cn(styles.Pagination_previous, {
            [styles.disabled]: page === 0,
          })}
          nextClassName={cn(styles.Pagination_next, {
            [styles.disabled]: page === pagesCount - 1,
          })}
          nextLabel={<Icon name='farChevronRight' data-test-id='pagination-next' id='pagination-next' />}
          previousLabel={<Icon name='farChevronLeft' data-test-id='pagination-previous' id='pagination-previous' />}
        />
      )}
    </div>
  );
};

Pagination.displayName = 'Pagination';

export default React.memo(Pagination);
