export class ClipboardService {
  static copyHtmlToClipboard(html: string) {
    const copyMessage = document.createElement('div');

    const replaceTags = (str: string) =>
      str
        .replaceAll('<del>', '<s>')
        .replaceAll('</del>', '</s>')
        .replaceAll('<strong>', '<strong style="font-weight: 700">');

    copyMessage.innerHTML = replaceTags(html);

    const copyContainer = document.createElement('div');
    copyContainer.appendChild(copyMessage);
    const oldBodyBackground = document.body.style.background;
    document.body.style.background = 'transparent';
    document.body.appendChild(copyContainer);

    // Выделяем текст в созданном элементе
    window.getSelection()?.removeAllRanges();
    const range = document.createRange();
    range.selectNodeContents(copyContainer);
    window.getSelection()?.addRange(range);

    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(null) : rej();
      document.body.removeChild(copyContainer);
      document.body.style.background = oldBodyBackground;
    });
  }
  static copyTextToClipboard(text: string) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(text);
    }
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(null) : rej();
      textArea.remove();
    });
  }
}
