import React, { useEffect, useRef, useState } from 'react';

import { JustSelect, OptionType, useTranslation } from '@just-ai/just-ui';

type SearchUsersSelectProps = {
  searchFunction: (search: string) => Promise<{ label?: string; value?: string }[]>;
  onChange: (value: (string | number)[] | null) => void;
  className?: string;
  error?: string;
};

export default function SearchUsersSelect(props: SearchUsersSelectProps) {
  const { searchFunction, onChange, className, error } = props;

  const { t } = useTranslation();

  const [innerOptions, setInnerOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    searchFunction('').then(result => {
      setInnerOptions(result as OptionType[]);
    });
  }, [searchFunction]);

  const handleInput = async (value: string) => {
    timer.current && clearTimeout(timer.current);
    setIsLoading(true);
    timer.current = setTimeout(async () => {
      try {
        const result = await searchFunction(value);
        setInnerOptions(result as OptionType[]);
      } catch (error) {
        setInnerOptions([]);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      }
    }, 1000);
  };

  return (
    <JustSelect
      isLoading={isLoading}
      onInput={handleInput}
      onChange={value => onChange(value)}
      options={innerOptions as OptionType[]}
      invalid={!!error}
      errorText={error}
      size='md'
      multiple={true}
      className={className}
      messages={{ NOT_FOUND: t('notFound'), EMPTY: t('listEmpty') }}
    />
  );
}
