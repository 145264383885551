import React, { useCallback } from 'react';

import { Button, useTranslation, Icon } from '@just-ai/just-ui';
import cn from 'classnames';

import appHistory from '../../appHistory';
import { setJGuardModalShown } from '../../models/conversations/signals';
import { hasPermission } from '../../models/currentUser';
import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';

export default function DemoModePanel() {
  const { t } = useTranslation();

  const btnClickHandler = useCallback(() => {
    if (appHistory.location.pathname !== '/account') {
      appHistory.push('/account', { toJGuard: true });
      return;
    }
    setJGuardModalShown(true);
  }, []);

  return (
    <div data-test-id='TopPanel.jGuard' className={cn(styles.topPanel, styles.topPanel_failed, styles.topPanel_jguard)}>
      {hasPermission('COPILOT_VIEW_BALANCES') && appOptions.value?.billingEnabled ? (
        <>
          <p>{t('jayGuard:Panel:title', { productName: t('jayGuard') })}</p>
          <Button data-test-id='TopPanel.jGuard.btn' color='primary' size='sm' onClick={btnClickHandler}>
            {t('jayGuard:Panel:btn', { productName: t('jayGuard') })}
          </Button>
        </>
      ) : (
        <>
          <Icon name='farExclamationCircle' size='lg' className={cn(styles['topPanel_jguard--error'])} />
          <p className={cn(styles['topPanel_jguard--error'])}>
            <b>{t('jayGuard:Panel:titleNoBtn', { productName: t('jayGuard') })}</b>
          </p>
        </>
      )}
    </div>
  );
}
