import MockAdapter from 'axios-mock-adapter';

import apiClient from './client';
import { mockUser, mockConversations, mockAssistant } from './constants';
import { showSignupModal } from '../hooks/showSignup';
import { setCurrentUser } from '../models/currentUser';

export default function initAxiosMock() {
  const mock = new MockAdapter(apiClient);
  setCurrentUser(mockUser);

  const fileDownloadPathRegex = new RegExp('/api/appsAdapter/files/*');
  const allowedAccountPathRegex = new RegExp('/api/accountsadmin/c/users/80085/allowed-accounts*');

  mock
    .onGet('/api/accountsadmin/c/authorization/check-authorized')
    .reply(200, {
      userData: mockUser,
    })
    .onGet('/api/gateway/web/billing/status')
    .reply(200, {})
    .onGet('/api/gateway/web/user/limits')
    .reply(200, {})
    .onGet('/api/gateway/web/user')
    .reply(200, {
      userData: mockUser,
    })
    .onGet('/api/appsAdapter/conversations/')
    .reply(200, {
      conversations: mockConversations,
    })
    .onGet('/api/appsAdapter/templates/')
    .passThrough()
    .onGet('/api/appsAdapter/apps/favorite')
    .reply(200, {
      apps: [],
    })
    .onGet('/api/appsAdapter/tools/hf/billing')
    .reply(404)
    .onGet('/api/appsAdapter/conversations/assistant')
    .reply(200, mockAssistant)
    .onGet('/api/appsAdapter/conversations/painterDemo')
    .reply(200, mockConversations[0])
    .onGet('/api/appsAdapter/conversations/chatGptDemo')
    .reply(200, mockConversations[1])
    .onGet('/api/appsAdapter/conversations/siteSearchDemo')
    .reply(200, mockConversations[2])
    .onGet('/api/appsAdapter/conversations/copywriterDemo')
    .reply(200, mockConversations[3])
    .onGet('/api/appsAdapter/conversations/assistantDemo')
    .reply(200, mockAssistant)
    .onGet('/api/gateway/web/balances/')
    .reply(200)
    .onGet(allowedAccountPathRegex)
    .reply(200)
    .onGet('/api/accountsadmin/c/account-invitation/get-by-user?userId=80085')
    .reply(200)
    .onGet('/api/gateway/web/dataguard/api-token/is-valid')
    .reply(200, true)
    .onGet('/api/appsAdapter/external')
    .reply(200, [])
    .onHead(fileDownloadPathRegex)
    .reply(404)
    .onAny()
    .reply(args => {
      console.log('missing request in mock args', args);
      showSignupModal(true);
      return [200];
    });

  return mock;
}
