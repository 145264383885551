import React, { useEffect, useState } from 'react';

import { Modal, useTranslation, usePromiseProcessing, Icon, IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import { currentUser } from '../../models/currentUser';
import useApiService from '../../services/useApiService';
import styles from './style.module.scss';

type ApiKeyModalProps = {
  closeModal: () => void;
  setUserApiKey: (key: string) => void;
};

export default function ApiKeyModal(props: ApiKeyModalProps) {
  const { closeModal, setUserApiKey } = props;

  const { t } = useTranslation();

  const { createUserApiKey } = useApiService();

  const [createdKey, setCreatedKey] = useState('');

  const [{ loading }, generateUserToken] = usePromiseProcessing(
    async () => {
      if (currentUser.value?.userId) {
        const { data: createdApiKey } = await createUserApiKey(currentUser.value.userId);

        setUserApiKey(createdApiKey);
        setCreatedKey(createdApiKey);
        navigator.clipboard.writeText(createdApiKey);
      }
    },
    { deps: [createUserApiKey] }
  );

  useEffect(() => {
    generateUserToken();
  }, [generateUserToken]);

  return (
    <Modal
      title={t(`Account:Apikey:Modal:title`)}
      isOpen={true}
      onCancelClick={closeModal}
      inProgress={loading}
      buttonCancelText={t('close')}
      buttonCancelColor='secondary'
      size='md'
    >
      <div className={cn(styles.accountPage__modal__copyWrapper)}>
        <b className='text-ellipsis'>{createdKey}</b>
        <IconButton
          name='farCopy'
          flat
          onClick={() => navigator.clipboard.writeText(createdKey)}
          data-test-id='Account.KeyCopy'
        />
      </div>
      <div className={cn(styles.accountPage__modal__info)}>
        <Icon name='faInfoCircle' color='primary' />
        <p>{t('Account:Apikey:Modal:info')}</p>
      </div>
    </Modal>
  );
}
