import React, { memo } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { useAppContext } from '../../contexts/appContext';
import { templates } from '../../models/templates';
import { ResponseIcon } from './ChatMessageIcons';
import styles from './style.module.scss';

export const ChatLoader = memo<{ selectedConversationTemplate: string }>(({ selectedConversationTemplate }) => {
  const { state } = useAppContext();
  const { t } = useTranslation();

  return (
    <div
      className={cn(`group`, state.lightMode, styles.chat__messageWrapper, styles[`chat__messageWrapper__response`])}
      style={{ overflowWrap: 'anywhere' }}
    >
      <div className={cn('relative m-auto flex', styles.chat__message)}>
        <ResponseIcon className={styles.chat__message__icon} />
        <div className='flex flex-column'>
          <div className={cn('flex ', styles.chat__message__title)}>
            <div className={styles.chat__message__title__person}>
              {templates.value.templatesMap[selectedConversationTemplate]?.info?.title ?? t('assistantJay')}
            </div>
          </div>
          <span className={cn('w-full cursor-default mt-1', styles.chat__loader)}>{t('chatLoaderText')}</span>
        </div>
      </div>
    </div>
  );
});
ChatLoader.displayName = 'memo(ChatLoader)';
