import React, { MouseEventHandler } from 'react';

import { IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from '../header.module.scss';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  hasSelectedConversation: boolean;
};

export default function ChatHeaderConfirmationPanel({
  onConfirm: handleConfirm,
  onCancel: handleCancel,
  hasSelectedConversation,
}: Props) {
  const handleConfirmEvent: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    if (!hasSelectedConversation) return;
    handleConfirm();
  };

  const handleCancelEvent: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    handleCancel();
  };

  return (
    <>
      <IconButton
        data-test-id='Chat.name_btn_accept'
        className={cn(styles.header__icon)}
        name='farCheck'
        onClick={handleConfirmEvent}
      />
      <IconButton
        data-test-id='Chat.name_btn_cancel'
        className={styles.header__icon}
        name='farTimes'
        onClick={handleCancelEvent}
      />
    </>
  );
}
