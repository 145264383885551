import { useCallback, useEffect } from 'react';

import { TemplateResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { usePromiseProcessing } from '@just-ai/just-ui';
import { signal } from '@preact/signals-react';

import useApiService from '../../services/useApiService';

interface Templates {
  templatesMap: { [template: string]: TemplateResponse };
  templatesList: (TemplateResponse | { template: string })[];
}

export const templates = signal<Templates>({ templatesMap: {}, templatesList: [] });
export const templatesLoaded = signal(false);

export const useTemplates = (locale: 'ru' | 'eng', shouldInitialLoad?: boolean) => {
  const { getTemplatesForLocale } = useApiService();
  const [{ result: loadedTemplates, error: errorTemplates, loading: loadingTemplates }, getTemplatesHandle] =
    usePromiseProcessing(getTemplatesForLocale, {
      throwOnError: true,
    });

  const loadTemplates = useCallback(() => {
    getTemplatesHandle(locale).then(loadedTemplates => {
      templates.value = loadedTemplates;
      templatesLoaded.value = true;
    });
  }, [getTemplatesHandle, locale]);

  useEffect(() => {
    shouldInitialLoad && loadTemplates();
  }, [loadTemplates, shouldInitialLoad]);

  return {
    loadedTemplates,
    errorTemplates,
    loadingTemplates,
    loadTemplates,
  };
};

export const useTelegramTemplates = (locale: string, shouldInitialLoad?: boolean) => {
  const { getTelegramTemplatesForLocale } = useApiService();
  const [{ result: loadedTemplates, error: errorTemplates, loading: loadingTemplates }, getTemplatesHandle] =
    usePromiseProcessing(getTelegramTemplatesForLocale, {
      throwOnError: true,
    });

  const loadTemplates = useCallback(() => {
    getTemplatesHandle(locale).then(loadedTemplates => {
      templates.value = loadedTemplates;
      templatesLoaded.value = true;
    });
  }, [getTemplatesHandle, locale]);

  useEffect(() => {
    shouldInitialLoad && loadTemplates();
  }, [loadTemplates, shouldInitialLoad]);

  return {
    loadedTemplates,
    errorTemplates,
    loadingTemplates,
    loadTemplates,
  };
};
