import { MouseEventHandler, ReactElement } from 'react';
import React from 'react';

import { Button } from '@just-ai/just-ui';

interface Props {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactElement;
}

const SidebarActionButton = ({ handleClick, children }: Props) => (
  <Button flat size='sm' className='color-gray-600' onClick={handleClick}>
    {children}
  </Button>
);

export default SidebarActionButton;
