import React, { memo, useMemo } from 'react';

import { CustomCollapse, useTranslation } from '@just-ai/just-ui';
import classnames from 'classnames';

import { templates } from '../../models/templates';
import { SystemUpdateMessagePart } from '../../types/chat';
import classes from './style.module.scss';

const getDeepKey = (objToSearchIn: Record<string, any>, keyString: string, parentString: string = '') => {
  let result = '';
  for (let key of Object.keys(objToSearchIn)) {
    if (typeof objToSearchIn[key]?.params === 'object') {
      let parentStringDeep = objToSearchIn[key]?.['label'] || objToSearchIn[key]?.['title'] || '';
      const deepResult = getDeepKey(objToSearchIn[key].params, keyString, parentStringDeep);
      if (deepResult) {
        result = deepResult;
        break;
      }
    }
    if (keyString === key) {
      result = `${parentString ? parentString + ' - ' : ''}${objToSearchIn[key]?.['label']}`;
      break;
    }
  }
  return result;
};

export const SystemAppUpdate = memo(
  ({ content, agentTemplateName }: { content: SystemUpdateMessagePart; agentTemplateName: string }) => {
    const { t, tWithCheck } = useTranslation();
    const CalculatedDiff = useMemo(() => {
      if (!templates.value.templatesMap[agentTemplateName]) {
        return '';
      }
      return Object.entries(content.newAppParams).reduce((acc, [keyString, value]) => {
        let label =
          templates.value.templatesMap[agentTemplateName].params?.[keyString]?.['label'] ??
          getDeepKey(templates.value.templatesMap[agentTemplateName].params, keyString);
        switch (typeof value) {
          case 'object':
            if (JSON.stringify(content.oldAppParams[keyString]) !== JSON.stringify(value)) {
              acc += `${label}: ${tWithCheck(`Key:${value}`) || tWithCheck(value) || value}\n`;
            }
            break;
          case 'boolean':
            if (content.oldAppParams[keyString] !== value) {
              acc += `${label}: ${tWithCheck(`Key:Boolean:${value}`) || value}\n`;
            }
            break;
          default:
            if (content.oldAppParams[keyString] !== value && value !== '') {
              acc += `${label}: ${tWithCheck(`Key:${value}`) || tWithCheck(value) || value}\n`;
            }
            break;
        }

        return acc;
      }, '');
    }, [agentTemplateName, content.newAppParams, content.oldAppParams, tWithCheck]);

    if (!CalculatedDiff) {
      return (
        <div className='flex flex-col relative'>
          <div className={classnames('flex-1', classes.chat__messageText)}>
            <div className={classes.SystemAppUpdate_header}>{t('SystemAppUpdate:contextReset')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className='flex flex-col relative'>
        <div className={classnames('flex-1', classes.chat__messageText)}>
          <CustomCollapse
            chevronPosition='left'
            title={t('SystemAppUpdate:appUpdated')}
            className={classes.SystemAppUpdate_main}
            headerClassName={classes.SystemAppUpdate_header}
            toggleIcon='farChevronRight'
            headerIconClassName={classes.SystemAppUpdate_icon}
          >
            <div className='padding-left-28 pre-line'>{CalculatedDiff}</div>
          </CustomCollapse>
        </div>
      </div>
    );
  }
);

SystemAppUpdate.displayName = 'memo(SystemAppUpdate)';
