import React, { memo, useCallback, useState, useRef } from 'react';

import { Modal, Spinner, useTranslation } from '@just-ai/just-ui';
import { signal, useSignalEffect } from '@preact/signals-react';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import styles from './style.module.scss';
import { useAppContext } from '../../contexts/appContext';
import { buildingRequests, findConversationIndex } from '../../models/conversations';
import { dropSilentMessages } from '../../models/conversations/canvas/silentMessage.signal';
import { dropFilesToUpload } from '../../models/conversations/canvas/uploadFiles.signal';
import { setConversationsValue } from '../../models/conversations/signals';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import useApiService from '../../services/useApiService';
import { AgentType, ValidationTemplate } from '../../types/agents';
import { Conversation } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import { processConversationContext, processHistory } from '../../utils/app/conversation';
import TemplateForm from '../Agents/components/TemplateForm';
import { FormBuilderProvider } from '../Agents/components/TemplateForm/useFormBuilder';
import {
  appHasUnsavedChanges,
  resetContextSilent,
  settingsModalIsOpenedFromChat,
} from '../Chat/signals/ChatUpdateSignal';

export const isOpenAgentSettings = signal(false);
export const handleAgentSettingsMobileToggle = () => {
  isOpenAgentSettings.value = !isOpenAgentSettings.value;
};
export const hideAgentSettingsMobileToggle = () => {
  isOpenAgentSettings.value = false;
};

type Props = {
  selectedConversation: Conversation;
  hidden: boolean;
};
export const AgentSettings = memo(({ selectedConversation, hidden }: Props) => {
  const {
    state: { locale },
  } = useAppContext();
  const cancelRefUpdating = useRef<AbortController>();
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState<AgentType | null>(null);
  const [errorState, setErrorState] = useState<ValidationTemplate>(null);

  const settingsForm = useRef<UseFormReturn<FieldValues>>();

  const { t } = useTranslation();

  const { state, addAlert } = useAppContext();
  const { uploadFile, updateUserChat, prevalidateApp } = useApiService();

  const handleAgentUpdate = useCallback(
    async agentData => {
      setLoading(true);
      setErrorState(null);
      const sendData = {
        template: agentData.template,
        params: agentData.params,
      };
      cancelRefUpdating.current = new AbortController();

      try {
        const { data: checkResult } = await prevalidateApp(sendData, cancelRefUpdating.current);
        if (!!checkResult.deniedEntities?.length) {
          setErrorState(checkResult.deniedEntities as ValidationTemplate);
          setUpdateData(null);
          return;
        }
        const { data: newConversation } = await updateUserChat(selectedConversation.id, sendData);
        const updatedConversation = cloneDeep(selectedConversation);
        updatedConversation.history = processHistory(newConversation.history);
        updatedConversation.contextValue = processConversationContext(updatedConversation.history);
        setConversationsValue(conversations => {
          let conversationForUpdateIndex = findConversationIndex(updatedConversation.id);
          if (conversationForUpdateIndex > -1) {
            conversations[conversationForUpdateIndex] = {
              ...newConversation,
              config: { ...updatedConversation.config, params: newConversation.app.params as AgentType['params'] },
              history: updatedConversation.history,
              messageIsStreaming: newConversation.status === 'BUILDING',
              contextValue: updatedConversation.contextValue,
              isCanvasChat:
                !!updatedConversation.externalInstances &&
                Object.keys(updatedConversation.externalInstances).length > 0,
            };
          }
        });

        setUpdateData(null);
        appHasUnsavedChanges.value = false;
        settingsForm.current?.reset(newConversation.app.params);
        isOpenAgentSettings.value = false;
        if (!resetContextSilent.value) addAlert(t('agentUpdateSuccess'), 'success');
        guideTourEvent$.next('AgentSettings:Update:Submit');
      } catch (error) {
        addAlert(t('agentUpdateErr'));
      } finally {
        setLoading(false);
      }
    },
    [addAlert, prevalidateApp, selectedConversation, t, updateUserChat]
  );

  const handleUploadFile = useCallback(
    async data => {
      setLoading(true);
      try {
        const { data: fileResponse } = await uploadFile(data);
        return { fileId: fileResponse.id, fileName: fileResponse.name, url: fileResponse.url };
      } catch (error) {
        addAlert(t('fileUploadErr') + '\n' + (error as AxiosError).message);
      } finally {
        setLoading(false);
      }
    },
    [addAlert, t, uploadFile]
  );

  const openSubmitModal = useCallback((data: AgentType) => {
    setUpdateData(data);
  }, []);

  useSignalEffect(() => {
    if (resetContextSilent.value) {
      handleAgentUpdate(selectedConversation.app).then(() => {
        addAlert(t('agentContextReloadSuccess'), 'success');
        resetContextSilent.value = false;
        dropSilentMessages();
        dropFilesToUpload();
      });
    }
  });

  const handleCancelClick = useCallback(() => {
    setUpdateData(null);
    if (!settingsModalIsOpenedFromChat.value) {
      settingsForm.current?.reset();
    }
    settingsModalIsOpenedFromChat.value = false;
  }, []);

  if (selectedConversation.app.template === 'toolAssistant') return null;

  return (
    <div
      className={cn(styles.agentSettings__wrapper, {
        'd-none': hidden,
        visible: isOpenAgentSettings.value,
      })}
    >
      {loading && <Spinner bluredBackground />}

      <FormBuilderProvider
        data={{
          userId: state.userId,
          template: { ...selectedConversation.config, id: selectedConversation.app.id },
          isEdit: true,
          isOpenFromTelegram: false,
          submit: openSubmitModal,
          cancel: () => {},
        }}
      >
        {({ formMethods, onSubmit }) => {
          settingsForm.current = formMethods;
          return (
            <>
              <TemplateForm
                isOpenFromTelegram={false}
                formMethods={formMethods}
                cancel={() => {}}
                isEdit
                locale={locale}
                template={{
                  ...{
                    ...selectedConversation.config,
                    name: selectedConversation.name,
                    params:
                      selectedConversation.config.params || (selectedConversation.app.params as Record<string, any>),
                  },
                  id: selectedConversation.app.id,
                }}
                submit={onSubmit}
                handleFileUpload={handleUploadFile}
                disabledUpdateButton={
                  buildingRequests.value.findIndex(buildingConv => buildingConv.id === selectedConversation.id) > -1
                }
                isDirtyCallback={isDirty => {
                  appHasUnsavedChanges.value = isDirty;
                }}
                errorState={errorState}
              />
              {updateData && (
                <Modal
                  className='mobileBottomModal'
                  isOpen={!!updateData}
                  onActionClick={() => handleAgentUpdate(updateData)}
                  onCancelClick={handleCancelClick}
                  title={t('agentUpdateModalTitle')}
                  buttonSubmitTestId='AgentSettings:Update:Submit'
                  buttonCancelText={t('cancel')}
                  buttonCancelTestId='AgentSettings:Update:Cancel'
                  buttonSubmitText={t('Confirm:Template:Update')}
                  buttonCancelColor={isMobile() ? 'secondary' : 'primary'}
                >
                  {t('agentUpdateModalText')}
                </Modal>
              )}
            </>
          );
        }}
      </FormBuilderProvider>
    </div>
  );
});

AgentSettings.displayName = 'AgentSettings';
