import React, { FC, useCallback, useEffect, useRef } from 'react';

import { IconButton, useToggle } from '@just-ai/just-ui';

export const AudioPlayerButton: FC<{ audio?: string }> = ({ audio }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, , setStop, togglePlaying] = useToggle();

  const onFinished = useCallback(() => {
    setStop();
    if (audioRef.current) audioRef.current.currentTime = 0;
  }, [setStop]);

  const onClick = useCallback(() => {
    if (!audioRef.current) return;
    togglePlaying();
    if (isPlaying) audioRef.current.pause();
    else audioRef.current.play();
  }, [isPlaying, togglePlaying]);

  useEffect(() => {
    if (audioRef.current) {
      setStop();
      audioRef.current.currentTime = 0;
    }
  }, [audio, setStop]);

  return (
    <>
      <IconButton
        color='secondary'
        flat
        outline
        name={isPlaying ? 'faPause' : 'faPlay'}
        onClick={onClick}
        disabled={!audio}
        style={{ paddingTop: 9, paddingBottom: 9 }}
      />
      {audio ? (
        <audio ref={audioRef} src={audio} onEnded={onFinished} className='absolute' style={{ left: -10000 }} />
      ) : null}
    </>
  );
};
