import React, { FC } from 'react';

import { Button, Icon, ICONS } from '@just-ai/just-ui';
import cn from 'classnames';

import classes from './style.module.scss';

interface Props {
  text: string;
  iconLeft?: ICONS;
  iconRight?: ICONS;
  onClick?: () => void;
  disabled?: boolean;
  dataTestId?: string;
  className?: string;
}

export const SidebarButton: FC<Props> = ({ text, iconLeft, iconRight, onClick, dataTestId, disabled, className }) => {
  return (
    <Button
      disabled={disabled}
      data-test-id={dataTestId}
      className={cn('flex w-full justify-start gap-8', classes.sidebarButton, className)}
      onClick={onClick}
      flat
    >
      {iconLeft && <Icon name={iconLeft} color='secondary' />}
      <span className='flex flex-grow-1 justify-start text-default'>{text}</span>
      {iconRight && <Icon name={iconRight} color='secondary' />}
    </Button>
  );
};
