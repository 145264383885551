import { IconProps } from '@just-ai/just-ui';

import { isAlpha } from '../../isAlpha';
import { isX5 } from '../../isX5';
import { hasFeature } from '../../models/currentUser';

export type CategoryValue =
  | 'all'
  | 'beta'
  | 'legal'
  | 'popular'
  | 'favor'
  | 'marketing'
  | 'content'
  | 'texts'
  | 'assistants'
  | 'images'
  | 'data'
  | 'it'
  | 'audio'
  | 'database'
  | 'directAccess';

export type Category = { value: CategoryValue; icon: IconProps['name']; requiredFeature?: string };

export const categories: Category[] = [
  { value: 'popular', icon: 'faThumbsUp' },
  { value: 'marketing', icon: 'faRocketLaunch' },
  { value: 'legal', icon: 'faBalanceScale' },
  { value: 'assistants', icon: 'faLightbulb' },
  { value: 'content', icon: 'faShapes' },
  { value: 'data', icon: 'faFileInvoice' },
  { value: 'directAccess', icon: 'faHeadSideBrain' },
  { value: 'all', icon: 'faGlobe' },
  { value: 'favor', icon: 'faStar' },
  { value: 'beta', icon: 'fadStarChristmas', requiredFeature: 'copilot_category_beta_access' },
];

const x5CategoryDeprecated = ['legal'];
export const categoriesX5: Category[] = categories.filter(category => !x5CategoryDeprecated.includes(category.value));

export const categoriesAlpha: Category[] = [
  { value: 'all', icon: 'faGlobe' },
  { value: 'marketing', icon: 'faRocketLaunch' },
  { value: 'it', icon: 'faLaptopCode' },
  { value: 'content', icon: 'faShapes' },
  { value: 'data', icon: 'faFileInvoice' },
  { value: 'favor', icon: 'faStar' },
  { value: 'directAccess', icon: 'faHeadSideBrain' },
  { value: 'database', icon: 'faDatabase' },
];
export const localCategories = (isAlpha ? categoriesAlpha : isX5 ? categoriesX5 : categories).filter(category => {
  if (category.requiredFeature) return hasFeature(category.requiredFeature);
  return category;
});
export const localCategoriesMap = localCategories.map(category => category.value);
