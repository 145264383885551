import React, { memo } from 'react';

import { Icon, useTranslation } from '@just-ai/just-ui';

function FilesHeader() {
  const { t } = useTranslation();
  return (
    <div className='flex-column gap-8'>
      <h1>{t('Files:Page:title')}</h1>
      <p>{t('Files:Page:subtitle')}</p>
      <a
        className='font-weight-600 color-primary d-flex align-items-center gap-4 text-decoration-none'
        href={t('Files:Page:link-url')}
      >
        <Icon name='farQuestionCircle' />
        {t('Files:Page:link-text')}
      </a>
    </div>
  );
}

export default memo(FilesHeader);
