import { AxiosInstance } from 'axios';

import { DefaultApi } from '../generated/billing';

export default class BillingService {
  static readonly BASE_PATH = '';
  private billingApi: DefaultApi;

  constructor(axios: AxiosInstance) {
    this.billingApi = new DefaultApi({}, BillingService.BASE_PATH, axios);
  }

  async getBalance() {
    const { data } = await this.billingApi.getBalances();
    return data;
  }

  async buyPackage(packageName: string) {
    const { data } = await this.billingApi.buyCCPackage({
      packageName,
      redirectUrl: window.location.href,
    });
    return data;
  }
}
