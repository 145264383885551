import { useCallback, useState } from 'react';

import { useDebounceFn } from '@just-ai/just-ui';

function useDelayToggle(defaultValue: boolean, time = 300): [boolean, () => void] {
  const [status, setStatus] = useState(defaultValue);
  const reset = useDebounceFn(() => setStatus(defaultValue), time);

  const toggle = useCallback(() => {
    setStatus(true);
    reset();
  }, [reset]);

  return [status, toggle];
}

export default useDelayToggle;
