import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';

import { Conversation, ExpenseField, ExpensesHistoryResponse } from '@just-ai/api/dist/generated/CopilotGateway/api';
import { Tabs, useTranslation, usePromiseProcessing, Button, Spinner } from '@just-ai/just-ui';
import axios from 'axios';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import { DateTime } from 'luxon';
import { currentUser, hasPermission } from 'models/currentUser';
import useApiService from 'services/useApiService';

import styles from './styles.module.scss';
import { useAppContext } from '../../../contexts/appContext';
import { addAlert } from '../../../models/alerts';
import { goToConversation } from '../../../routes';
import { AnalyticsDatePicker } from '../components/AnalyticsDatePicker';
import { BarChart } from '../components/BarChart';
import { DonutChart } from '../components/DonutChart';
import { AnalyticsTable } from '../components/Tables';
import { ReplenishTable } from '../components/Tables/ReplenishTable';
import { UserExpenses } from '../components/UserExpenses';
import { downloadReportAsFile } from '../components/utils';

export const TabsTypes = {
  EXPENSES: 'expenses',
  EXPENSES_USERS: 'expensesUsers',
  EXPENSES_DAILY: 'expensesDaily',
  REPLENISH: 'replenish',
};

const defaultDate = {
  startDate: DateTime.now().startOf('day').minus({ week: 1 }).toJSDate(),
  endDate: DateTime.now().endOf('day').toJSDate(),
};

export type DrawerData = {
  id?: number;
  email?: string;
  name?: string;
  selectedChat?: Conversation;
};

export const AnalyticsTab = memo(() => {
  const { t } = useTranslation();

  const { state: appState } = useAppContext();

  const [activeTab, setTab] = useState(TabsTypes.EXPENSES);
  const [expensesData, setExpensesData] = useState<ExpensesHistoryResponse>();
  const [userExpensesData, setuserExpensesData] = useState<ExpensesHistoryResponse>();

  const [drawerData, setDrawerData] = useState<DrawerData | null>(null);

  const { paginationInfo: paginationInfoUsers, changePage: changePageUsers } = usePagination({
    pageNum: userExpensesData?.page.number ?? 0,
    pageSize: userExpensesData?.page.size ?? 10,
    totalCount: userExpensesData?.page.totalElements ?? 0,
    totalPages: userExpensesData?.page.totalPages ?? 0,
  });

  const { paginationInfo, changePage } = usePagination({
    pageNum: expensesData?.page.number ?? 0,
    pageSize: expensesData?.page.size ?? 10,
    totalCount: expensesData?.page.totalElements ?? 0,
    totalPages: expensesData?.page.totalPages ?? 0,
  });

  const { getExpensesHistory, getExpensesReportUrl, getUserChat } = useApiService();
  const [dates, setDates] = useState(defaultDate);
  const [search, setSearch] = useState('');

  const setSearchHandler = useCallback((value: string) => {
    setSearch(value.trimStart());
  }, []);

  const isAdmin = hasPermission('COPILOT_VIEW_ACCOUNT_ANALYTICS');
  const hasInvitedUsers = Boolean(
    currentUser.value?.numberOfUsersInAccount && currentUser.value?.numberOfUsersInAccount > 1
  );

  const [{ loading }, getDefaultExpensesData] = usePromiseProcessing(
    async () => {
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.Date, ExpenseField.Conversation],
        page: paginationInfo.pageNum,
        size: paginationInfo.pageSize,
        locale: appState.locale,
        userId: currentUser.value?.userId,
        sort: ['date', 'desc'],
        search,
      });
      setExpensesData(data);
    },
    {
      deps: [
        dates,
        getExpensesHistory,
        paginationInfo.pageNum,
        paginationInfo.pageSize,
        search,
        currentUser.value?.userId,
      ],
    }
  );

  const [{ loading: loadingUsersData }, getUserExpensesData] = usePromiseProcessing(
    async () => {
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.User],
        page: paginationInfoUsers.pageNum,
        size: paginationInfoUsers.pageSize,
        locale: appState.locale,
        sort: ['tokenAmount', 'desc'],
        search,
      });
      setuserExpensesData(data);
    },
    {
      deps: [dates, getExpensesHistory, paginationInfoUsers.pageNum, paginationInfoUsers.pageSize, search],
    }
  );

  useEffect(() => {
    getDefaultExpensesData();
    if (hasInvitedUsers) {
      getUserExpensesData();
    }
  }, [getDefaultExpensesData, getUserExpensesData, hasInvitedUsers]);

  const tabs = useMemo(() => {
    if (!isAdmin && !hasInvitedUsers)
      return [
        {
          value: TabsTypes.EXPENSES,
          name: t('Analytics:Tab:MyExpenses'),
        },
      ];
    const adminTabs = hasInvitedUsers
      ? [
          {
            value: TabsTypes.EXPENSES,
            name: t('Analytics:Tab:MyExpenses'),
          },
          {
            value: TabsTypes.EXPENSES_USERS,
            name: t('Analytics:Tab:UserExpenses'),
          },
          {
            value: TabsTypes.EXPENSES_DAILY,
            name: t('Analytics:Tab:ExpensesByDay'),
          },
          {
            value: TabsTypes.REPLENISH,
            name: t('Analytics:Tab:Replenishment'),
          },
        ]
      : [
          {
            value: TabsTypes.EXPENSES,
            name: t('Analytics:Tab:MyExpenses'),
          },
          {
            value: TabsTypes.EXPENSES_DAILY,
            name: t('Analytics:Tab:ExpensesByDay'),
          },
          {
            value: TabsTypes.REPLENISH,
            name: t('Analytics:Tab:Replenishment'),
          },
        ];
    return adminTabs;
  }, [hasInvitedUsers, isAdmin, t]);

  const handleTabChange = useCallback(
    (value: string) => {
      setTab(value);
      changePage(0);
    },
    [changePage]
  );

  const [{ loading: processingRedirect }, toggleDrawer] = usePromiseProcessing(
    async (value?: DrawerData, owner?: boolean) => {
      if (owner && value?.selectedChat) {
        try {
          const { data } = await getUserChat(value.selectedChat.id);
          if (data?.id) {
            return goToConversation(data.id);
          }
        } catch (error) {}
      }
      const processedValue = value
        ? {
            email: currentUser.value?.email,
            id: currentUser.value?.userId,
            name: currentUser.value?.fullName,
            ...value,
          }
        : null;
      setDrawerData(processedValue);
    },
    { deps: [getUserChat] }
  );

  const [{ loading: reportLoading }, getExpensesReportLocal] = usePromiseProcessing(
    async () => {
      const url = await getExpensesReportUrl({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        locale: appState.locale,
      });
      await downloadReportAsFile(
        url,
        `Report: ${dates.startDate.toLocaleDateString(appState.locale)} - ${dates.endDate.toLocaleDateString(
          appState.locale
        )}.xlsx`
      );
    },
    {
      deps: [dates, getExpensesReportUrl],
      onError: async error => {
        if (axios.isAxiosError(error)) {
          const errorBlob: Blob = error.response?.data;
          const errorObj = JSON.parse(await errorBlob.text());
          addAlert(t(errorObj.error));
        }
      },
    }
  );

  if (loading || loadingUsersData || processingRedirect) {
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='flex w-full flex-column'>
      <div className='flex w-full justify-between items-center'>
        <AnalyticsDatePicker dates={dates} setDates={setDates} />
        <Button
          color='secondary'
          outline
          size='sm'
          className='d-none d-sm-flex'
          iconRight='farArrowToBottom'
          disabled={reportLoading}
          onClick={getExpensesReportLocal}
        >
          {t('Analytics:Button:Download')}
        </Button>
      </div>
      {hasPermission('COPILOT_VIEW_ACCOUNT_ANALYTICS') ? (
        <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} className={styles.tabs} />
      ) : (
        <hr />
      )}
      {activeTab === TabsTypes.EXPENSES && (
        <>
          {expensesData && expensesData.page.totalElements > 0 && (
            <DonutChart dates={dates} userId={currentUser.value?.userId} />
          )}
          <AnalyticsTable data={expensesData} dataType='chats' openDrawer={data => toggleDrawer(data, true)} />
          {paginationInfo.totalPages >= 1 && (
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              changePage={changePage}
              totalCount={paginationInfo.totalCount}
            />
          )}
        </>
      )}
      {activeTab === TabsTypes.EXPENSES_USERS && (
        <>
          {userExpensesData && userExpensesData.page.totalElements > 0 && <DonutChart dates={dates} />}
          <AnalyticsTable
            data={userExpensesData}
            openDrawer={toggleDrawer}
            dataType='users'
            searchHandler={setSearchHandler}
            searchVal={search}
          />
          {paginationInfoUsers.totalPages >= 1 && (
            <Pagination
              page={paginationInfoUsers.pageNum}
              size={paginationInfoUsers.pageSize}
              changePage={changePageUsers}
              totalCount={paginationInfoUsers.totalCount}
            />
          )}
        </>
      )}
      {activeTab === TabsTypes.EXPENSES_DAILY && <BarChart dates={dates} />}
      {activeTab === TabsTypes.REPLENISH && <ReplenishTable dates={dates} />}

      {drawerData && <UserExpenses drawerData={drawerData} close={toggleDrawer} initialDates={dates} />}
    </div>
  );
});

AnalyticsTab.displayName = 'AnalyticsTab';
