import React from 'react';

import { TranslationContextProvider } from '@just-ai/just-ui';
import localize, { t, tWithCheck } from 'localization';
import ReactDOM from 'react-dom';

import './sentry';

import App from './App';
import { isTovie } from './isTovie';

localize.setLocale(isTovie ? 'eng' : 'ru');
ReactDOM.render(
  <TranslationContextProvider t={t} tWithCheck={tWithCheck} getLocale={localize.getLocale}>
    <App />
  </TranslationContextProvider>,
  document.getElementById('root')
);
