import React, { useCallback, useEffect, useState } from 'react';

import { Conversation, ExpenseField, ExpensesHistoryResponse } from '@just-ai/api/dist/generated/CopilotGateway';
import { Button, Icon, Spinner, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import axios from 'axios';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import useApiService from 'services/useApiService';

import styles from './styles.module.scss';
import { useAppContext } from '../../../../contexts/appContext';
import { addAlert } from '../../../../models/alerts';
import { currentUser } from '../../../../models/currentUser';
import { Drawer } from '../../../Drawer';
import { DrawerData } from '../../tabs/AnalyticsTab';
import { AnalyticsDatePicker } from '../AnalyticsDatePicker';
import { ChatHistoryWrapper } from '../ChatHistoryDrawer';
import { DonutChart } from '../DonutChart';
import { AnalyticsTable } from '../Tables';
import { downloadReportAsFile } from '../utils';

export const UserExpenses = ({
  drawerData,
  close,
  initialDates,
}: {
  drawerData: DrawerData;
  close: () => void;
  initialDates: Range;
}) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState(initialDates);

  const [selectedChat, setSelectedChat] = useState<Conversation | undefined>(drawerData.selectedChat);

  const [userExpenses, setUserExpenses] = useState<ExpensesHistoryResponse>();
  const { getExpensesHistory, getExpensesReportUrl } = useApiService();
  const { state: appState } = useAppContext();
  const { paginationInfo, changePage } = usePagination({
    pageNum: userExpenses?.page.number ?? 0,
    pageSize: userExpenses?.page.size ?? 10,
    totalCount: userExpenses?.page.totalElements ?? 0,
    totalPages: userExpenses?.page.totalPages ?? 0,
  });

  const [{ loading }, getDefaultExpensesData] = usePromiseProcessing(
    async () => {
      if (!drawerData) return;
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.Date, ExpenseField.Conversation],
        userId: drawerData.id,
        locale: appState.locale,
        page: paginationInfo.pageNum,
        size: paginationInfo.pageSize,
      });
      setUserExpenses(data);
    },
    {
      deps: [dates, getExpensesHistory, drawerData.id, paginationInfo.pageNum, paginationInfo.pageSize],
    }
  );

  useEffect(() => {
    getDefaultExpensesData();
  }, [getDefaultExpensesData]);

  const handleCloseDrawer = useCallback(() => {
    close();
  }, [close]);

  const [{ loading: reportLoading }, getExpensesReportLocal] = usePromiseProcessing(
    async () => {
      if (!drawerData) return;
      const url = await getExpensesReportUrl({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        userId: drawerData.id,
        locale: appState.locale,
      });
      await downloadReportAsFile(
        url,
        `Report: ${dates.startDate.toLocaleDateString(appState.locale)} - ${dates.endDate.toLocaleDateString(
          appState.locale
        )}.xlsx`
      );
    },
    {
      deps: [dates, getExpensesReportUrl, drawerData.id],
      onError: async error => {
        if (axios.isAxiosError(error)) {
          const errorBlob: Blob = error.response?.data;
          const errorObj = JSON.parse(await errorBlob.text());
          addAlert(t(errorObj.error));
        }
      },
    }
  );

  const handleBackClick = () => {
    if (drawerData.id !== currentUser.value?.userId) return setSelectedChat(undefined);
    return handleCloseDrawer();
  };

  if (!drawerData.id && !selectedChat) return null;

  return (
    <>
      <Drawer isOpen={Boolean(drawerData)} onClose={handleCloseDrawer}>
        {loading && <Spinner />}
        <div className={styles.UserExpenses__header}>
          {selectedChat ? (
            <>
              <Icon name='farArrowLeft' size='lg' onClick={handleBackClick} />
              <h1>{t('Analytics:chatHistory')}</h1>
              <Icon name='falTimes' size='lg' onClick={handleCloseDrawer} />
            </>
          ) : (
            <>
              <h1>{t('Analytics:UserExpenses')}</h1>
              <Icon name='falTimes' size='lg' onClick={handleCloseDrawer} />
            </>
          )}
        </div>
        <div className={styles.UserExpenses__content}>
          <div className={styles.UserExpenses__userdata}>
            <h3>{drawerData.email}</h3>
            <h3>{drawerData.name}</h3>
          </div>
          {selectedChat ? (
            <ChatHistoryWrapper conversation={selectedChat} userData={drawerData} />
          ) : (
            <>
              <div className='flex w-full justify-between items-center'>
                <AnalyticsDatePicker dates={dates} setDates={setDates} />
                <Button
                  color='secondary'
                  outline
                  size='sm'
                  className='d-none d-sm-flex'
                  iconRight='farArrowToBottom'
                  disabled={reportLoading}
                  onClick={getExpensesReportLocal}
                >
                  {t('Analytics:Button:Download')}
                </Button>
              </div>
              <hr />
              {userExpenses && userExpenses.page.totalElements > 0 && (
                <DonutChart dates={dates} userId={drawerData.id} />
              )}
              <AnalyticsTable
                data={userExpenses}
                dataType='chats'
                openDrawer={value => setSelectedChat(value?.selectedChat)}
              />
              {paginationInfo.totalPages > 1 && (
                <Pagination
                  page={paginationInfo.pageNum}
                  size={paginationInfo.pageSize}
                  changePage={changePage}
                  totalCount={paginationInfo.totalCount}
                />
              )}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};
