export const corporateLocalization = {
  corporateHeader: {
    eng: '',
    ru: '#{config.productName} для бизнеса',
  },
  corporateHeaderSub: {
    eng: '',
    ru: 'Внедряйте GenAI-решения для роста эффективности команд, подразделений и целых бизнес-юнитов',
  },
  llmsListHeader: {
    eng: '',
    ru: '#{config.productName} — корпоративный AI-ассистент на основе генеративных нейросетей для сотрудников вашей компании',
  },
  llmsList: {
    eng: '',
    ru: 'ChatGPT • GigaChat • YandexGPT 3 • DALL·E 3 • Whisper • Stable Diffusion • Mistral AI',
  },
  'Tab:plans': {
    eng: '',
    ru: 'Корпоративные тарифы',
  },
  'Tab:integrations': {
    eng: '',
    ru: 'Интеграции',
  },
  'Tab:analytics': {
    eng: '',
    ru: 'Управление и аналитика',
  },
  'Tab:onprem': {
    eng: '',
    ru: 'On-premise',
  },
  'TabsContent:plans': {
    eng: '',
    ru: 'Мы предлагаем гибкие корпоративные тарифы и скидки при большом объеме трафика. Получите доступ к российским и зарубежным нейросетевым моделям, а также к +20 AI‑приложениям с возможностью оплаты в рублях и по безналичному расчету.',
  },
  'TabsContent:integrations': {
    eng: '',
    ru: 'API Jay Copilot позволяет разрабатывать и интегрировать AI-приложения в ваши системы, а также реализовывать собственные приложения с использованием сразу нескольких популярных нейросетевых моделей.',
  },
  'TabsContent:analytics': {
    eng: '',
    ru: 'Функциональность ролевых моделей позволяет настраивать доступ ваших сотрудников к различным приложениям, а инструменты аналитики использования сервиса помогут вам оценить эффективность и продуктивность работы команды.',
  },
  'TabsContent:onprem': {
    eng: '',
    ru: 'Мы предлагаем комплексное решение для оперирования моделями в контуре и дальнейшего вывода LLM в продакшн. Совместное использование продуктов Caila и Jay Copilot обеспечит безопасность данных и масштабируемость вашего проекта.',
  },
  contactManager: {
    eng: '',
    ru: 'Связаться с менеджером',
  },
  docsCorp: {
    eng: '',
    ru: 'Документация: #{config.productName} API',
  },
  genAiSolutions: {
    eng: '',
    ru: 'Генеративные AI-решения от Just AI',
  },
  'solutionsBlock:title:database': {
    eng: '',
    ru: 'База знаний',
  },
  'solutionsBlock:title:caila': {
    eng: '',
    ru: 'Caila',
  },
  'solutionsBlock:title:jguard': {
    eng: '',
    ru: 'Jay Guard',
  },
  'solutionsBlock:info:database': {
    eng: '',
    ru: 'Решение для расширения знаний LLM на основе ваших корпоративных данных',
  },
  'solutionsBlock:info:caila': {
    eng: '',
    ru: 'Платформа для агрегации, хостинга, эксплуатации и дообучения LLM и других ML-сервисов',
  },
  'solutionsBlock:info:jguard': {
    eng: '',
    ru: 'Решение для контролируемого и безопасного использования облачных нейросетевых моделей',
  },
  'solutionsBlock:btn:database': {
    eng: '',
    ru: 'Оставить заявку',
  },
  'solutionsBlock:btn:caila': {
    eng: '',
    ru: 'Узнать больше',
  },
  'solutionsBlock:btn:jguard': {
    eng: '',
    ru: 'Узнать больше',
  },
};
