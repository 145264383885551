import React, { FC } from 'react';

import cn from 'classnames';
import { Control } from 'react-hook-form';

import FileField from './FormFields/FileField';
import FormSelect from './FormFields/FormSelect';
import HelperField from './FormFields/HelperField';
import Prompt from './FormFields/Prompt';
import { Radio } from './FormFields/Radio';
import { FormSlider } from './FormFields/Slider';
import { StringArray } from './FormFields/StringArray';
import Switch from './FormFields/Switch';
import TextField from './FormFields/TextField';
import UrlField from './FormFields/UrlField';
import classes from './FromBuilder.module.scss';
import { FormBuilderField } from './types';
import { TemplateFormFieldController } from './WithTemplateFormControllerHOC';

const fieldsMap: Record<string, FC<FormBuilderField> | undefined> = {
  prompt: Prompt,
  select: FormSelect,
  selectWithAudio: FormSelect,
  stringArray: StringArray,
  switch: Switch,
  file: FileField,
  text: TextField,
  slider: FormSlider,
  link: UrlField,
  video: UrlField,
  card: Radio,
  helper: HelperField,
};

export type FormBuilderProps = {
  isEdit?: boolean;
  param: any;
  data: any;
  handleFileUpload: FormBuilderField['handleFileUpload'];
  control: Control;
  disabled?: boolean;
  error?: boolean;
};

const FormBuilder = (formBuilderProps: FormBuilderProps) => {
  const { param, handleFileUpload, data, isEdit, control, disabled, error } = formBuilderProps;
  if (param.type === 'group') {
    return (
      <div
        className={cn(
          classes.onEmptyGroupHide,
          'from-group-gray bg-color-gray-100 padding-16 flex-column gap-16 border-radius-4'
        )}
      >
        <b>{param.title}</b>
        <div className={cn(classes.groupParams, 'd-flex flex-column gap-24')}>
          {Object.keys(param.params).map(fieldName => (
            <TemplateFormFieldControllerFormBuilder
              {...formBuilderProps}
              key={fieldName}
              param={{ ...param.params[fieldName], name: fieldName }}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
    );
  }

  const Cmp = fieldsMap[param.type] || fieldsMap['text']!;
  return (
    <Cmp
      control={control}
      isEdit={isEdit}
      data={data}
      handleFileUpload={handleFileUpload}
      name={param.name}
      voiceAudios={param.type === 'selectWithAudio' ? data.voiceAudios : undefined}
      disabled={disabled}
      error={error}
      {...param}
    />
  );
};

const TemplateFormFieldControllerFormBuilder = TemplateFormFieldController(FormBuilder);
export default TemplateFormFieldControllerFormBuilder;
