import { useEffect, useRef, useState } from 'react';

import { saveNewTitle } from '../../../models/conversations';
import { Conversation } from '../../../types/chat';
import { KeyValuePair } from '../../../types/data';

export default function useConversationRenameAction({
  selectedConversation,
  submitConversationUpdate,
}: {
  selectedConversation: Conversation;
  submitConversationUpdate: (conversation: Conversation, data: KeyValuePair | KeyValuePair[]) => void;
}) {
  const [isRenaming, setIsRenaming] = useState(false);
  const renamingValueRef = useRef('');

  useEffect(() => {
    setIsRenaming(false);
  }, [selectedConversation.id]);

  return {
    renamingValueRef,
    isRenaming,
    handleTriggerRename: () => setIsRenaming(true),
    handleCancelRename: () => setIsRenaming(false),
    handleConfirmRename: () => {
      const confirmedName = renamingValueRef.current;
      setIsRenaming(false);
      submitConversationUpdate(selectedConversation, [
        { key: 'name', value: confirmedName },
        { key: 'config', value: { ...selectedConversation.config, name: confirmedName } },
      ]);
      saveNewTitle({ id: selectedConversation.id, title: confirmedName });
    },
  };
}
