import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { ResourceToAccess } from '@just-ai/api/dist/generated/CopilotGateway';
import { Modal, useTranslation, usePromiseProcessing, Icon } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import { loadStripe } from '@stripe/stripe-js';
import { GA } from 'utils/app/common';

import PackageBlock from './PackageBlock';
import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';
import { TOKENS_BACKEND_MULT } from '../../api/constants';
import { PaymentSystemEnum } from '../../api/generated/billing';
import { useAppContext } from '../../contexts/appContext';
import { recursivePaymentProcessingPolling } from '../../hooks/showBilling';
import { isAlpha } from '../../isAlpha';
import { isTovie } from '../../isTovie';
import localize from '../../localization';
import { currentUser, hasPermission } from '../../models/currentUser';
import useApiService from '../../services/useApiService';
import RequestFeatureModal, { RequestFeatureModalInputs } from '../RequestFeatureModal';

type Props = {
  isShown: boolean;
  type: 'payment' | 'outOfTokens';
  close: () => void;
};

const packages = [
  {
    name: '370',
    nameNum: 370,
    value: 'copilot_tokens_500rub_web',
    price: '500',
    priceNum: 500,
  },
  {
    name: '1 058',
    nameNum: 1058,
    value: 'copilot_tokens_1000rub_web',
    price: '1 000',
    priceNum: 1000,
  },
  {
    name: '7 897',
    nameNum: 7897,
    value: 'copilot_tokens_5000rub_web',
    price: '5 000',
    priceNum: 5000,
  },
  { name: 'corporate', value: 'corporate' },
];

const BillingModal = memo(function BillingModal(props: Props) {
  const { isShown, close, type } = props;
  const { dispatch, addAlert, state } = useAppContext();
  const windowRef = useRef<Window | null>(null);
  const balance = state.balance;

  const { t, getLocale } = useTranslation();

  const { getBalance, buyPackage, sendUserActionToAnalytics, sendUserRequestForFeature } = useApiService();

  const [selectedPackage, setSelectedPackage] = useState(packages[1].value);

  const [requestModalShown, setRequestModalShown] = useState(false);

  const [{ loading }, getBalanceLocal] = usePromiseProcessing(
    async () => {
      const balanceRes =
        hasPermission('COPILOT_VIEW_BALANCES') && appOptions.value?.billingEnabled
          ? await getBalance()
          : { balances: [] };
      const summaryBalance = balanceRes.balances
        ?.filter(balance => balance.tokenType !== 'onboarding')
        .reduce((acc, curr) => {
          return acc + (curr.tokenAmount || 0);
        }, 0);
      dispatch({ field: 'balance', value: summaryBalance || 0 });
    },
    { deps: [getBalance] }
  );

  const [{ loading: buyLoading }, buyPackageLocalApi] = usePromiseProcessing(
    async (name: string) => {
      if (name === 'corporate') {
        return setRequestModalShown(true);
      }
      GA('button_payment', name);
      const paymentInfo = await buyPackage(name);
      sendUserActionToAnalytics({
        eventName: 'TryToPay',
        eventValue: {
          paymentSum: packages.find(pkg => pkg.name === name)?.price,
          tokensSum: name,
        },
      });
      switch (paymentInfo.paymentSystem) {
        case PaymentSystemEnum.YANDEXCASH: {
          if (paymentInfo.redirectUrl) {
            close();
            recursivePaymentProcessingPolling();
            if (windowRef.current) {
              windowRef.current.location = new URL(paymentInfo.redirectUrl).toString();
            }
            return;
          }
          break;
        }
        case PaymentSystemEnum.STRIPE: {
          if (paymentInfo.stripePublicKey) {
            let stripe = loadStripe(paymentInfo.stripePublicKey);
            stripe.then(payload => {
              recursivePaymentProcessingPolling();
              payload && payload.redirectToCheckout({ sessionId: paymentInfo.stripeSessionId || '' });
              close();
            });
          }
          break;
        }
      }
    },
    {
      onError: error => {
        addAlert(`Error buying package \n ${error}`, 'error');
        AppLogger.error({
          message: `Error buying package \n ${error}`,
        });
      },
      deps: [buyPackage, addAlert, close],
    }
  );

  const buyPackageLocal = useCallback(() => {
    if (selectedPackage !== 'corporate') {
      windowRef.current = window.open();
    }
    buyPackageLocalApi(selectedPackage);
  }, [buyPackageLocalApi, selectedPackage]);

  useEffect(() => {
    if (isShown)
      sendUserActionToAnalytics({
        eventName: 'PressPayment',
        eventValue: {
          paymentType: props.type,
        },
      });
  }, [isShown, props.type, sendUserActionToAnalytics]);

  useEffect(() => {
    if (isShown && !state.balance && !isAlpha) {
      getBalanceLocal();
    }
  }, [getBalanceLocal, isShown, state.balance]);

  const sendRequestForCorporate = useCallback(
    async (data: RequestFeatureModalInputs) => {
      await sendUserRequestForFeature({
        ...data,
        industry: t(`Modal_RequestFeature:option:${data.industry}`),
        fullName: data.name,
        resourceType: ResourceToAccess.CorporateAccount,
        phone: data.phone.code + data.phone.number,
      });
      addAlert(t('RequestForFeature'), 'success');
      setRequestModalShown(false);
      close();
    },
    [addAlert, close, sendUserRequestForFeature, t]
  );

  const balanceToDisplay = balance
    ? (balance / TOKENS_BACKEND_MULT).toLocaleString(getLocale(), { maximumFractionDigits: 3 })
    : '0';

  if (requestModalShown)
    return (
      <RequestFeatureModal
        type='paywall'
        title={t('Modal_RequestFeatureFor_corporate:title')}
        infoText={t('Modal_RequestFeatureFor_corporate:info')}
        sendRequestForTemplate={sendRequestForCorporate}
        isRequestModalOpen={requestModalShown}
        closeModal={() => setRequestModalShown(false)}
      />
    );

  if (!hasPermission('COPILOT_BUY_PACKAGE') || !appOptions.value?.billingEnabled)
    return (
      <Modal title={t(`Billing:Modal:title:org`)} isOpen={isShown} onClickCloseIcon={close} size='md'>
        {t(`Billing:Modal:info:${currentUser.value?.organization ? 'org' : 'admin'}`)}
      </Modal>
    );

  return (
    <Modal
      title={t(`Billing:Modal:title${type === 'outOfTokens' ? `:${type}` : ''}`, { balance: balanceToDisplay })}
      isOpen={isShown}
      onCancelClick={close}
      buttonSubmitText={t(selectedPackage === 'corporate' ? 'Billing:Modal:continue' : 'Billing:Modal:buy')}
      buttonCancelText={t('cancel')}
      onActionClick={buyPackageLocal}
      disableActionButtonAutoFocus
      inProgress={loading || buyLoading}
      className='mobileBottomModal'
    >
      {!loading && type === 'outOfTokens' && (
        <p>
          {t('Billing:Modal:info', {
            balance: balanceToDisplay,
            tokenString: localize.translateWithPlural('jayToken', Number(balanceToDisplay)),
            productName: t('defaultProductName'),
          })}
        </p>
      )}
      <div className={styles.packages}>
        {packages.map((pack, index) => (
          <PackageBlock
            pack={pack}
            dataTestId={`Account:package_${pack.name}`}
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
            key={pack.name}
          />
        ))}
      </div>
      {isTovie ? null : (
        <a
          href={`${t('docsUrl')}/pricing/`}
          rel='noopener noreferrer'
          target='_blank'
          className={styles.packages__bottomLink}
        >
          {t('Billing:Modal:sub')}
          <Icon name='farExternalLink' size='sm' />
        </a>
      )}
    </Modal>
  );
});

export default BillingModal;
